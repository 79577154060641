import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IHttpResponse } from "angular";
import { AuthScopes } from '@app/ajs-upgraded-providers';
import { DialogService, WizardComponent } from '@app/components/index';
import { AuthService } from '../../../features/auth';
import {
  ApiClientCreateParams,
  ApiClientWithSecret,
  CredentialRole,
  CredentialType
} from '@app/features/credentials/credentials.interface';
import { CredentialsService } from '@app/features/credentials/credentials.service';
import { VMEncryptionServiceBindingResponse } from '@app/features/gem-services/cloud/protectV/wizard/protectV.interface';
import { ServiceBrokerService } from "../../services";

// These permissions appear in the list for every type of service.
const defaultPermissions = [
  'Create, regenerate & delete service credentials',
];

const finalStep = 1;

/**
 * Wizard that creates new credentials then shows a summary page displaying the created
 * credentials.
 *
 * This is implemented by having the wizard close itself after leaving Step 0, make the creation
 * call, then reopen itself on Step 1 with the result.
 */
@Component({
  selector: 'new-credentials-wizard',
  templateUrl: './new-credentials-wizard.component.html',
  styleUrls: ['./new-credentials-wizard.component.scss']
})
export class NewCredentialsWizardComponent extends WizardComponent implements OnInit {
  @Input() credentialsNamePrefix: string;

  @Input() credentialType: CredentialType = CredentialType.Service;

  @Input() role: CredentialRole;

  /** required when `credentialType === 'Service'`. Not applicable otherwise */
  @Input() serviceId: string;

  /** required when `credentialType === 'Service'`. Not applicable otherwise */
  @Input() serviceName: string;

  /** required when `credentialType === 'Service'`. Not applicable otherwise */
  @Input() serviceType: string;

  /** Additional use-case-specific permissions to show on the 'permissions' step */
  @Input() extraPermissions: string[] = [];

  /** True if this wizard is finishing up and we should jump straight to the summary step. */
  @Input() gotoSummary = false;

  /** When `gotoSummary == true` this holds the created credentials */
  @Input() createdCreds: ApiClientWithSecret = {} as ApiClientWithSecret;

  /** use the service broker binding call to generate credentials **/
  @Input() useServiceBroker = false;

  credsNameSuffix = '01'; // TODO programatically determine number suffix
  credentialsName: string;

  constructor(private AuthService: AuthService,
              @Inject(AuthScopes) private AuthScopes: any,
              private activeModal: NgbActiveModal,
              private dialogService: DialogService,
              private credentialsService: CredentialsService,
              private serviceBrokerService: ServiceBrokerService,) {
    super(activeModal);
  }

  ngOnInit() {
    if (this.gotoSummary) {
      this.currentStepIdx = finalStep;
    }

    this.credentialsName = this.defaultName();
  }

  defaultName() {
    if (!this.credentialsNamePrefix) {
      return `Creds_${this.credsNameSuffix}`;
    }
    return `${this.credentialsNamePrefix}_Creds_${this.credsNameSuffix}`;
  }

  get step() {
    return this.currentStepIdx;
  }

  // Override step transition to create the credentials when entering step 2
  set step(step: number) {
    if (step === finalStep && !this.gotoSummary) {
      this.createCredentials();
    } else {
      this.currentStepIdx = step;
    }
  }

  getTitle() {
    let type = this.credentialType;
    if (this.credentialType === CredentialType.SPAdmin){
      type = CredentialType.Platform
    }
    return `Generate ${type} Credentials`;
  }

  getCreateParams(): ApiClientCreateParams {
    return {
      name: this.credentialsName,
      role: this.getTypeOfCredential(),
      serviceIds: this.serviceId ? [this.serviceId] : [],
    };
  }

  /**
   * this method returns the type of role of the credential
   * a service credential can maintain a single service
   * a app owner credential can maintain all services within a subscriber group
   * @return CredentialRole the type of credential that will be created
   */
  getTypeOfCredential(): CredentialRole {
    if (this.credentialType === CredentialType.Service) {
      return CredentialRole.service;
    } else if (this.credentialType === CredentialType.SPAdmin) {
      return CredentialRole.spadmin
    } else if (this.AuthService.hasScope(this.AuthScopes.owner)) {
      return CredentialRole.appowner;
    }

    throw new Error('Error occurred with type of credential.  Bad parameters');
  }

  createCredentials() {
    this.close();

    const progress = this.dialogService.progress("Creating Credentials...");
    const createParams: ApiClientCreateParams = this.getCreateParams();
    let promise;

    if (this.useServiceBroker) {
      promise = this.serviceBrokerService.bindServiceInstance<VMEncryptionServiceBindingResponse>(createParams.serviceIds[0], {
        name: createParams.name,
        bindingParams: {
          dpod_service_id: createParams.serviceIds[0],
        }
      }).then((response: IHttpResponse<VMEncryptionServiceBindingResponse>) => {

        const {client_id: clientId, client_secret: clientSecret} = response.data.credentials;

        // format this in a way to work with the summary step of the wizard
        const summaryCreds: ApiClientWithSecret = {
          id: null,
          clientId,
          clientSecret,
          name: createParams.name,
          createdAt: null,
          createdBy: null,
          role: CredentialRole.service,
          serviceIds: [],
        };

        this.serviceBrokerService.resync();
        return this.openSummary(summaryCreds);
      });
    } else {
      promise = this.credentialsService.create(createParams)
        .then((createdCreds: ApiClientWithSecret) => this.openSummary(createdCreds));
    }

    promise.finally(progress.close.bind(progress));

    return promise;
  }

  openSummary(createdCreds: ApiClientWithSecret) {
    // Reopen ourself and show the creds
    const opts = {windowClass: 'modal-wide'};
    const wizard = this.dialogService.open<NewCredentialsWizardComponent>(NewCredentialsWizardComponent, opts).componentInstance;
    wizard.gotoSummary = true;
    wizard.serviceName = this.serviceName;
    wizard.serviceType = this.serviceType;
    wizard.credentialType = this.credentialType;
    wizard.createdCreds = createdCreds;
  }

  finish() {
    this.close();
  }

  getSummaryInstructions() {
    let type = this.credentialType;
    if (this.credentialType === CredentialType.SPAdmin){
      type = CredentialType.Platform
    }
    return `Here are your ${type.toLowerCase()} credentials.`;
  }

  getPermissions() {
    if (this.credentialType === "Service") {
      return this.extraPermissions.concat(defaultPermissions);
    }

    return this.extraPermissions;
  }
}
