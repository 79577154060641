<wizard [title]="modalTitle" (submit)="finish()">

    <terms-of-service-step name="Service Information" instructions="Service Information"
                           [serviceTypeName]="serviceTypeName"
                           [subscriptionInfo]="tile.subscriptionInfo">
    </terms-of-service-step>

    <configure-step name="Configure Service" instructions="Give your Service a name"
        (serviceName)="serviceName = $event"
        (deviceType)="deviceType = $event"
        [servicePlan]="servicePlan"
    ></configure-step>

    <summary-step name="Summary" instructions="Review your configuration" [serviceName]="serviceName"></summary-step>

</wizard>
