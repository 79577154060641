import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { isEqual } from 'lodash';
import { Subscription } from "rxjs";
import { DialogService } from "../../components";
import { NewCredentialsWizardComponent } from "../../shared/components/new-credentials-wizard/new-credentials-wizard.component";
import { ApiClientUI, CredentialType } from "./credentials.interface";
import { CredentialsService } from "./credentials.service";
import {AuthScopes} from '../../ajs-upgraded-providers';
import { AuthService } from '../auth';

@Component({
  selector: "credentials",
  templateUrl: "./credentials.component.html",
})
export class CredentialsComponent implements OnInit, OnDestroy {

  readonly CredentialType = CredentialType;

  resourcePrompt = '';

  serviceCredentials: ApiClientUI[] = [];
  platformCredentials: ApiClientUI[] = [];
  serviceCredentialsLoaded = false;
  private sub: Subscription;

  constructor(private authService: AuthService,
              @Inject(AuthScopes) private authScopes: any,
              public credentialsService: CredentialsService,
              public dialogService: DialogService,
  ) {}

  ngOnInit() {
    // Resync all creds
    const credsService = this.credentialsService;
    credsService.resync()
    .then((creds: ApiClientUI[]) => {
      this.serviceCredentials = credsService.getAllService();
      this.platformCredentials = credsService.getAllPlatform();
      this.serviceCredentialsLoaded = true;

      // subscribe for updates
      this.sub = credsService.subscribe((s: any) => {
        this.serviceCredentials = this.compare(this.serviceCredentials, credsService.getAllService());
        this.platformCredentials = this.compare(this.platformCredentials, credsService.getAllPlatform());
      });
    });

    // set resource prompt
    if(this.authService.hasScope(this.authScopes.spadmin)){
      this.resourcePrompt = 'These credentials will allow a client to manage tenant accounts, control what services tenants can see and deploy, and generate tenant reports.';
    }
    else{
      this.resourcePrompt = 'Platform credentials will allow a client to manage services, service clients and service-level credentials.';
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /**
   * Returns the new value if it differs from the old value.
   */
  compare(oldValue: ApiClientUI[], newValue: ApiClientUI[]) {
    if (isEqual(oldValue, newValue)) {
      return oldValue;
    }
    return newValue;
  }

  createPlatformCredentials() {
    const opts = { windowClass: "modal-wide" };
    const wizard = this.dialogService.open<NewCredentialsWizardComponent>(NewCredentialsWizardComponent, opts).componentInstance;

    if(this.authService.hasScope(this.authScopes.spadmin)){
      wizard.credentialsNamePrefix = "SP_Platform";
      wizard.credentialType = CredentialType.SPAdmin;
      wizard.extraPermissions = [
        "Create a customized onboarding experience for new tenant accounts",
        "Create & manage tenant accounts",
        "Change tenant account branding",
        "Reset tenant account admin passwords",
        "Generate reports",
      ];
    }
    else{
      wizard.credentialsNamePrefix = "Platform";
      wizard.credentialType = CredentialType.Platform
      wizard.extraPermissions = [
        "Create, view, edit & delete services",
        "Create & delete service clients",
        "Create & delete service credentials",
      ];
    }

  }

  hasCredentials() {
    return this.serviceCredentialsLoaded && this.serviceCredentials.length;
  }

}
