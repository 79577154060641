<div class="details-panel main-panel card" [attr.data-secret-type]="this.secretType">

    <div class="card-header">
        <div class="gem-header panel-header float-left">{{getSecretType()}} Secrets</div>

        <div *ngIf="canEdit && !updating" class="float-right rotation-toggle-switch">
            <div class="d-inline color-primary">Automatic Rotation</div>

            <div class="float-right ml-1">

                <toggleable-switch (click)="togglePolicy(enabled)" [(val)]="enabled"></toggleable-switch>

                <label class="label-status toggle-rotation" (click)="togglePolicy()" [ngClass]="enabled ? 'on' : 'off'">
                    <span class="off">OFF</span>
                    <span class="on">ON</span>
                </label>
            </div>

        </div>
    </div>

    <div class="card-block">

        <div *ngIf="!disabled">

            <div *ngIf="!updating">

                <div *ngIf="enabled">

                    <alert-with-icon *ngIf="showRotationDateText()" class="alert-custom-info rotation-date-text mb-4"
                                     [icon]="'#info-round'">
                        <div [innerHTML]="getRotationDateText()"></div>
                    </alert-with-icon>

                    <div *ngIf="rotationWillHappen()[0] && editing">
                        <alert-with-icon class="rotation-imminent-warning" [icon]="'#bang-triangle'"
                                         [class]="'alert-custom-warning'">
                            <strong>Your {{getSecretType()}} secrets will be rotated today.</strong>
                            <span [innerHTML]="rotationWillHappen()[1]"></span>
                        </alert-with-icon>
                    </div>

                    <div class="mb-3">
                        Rotate {{getSecretType()}} secrets after a usage period of
                    </div>

                    <div *ngIf="editing">

                        <div class="form-inline">

                            <select name="intervalSelection"
                                    [(ngModel)]="intervalSelection"
                                    (ngModelChange)="updateIntervalValues($event)" class="form-control">
                                <option value="3 months">3 months</option>
                                <option value="6 months">6 months</option>
                                <option value="12 months">12 months</option>
                                <option value="24 months">24 months</option>
                            </select>

                        </div>
                    </div>

                    <div *ngIf="!editing && getKeyRotationText() !== null">
                        <strong class="current-interval"><span>{{getKeyRotationText()}}</span><span
                            [ngPlural]="getRotationIntervalValue()"><ng-template
                            ngPluralCase="1"></ng-template><ng-template ngPluralCase="other">s</ng-template></span>
                        </strong>
                    </div>

                    <br/>
                    <hr/>

                    <div class="btn-group">

                        <div *ngIf="editing">
                            <button (click)="savePolicy(keyRotationFields)" name="save-schedule"
                                    class="btn btn-next">Save Schedule
                            </button>
                            <button (click)="cancelSetKeyRotationPolicy()" name="cancel-schedule"
                                    class="btn btn-default">
                                Cancel
                            </button>
                        </div>

                        <div *ngIf="!editing">
                            <button *ngIf="canEdit" (click)="editPolicy()" name="edit-schedule" class="btn btn-next">
                                Edit
                                Schedule
                            </button>
                        </div>

                        <gem-feedback class="feedback ml-2" text="Changes Saved!" [(trigger)]="rotationPolicySaved"></gem-feedback>

                    </div>

                </div>


                <div *ngIf="!enabled" class="no-rotation-policy-set">

                    <div *ngIf="canEdit">

                        <h6 class="color-primary">Automatic rotation for {{getSecretType()}} secrets is off.</h6>
                        <p>
                            Toggle it on to set a schedule.
                        </p>

                    </div>

                    <div *ngIf="!canEdit">
                        <h5>A rotation policy has not been set for this secret type.</h5>
                        Only Application Owners can set a rotation schedule.
                    </div>

                </div>

            </div>


            <div class="text-center details-panel-section updating-block" *ngIf="updating">
                <spinner class="d-inline-block align-middle"></spinner>
                <h4 class="d-inline align-middle">Updating...</h4>
            </div>

        </div>
    </div>
</div>
