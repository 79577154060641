<button class="card"
    [ngClass]="{ 'body-clickable': bodyClickable }"
    [attr.title]="tooltip"
    [disabled]="disabled"
    (click)="onClick($event)"
    tabindex="{{ bodyClickable ? 0 : -1 }}"
>
    <div class="row no-gutters card-top">
        <div class="col-12">
            <img *ngIf="imageUrl" class="card-icon" src="{{imageUrl}}" alt="" />
        </div>
    </div>

    <div class="row no-gutters card-middle">
        <div class="col-12">
            <h6 class="card-title mb-2">{{cardTitle}}</h6>
            <ng-content select="[content]"></ng-content>
        </div>
    </div>

    <!--
        The stopPropagation() prevents clicks inside the actions area from bubbling up & firing
        the click listener on the main body.
    -->
    <div class="row card-bottom d-flex justify-content-center align-items-end" (click)="$event.stopPropagation()">
        <ng-content select="[actions]"></ng-content>
    </div>
</button>
