<ngb-tabset type="pills">
    <ngb-tab>
        <ng-template ngbTabTitle>
            <div data-locator="tab-service-clients">Service Clients</div>
        </ng-template>
        <ng-template ngbTabContent>
            <cloud-hsm-clients-table
                [clients]="getClients()"
                (create)="addClient()"
                [service]="service"
                (download)="downloadClient($event)"
                (delete)="deleteClient($event)">
            </cloud-hsm-clients-table>
        </ng-template>
    </ngb-tab>

    <ngb-tab>
        <ng-template ngbTabTitle>
            <div data-locator="tab-credentials">Credentials</div>
        </ng-template>
        <ng-template ngbTabContent>
            <credentials-section
                [service]="service"
                [permissions]="['Create, regenerate & delete service clients']"
            ></credentials-section>
        </ng-template>
    </ngb-tab>
</ngb-tabset>

