import { FeatureToggles } from "../../../feature-toggles";
import { HSMonDemandCreatedService } from "../cloudHSM.model";
import { DocsService } from "@app/shared/services";
import { ServiceShortCode } from "@app/features/gem-services/services.constants";

const template = require('./cloudHSM-details.component.html').default;
const cloudHSMDetailsModule = require('./_init').default;

class CloudHSMController {
  private service: HSMonDemandCreatedService;
  private openDownloadClientBundleSection = false;

  // both dependencies being used within the template
  constructor(private FeatureToggles: FeatureToggles, private DocsService: DocsService) {
  }

  $onInit() {
    if (!this.service) {
      return;
    }
  }

  isP2PEService(): boolean {
    return this.service.serviceType === ServiceShortCode.p2pe;
  }

}

CloudHSMController.$inject = ["FeatureToggles", "DocsService"];

cloudHSMDetailsModule.component('cloudHsmDetails', {
  template,
  bindings: {
    service: '<',
    openDownloadClientBundleSection: '<',
  },
  controller: CloudHSMController,
});
