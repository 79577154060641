import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { WizardStep } from '@app/components';
import { SecretStatus } from '@app/features/gem-services/cloud/salesforce/salesforce.common';
import { sfdcTypes } from "@app/features/gem-services/cloud/salesforce/salesforce.constants";

@Component({
  selector: 'configure-step',
  templateUrl: './configure.step.component.html',
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => ConfigureStepSalesforceComponent)}
  ]
})
export class ConfigureStepSalesforceComponent extends WizardStep {

  @Input() service = {
    name: '',
  };
  @Input() secretsStatusList: SecretStatus[] = [];
  showGenerateTenantSecret = false;
  // this value will be updated as the child component `generateMultipleSecretTypes` is updated
  // we convert it to string until we get the time to convert the details page to also use this new `SecretStatus` interface
  generateMultipleSecretsList: string[] = [];

  constructor() {
    super();
  }

  /**
   * Updates the secretsStatusList on whether secrets should be generated or not
   * @param event   event on whether secrets should be generated or not
   */
  generateSecrets(event: boolean): void {
    if (event) {
      if (!this.generateMultipleSecretsList.length) {
        // if the user selects to generate secrets and they have none selected, we immediately check the "Data" box
        this.secretsStatusList.find(item => item.value === sfdcTypes.Data).generate = true;
        this.generateMultipleSecretsList = [sfdcTypes.Data];
      }

      this.secretsToGenerateChanged(this.generateMultipleSecretsList);
    } else {
      this.secretsToGenerateChanged([]);
    }
  }

  /**
   * Called from child component which updates what secrets to generate
   * @param secretValuesToGenerate  only contains the values of secrets that will be generated
   */
  secretsToGenerateChanged(secretValuesToGenerate: string[]): void {
    this.secretsStatusList.forEach(item => {
      // if we find it in the argument, we convert it to boolean and we set the generate value
      const sec: string | undefined = secretValuesToGenerate.find(str => str === item.value);
      item.generate = !!sec;
    });
  }

}
