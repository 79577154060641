<dpod-table (filter)="onFilter($event)" [dataLength]="dataSource.data.length">
    <table #table mat-table matSort [dataSource]="dataSource">
        <caption class="d-none">List of user accounts</caption>

        <!-- Email (username) column -->
        <ng-container matColumnDef="username">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Email Address</th>
            <td mat-cell *matCellDef="let user" class="username-column">
                <a class="gem-link" [uiSref]="getEditState()" [uiParams]="{ id: user.id }">{{user.username}}</a>
            </td>
        </ng-container>

        <!-- Account Role column -->
        <ng-container matColumnDef="accountRole">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let user" class="role-column">
                {{ getAccountRole(user) }}
            </td>
        </ng-container>

        <!-- Subscriber Group column -->
        <ng-container matColumnDef="subscriberGroup">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Subscriber Group</th>
            <td mat-cell *matCellDef="let user" class="subscriber-group-column">
                {{ getSubscriberGroup(user) }}
            </td>
        </ng-container>

        <!-- Given Name column -->
        <ng-container matColumnDef="givenName">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>First Name</th>
            <td mat-cell *matCellDef="let user" class="given-name-column">
                {{ user.givenName }}
            </td>
        </ng-container>

        <!-- Family Name column -->
        <ng-container matColumnDef="familyName">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Last Name</th>
            <td mat-cell *matCellDef="let user" class="family-name-column">
                {{ user.familyName }}
            </td>
        </ng-container>

        <!-- Created By column -->
        <ng-container matColumnDef="createdByUsername">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
            <td mat-cell *matCellDef="let user" class="created-by-column">
                {{ user.createdByUsername }}
            </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let user" class="actions-column">
                <div class="action-cell text-center">
                    <div ngbDropdown class="gem-dropdown">
                        <span role="button" tabindex="0" class="grid-action" ngbDropdownToggle id="user-actions-{{user.id}}">
                            <svg class="icon-svg">
                                <use xlink:href="#dropdown" />
                            </svg>
                        </span>

                        <ul class="dropdown-menu gem" ngbDropdownMenu role="menu"
                            [attr.aria-labelledby]="'user-actions-' + user.id">
                            <li *ngIf="!isLoggedInUserRow(user.username)" class="dropdown-item reset-password"
                                data-locator="reset-password"
                                (click)="resetUserPassword(user.id)">Reset User Password</li>
                            <li *ngIf="isLoggedInUserRow(user.username)" class="dropdown-item change-password"
                                data-locator="change-password"
                                (click)="changePassword()">Change Password</li>
                            <li class="dropdown-item reset-mfa"
                                data-locator="reset-mfa"
                                (click)="resetMfaToken(user.id)">Reset MFA Token</li>
                            <li *ngIf="canDeleteUser(user)" class="dropdown-item delete-user"
                                data-locator="delete"
                                (click)="deleteUser(user.id)">Delete User</li>
                        </ul>
                    </div>
                </div>
            </td>
        </ng-container>

        <!-- Rows definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let user; columns: displayedColumns;" [attr.data-username]="user.username">
        </tr>
    </table>
</dpod-table>
