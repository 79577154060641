import { Pipe, PipeTransform } from '@angular/core';
import { DocsService } from '../services';

/**
 * Send a string to this pipe and it will be turned into an absolute link to the
 * documentation website.
 *
 * If the URL exists in portal-links.json, consider using the docLinkById pipe
 *
 * Example:
 * ```html
 *  <a href="{{ '/dpod/services/luna_cloud_hsm/service/quick_start/index.html' | docLink }}">Click here for more info</a>
 * ```
 *
 * will return the `DOCS_BASE_URL + /dpod/services/luna_cloud_hsm/service/quick_start/index.html`
 * ex. https://thalesdocs.com/dpod/services/luna_cloud_hsm/service/quick_start/index.html
 */
@Pipe({ name: 'docLink' })
export class DocLinkPipe implements PipeTransform {
  constructor(private DocsService: DocsService) { }

  transform(value: string): string {
    return this.DocsService.getDocsLink(value);
  }
}
