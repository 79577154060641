<wizard [title]="modalTitle" [lastSlideClose]="true" [(currentStepIdx)]="step" (submit)="finish()">

    <terms-of-service-step name="Service Information" instructions="Service Information"></terms-of-service-step>

    <configure-step name="Configure your Service" [service]="service" [credentials]="credentials"
                    instructions="Give your service a name."></configure-step>

    <wizard-step name="Review your configuration"
                 instructions="When configuring your ProtectV server, authenticate Data Protection On Demand using the credentials provided below.">

        <summary-step
            [serviceName]="service.name"
            [credentialsName]="credentials.credentialsName"
            [apiClientId]="apiClientId"
            [serviceType]="serviceType"
            [credentialType]="credentialType"
            [apiClientSecret]="apiClientSecret"></summary-step>

    </wizard-step>

</wizard>
