import { Component, EventEmitter, Input, Output } from '@angular/core';
import {sfdcTypes} from '../salesforce.constants';

type secret = {
  val: string;
  name: string;
}

// takes the Salesforce secret types and sorts by `Data` `being first
function shiftDataSecretToStart(): secret[] {
  return Object.entries(sfdcTypes)
    .sort(entry => entry[0] === 'Data' ? -1 : 0) // make Data as the 1st entry
    .map(([val, name]: [string, string]) => ({ val, name }));
}

@Component({
  selector: 'generate-multiple-secret-types',
  templateUrl: './generate-multiple-secret-types.component.html',
})
export class GenerateMultipleSecretTypesComponent {

  @Input() selectedSecrets: string[] = [];
  @Output() selectedSecretsChange: EventEmitter<string[]> = new EventEmitter();

  sfdcTypes: secret[] = shiftDataSecretToStart();

  isChecked(tenantSecretType): boolean {
    return this.selectedSecrets.includes(tenantSecretType);
  }

  toggleTenantSecret(tenantSecretTypeValue: string): void {
    if (this.isChecked(tenantSecretTypeValue)) {
      const index = this.selectedSecrets.findIndex(item => item === tenantSecretTypeValue);
      this.selectedSecrets.splice(index, 1);
    } else {
      this.selectedSecrets.push(tenantSecretTypeValue);
    }
    this.selectedSecretsChange.emit(this.selectedSecrets);
  }

}
