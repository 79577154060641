import { parseError } from '@app/shared/services/global-error-handler';
import { DismissalError } from '@app/components';

/**
 * This decorator renders an uncaught AngularJS exception in a basic error dialog.
 */
function GlobalErrorDecorator($delegate, $injector) {

  let dialogService;
  let isActive;

  return function (exception, cause) {
    if (!dialogService) {
      // inject at runtime to avoid circular dependency
      dialogService = $injector.get('DialogService');
    }

    try {
      // Take care to only ever show 1 dialog at a time. This prevents an infinite loop if
      // the dialog code itself throws an exception and causes us to get called again
      const errorObj = parseError(exception);
      if (!isActive && shouldShow(errorObj)) {
        isActive = true;
        const modal = dialogService.error(errorObj);
        modal.finally(() => isActive = false);
      }
    } finally {
      // Delegate to other handlers (e.g. $log)
      $delegate(exception, cause);
    }
  };
}
GlobalErrorDecorator.$inject = ['$delegate', '$injector'];

function shouldShow(err) {
  return err.message !== "Session expired" && err.code !== DismissalError.code;
}

export default GlobalErrorDecorator;
