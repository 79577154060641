import { Component, Inject, Input } from '@angular/core';
import { CredentialType } from "../../../../features/credentials/credentials.interface";

@Component({
  selector: 'service-credential-summary',
  templateUrl: './service-credential-summary.component.html',
})
export class ServiceCredentialSummaryComponent {

  @Input() serviceName: string;
  @Input() credentialsName: string;
  @Input() credentialType: CredentialType;
  @Input() serviceType: string;

  @Input() apiClientId: string;
  @Input() apiClientSecret: string;

  copiedId = false;
  copiedSecret = false;

  constructor() {
  }

  isServiceCredentials(): boolean {
    return this.credentialType === "Service";
  }

  hideAllCopiedMessages(): void {
    this.copiedId = false;
    this.copiedSecret = false;
  }

}
