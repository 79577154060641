import {NgModule} from '@angular/core';
import {StepsModule} from '@app/components/steps';
import {EvalConvertComponent} from '@app/features/eval-convert/eval-convert.component';
import {convertStartState} from '@app/features/eval-convert/eval-convert.constants';
import {EvalConvertTermsComponent} from '@app/features/eval-convert/eval-convert.terms.component';
import {TileSelectComponent} from '@app/features/eval-convert/tile-select/tile-select.component';
import {SharedModule} from '@app/shared';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';

import * as AuthScopes from '../auth/roles.constants';
import {EvalConvertServicesComponent} from './eval-convert.services.component';
import {EvalConvertSummaryComponent} from "@app/features/eval-convert/eval-convert-summary.component";
import {EvalConvertSubmittedModalComponent} from "@app/features/eval-convert/submitted-modal/eval-convert-submitted.modal.component";
import {EvaluationConvertFooterComponent} from "@app/features/eval-convert/evaluation-convert-footer";

export const states = [{
  name: convertStartState,
  params: {
    hidePurplePacketFooter: true,
    requiresRole: AuthScopes.admin,
    serviceType: '',
    servicePlans: null
  },
  component: EvalConvertComponent,
}];

@NgModule({
  declarations: [
    EvalConvertComponent,
    EvalConvertServicesComponent,
    EvalConvertTermsComponent,
    EvalConvertSummaryComponent,
    TileSelectComponent,
    EvalConvertSubmittedModalComponent,
    EvaluationConvertFooterComponent,
  ],
  imports: [
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states,
    }),
    StepsModule,
  ],
  providers: [],
  entryComponents: [
    EvalConvertComponent,
    EvalConvertServicesComponent,
    EvalConvertTermsComponent,
    EvalConvertSummaryComponent,
    EvalConvertSubmittedModalComponent,
    EvaluationConvertFooterComponent,
  ],
})
export class EvalConvertModule {
}
