import { IHttpService } from "angular";
import { DownloadService } from "@app/shared/services/download.service";
import { HSMonDemandClient, HSMonDemandCreatedService } from "./cloudHSM.model";
import { ServiceCreateParams } from "./wizard/cloudHSM.interface";
import { TilesService } from '@app/shared/services/tiles.service';
import { Tile } from '@app/features/marketplace/tiles.interface';
import { AuthService } from '../../auth';
import { Injectable, Inject } from '@angular/core';
import { Haro } from '@app/shared/services/haro.service';
import { HttpService, AuthScopes } from '@app/ajs-upgraded-providers';
import { ServiceCommonClass } from "@app/shared/services/service-common.class";
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

const encode = encodeURIComponent;

export const BASE_URL = "/v1/services";
export const BASE_URL_SVC_INSTANCES = "/v1/service_instances";

/**
 * TODO: instead of casting IPromises to Promise<T> in this file, we should wrap the
 * calls with Promise.resolve(). This requires moving the unit tests to Angular first.
 */
@Injectable({
  providedIn: 'root'
})
export class HSMService extends ServiceCommonClass {

  constructor(
    @Inject(Haro) haro: any,
    @Inject(HttpService) protected http: IHttpService,
    @Inject(AuthScopes) private authScopes: any,
    private downloadService: DownloadService,
    protected tilesService: TilesService,
    private authService: AuthService,
  ) {
    super(haro, http, BASE_URL, tilesService);

    // don't automatically retrieve tiles or services for spadmin/operator
    // do not have permissions
    if (!this.authService.hasScope(this.authScopes.spadmin) && !this.authService.hasScope(this.authScopes.operator)) {
      this.reannotateServices();
    }

  }

  getKey() {
    return "service_id";
  }

  annotate(service): HSMonDemandCreatedService {
    service.clients.forEach(c => c.formattedCreatedAt = ServiceCommonClass.formatDate(c.createdAt));
    return super.annotate(service);
  }

  /**
   * Creates a service.
   *
   * @deprecated Use ServiceBrokerService.create() instead
   */
  doCreate(params: ServiceCreateParams): Promise<any> {
    // tileId replaces serviceType
    delete params.serviceType;
    return super.doCreate(params);
  }

  doSave(): Promise<any> {
    throw new Error("Not supported");
  }

  /**
   * @override Fetch list of services.
   *
   * This override method will cause the GET requests to use 'v1/service_instances' instead of the deprecated 'v1/services'.
   */
  doResync(): Promise<any> {
    return Promise.resolve(this.http.get(BASE_URL_SVC_INSTANCES));
  }

  /**
   * Gets client key material that is used to communicate with the service
   *
   * @param id    id of the service
   * @param name  name of the client
   * @param os    OS the client bundle will be created for
   * @returns Resolves to true if the client bundle was downloaded, false otherwise
   */
  bind(id: string, name: string, os: "linux" | "windows" = "linux"): Promise<boolean> {
    console.log("calling bind...");
    return this.http.put(`${this.baseUrl}/${encode(id)}/client/`, { name, os }, { responseType: "arraybuffer" })
      .then(response => {

        let type = "application/zip",
          fileName = `setup-${name}.zip`;

        // if the OS type is windows the download is an executable
        if (os === "windows") {
          type = "application/vnd.microsoft.portable-executable";
          fileName = `setup-${name}.exe`;
        }

        const file = new Blob([response.data as ArrayBuffer], {
          type,
        });

        this.downloadService.downloadFile(file, fileName, 'client');

        // update our cache with the new client name (if it doesn't already exist)
        const service = this.get(id);
        const clientId = response.headers("Location").split("/").pop();
        if (!service.clients.some(s => s.name === name)) {
          const now = new Date().toISOString();
          service.clients.push({
            name,
            os,
            id: clientId,
            createdAt: now,
            formattedCreatedAt: ServiceCommonClass.formatDate(now),
          });
        }
        this.set(service);

        return true;
      },
        response => {
          // Something went wrong. Try a conversion of the response data to a JSON error body...
          // This is based on https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
          // Could probably use some improvements...
          let jsonError;
          try {
            jsonError = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.data)));
          } catch (err) {
            // Failed to parse the response data as a JSON so just return a generic error.
            throw new Error("Operation failed. Contact your administrator.");
          }
          throw jsonError;
        }) as Promise<boolean>;
  }

  /**
   * Removes a client binding from the service. That client will no longer be able to
   * communicate with the service.
   *
   * @param id - The id of the service.
   * @param clientId The id of the client.
   * @returns The updated service
   */
  unbind(id: string, clientId: string): Promise<HSMonDemandCreatedService> {
    const url = `${this.baseUrl}/${encode(id)}/client/${encode(clientId)}`;
    console.log(`Unbind service  id: ${clientId} url: ${url}`);
    return this.http.delete(url)
      .then(() => {
        const service = this.get(id);
        const index = service.clients.findIndex(c => c.id === clientId);
        // remove the client name from our cache
        if (index !== -1) {
          service.clients.splice(index, 1);
        }
        // using the overwrite flag because removing an element from clients
        // and modifying the record in-place results in duplicated client
        // entries.  possibly a bug in haro?
        return this.store.set(null, service, false, true);
      }) as Promise<HSMonDemandCreatedService>;
  }

  /**
   * Gets the client with the given name that's bound to the service with the given id
   * @param name Client name
   * @param id  Service id
   */
  getClientByName(id, name): HSMonDemandClient {
    const service = this.get(id);
    return service ? service.clients.find(c => c.name === name) : null;
  }
}
