import {Component, Input} from '@angular/core';
import {NgClass} from '@angular/common';

// Shows the input `value` if it is truthy, otherwise shows "Not Provided"
@Component({
  selector: 'optional',
  templateUrl: './optional.component.html',
})
export class OptionalComponent {
  @Input()
  value: string;
}
