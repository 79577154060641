import { Component, ElementRef, Inject, Injector, Input, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { FEATURE_TOGGLES, FeatureToggles } from "../../feature-toggles";

/**
 * Wraps the AngularJS `serviceDetails` component so it can be used from Angular.
 *
 * TODO migrate functionality into this component, it should eventually replace serviceList
 */
@Component({
  selector: 'gem-service-details',
  template: '',
  styleUrls: ["./details.scss"],
})
export class ServiceDetailsComponent extends UpgradeComponent implements OnInit, OnDestroy {
  @Input() id: string;
  // this is specifically for HSMoD
  // if true, the download client section will render open
  @Input() openDownloadClientBundleSection = false;

  constructor(elementRef: ElementRef, injector: Injector, @Inject('service_id') serviceId: string) {
    super('serviceDetails', elementRef, injector);
    this.id = serviceId;
  }

  ngOnInit() {
    // Manually trigger an update of serviceDetails' `id` binding by sending a change for it.
    // UpgradeComponent listens for change events only
    super.ngOnChanges({
      openDownloadClientBundleSection: new SimpleChange(undefined, this.openDownloadClientBundleSection, true),
      id: new SimpleChange(undefined, this.id, true)
    });
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
