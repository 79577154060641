import * as angular from 'angular';

// displays success/error icon depending on whether the element is valid or invalid.
// note: this directive does not have isolate scope.  Each element MUST have a dynamic name
// <input name="example" validation-icon>
export default function ValidationIconComponentFactory($compile) {
    return {
      require: 'ngModel',
      link: (scope, element, attrs, ctrl) => {

        // the reason why these are ng-if and not ng-show are due to nested directives used in the wizard.
        // Using ng-show displays them for a split second because it uses CSS selectors
        const template = `
            <svg focusable="false" ng-if="validationIcon.${attrs.name}.valid && (validationIcon.${attrs.name}.ctrl.$dirty
            || validationIcon.${attrs.name}.edited)" class="icon-svg input-icon input-success">
                <use xlink:href="#check"></use>
            </svg>
            <svg focusable="false" ng-if="!validationIcon.${attrs.name}.valid && (validationIcon.${attrs.name}.ctrl.$dirty
             || validationIcon.${attrs.name}.edited)" class="icon-svg input-icon input-error">
                <use xlink:href="#warning"></use>
            </svg>`;

         element.addClass('with-icons').after($compile(angular.element(template))(scope));

         if (scope.validationIcon === undefined) {
            scope.validationIcon = {};
         }

         scope.validationIcon[attrs.name] = {
            ctrl,
            edited: false,
            valid: false,
         };

         scope.$watch(() => ctrl.$viewValue, // if input is preloaded with text, we will show validation
            val => {
               if (val) {
                  scope.validationIcon[attrs.name].edited = true;
               }
            });

         scope.$watch(`validationIcon.${attrs.name}.ctrl.$valid`, () => scope.validationIcon[attrs.name].valid = ctrl.$valid);
      },
      restrict: 'A',
   };
}
ValidationIconComponentFactory.$inject = ['$compile'];
