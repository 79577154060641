import {Component, Inject} from "@angular/core";
import {StateParams} from "@app/ajs-upgraded-providers";
import { AuthService } from "../auth";

@Component({
  selector: "session-logged-out",  // removing the selector affects CSS
  templateUrl: "./session-logged-out.html",
  styleUrls: ["./session-logged-out.scss"],
})
export class SessionLoggedOutComponent {

  private previousState: string;

  constructor(private AuthService: AuthService, @Inject(StateParams) private $stateParams) {
    this.previousState = $stateParams.expiredState;
  }

  redirectToLogin() {
    this.AuthService.login(this.previousState);
  }

}
