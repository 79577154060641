import {NgModule} from '@angular/core';
import {SharedModule} from '@app/shared';
import {LinkageComponent} from './linkage.component';
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";

import * as AuthScopes from '../auth/roles.constants';

export const linkageState = {
  name: "linkage",
  url: "/linkage",
  params: {
    hidePurplePacketFooter: true,
    requiresRole: `${AuthScopes.admin}`,
  },
  component: LinkageComponent,
};

@NgModule({
  declarations: [LinkageComponent],
  imports: [
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states: [linkageState]
    }),
  ],
  providers: [],
  entryComponents: [LinkageComponent],
})
export class LinkageModule {
}
