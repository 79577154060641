// used to invalidate an input (and the containing form) when the input's value does not
// match the value of a paired input. to pair an input, use the directive like this:
//
// <form name=myform>
//    <input name="main" ng-model="data.main"/>
//    <input name="check" ng-model="data.check" confirm-input="myform.main"/>
// </form>
//
// the value of the confirm-input tag should be set to the full *name* of the paired
// input; i.e. [form_name].[element_name] -- NOT the name of the linked ng-model.
//
// confirm-input watches the model value of the paired input. when that value
// changes, the *view* value of that input is compared to the *view* value of the
// confirmation input. if the values don't match, the confirmation field is marked
// as $invalid, and "mismatch: true" is set on the field's $error object
export default function ConfirmInputDirectiveFactory() {
   return {
      restrict: "A",
      require: "ngModel",
      scope: {
         otherValue: "=confirmInput",
      },
      link(scope, elem, attrs, ngModel) {
         ngModel.$validators.mismatch = function(modelValue, viewValue) {
            // don't reset viewValue or you"ll trigger another digest cycle
            const a = viewValue || "";
            const b = scope.otherValue.$viewValue || "";
            return a === b;
         };

         scope.$watch("otherValue.$modelValue", function() {
            ngModel.$validate();
         });
      },
   };
}
ConfirmInputDirectiveFactory.$inject = [];
