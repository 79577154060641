import { Component, Inject, OnInit } from '@angular/core';
import { AbstractTilesView } from './AbstractTilesView';
import { RequiresRoleService, TilesService } from '@app/shared/services';
import { ConfigToken } from "@dpod/gem-ui-common-ng";
import { DpodUiConfig } from "@app/core/dpod-ui-config";

/**
 * Renders marketplace tiles organized into categories.
 */
@Component({
  selector: 'tiles-create-view',
  templateUrl: './tiles-create-view.component.html',
  styleUrls: ['./tiles-view.component.scss']
})
export class TilesCreateViewComponent extends AbstractTilesView implements OnInit {
  constructor(requiresRoleService: RequiresRoleService,
              tilesService: TilesService,
              @Inject(ConfigToken) public config: DpodUiConfig) {
    super(requiresRoleService, tilesService, config);
  }
}
