import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { DialogService } from '@app/components/gem-dialogs';
import { DpodUiConfig } from '@app/core/dpod-ui-config';
import { WindowToken } from '@app/shared/services/window';
import { ConfigToken } from '@dpod/gem-ui-common-ng';
import { StateService } from '@uirouter/core';
import { LocationService } from '../../ajs-upgraded-providers';
import { AuthService } from './auth.service';

const POLL_INTERVAL = 1000 * 5; // 5 seconds

/**
 * Polls to check whether the expiry time given in the user's JWT has passed.
 * If it has, resets the `AuthService` and sends the user to the "session expired" state.
 *
 * TODO this service is only useful when using the implicit auth flow, it should be
 * deleted after we move to Hodor (session cookie)
 */
@Injectable()
export class SessionExpiryService {
  private expiryTime: number = null;
  private intervalId: number;

  constructor(
    @Inject(LocationService) private $location: ng.ILocationService, // TODO Angular location service
    @Inject(StateService) private $state: any,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WindowToken) private window: Window,
    @Inject(ConfigToken) private config: DpodUiConfig,
    private authService: AuthService,
    private zone: NgZone,
    private injector: Injector,
  ) {
    const identity = this.authService.getIdentity();
    if (identity) {
      this.expiryTime = identity.exp * 1000; // convert to milliseconds so it's comparable to Date.now()
    }
    this.startTimer();
  }

  startTimer() {
    if (this.intervalId) {
      return;
    }

    // Long running intervals can block Protractor from starting, so we schedule this
    // interval outside the Angular zone
    // http://www.syntaxsuccess.com/viewarticle/ngupgrade-and-long-running-intervals
    this.zone.runOutsideAngular(() => {
      this.intervalId = this.window.setInterval(this.check.bind(this), POLL_INTERVAL);
    });
  }

  stopTimer() {
    this.window.clearInterval(this.intervalId);
    this.intervalId = null;
  }

  check() {
    if (!this.isSessionEnded()) {
      return;
    }

    this.logout();
  }

  /**
   * Called when we detect the implicit JWT has expired, or when we get a 401 response
   * on an Ajax call.
   */
  logout() {
    this.getDialogService().dismissAll();
    this.stopTimer();

    // The action we take depends on whether we're using session (hodor) or implicit
    if (this.config.FF_USE_HODOR) {
      // Immediately redirect to the login flow. This is the least disruptive way to
      // handle an auth failure, as the user will typically have a preexisting UAA
      // cookie that avoids having to enter their creds again.
      this.authService.login();
    } else {
      this.authService.reset();
      if (this.$state.current.name !== 'sessionLoggedOut') {
        this.$state.go('sessionLoggedOut', { expiredState: this.$location.path() });
      }
    }
  }

  getDialogService(): DialogService {
    // Get DialogService lazily to avoid circular dependencies at startup
    return this.injector.get(DialogService);
  }

  isSessionEnded() {
    return typeof this.expiryTime === 'number' && Date.now() > this.expiryTime;
  }
}
