/**
 * This run block updates the UI-Router $state to include the previous state
 */
export default function stateHook($transitions, $state) {
    "ngInject";

    const hook = () => {
        $state.previous = $state.current; // before we change pages, lets save the previous state in case we need to know where we came from
    };

    $transitions.onBefore({}, hook);
}
