export * from './help-drawer.component';

// AngularJS compatibility
import {downgradeComponent} from '@angular/upgrade/static';
import {HelpDrawerComponent} from './help-drawer.component';
import * as angular from 'angular';

export default angular.module("dpaas.help-drawer", [])
    .directive('gemHelpDrawer', downgradeComponent({component: HelpDrawerComponent}))
    .name;
