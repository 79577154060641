import { Component, OnDestroy, OnInit } from "@angular/core";
import { SubscribersApi } from '@app/features/accounts/api/subscribersApi.service';
import { StateParams, StateService, UIRouterGlobals } from '@uirouter/core';
import { cloneDeep } from "lodash";
import { UsersApi } from "../../features/accounts/api/usersApi.service";
import { AuthService } from "../../features/auth";
import { ResetMfaDialogComponent } from "../../shared/components/reset-mfa-dialog/reset-mfa-dialog.component";
import { DialogService } from "../gem-dialogs";
import { ResetPasswordDialogComponent } from '../password-reset/reset-password-dialog.component';
import { User } from "@app/features/accounts/accounts.model";

const user: User = {
  accountRole: null,
  createdBy: null,
  createdByUsername: null,
  subscriberGroups: [],
  username: null,
  givenName: null,
  familyName: null,
  createdAt: null,
  id: null,
  phoneNumber: null,
  rootAdmin: null,
  emailVerificationSent: null,
  verified: null,
};

@Component({
  selector: "users-edit",
  templateUrl: "./users-edit.html",
  styleUrls: ["./users-edit.scss"],
})
export class UsersEditComponent implements OnInit, OnDestroy {

  /**
   * Whether to show the subscriber groups column
   */
  showSubscriberGroups = true;
  groups = [];
  user: User = Object.assign({}, user);
  placeholders: User = Object.assign({}, user);
  editing = false;
  saved = false;
  userSubscription = null;
  stateParams: StateParams;

  constructor(
    private stateService: StateService,
    uiRouter: UIRouterGlobals,
    protected dialogService: DialogService,
    protected usersApi: UsersApi,
    private subscribersApi: SubscribersApi,
    private authService: AuthService,
  ) {
    this.groups = this.subscribersApi.getAll();
    this.subscribersApi.resync().then(groups => this.groups = groups);
    this.editing = false;
    this.saved = false;
    this.stateParams = uiRouter.params;

    // showSubscriberGroups is true by default but overrideable via StateParams
    if (this.stateParams.showSubscriberGroups !== undefined) {
      this.showSubscriberGroups = this.stateParams.showSubscriberGroups;
    }
  }

  ngOnInit() {
    this.userSubscription = this.usersApi.subscribeTo(this.stateParams.id, (user: User) => {
      if (user) {
        this.user = cloneDeep(user);
        this.placeholders = user;
      } else {
        this.stateService.go('^');
      }
    }, true);
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  /**
   *  This method lets the HTML know whether to apply the 'page' class
   *  The 'page' class is required only for SPAdmin User details page
   *  The 'page' class is not required for Accounts User Details page as it is a nested ui-view
   *  and adding this style brings down the header causing inconsistency in UI
   * */
  hasPageClass() : boolean {
    return this.stateService.$current &&
      this.stateService.$current.name !== 'accounts.users.details';
  }

  edit() {
    this.saved = false;
    this.editing = true;
  }

  cancel() {
    this.saved = false;
    this.editing = false;
    this.user = cloneDeep(this.placeholders);
  }

  delete() {
    this.dialogService.confirm({
      title: 'Delete User?',
      content: 'This User will no longer be able to log in to Data Protection on Demand, and will lose access to their tenant space. Do you wish to continue?',
      yesLabel: 'Delete',
      noLabel: 'Cancel',
    })
      .then(() => {
        const progress = this.dialogService.progress('Deleting User...');
        this.usersApi.delete(this.stateParams.id)
          .then(() => this.stateService.go('^'))
          .catch(error => this.dialogService.error(error))
          .finally(() => progress.close());
      });
  }

  /**
   * Determines if the row is of the user logged in
   * @param {string} username  the email of the user row
   * @return boolean  returns true if the row is of the logged in user
   */
  isLoggedInUser(username: string) {
    return username === this.authService.getIdentity().email;
  }

  /**
   * Determines if this user can be deleted.  A user cannot be deleted if they are root admin or trying to delete themselves
   * @param {User} user
   * @return {boolean}  returns true if the user can be deleted
   */
  canDeleteUser(user: User): boolean {
    return !user.rootAdmin && !this.isLoggedInUser(user.username);
  }

  resetPassword() {
    const ref = this.dialogService.open<ResetPasswordDialogComponent>(ResetPasswordDialogComponent);
    ref.componentInstance.userId = this.stateParams.id;
  }

  changePassword() {
    this.dialogService.entityFn(result => {
      const {oldPassword, newPassword} = result;
      return this.usersApi.changePassword(oldPassword, newPassword);
    }, 'changeUserPassword', null /*resolves*/, 'Changing password...');
  }

  resetMfaToken() {
    const modal = this.dialogService.open<ResetMfaDialogComponent>(ResetMfaDialogComponent, { windowClass: 'mfa-modal' }).componentInstance;
    modal.tenantId = this.authService.getTenantId(); // same tenant we're logged into
    modal.userId = this.stateParams.id;
  }

  save() {
    const progress = this.dialogService.progress('Saving User...');
    this.usersApi.save(this.user).then(() => {
      this.saved = true;
      this.editing = false;
      this.placeholders = cloneDeep(this.user);
    })
      .finally(() => progress.close())
      .catch(error => this.dialogService.error(error));
  }

  getAccountRole(): string {
    switch (this.user.accountRole) {
      case 'admin': return 'Administrator';
      case 'spadmin': return 'Service Provider Administrator';
    }
    return 'Application Owner';
  }

  getCreatedBy() {
    const createdByUsername = this.user.createdByUsername;
    const createdBy = this.user.createdBy;
    if (!createdBy) {
      return 'None';
    }

    if (createdBy === "00000000-0000-0000-0000-000000000000") {
      return "admin";
    }

    if (!createdByUsername) {
      const user = this.usersApi.getAll().find(u => u.id === createdBy);
      return user ? user.username : "Unavailable";
    }
    return createdByUsername;
  }

  getSubscriberGroup() {
    if (this.user && this.user.subscriberGroups) {
      const subscriberGroup = this.groups.find(group => group.id === this.user.subscriberGroups[0]);
      return subscriberGroup !== undefined ? subscriberGroup.name : null;
    }

    return null;
  }

}
