import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {timer} from "rxjs";

/**
 * Uses a timer because visibility is triggered outside this element by example: switching tabs.
 */
@Component({
  selector: 'gem-feedback',
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent implements OnChanges {

  // if passing in the result of a function, just use `[trigger]` otherwise you'll have issues
  @Input() trigger = false;
  @Input() text: string;

  @Output() triggerChange = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.trigger && changes.trigger.currentValue) {
      const subscriber = timer(2000)
        .subscribe(() => {
          // hide and call parent component
          this.trigger = false;
          this.triggerChange.emit(this.trigger);
          subscriber.unsubscribe();
        })
    }
  }

}
