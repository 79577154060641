import module from "./_init";

/**
 * Updates the "title" tag to include the tenant name followed by a suffix
 *
 * This is pretty basic and returns the title in the form of `tenantName - applicationName`
 * May need to be worked on if to be considered a shared component
 *
 * This is a directive because other elements use a title tag
 */
module.directive("gemTitle", $location => {

    const getHostName = () => {
        let prefix = "localhost";
        const host = $location.$$host;
        if (host !== "localhost") {
            prefix = host.split(".")[0];
        }
        return prefix;
    };

    return {
        restrict: "A",
        transclude: "=",
        scope: {},
        controller: ($scope, $transclude, $element) => {
            $transclude($scope, clone => {
                // tenantName - applicationName
                $element[0].innerText = `${getHostName()} - ${clone[0].textContent}`;
            });
        }
    };
});
