import { Inject, Injectable, Provider } from '@angular/core';
import { WindowToken } from '@app/shared/services/window';
import * as jwtDecodeLib from 'jwt-decode';
import { LocationService } from '../../ajs-upgraded-providers';
import { unverifiedScopeSuffix } from './auth.constants';
import { IAuthService, JWT } from './auth.interface';
import { getTenantId, getSubscriberGroupId, isEmailVerified } from './auth.utils';
import { JwtDecode } from './jwt-decode';
import { OAuth2ClientJS } from './oauth2-client-js';
import * as AuthScopes from './roles.constants';
import ValidStates from './valid-states.constants';

/**
 * Creates an AuthService.
 *
 * After the constructor returns, the AuthService is in one of two states:
 *
 * - The user is logged in. `getToken()` and `getIdentity()` return nonnull.
 * - The user is not logged in. `getToken()` and `getIdentity()` return null.
 */
@Injectable()
export class AuthService implements IAuthService {
  private identity = null;
  private provider = null;
  private scopes: readonly string[] = [];
  private redirecting = false;

  constructor(
    @Inject(WindowToken) private window: Window,
    @Inject(LocationService) private $location: ng.ILocationService,
    @Inject(OAuth2ClientJS) private oauth2: any,
    @Inject(JwtDecode) private jwtDecode: JWTDecodeFn,
  ) {
    this.init();
  }

  // Helper function called only from the constructor
  init() {
    if (this.provider) {
      throw new Error("init() was already invoked");
    }

    this.provider = new this.oauth2.Provider({
      id: 'dpodui',
      authorization_url: '/oauth/authorize',
    });

    // First check if we are returning from a login redirect. Note, need to parse the
    // hash with Angular routing params stripped out (otherwise provider gets confused).
    const $location = this.$location;
    const hash = $location.hash();
    let fromRedirect = false;
    if (hash) {
      try {
        const response = this.provider.parse(hash);
        const prev = response.metadata;
        fromRedirect = true;
        $location.hash(""); // clean out token params from hash
        if (prev && typeof prev === "string") {
          $location.path(prev); // restore ui-router state
        }
      } catch (e) {
        // Ignore
      }
    }

    // At this point we might have a token (either from the login redirect or from localStorage)
    const token = this.provider.getAccessToken();
    if (token) {
      console.log(`Got token ${fromRedirect ? 'from oauth redirect' : 'from cache'}`);
      this.identity = this.jwtDecode(token);
      this.scopes = this.identity.scope;
    } else {
      console.log('Not logged in');
    }
  }

  /**
   * Determine whether the user is allowed to view the given UI-router state. In other words,
   * check whether the user has any scope for which the state is valid.
   */
  isStateValid(state): boolean {
    return Object.keys(AuthScopes).some(scopeName => {
      const scope = AuthScopes[scopeName];
      return this.hasScope(scope) && (ValidStates[scopeName] || []).includes(state);
    });
  }

  /**
   * @returns Whether the user has verified their email address
   */
  isEmailVerified(): boolean {
    return isEmailVerified(this.scopes);
  }

  /**
   * @returns Whether we've started redirecting the user to the login or logout page.
   */
  isRedirecting(): boolean {
    return this.redirecting;
  }

  /**
   * @returns The encoded JWT (also called access_token)
   */
  getToken(): string {
    return this.provider.getAccessToken();
  }

  /**
   * @returns The decoded JWT
   */
  getIdentity(): JWT {
    return this.identity;
  }

  /**
   * @returns The id of the tenant that the user is logged into.
   */
  getTenantId(): string {
    return getTenantId(this.scopes);
  }

  /**
   * @returns The id of the subscriber group that the user belongs to.
   * null is returned if the user doesn't belong to a subscriber group (e.g. if the user is an SP Admin or Operator)
   */
  getSubscriberGroupId(): string {
    return getSubscriberGroupId(this.scopes);
  }

  /**
   * todo verify that nothing is using this incorrectly as we also include unverified scopes
   * @returns `true` if the user has the given scope or an "unverified" variant thereof.
   */
  hasScope(scope: string): boolean {
    return this.scopes.includes(scope) || this.scopes.includes(scope + unverifiedScopeSuffix);
  }

  /**
   * Checks if one of the scopes exist for this user
   * use this when possible instead of `hasScope`
   * which also checks for `unverified` which may lead to something unintended
   * @param scopes  array of passed in scopes
   */
  hasAnyScope(...scopes: string[]): boolean {
    return !!this.scopes.find(userScope => scopes.includes(userScope));
  }

  /**
   * @param returnTo The $location path to restore after login (default: current path)
   */
  login(returnTo?: string) {
    const window = this.window;
    const $location = this.$location;
    const request = new this.oauth2.Request({
      client_id: 'dpodui',
      redirect_uri: this.getRedirectLocation(),
      metadata: returnTo || $location.path(),
    });

    const uri = this.provider.requestToken(request);

    // Later we need to check if the response was expected so save the request
    this.provider.remember(request);

    console.warn(`redirecting to login page:${uri}`);
    this.redirecting = true;
    window.location.href = uri;
  }

  /**
   * @returns The URI where UAA should redirect back to after the user logs in.
   */
  private getRedirectLocation(): string {
    const window = this.window;
    const path = window.location.pathname;
    // discard fragment to avoid endless loop
    return window.location.origin + (path === '/' ? '' : path);
  }

  logout() {
    this.reset();
    const redirectUri = this.getRedirectLocation();
    // Log user out of UAA then redirect back to this page. After the redirect the user should
    // end up in the login flow again, allowing them to re-login to DPOD if they wish.
    const uri = `/logout.do?redirect=${encodeURIComponent(redirectUri)}`;
    console.warn(`redirecting to logout page: ${uri}`);
    this.redirecting = true;
    this.window.location.href = uri;
  }

  /**
   * Returns the AuthService to the "not logged in" state.
   */
  reset() {
    this.provider.deleteTokens();
    this.identity = null;
    this.scopes = [];
  }
}

export const AuthServiceProvider: Provider = AuthService;

// Can't seem to directly reference this as a type from jwt-decode library
type JWTDecodeFn = (token: string, options?: jwtDecodeLib.Options) => JWT;
