import { dpodScopeRegex, unverifiedScopeSuffix } from './auth.constants';

/**
 * Given the `scope` claim from the access token, returns the id of the tenant
 * that the user is logged into.
 *
 * @returns The tenant id, or `null`
 */
export function getTenantId(scopes: readonly string[]): string {
  const match = getScopeMatch(scopes);
  return match ? match[1] : null;
}

/**
 * Given the `scope` claim from the access token, returns the id of the subscriber group
 * that the user belongs to.
 *
 * @returns The subscriberGroup id, or `null`
 */
export function getSubscriberGroupId(scopes: readonly string[]): string {
  const match = getScopeMatch(scopes, true);
  return match ? match[2] : null;
}

function getScopeMatch(scopes: readonly string[], sguidRequested = false): RegExpExecArray {
  for (const s of scopes) {
    const match = dpodScopeRegex.exec(s);
    if (match && !sguidRequested) {
      return match;
    }
    if (match && match[2]) {
      return match;
    }
  }
  return null;
}

/**
 * Given the `scope` claim from the access token, returns whether the logged-in-user's
 * email address has been verified.
 *
 * @returns `true` if their email is verified, `false` if not verified
 */
export function isEmailVerified(scopes: readonly string[]): boolean {
  for (const s of scopes) {
    if (s.endsWith(unverifiedScopeSuffix)) {
      return false;
    }
  }
  return true;
}
