"use strict";

import iconModule from "./_init";
import template from "./icons.svg.html";

class GemIcons {
}

export default iconModule.component("gemIcons", {
   controller: GemIcons,
   template,
});
