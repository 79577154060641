import "../gem-services/details/details-view.component";
import "../reports/reports.component";

export default function routing($stateProvider, $urlRouterProvider) {
   'ngInject';

   $urlRouterProvider.otherwise($injector => {
      // send them home which will pick the default state based on user's role
      const $state = $injector.get('$state');
      $state.go('home', {location: 'replace', inherit: true});
   });

   $stateProvider.state('home', {});
}
