<div class="row">
    <div class="col">

        <ngb-tabset type="pills">
            <ngb-tab title="Credentials">
                <ng-template ngbTabContent>

                    <div class="details-panel">
                        <div class="details-panel-section">

                            <alert-with-icon [icon]="'#bang-triangle'" [class]="'alert-custom-warning'">
                                Please copy and save your
                                <strong>Client Secret</strong> in a secure location; it will not be stored.
                            </alert-with-icon>

                            <hr/>

                            <div class="row">

                                <div class="col-sm-6">

                                    <strong>Client ID</strong>

                                    <div class="d-flex">
                                        <input type="text" data-locator="api-client-api" class="form-control mr-1"
                                               #clientId
                                               [(ngModel)]="apiClientId" name="api-client-api" readonly/>
                                        <copy-input data-locator="copy-api-client-api"
                                                    (cbOnSuccess)="hideAllCopiedMessages(); copiedId = true"
                                                    [ngxClipboard]="clientId"></copy-input>
                                    </div>

                                    <div class="mt-1">
                                        <gem-feedback text="Client ID copied to clipboard!"
                                                      [(trigger)]="copiedId"></gem-feedback>
                                    </div>

                                </div>

                                <div class="col-sm-6">

                                    <strong>Client Secret</strong>

                                    <div class="d-flex">
                                        <input type="text" data-locator="api-client-secret" class="form-control mr-1"
                                               #clientSecret
                                               [(ngModel)]="apiClientSecret" name="api-client-secret" readonly/>
                                        <copy-input data-locator="copy-api-client-secret"
                                                    (cbOnSuccess)="hideAllCopiedMessages(); copiedSecret = true;"
                                                    [ngxClipboard]="clientSecret"></copy-input>
                                    </div>

                                    <div class="mt-1">
                                        <gem-feedback text="Client secret copied to clipboard!"
                                                      [(trigger)]="copiedSecret"></gem-feedback>
                                    </div>

                                </div>
                            </div>

                            <div class="row mt-3">

                                <div class="col-sm-12">

                                    <strong>Execute the below command on the ProtectV Manager cluster to integrate this
                                        service.</strong>

                                    <div class="d-flex">
                                        <input type="text" data-locator="api-command" class="form-control mr-1" #command
                                               [ngModel]="getProtectVCLICommand()" name="api-client-api" readonly/>
                                        <copy-input data-locator="copy-api-command"
                                                    (cbOnSuccess)="hideAllCopiedMessages(); copiedCommand = true;"
                                                    [ngxClipboard]="command"></copy-input>
                                    </div>

                                    <div class="mt-1">
                                        <gem-feedback text="CLI command copied to clipboard!"
                                                      [(trigger)]="copiedCommand"></gem-feedback>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                </ng-template>
            </ngb-tab>
            <ngb-tab title="Service Info">
                <ng-template ngbTabContent>

                    <div class="details-panel">
                        <div class="details-panel-section">
                            <div class="row form-group">
                                <div class="col-sm-5">
                                    <label>
                                        Service Name
                                        <br/>
                                        <strong>{{serviceName}}</strong>
                                    </label>
                                </div>

                                <div class="col-sm-7">
                                    <label>
                                        Credentials Name
                                        <br/>
                                        <strong>{{credentialsName}}</strong>
                                    </label>
                                </div>
                            </div>

                            <hr class="mt-2"/>

                            <div class="row form-group">
                                <div class="col-sm-5">
                                    <label class="word-break-break-all">
                                        Keystore URL
                                        <br/>
                                        <strong>{{getKeystoreUrl()}}</strong>

                                        <div title="Copy to Clipboard"
                                             ngxClipboard
                                             [cbContent]="getKeystoreUrl()"
                                             (cbOnSuccess)="hideAllCopiedMessages(); copiedKeystoreUrl = true"
                                             class="action-cell icon-inside-icon copy">
                                           <span class="grid-action">
                                              <svg class="icon-svg outer-icon"><use
                                                  xlink:href="#action-box"></use></svg>
                                              <svg class="icon-svg inner-icon"><use xlink:href="#copy"></use></svg>
                                           </span>
                                        </div>
                                    </label>
                                    <gem-feedback class="d-block mt-1" text="Client Keystore URL copied to clipboard"
                                                  [(trigger)]="copiedKeystoreUrl"></gem-feedback>
                                </div>

                                <div class="col-sm-7">
                                    <label class="word-break-break-all">
                                        OAuth URL
                                        <br/>
                                        <strong>{{getOAuthUrl()}}</strong>

                                        <div title="Copy to Clipboard"
                                             ngxClipboard
                                             [cbContent]="getOAuthUrl()"
                                             (cbOnSuccess)="hideAllCopiedMessages(); copiedOAuthUrl = true"
                                             class="action-cell icon-inside-icon copy">
                                           <span class="grid-action">
                                              <svg class="icon-svg outer-icon"><use xlink:href="#action-box"></use></svg>
                                              <svg class="icon-svg inner-icon"><use xlink:href="#copy"></use></svg>
                                           </span>
                                        </div>
                                    </label>

                                    <gem-feedback class="d-block mt-1" text="Client OAuth URL copied to clipboard"
                                                  [(trigger)]="copiedOAuthUrl"></gem-feedback>

                                </div>

                            </div>

                        </div>
                    </div>

                </ng-template>
            </ngb-tab>
        </ngb-tabset>

    </div>
</div>
