export const confirmTitle = 'Delete Subscriber Group?';
export const confirmText = 'You will no longer be able to assign Users to this Subscriber Group.';
export const deleting = 'Deleting Subscriber Group...';
export const saving = 'Saving Subscriber Group...';

export const noPlans = {
  message: "To purchase this service please contact your ",
  linkText: "Thales Sales Representative",
  linkRef: "https://cpl.thalesgroup.com/encryption/contact-us"
};

export const plansError = {
  line1: {
    message: "There was an error loading the service plans."
  },
  line2: {
    message1: "Check our ",
    linkText: "Status Page",
    linkRef: "https://status.dpondemand.io/",
    message2: " for the latest information on DPoD service availability."
  }
};