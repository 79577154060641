import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { WizardStep } from "@app/components";
import { TileServicePlan } from "@app/features/marketplace/tiles.interface";
import { cloudProviderRegionMap } from "@app/features/gem-services/services.constants";

@Component({
  selector: 'ciphertrust-configure',
  templateUrl: './ciphertrust-configure.component.html',
  styleUrls: ['./ciphertrust-configure.component.scss'],
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => CiphertrustConfigureComponent)}
  ]
})
export class CiphertrustConfigureComponent extends WizardStep implements OnInit {
  passwordPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\\w\\s]|[_])).{8,30}$";
  serviceNameInternal = '';
  cloudProviders = [];
  cloudRegions = [];
  selectedCloudProvider = {};
  selectedCloudRegion = {};
  userNameInternal = 'admin';
  adminPasswordInternal = '';
  confirmPassword = '';

  @Input() servicePlan: TileServicePlan[];
  @Output() serviceName = new EventEmitter<string>();
  @Output() cloudProvider = new EventEmitter<any>();
  @Output() cloudRegion = new EventEmitter<any>();
  @Output() userName = new EventEmitter<string>();
  @Output() adminPassword = new EventEmitter<string>();

  ngOnInit(): void {
    this.initCloudProviderRegionFromServicePlan();
    this.userName.emit(this.userNameInternal);
    if (this.cloudProviders.length === 1) {
      this.selectedCloudProvider = this.cloudProviders[0];
      this.onCloudProviderChange();
    } else {
      this.selectedCloudProvider = '';
    }

    if (this.cloudRegions.length === 1) {
      this.selectedCloudRegion = this.cloudRegions[0];
      this.onCloudRegionChange();
    } else {
      this.selectedCloudRegion = '';
    }
  }

  initCloudProviderRegionFromServicePlan() {
    const cloudResources = this.extractCloudProvidersAndRegion();
    this.cloudProviders = this.getCloudProviders(cloudResources);
    this.cloudRegions = this.getCloudRegions(cloudResources);
  }

  /**
   * If we had cloud resource information as part of the service plan, then we generate the dropdown values for provider and region from that cluster["enum"] information
   * @private
   */
  private extractCloudProvidersAndRegion() {
    if (this.servicePlan && this.servicePlan.length > 0) {
      // DPoD Marketplace supports only one service plan now, so servicePlan[0] is fine
      const createParams = this.servicePlan[0].schemas.service_instance.create.parameters;
      const cluster = createParams.properties["cluster"];
      if (cluster && cluster["enum"]) {
        return this.getCloudResourcesFromCluster(cluster);
      }
    }
    return null;
  }

  /**
   * Reads the cluster property in service plan and generate a cloud resource data for cloud providers and cloud regions
   * @param cluster
   * @private
   */
  private getCloudResourcesFromCluster(cluster) {
    const cloudResources = [];
    // extracting all the cloud provider codes and regions from the service plan
    cluster["enum"].forEach(resource => {
      const cloudResourceData = resource.split(/-(.*)/s); // "gcp-us-west1"
      const providerCode = cloudResourceData[0]; // "gcp"
      const regionCode = cloudResourceData[1]; // "us-west1"
      let provider = cloudResources.find(r => r.name === providerCode);
      if (!provider) {
        provider = {
          name: providerCode,
          regions: []
        }
      }
      if (!provider.regions.includes(regionCode)) {
        provider.regions.push(regionCode);
      }
      // cloudResources will have a structure like this: [{name: "gcp", regions: ["us-east1", "europe-west3"]}]
      if (!cloudResources.find(r => r.name === providerCode)) {
        cloudResources.push(provider);
      }
    });
    return cloudResources;
  }

  /**
   * Returns the cloud providers from the cloudResources(generated from service plan) or a default set of cloud providers
   * @param cloudResources
   * @private
   */
  private getCloudProviders(cloudResources) {
    const cloudProviders = [];
    if (cloudResources && cloudResources.length > 0) {
      // build the data for cloud provider dropdown
      cloudResources.forEach(providerCode => {
        const providerCodeName = cloudProviderRegionMap[providerCode.name]
        const provider = {
          label: providerCodeName ? providerCodeName.label : providerCode.name,
          code: providerCode.name
        }
        cloudProviders.push(provider);
      });
      return cloudProviders;
    }
    // return the default cloud providers if not found from service plan
    return [{label: 'GCP', code: 'gcp'}];
  }

  /**
   * Returns the cloud regions from the cloudResources(generated from service plan) or a default set of cloud regions
   * @param cloudResources
   * @private
   */
  private getCloudRegions(cloudResources) {
    const cloudRegions = [];
    if (cloudResources && cloudResources.length > 0) {
      cloudResources.forEach(providerCode => {
        const providerCodeName = cloudProviderRegionMap[providerCode.name];
        // build the data for cloud region dropdown
        providerCode.regions.forEach(regionCode => {
          const regionLabel = providerCodeName && providerCodeName.regions[regionCode] ?
            providerCodeName.regions[regionCode] : regionCode;
          const region = {
            label: regionLabel,
            code: regionCode
          }
          cloudRegions.push(region);
        });
      })
      cloudRegions.sort((a, b) => (a.code < b.code ? -1 : 1)); //sorting alphabetically
      return cloudRegions;
    }
    // return the default cloud regions if not found from service plan
    return [{label: 'europe-west3 (Frankfurt)', code: 'europe-west3'}];
  }

  nameChange() {
    this.serviceName.emit(this.serviceNameInternal);
  }

  onCloudProviderChange() {
    this.cloudProvider.emit(this.selectedCloudProvider);
  }

  onCloudRegionChange() {
    this.cloudRegion.emit(this.selectedCloudRegion);
  }

  adminPasswordChange() {
    this.adminPassword.emit(this.adminPasswordInternal);
  }

  resetConfirmPassword() {
    this.confirmPassword = ""; //clean field when password changes
  }

  testGetCloudRegions(cloudResources){
    return this.getCloudRegions(cloudResources);
  }
}
