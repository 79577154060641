<h5>{{ status | titlecase }} Service</h5>

<!-- If you need to make "View Services" a hyperlink, please refer to the MRs #1568, #1590 -->
<p>
    This service is being {{ getStatusMessage(status) }}. You can check back later in View Services.
</p>

<div class="row no-gutters">
    <div class="float-sm-left">
        <button class="btn btn-next" name="close" type="button" (click)="self.dismiss()">Close</button>
    </div>
</div>
