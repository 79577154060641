<h5 class="mb-3">
    <svg class="icon-svg error-svg">
        <use xlink:href="#info-round"/>
    </svg>
    Credentials Detail
</h5>

<hr class="mt-3"/>

<form #credsForm="ngForm">
    <div class="form-group row">
        <div class="col">
            <strong>
                <label for="credential-name">Credential Name</label>
            </strong>
        </div>
        <div class="col">
            <input data-locator="credential-name" id="credential-name" name="credential-name" #credentialsName="ngModel" maxlen="64" class="form-control"
                   [ngModel]="credentials.name | replaceCharactersWith: ' ': '_'"
                   (ngModelChange)="credentials.name = $event"
                   required disabled />
            <div *ngIf="credentialsName.dirty" id="error-message-credentialsName" class="text-danger error-message"
                 role="alert">
                <div *ngIf="credentialsName.errors?.required">Please enter a credentials name</div>
                <div *ngIf="credentialsName.errors?.maxlen">Maximum 64 characters</div>
            </div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col">
            <strong>
                <label for="client-id">Client ID</label>
            </strong>
        </div>
        <div class="col">
            <div class="d-flex">
                <input data-locator="client-id" id="client-id" #clientId class="mr-1 form-control" [value]="credentials.clientId" readonly/>
                <copy-input data-locator="copy" [ngxClipboard]="clientId" (click)="copied = true"></copy-input>
            </div>
            <gem-feedback class="d-block mt-1" text="Copied to clipboard!" [(trigger)]="copied"></gem-feedback>
        </div>
    </div>

    <hr class="mt-3" />

    <div class="form-group row">
        <div class="col">
            <strong>
                <label>Credential Type</label>
            </strong>
        </div>
        <div class="col" data-locator="credential-type">
            {{type}}
        </div>
    </div>

    <div *ngIf="serviceName" class="form-group row">
        <div class="col">
            <strong>
                <label>Service Name</label>
            </strong>
        </div>
        <div class="col" data-locator="service-name">
            {{serviceName}}
        </div>
    </div>

    <hr class="mt-3" />

    <div class="form-group row">
        <div class="col">
            <strong>
                <label>Created</label>
            </strong>
        </div>
        <div class="col" data-locator="created-at">
            {{credentials.formattedCreatedAt}}
        </div>
    </div>

    <div class="form-group row">
        <div class="col">
            <strong>
                <label>Created By</label>
            </strong>
        </div>
        <div class="col" data-locator="created-by">
            {{credentials.createdBy}}
        </div>
    </div>

    <hr/>

    <button class="btn btn-next" data-locator="save-and-close" (click)="done()">Close</button>
</form>
