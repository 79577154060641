import module from '../_init';
import template from './modal-changeUserPassword.content.html';

export class ChangePasswordController {
   constructor() {
      this.body = {
         oldPassword: '',
         newPassword: '',
      };
   }

   change() {
      this.close({$value: this.body});
   }
}

module.component('changeUserPassword', {
   template,
   bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
   },
   controller: ChangePasswordController,
});
