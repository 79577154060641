<header class="d-flex justify-content-between align-items-center container theader px-0">
    <div class="header-icon-holder">
        <gem-banner-image></gem-banner-image>
    </div>

    <div class="d-flex">
        <div ngbDropdown>
            <button class="user d-flex rounded align-items-center p-0"
                ngbDropdownToggle id="headerDropdownToggle" role="button">
                <div class="avatar">
                    <svg class="icon-svg">
                        <use xlink:href="#admin-user"></use>
                    </svg>
                </div>
                <div class="d-flex flex-column text-left user-info">
                    <span class="username">{{userName}}</span>
                    <span class="role">{{userRole}}</span>
                    <span class="role" id="tenant">
                        {{tenantId}}
                        <div title="Copy to Clipboard" (click)="copyToClipboard($event)"
                             id="copyTenantId"
                             ngxClipboard
                             [cbContent]="tenantId"
                             class="action-cell icon-inside-icon copy">
                                 <span class="grid-action">
                                     <svg class="icon-svg copy-icon-header"><use xlink:href="#copy"></use></svg>
                                 </span>
                        </div>
                    </span>
                </div>
                <div class="dropdown">
                    <svg class="icon-svg">
                        <use xlink:href="#caret-down"></use>
                    </svg>
                </div>
            </button>

            <ul class="dropdown-menu w-100" ngbDropdownMenu role="menu" aria-labelledby="headerDropdownToggle">
                <li class="dropdown-item" *ngFor="let item of getUserActions()"
                    [attr.data-locator]="item.locator" (click)="item.action()">{{item.text}}
                </li>
            </ul>
        </div>
    </div>
</header>
