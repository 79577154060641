import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { IHttpResponse } from "angular";
import { DialogService, DismissalError } from "../../components";
import { ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

/**
 * Intercepts uncaught exceptions and shows them in the generic error dialog.
 */
@Injectable()
export class GlobalErrorHandler extends ErrorHandler implements ErrorHandler {
  // Tracks whether the error dialog is being shown. Checked to prevent re-entrant calls
  // to handleError() from creating an infinite loop.
  private showing = false;

  private dialogService: DialogService;

  constructor(private injector: Injector) {
    super();
  }

  init(err: any) {
    // DialogService is obtained lazily to avoid circular dependency/bootstrap errors
    try {
      this.dialogService = this.dialogService || this.injector.get(DialogService);
    } catch (e) {
      if (/injector before it being set/.test(e.message)) {
        // Error bootstrapping Angular. We won't be able to display a dialog in this
        // state so just log it
        // console.log(err);
        throw err;
      }
    }
  }

  handleError(error: any) {
    this.init(error);

    // Unwrap a Zone.js error if present
    if (isUncaughtPromiseError(error)) {
      error = error.rejection;
    }

    // Unwrap an AngularJS HTTP response if that's what we have, to get the response body
    // Note: this case should be removed someday
    if (isAngularHttpResponse(error)) {
      error = error.data;
    }

    // If it's a spurious error resulting from cancelling a bootstrap dialog, hide it
    if (error.code === DismissalError.code) {
      return;
    }

    // Delegate to Angular's default ErrorHandler which logs to the console
    super.handleError(error);

    const parsedError = parseError(error);
    if (parsedError.message === 'Session expired') {
      return;
    }

    if (!this.showing) {
      this.showing = true;
      this.dialogService.error(parsedError)
      .finally(() => this.showing = false);
    }
  }
}

export const GlobalErrorHandlerProvider = {
  provide: ErrorHandler,
  useClass: GlobalErrorHandler,
};

// Type guard that checks whether e is an UncaughtPromiseError from Zone.js
// http://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
function isUncaughtPromiseError(e: any): e is ZoneJsUncaughtPromiseError {
  const ze: ZoneJsUncaughtPromiseError = e as ZoneJsUncaughtPromiseError;
  return typeof ze.rejection === 'object' && typeof ze.promise === 'object';
}

function isAngularHttpResponse(e: any): e is IHttpResponse<any> {
  return typeof e.data === 'object' && typeof e.status === 'number';
}

interface ZoneJsUncaughtPromiseError {
  rejection: any;
  promise: Promise<any>;
}

/**
 * Tries to parse the exception into a semi-meaningful object
 * @param {object|string|Error} exception
 * @returns object
 */
export function parseError(exception: any): ParsedError {
  let parsed;
  if (typeof exception === 'string') {
    // Chop off this annoying prefix (if present) and try to parse into a proper object
    const exceptionClean = exception.replace(unhandledRejection, '');
    try {
      parsed = JSON.parse(exceptionClean);
    } catch (e) {
      parsed = { message: exceptionClean };
    }
  } else if (typeof exception === 'object') {
    parsed = exception;
  } else {
    parsed = { message: exception };
  }

  return parsed;
}

const unhandledRejection = /^Possibly unhandled rejection: ?/;

interface ParsedError {
  message: string;
  [key: string]: any;
}
