import { Inject, Injectable } from '@angular/core';
import { HttpService, Q } from '@app/ajs-upgraded-providers';
import { RequiresRoleService } from '@app/shared/services';
import { Haro } from '@app/shared/services/haro.service';
import { cloneDeep } from 'lodash';
import * as roles from '../../auth/roles.constants';
import { SubscriberGroup, User } from '../accounts.model';
import AccountsDataApi from './accountsDataApi';
import { UsersApi } from './usersApi.service';

/**
 * Service that manages subscriber groups.
 *
 * Because subscriber groups maintain a count of users who are members, this service
 * also listens to addition/removal of users in the `UsersApi service and updates
 * itself accordingly.
 */
@Injectable({
  providedIn: 'root'
})
export class SubscribersApi extends AccountsDataApi {
  // Maps a user to the ids of the subscriber groups they're a member of
  private userToGroups: Map<string, Set<string>> = new Map();

  constructor(
    @Inject(Haro) haro,
    @Inject(HttpService) $http,
    @Inject(Q) $q,
    private requiresRoleService: RequiresRoleService,
    private usersApi: UsersApi,
  ) {
    super(haro, $http, $q, '/v1/subscriber_groups');
    this.resync();

    this.usersApi.subscribe((event: string | [string, User]) => {
      if (Array.isArray(event)) {
        this.onUserAdded(event[1]);
      } else {
        this.onUserDeleted(event);
      }
    })
  }

  resync() {
    // Only Tenant Admins can fetch subscriber groups, other roles get a 403 error
    if (this.requiresRoleService.hasRole([roles.admin])) {
      return super.resync();
    }
    return this.$q.resolve();
  }

  /**
   * Called when a user is added or changed
   * @param user
   */
  private onUserAdded(user: User) {
    let groups: Set<string> = this.userToGroups.get(user.id);
    if (!groups) {
      groups = new Set();
      this.userToGroups.set(user.id, groups);
    }
    user.subscriberGroups.forEach(s => groups.add(s));
  }

  /**
   * Called when a user has been deleted. Decrements the `totalUsers` count for the
   * group(s) that the user was a member of.
   * @param id
   */
  private onUserDeleted(id: string) {
    const groupsToUpdate = this.userToGroups.get(id);
    if (!groupsToUpdate) {
      return;
    }
    groupsToUpdate.forEach(id => {
      const group: SubscriberGroup = this.get(id);
      if (!group) {
        return;
      }
      const updatedGroup = cloneDeep(group);
      updatedGroup.totalUsers = group.totalUsers - 1;
      this.set(updatedGroup);
    });
  }

}
