<h5>Delete Service "{{serviceNameToDelete}}"?</h5>
<div class="alert alert-danger" role="alert">
    <svg class="icon-svg">
        <use xlink:href="#bang-round" class="alert-text" />
    </svg>
    <strong>Warning! This is an unrecoverable operation!</strong>
</div>

<p class="delete-service-content">
{{customWarning}}
</p>

<p>Please confirm this action by entering this Service's name.</p>

<form #deleteService="ngForm">
    <input type="hidden" #actualServiceName="ngModel"
        name="actualServiceName" [ngModel]="serviceNameToDelete" />

    <div class="row service-input form-group">
        <div class="col-8">
            <label for="serviceName" class="control-label">Enter Service Name</label>
            <input id="serviceName" type="text" name="enteredName" class="form-control"
                ngModel #enteredName="ngModel"
                confirmInput="actualServiceName"
                validationIcon autofocus required>

            <div id="error-message-serviceName" class="text-danger error-message" role="alert"
                    *ngIf="enteredName?.dirty">
                <div *ngIf="enteredName.errors?.mismatch">Please enter the correct service name</div>
            </div>
        </div>
    </div>

    <br />
    <div class="row no-gutters">
        <div class="float-sm-left">
            <button class="btn btn-next" name="next" type="button"
                (click)="self.close()"
                [disabled]="!deleteService.valid">Delete</button>

            <button class="btn btn-default" name="cancel" type="button"
                (click)="self.dismiss()">Cancel</button>
        </div>
    </div>

</form>
