/**
 * All external URL links can be placed here.
 * It is structured in a way we can add multiple URLs from the same domain
 *
 * Example: EXTERNAL_LINKS = {
 *   "FUNCTIONALITY_1" : {
 *     baseUrl : 'https://domain1.com',
 *     url1 : '/func1/topic1',
 *     url2: '/func1/topic2'
 *     ....
 *   }
 *   "FUNCTIONALITY_2" : {
 *     baseUrl : 'https://domain2.com',
 *     ....
 *   }
 * }
 * */
export const EXTERNAL_LINKS = {
  "SERVICE_ELECTIONS" : {
    baseUrl: 'https://www6.thalesgroup.com',
    addServiceElectionsUrl : '/data-protection-on-demand/additional-service-elections'
  }
}
