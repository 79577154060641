<div class="wizard-modal">
   <form #wizForm="ngForm">
      <h4 class="dialog-title">{{title}}</h4>
      <div class="wizard-container">

         <steps [steps]="getStepNames()" [currentStepIdx]="currentStepIdx" *ngIf="showSteps"></steps>

         <div class="wizard-content-container">
            <!-- wizard page content goes here -->
            <ng-content></ng-content>
         </div>

         <div class="wizard-navigation-container row no-gutters">

            <div *ngIf="!showClose()" class="col-6">
               <div class="float-sm-left">
                  <button class="btn btn-default" name="cancel" type="button" (click)="dismiss()">Cancel</button>
               </div>
            </div>
            <div *ngIf="!showClose()" class="col-6">
               <div class="float-sm-right">
                  <button *ngIf="currentStepIdx > 0" class="btn btn-default" name="previous" type="button"
                          (click)="gotoStep(currentStepIdx - 1)">Go Back
                  </button>
                  <button id="wizNext" class="btn btn-next" name="next" type="button" [disabled]="!isCurrentStepValid()"
                          (click)="gotoStep(currentStepIdx + 1)">{{nextLabel()}}
                  </button>
               </div>
            </div>

             <div *ngIf="showClose()" class="col">
                 <div class="float-sm-right">
                     <button id="wizClose" class="btn btn-next" name="close" type="button" (click)="close()"
                             [disabled]="!isCurrentStepValid()">
                         Close
                     </button>
                 </div>
             </div>
         </div>
      </div>
   </form>
</div>
