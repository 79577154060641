import { NgModule } from "@angular/core";

// This is the AngularJS part of the module
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from "angular";
import {AlertWithIconComponent} from "./alert-with-icon.component";
import {CommonModule} from "@angular/common";

export const ng1AlertWithIconModule = angular.module("gem.alert-with-icon", [])
  .directive("alertWithIcon", downgradeComponent({component: AlertWithIconComponent}) as angular.IDirectiveFactory);
