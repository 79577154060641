import { Component, ElementRef, Input, NgZone, OnChanges, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NgForm, NgModel, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

/**
 * Allows user to set quota for a tenant.
 */
@Component({
  selector: 'gem-tenant-quota',
  templateUrl: './tenant-quota.component.html',
  styleUrls: ['./tenant-quota.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TenantQuotaComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: TenantQuotaComponent, multi: true },
  ],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm }
  ]
})
export class TenantQuotaComponent implements OnChanges, ControlValueAccessor, Validator {
  // If false, the Unlimited toggle is hidden and only the quota input is shown
  @Input() showUnlimitedToggle: boolean;

  @Input() maxQuota: number;

  @ViewChild("form", { static: true }) quotaForm: ElementRef;
  @ViewChild("quotaField", { static: true }) quotaField: ElementRef;
  @ViewChildren(NgModel) childControls: QueryList<NgModel>;

  // View state of the unlimited/set toggle
  isUnlimitedChecked: boolean;

  private _quota: number;
  private onChange: (_: number) => void = () => {};
  onTouched: (_: any) => void = () => {};

  constructor(private zone: NgZone) {}

  ngOnChanges() {
    this.updateCheckedState();
  }

  updateCheckedState() {
    if (this.showUnlimitedToggle && this.quota >= this.maxQuota) {
      this.isUnlimitedChecked = true;
    } else {
      this.isUnlimitedChecked = false;
    }
  }

  set quota(value: number) {
    this._quota = value;
    this.updateCheckedState();
    this.onChange(value);
  }

  get quota() {
    return this._quota;
  }

  setUnlimited() {
    this.quota = this.maxQuota;
  }

  setCustom() {
    this.isUnlimitedChecked = false;

    // Clear the quota input field.. this is a hack but it works
    this.quota = null;

    // Focus the quota field
    this.zone.runOutsideAngular(() => {
      setTimeout(() => this.quotaField.nativeElement.focus(), 0);
    });
  }

  quotaModelChange(value: number) {
      this.quota = value;
  }

  getQuotaViewValue(): string {
    if (this.isUnlimitedChecked) {
      return null; // display as empty when unlimited quota is set
    }
    return this.quota + '';
  }

  // Implement Validator by merging any errors from our child inputs
  validate(c: AbstractControl): ValidationErrors {
    const errs = {};
    this.childControls.forEach(c => Object.assign(errs, c.errors));
    return errs;
  }

  // The methods below implement ControlValueAccessor to make this component bindable as an ngModel.

  writeValue(value: any): void {
    this.quota = value as number;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    const inputs: HTMLInputElement[] = Array.from(this.quotaForm.nativeElement.querySelectorAll('input'));
    inputs.forEach(i => i.disabled = isDisabled);
  }
}
