import { environment } from '@env/environment';
import * as angular from 'angular';

import mockData from './mock-data';

const mockDelay = require('./mock-delay').default;
const ngMockE2E = require('angular-mocks/ngMockE2E');

// import mockDelay from './mock-delay';
// const mockData = require('./mock-data');

const deps = [];

if (environment.fuzzAjax) {
  // this must be loaded before `mockData`
  const mockFuzzData = require("./fuzz/mock-fuzz-data").default;
  deps.push(mockFuzzData);
}

deps.push(ngMockE2E, mockData, mockDelay);

if (environment.fuzzAjax) {
  // TODO typescript?
  const mockXSS = require('./mock-xss.interceptor').default;
  deps.push(mockXSS);
}

export default angular.module("dpaas.mocks", deps);
