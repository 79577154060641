
/*
 * Adds checks for Salesforce errors
 */
export default class SalesforceErrorResponseInterceptor {
    constructor($q) {
        "ngInject";
        this.$q = $q;
        this.responseError = responseError.bind(this);
    }
}

function responseError(res) {
    if (!res || !res.config) {
        return res;
    }
    const isKeybrokerURL = res.config.url.includes("/keybroker/");

    if (res.status === 401 && isKeybrokerURL) { // user is not authorized for Salesforce
        throw new Error("Unauthorized Access"); // error chain is stopped.  In this case, if we return the promise, the user will be logged out
    }

    return this.$q.reject(res);
}
