<dpod-table (filter)="onFilter($event)" (page)="onPageChanged($event)" [dataLength]="dataSource.data.length">
    <table #table mat-table matSort [dataSource]="dataSource">
        <!-- Status column -->
        <ng-container matColumnDef="accountStatus">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let tenant" class="status-column">
                <div [ngSwitch]="getStatus(tenant)">
                    <span *ngSwitchCase="'active'" class="status">
                        <svg class="icon-svg success">
                            <use xlink:href="#check" />
                        </svg>{{getStatusLabel(tenant)}}
                    </span>
                    <span *ngSwitchCase="'disabled'" class="status">
                        <svg class="icon-svg suspended">
                            <use xlink:href="#warning" />
                        </svg>{{getStatusLabel(tenant)}}
                    </span>
                    <span *ngSwitchCase="'failed'" class="status">
                        <svg class="icon-svg danger">
                            <use xlink:href="#warning" />
                        </svg>{{getStatusLabel(tenant)}}
                    </span>
                    <span *ngSwitchCase="'deleting'" class="status">
                        <spinner class="icon-svg"></spinner>{{getStatusLabel(tenant)}}…
                    </span>
                    <span *ngSwitchCase="'pending'" class="status">
                        <spinner class="icon-svg"></spinner>{{getStatusLabel(tenant)}}…
                    </span>
                </div>
            </td>
        </ng-container>

        <!-- Tenant Name column -->
        <ng-container matColumnDef="name">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Tenant Name</th>
            <td mat-cell *matCellDef="let tenant" class="name-column">
                <span class="gem-link tenant-name-whitespace" [attr.data-tenant-name]="tenant.name" title="{{tenant.name}}"
                    (click)="showDetails(tenant.id)">{{tenant.name}}</span>
            </td>
        </ng-container>

        <!-- Initial Service Elections column (SP admins only) -->
        <ng-container matColumnDef="election-status">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Initial Service Elections</th>
            <td mat-cell *matCellDef="let tenant" class="elections-status-column">
                <div *ngIf="getAccountTypeLabel(tenant) !== 'Service Provider'">
                    <ng-container [ngSwitch]="getElectionsStatus(tenant.id)">
                        <span *ngSwitchCase="'Requires Approval'" class="status require-approval">
                            {{getElectionsStatus(tenant.id)}}
                        </span>
                        <span *ngSwitchDefault class="status">
                            {{getElectionsStatus(tenant.id)}}
                        </span>
                    </ng-container>
                </div>
            </td>
        </ng-container>


        <!-- Account Type column-->
        <ng-container matColumnDef="accountType">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Account Type</th>
            <td mat-cell *matCellDef="let tenant" class="account-type-column">{{ getAccountTypeLabel(tenant) }}</td>
        </ng-container>

        <!-- Tenant Admin column -->
        <ng-container matColumnDef="admin">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Tenant Admin</th>
            <td mat-cell *matCellDef="let tenant" class="admin-column">{{ getAdminName(tenant) }}</td>
        </ng-container>

        <!-- Country column -->
        <ng-container matColumnDef="country">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Country</th>
            <td mat-cell *matCellDef="let tenant" class="country-column">{{ tenant?.billingAddress?.country }}</td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
            <th scope="col" mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let tenant" class="actions-column">
                <div class="action-cell">

                    <div *ngIf="isOperator()" class="icon-inside-icon">
                        <span title="Delete Tenant" class="grid-action delete" *ngIf="getStatus(tenant) != 'deleting'"
                            (click)="getStatus(tenant) !== 'pending' && deleteTenantDialog(tenant.id)">
                            <svg class="icon-svg outer-icon">
                                <use xlink:href="#action-box" />
                            </svg>
                            <svg class="icon-svg inner-icon">
                                <use xlink:href="#trash" />
                            </svg>
                        </span>
                    </div>

                    <div *ngIf="!isOperator()" ngbDropdown class="gem-dropdown">
                        <span class="grid-action" ngbDropdownToggle id="tenant-actions-{{tenant.id}}">
                            <svg class="icon-svg">
                                <use xlink:href="#dropdown" />
                            </svg>
                        </span>

                        <ul class="dropdown-menu gem" ngbDropdownMenu role="menu" [attr.aria-labelledby]="'tenant-actions-' + tenant.id">
                            <li class='dropdown-item view-details'
                                *ngIf="getAccountTypeLabel(tenant) !== 'Service Provider'"
                                [attr.data-locator]="'view-details'"
                                (click)="showDetails(tenant.id)">View Initial Elections</li>
                            <li class='dropdown-item delete-tenant'
                                *ngIf="getStatus(tenant) != 'deleting'"
                                [attr.data-locator]="'delete'"
                                (click)="getStatus(tenant) !== 'pending' && deleteTenantDialog(tenant.id)">Delete Tenant</li>
                        </ul>
                    </div>
                </div>
            </td>
        </ng-container>

        <!-- Rows definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let tenant; columns: displayedColumns;"
            [attr.data-tenant-status]="getStatus(tenant)"></tr>
    </table>
</dpod-table>
