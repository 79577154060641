import { Injectable } from '@angular/core';
import { AuthService } from '@app/features/auth';
import * as roles from '@app/features/auth/roles.constants';
import { TenantAccountStatus } from '@app/features/tenant/tenant.model';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RequiresRoleService } from './requires-role.service';
import { BackofficeService } from "@app/shared/services/backoffice.service";

// todo should this be remvoed and made part of backoffice.service?
/**
 * Emits the TenantAccountStatus of the tenant that the user is logged into.
 */
@Injectable({
  providedIn: 'root'
})
export class AccountStatusService {
  /**
   * Emits the account status of the tenant that the user is logged into. The status will be
   * "replayed" (emitted) to every new subscriber.
   */
  private status$: ReplaySubject<TenantAccountStatus> = new ReplaySubject(1);

  constructor(
    private backofficeService: BackofficeService,
    private requiresRoleService: RequiresRoleService,
    private authService: AuthService,
  ) {
    // Our replay subject is powered by a filtered version of the BackofficeService
    // account status stream.
    backofficeService.accountStatusStream
      .pipe(filter(s => {
        // todo in mock mode this will never return true because the tenant id we assign to the logged in user
        // will never match the tenantId returned from the mock backoffice
        // makes it hard for testing
        return s.tenantId === this.authService.getTenantId()
      }))
      .subscribe(this.status$);

    // Fetch the account status of the tenant we're logged into. The Operator
    // and SP tenants cannot have account status, so don't bother for them.
    if (authService.hasAnyScope(roles.admin, roles.owner)) {
      backofficeService.getAccountStatus(authService.getTenantId());
    }
  }

  /**
   * @returns An observable that emits the trial state of the tenant
   * that the user is logged into.
   */
  getAccountStatus(): Observable<TenantAccountStatus> {
    return this.status$.asObservable();
  }
}
