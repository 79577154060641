import { Component, Input } from '@angular/core';
import { UsersApi } from '@app/features/accounts/api/usersApi.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../gem-dialogs';

type PasswordResetFn = (id: string) => Promise<string>;

/**
 * Shows a dialog allowing a user's password to be reset. If the user confirms,
 * performs the reset and then reopens itself to show the temporary password.
 *
 * By default, `UsersApi.resetPassword()` performs the actual reset. This can
 * be overridden by calling `resetPasswordFn(value)` and supplying a different
 * function.
 */
@Component({
  selector: 'gem-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
})
export class ResetPasswordDialogComponent {
  /**
   * Id of the user whose password will be reset.
   * Optional; can be omitted if a custom `resetPasswordFn` is set.
   */
  @Input() userId: string;

  /** Internal use only, holds the temporary password */
  @Input() temporaryPassword: string;

  private resetFn: PasswordResetFn;

  constructor(
    public self: NgbActiveModal,
    private DialogService: DialogService,
    private UsersApi: UsersApi,
  ) {
    this.resetFn = this.defaultReset.bind(this);
  }

  private async defaultReset(id: string) {
    return this.UsersApi.resetPassword(id);
  }

  /**
   * Sets the async function that will do the actual password reset.
   * @param value The function. Takes a string and returns a Promise<string>
   * resolving to the temporary password.
   */
  set resetPasswordFn(value: PasswordResetFn) {
    this.resetFn = value;
  }

  async doReset() {
    this.self.close();

    const progress = this.DialogService.progress("Resetting Password…");
    try {
      const temporaryPassword = await this.resetFn(this.userId);
      progress.close();

      // reopen ourself to show the temporary password
      const ref = this.DialogService.open<ResetPasswordDialogComponent>(ResetPasswordDialogComponent);
      ref.componentInstance.temporaryPassword = temporaryPassword;
      return ref.result;
    } finally {
      progress.close();
    }
  }
}
