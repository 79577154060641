import ServiceBase from "@app/components/service/service-base";
import { Inject, Injectable } from "@angular/core";
import { Haro } from "@app/shared/services/haro.service";
import { take } from "rxjs/operators";
import { Tile } from "@app/features/marketplace/tiles.interface";
import { FipsState, HSMonDemandCreatedService } from "@app/features/gem-services/cloudHSM/cloudHSM.model";
import { cloneDeep } from "lodash";
import { TilesService } from "@app/shared/services/tiles.service";
import { HttpService } from "@app/ajs-upgraded-providers";
import { IHttpResponse, IHttpService } from "angular";
import moment from "moment";

const encode = encodeURIComponent;

/**
 * ServiceCommonClass is a base class for functionality that both the HSM service and the service-broker service
 * share
 */
@Injectable({
  providedIn: 'root'
})
export class ServiceCommonClass extends ServiceBase {
  protected shortCodeToTileName = new Map<string, string>();

  constructor(
    @Inject(Haro) haro: any,
    @Inject(HttpService) protected http: IHttpService,
    baseUrl: string,
    protected tilesService: TilesService,
  ) {
    super(haro, baseUrl);
    this.baseUrl = baseUrl;
  }

  annotate(service): HSMonDemandCreatedService {
    // Turn the createdAt field's ISO date into a formatted local string
    service.formattedCreatedAt = ServiceCommonClass.formatDate(service.createdAt);

    if (this.shortCodeToTileName.has(service.serviceType)) {
      service.formattedServiceType = this.shortCodeToTileName.get(service.serviceType);
    } else {
      service.formattedServiceType = '';
    }

    service.fipsState = service.device_type === 'cryptovisor_fips' ? FipsState.Fips : FipsState.NonFips;
    return service;
  }

  reannotateServices(){
    Promise.all([
      this.tilesService.tiles().pipe(take(1)).toPromise(),
      this.resync(),
    ]).then(([tiles, services]: [Tile[], HSMonDemandCreatedService[]]) => {
      // re-annotate the services using the Tile names
      tiles.forEach(t => this.shortCodeToTileName.set(t.shortCode, t.name));
      services.forEach(s => this.set(this.annotate(cloneDeep(s))));
    });
  }

  getKey() {
    return "service_id";
  }

  doResync() {
    return Promise.resolve(this.http.get(this.baseUrl));
  }

  doCreate(params): Promise<any>  {
    return Promise.resolve(this.http.post(this.baseUrl, params));
  }

  doFetch<T>(url: string): Promise<IHttpResponse<T>> {
    return Promise.resolve(this.http.get<T>(url));
  }

  doDelete(id: string): Promise<any> {
    return Promise.resolve(this.http.delete(`${this.baseUrl}/${encode(id)}`));
  }

  doSave() {
    return Promise.resolve();
  }

  protected static formatDate(date: string): string {
    return moment(date).format("D-MMM-YYYY H:mm");
  }

}
