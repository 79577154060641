import { Component, forwardRef, Input } from '@angular/core';
import { WizardStep } from '@app/components';
import { TenantCreateBody } from '../tenant.model';

@Component({
  selector: 'admin-user-step',
  templateUrl: './admin-user.step.component.html',
  providers: [{provide: WizardStep, useExisting: forwardRef(() => AdminUserStep)}]
})
export class AdminUserStep extends WizardStep {
  @Input() tenant: TenantCreateBody;

  confirmPassword: string;
}
