// this overrides any routing and data in the mock data
// note: in the current state it shouldn't be used for complex responses
import {
    angularBinding,
    breakJSON,
    closingHTMLTags,
    outsideScriptTag,
    scriptTag,
    strongTag
} from "./xss-test-vars.constants";

export default angular.module('mock.fuzzData', []).run(function ($httpBackend) {

    // passing in certain words triggers changePassword to return with errors
    // opening up the error dialog with the response
    $httpBackend.whenRoute('PATCH', `/v1/users/changePassword`)
        .respond((method, url, data, headers, params) => {

            const parsedData = JSON.parse(data);

            let error_description = null;

            switch (parsedData.newPassword) {
                case "script tag":
                    error_description = scriptTag;
                    break;
                case "closing html tags":
                    error_description = closingHTMLTags;
                    break;
                case "outside script tag":
                    error_description = outsideScriptTag;
                    break;
                case "strong tag":
                    error_description = strongTag;
                    break;
                case "break JSON":
                    error_description = breakJSON;
                    break;
                case "angular binding":
                    error_description = angularBinding;
                    break;
            }


            if (error_description) {
                return [500, {error_description}];
            } else {
                return [201];
            }

        });
})
.name;
