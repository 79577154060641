import { Component, Inject, OnInit } from '@angular/core';
import { EkmsApi } from "./ekmsApi.service";
import { HttpParams } from '@angular/common/http';
import { WindowToken } from "@app/shared/services/window";
import { interval, Subscription } from 'rxjs';
import { ServiceBrokerService } from "@app/shared/services";
import { switchMap } from 'rxjs/operators';

/**
 * Linkage/Association component.
 * It is responsible for linking a tenant account with external marketplace
 * Once account is linked, it retrieves dashboard_url of the service created and redirects there
 */
@Component({
  selector: 'gem-linkage',
  templateUrl: './linkage.component.html',
  styleUrls: ['./linkage.component.scss'],
})
export class LinkageComponent implements OnInit {

  marketplaceAccountId: string;
  subscriptionExists = true;
  errTitleText: string;
  errSubtitleText: string;
  errSubText: string;
  // we poll the service broker every 1 second to find the service with dashboard_url. If we do not find it after certain attempts (maxRetries) we throw an error asking to retry
  private retryCount = 0;
  private maxRetries = 30;
  subscription: Subscription;

  constructor(
    protected ekmsApi: EkmsApi,
    protected sbs: ServiceBrokerService,
    @Inject(WindowToken) private window: Window) {
  }

  ngOnInit() {
    const url = window.location.href;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      this.marketplaceAccountId = httpParams.get('marketplace_account_id');
    }
    this.associate();
  }

  associate() {
    this.retryCount = 0;
    this.ekmsApi.linkAccount(this.marketplaceAccountId).then(response => {
      if (response.entitlementCount > 0) {
        this.getServiceInstance();
      } else {
        this.subscriptionExists = false;
        this.setErrorMessage(
          "Your subscription has been cancelled",
          "Please subscribe again from Google Cloud Platform Marketplace",
          "If you have not cancelled your subscription please contact");
      }
    })
      .catch((/*error*/) => this.setErrorMessage());
  }


  getServiceInstance() {
    this.subscription = interval(1000).pipe(
      switchMap(() => this.sbs.resync())
    ).subscribe((result) => {
      this.poll(result);
    });
  }

  private setErrorMessage(errTitleText = "Sorry!",
                          errSubtitleText = "We ran into an issue preparing your service",
                          errSubText = "If you keep getting this error please contact") {
    this.errTitleText = errTitleText;
    this.errSubtitleText = errSubtitleText;
    this.errSubText = errSubText;
  }

  poll(result) {
    if (this.retryCount > this.maxRetries) {
      this.subscription.unsubscribe();
      this.setErrorMessage();
      return;
    }
    if (result) {
      const sb = result.find(v => v.dashboard_url);
      if (sb) {
        window.location.href = "/";
        this.subscription.unsubscribe();
        return;
      }
    }
    this.retryCount++;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
