export * from './evaluation-convert-footer.component';

// AngularJS compatibility
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { EvaluationConvertFooterComponent } from './evaluation-convert-footer.component';

export default angular.module('evaluation-convert-footer.component', [])
  .directive('evaluationConvertFooter', downgradeComponent({component: EvaluationConvertFooterComponent}))
  .name;
