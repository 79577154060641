import ServiceBase from "@app/components/service/service-base";
import moment from 'moment';
import { keystoreEndpoint, ProtectVKey, ProtectVKeyFormatted, ProtectVKeyState } from "./protectV.constants";

export class ProtectVService extends ServiceBase {

  private $http;

  constructor(haro, $http) {
    // 'keys2' is the keys resource, the team introduced 'keys2' instead of introducing a whole new version of the API
    // At some point the API will be cleaned up to be a 'v2' with a 'keys' resource
    super(haro, `${keystoreEndpoint}/keys2`);
    this.$http = $http;
  }

  annotate(protectVKey: ProtectVKey): ProtectVKeyFormatted {
    const formattedObject: ProtectVKeyFormatted = Object.assign({}, protectVKey) as ProtectVKeyFormatted;
    formattedObject.formattedUpdatedAt = moment(formattedObject.updatedAt).format("DD-MMM-YYYY HH:mm");
    formattedObject.formattedCreatedAt = moment(formattedObject.createdAt).format("DD-MMM-YYYY HH:mm");
    return formattedObject;
  }

  /**
   * Resync will return all keys and then filter on these keys
   * if state === `Active`, UI will display `unexportable=false` keys
   * if state === `Revoked`, UI will display `unexportable=true` keys
   *
   *  "active" with "unexportable"=false – > Show as "Active"
   *  "active" with "unexportable"=true – > Show as "Revoked"
   *  "deactivated" with "unexportable"=false – > Show as "Active" (SHOULD NOT HAPPEN, but "export" still works in this state)
   *  "deactivated" with "unexportable"=true – > Do not show.
   *  "destroyed" – > Do not show
   *  Any other state – > Do not show
   *
   * @param {string} params
   * @param {string} params.serviceId
   * @param {ProtectVKeyState} params.state   the state to filter on, should only be passed "Active" || "Revoked" || "" at this time
   * @returns {Promise<ProtectVKey[]>}
   */
  doResync(params = {
    serviceId: null,
    state: null,
  }): Promise<ProtectVKey[]> {
    const queryStringObject = {
      "service_id": params.serviceId,
    };
    return this.$http({
      url: this.baseUrl,
      params: {
        metaContains: queryStringObject,
        limit: -1,
      }
    }).then((response: { data: { resources: ProtectVKey[], total: number } }) => {

      // filter out all key states we don't care to show in the UI
      // filter also any `deactivated` keys where `unexportable=true`
      response.data.resources = response.data.resources.filter(item => {
        return item.state === ProtectVKeyState.active || (item.state === ProtectVKeyState.deactivated && item.unexportable === false);
      });

      if (params.state === "Active" || params.state === "Revoked") {
        const unexportableState = params.state === "Revoked";
        response.data.resources = response.data.resources.filter(item => item.unexportable === unexportableState);
      }

      response.data.total = response.data.resources.length; // update the `total` for UI pagination reasons

      return response;
    });
  }

  revoke(id: string): Promise<any> {
    return this.$http.patch(`${this.baseUrl}/${id}`, {
      unexportable: true,
    });
  }

  restore(id: string): Promise<any> {
    return this.$http.patch(`${this.baseUrl}/${id}`, {
      unexportable: false,
    });
  }

  /**
   * Destroys the PV Key
   * before we destroy it though, we check to see if it is `deactivated`
   * @param {string} id
   * @returns {Promise<any>}
   */
  destroy(id: string): Promise<any> {
    const key: ProtectVKey = this.get(id);

    // if a key is already in a `Deactivated` state it should not require deactivating state again
    if (key.state === ProtectVKeyState.deactivated) {
      return this.$http.post(`${this.baseUrl}/${id}/destroy`);
    } else {
      return this.deactivate(id)
        .then(() => this.$http.post(`${this.baseUrl}/${id}/destroy`));
    }
  }

  doCreate(data) {
    return Promise.resolve();
  }

  doDelete() {
    return Promise.resolve();
  }

  doFetch() {
    return Promise.resolve();
  }

  doSave() {
    return Promise.resolve();
  }

  /**
   * Sets a PV key as deactivated
   * UI has no reason to use this directly at this time
   * A key must be `deactivated` before it can be `destroyed`
   * @param {number} id   PV service key ID
   * @returns {Promise<any>}
   */
  private deactivate(id: string): Promise<any> {
    // make an empty PATCH request to get the `updatedAt` from the server
    // so we can set it for the `deactivationDate`
    // using date objects would fail and we want the closest date to the server time
    // this is kind of hacky but the backend is kind of hacky
    return this.$http.patch(`${this.baseUrl}/${id}`, {}) // send an empty object because we don't want to actually make any changes
      .then(response => response.data.updatedAt)
      .then(updatedAt => {
        return this.$http.patch(`${this.baseUrl}/${id}`, {
          deactivationDate: updatedAt,
        });
      });
  }

}

ProtectVService.$inject = ["haro", "$http"];
