import { filter, map, mergeAll } from 'rxjs/operators';
import { concat, from, Observable, ReplaySubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { IHttpService } from 'angular';
import { HttpService } from '@app/ajs-upgraded-providers';
import { DataStore } from '@app/components/service/data-store';
import { Tile, TileInternal, TileInternalCollection, TileServicePlan } from '@app/features/marketplace/tiles.interface';
import { Haro } from './haro.service';
import { cloneDeep as clone } from 'lodash';
import { AuthService } from '@app/features/auth';
import { TenantSubscription } from "@app/features/gem-services/services/tenant-subscription/tenant-subscription.interface";
import { TenantSubscriptions } from "@app/features/gem-services/services/tenant-subscription/subscription.constants";

/**
 * Client for the `/v1/tiles` API.
 *
 * Any callers interested in Tiles should use the observables returned by the `tiles()`
 * and `tilesFor()` methods to receive notification about tile data. Those methods will
 * "replay", so listeners added later on don't have to worry about missing the initial fetch.
 */
@Injectable()
export class TilesService extends DataStore<Tile> {
  private baseUrl = '/v1/tiles';

  // Emits all tiles that are returned by the server. Will replay the last Tile[] result
  // to any new subscribers. Note that this subject will emit Tiles for tenants other than
  // the one that the user is currently logged in to.
  private tilesSubject$ = new ReplaySubject<Tile[]>(1);

  allTiles: Tile[];

  constructor(
    @Inject(HttpService) private http: IHttpService, // TODO remove AngularJS dependency
    @Inject(Haro) haro: any,
    private authService: AuthService,
  ) {
    super(haro);

    // When a set() happens in the DataStore, it emits on storeStream. Connect that to our
    // tilesSubject so that we emit too. Note that we don't care about the actual event
    // from storeStream here, it's just a trigger for us to emit all the tiles again.
    this.storeStream.subscribe(_ => {
      this.tilesSubject$.next(this.getAll());
    });

    // Start fetching tiles
    this.list();
  }

  /**
   * Fetches all tiles from the server.
   *
   * @param tenantId ID of the tenant to query for tile availability. If not provided, will return
   * tiles for the tenant that the user is currently logged in to.
   * @returns A promise resolving to the tiles.
   */
  async list(tenantId?: string): Promise<Tile[]> {
    const params: { [key: string]: string } = tenantId ? { tenantId } : {};
    const res = await this.http.get<TileInternalCollection>(this.baseUrl, { params });
    const content = res.data.content;

    content.forEach(t => {
      this.set(this.annotate(t, tenantId));
    });

    const tiles = this.getAll();
    this.tilesSubject$.next(tiles);
    return tiles;
  }

  /**
   * Returns a list of Service plans about a specific service type
   * @param {string} tileId
   * @returns {IPromise<TileServicePlan>}
   */
  listServicePlans(tileId: string): Promise<TileServicePlan[]> {
    // TODO find a way to make IPromise<T> assignable to Promise<T> with .d.ts files
    return Promise.resolve(
      this.http.get<TileServicePlan[]>(`${this.baseUrl}/${tileId}/plans`)
        .then(res => res.data)
    );
  }

  /**
   * Gets a single tile
   * @param tileId
   * @returns A "live" observable for the tile
   */
  fetch(tileId: string): Observable<Tile> {
    return from(
      this.http.get<TileInternal>(`${this.baseUrl}/${tileId}`)
        .then(res => this.annotate(res.data))
    ).pipe(
      () => concat(this.observeTile(tileId))
    );
  }

  /**
   * Update a tile
   * @param tileId
   * @param changes
   * @returns A "live" observable for the tile
   */
  patch(tileId: string, changes: TilePatchRequest): Observable<Tile> {
    const config = {
      headers: {
        'content-type': 'application/json-patch+json'
      }
    };
    return from(
      this.http.patch<TileInternal>(`${this.baseUrl}/${tileId}`, changes, config)
        .then(res => {
          const tile = this.annotate(res.data);
          this.set(tile);
          return tile;
        })
    ).pipe(
      () => concat(this.observeTile(tileId))
    );
  }

  // Turns a TileInternal returned by the server into a Tile.
  private annotate(ti: TileInternal, tenantId: string = this.authService.getTenantId()): Tile {
    // For convenience, store the tenantId on each Tile. This allows us to use a single
    // subject to process all Tiles returned by the server (regardless of which tenant they
    // belong to), and easily filter them by tenantId later on to implement the `tiles` and
    // `tilesFor` streams.
    const tile = ti as Tile;
    tile.tenantId = tenantId;
    return tile;
  }

  /**
   * Helper for updating the `enabled` property of a tile
   */
  setEnabled(tileId: string, value: boolean): Observable<Tile> {
    // Set pendingAction flag while the operation is underway
    const tile = clone(this.get(tileId));
    tile.pendingAction = true;
    this.set(tile);

    return this.patch(tileId, [{
      op: "replace",
      path: '/enabled',
      value,
    }]);
  }

  /**
   * @returns An observable that emits the tiles for the current tenant.
   */
  tiles(): Observable<Tile[]> {
    return this.tilesFor(this.authService.getTenantId());
  }

  /**
   * @returns An observable that emits the tiles for the given tenant.
   *
   * **Note:** if `tenantId` is not the tenant that the user is currently
   * logged in to, you must have called `list(tenantId)` at some point to
   * fetch the tiles for the tenant. If you haven't done this then the returned
   * observable will not emit.
   */
  tilesFor(tenantId: string): Observable<Tile[]> {
    return this.tilesSubject$
    .pipe(
      map(ts => ts.filter(t => t.tenantId === tenantId))
    );
  }

  /**
   * @param tileId A live observable that emits the Tile whenever it changes
   */
  private observeTile(tileId: string): Observable<Tile> {
    return this.tilesSubject$
    .pipe(
      mergeAll(),
      filter(t => t.id === tileId)
    );
  }

  /**
   * Returns a list of tiles along with subscriptionInfo
   *
   */
  getTilesWithSubscriptionInfo(): Tile[] {
      return this.allTiles;
  }

  /**
   * Consolidates the subscriptionInfo within the tiles list for each tile
   *
   * @param subscriptions all subscriptions available
   */
  setTilesWithSubscriptionInfo(subscriptions) {
    // whenever setting the subscription info for the tiles, this ensures that it updates the most recent tiles array
    this.tiles().subscribe(tiles => {
      this.allTiles = tiles.map(t => ({
        ...t,
        subscriptionInfo: subscriptions ? this.getSubscriptionInfo(t.shortCode, subscriptions) : null
      }));
    });
  }

  /**
   * Determines if purchase is available for a particular tile based on subscriptions available for tile.
   * @param tile tile to be checked on
   */
  isPurchaseAvailableForTile(tile: Tile): boolean {
    // link available when we have a trial subscription that is active or expired
    return !!tile && !!tile.subscriptionInfo && tile.subscriptionInfo.length > 0 &&
      (tile.subscriptionInfo.some(s => s?.type === TenantSubscriptions.TYPE.TRIAL &&
        (s?.state === TenantSubscriptions.STATE.ACTIVE || s?.state === TenantSubscriptions.STATE.EXPIRED)
      ));
  }

  /**
   * Determines if purchase must be forced for a particular tile based on subscriptions available for tile.
   * @param tile tile to be checked on
   */
  isPurchaseRequiredForTile(tile: Tile): boolean {
    // we must force purchase when we have no active subscriptions, meaning that we have either
    // a trial subscription expired or a production subscription cancelled.
    const hasSubscriptions = !!tile && !!tile.subscriptionInfo && tile.subscriptionInfo.length > 0;
    return hasSubscriptions && !tile.subscriptionInfo.some(s => s?.state === TenantSubscriptions.STATE.ACTIVE);
  }

  /**
   * Gets the subscriptionInfo objects for a given short code ordered by state
   * @param shortCode short code for a particular service type
   * @param subscriptions all subscriptions
   */
  getSubscriptionInfo(shortCode: string, subscriptions): TenantSubscription[] {
    // returns an array with all subscriptions for the shortCode provided
    return subscriptions.filter(sub => sub.serviceType === shortCode).sort(function(a, b) {
      return a.state.localeCompare(b.state);
    });
  }
}

type TilePatchRequest = JSONPatchDocument[];

// Generic JSON patch types might need to get moved out
interface JSONPatchDocument {
  op: JSONPatchOperation;
  path: string;
  value?: any;
  from?: JSONPointer;
}

type JSONPatchOperation = "add" | "remove" | "replace" | "move" | "copy" | "test";

type JSONPointer = string;
