import { Pipe, PipeTransform } from "@angular/core";

/**
 * Replaces all occurrences of text in a string
 * example usage: `myModel | replaceCharactersWith: ' ': '_'` would replace spaces with underscore
 * @param {string} characterToReplace  to be treated as a RegExp, parameter is not escaped
 */
@Pipe({name: "replaceCharactersWith"})
export class ReplaceCharactersWithPipe implements PipeTransform {
  transform(value: string, characterToReplace: string, characterToReplaceWith: string): string {
    const regex: RegExp = new RegExp(characterToReplace, "g");
    return value.replace(regex, characterToReplaceWith);
  }
}
