import { Component, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ServiceStatus } from "@app/features/gem-services/services.constants";
import { HSMonDemandCreatedService } from "@app/features/gem-services/cloudHSM/cloudHSM.model";

@Component({
  selector: 'app-provision-dialog',
  templateUrl: './provision-dialog.component.html',
  styleUrls: ['./provision-dialog.component.scss']
})
export class ProvisionDialogComponent {
  @Input() service: HSMonDemandCreatedService;
  @Input() staticMessage: string;
  @Input() isStatusFailed: boolean

  constructor(public self: NgbActiveModal) { }
}
