import { Component, Inject } from '@angular/core';
import { AuthScopes } from "@app/ajs-upgraded-providers";
import { AuthService } from "@app/features/auth";
import { ConfigToken } from "@dpod/gem-ui-common-ng";
import { DpodUiConfig } from "@app/core/dpod-ui-config";

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})

export class SubscriptionsComponent {

  constructor(private authService: AuthService,
              @Inject(AuthScopes) private scopes: any,
              @Inject(ConfigToken) public config: DpodUiConfig
  ) {}

  isSubscriptionsTabAvailable(): boolean {
    return  this.config.FF_ENABLE_ZUORA &&
    (this.authService.hasScope(this.scopes.admin) || this.authService.hasScope(this.scopes.owner));
  }

}
