"use strict";
import module from './_init';

// Invalidates an input when the input's value matches any element of a list.
// It is used like this:
//
// <form name=myform>
//    <input name="main" ng-model="data.main" blacklist="data.forbiddenValues"/>
// </form>
//
// The 'blacklist' attribute (string[]) gives the list of forbidden values for the field.
// The field is marked as invalid if it takes any of the forbidden values.
module.directive('blacklist', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            list: '=blacklist',
        },
        link: function(scope, elem, attr, ngModel) {
            ngModel.$validators.blacklist = (modelValue, viewValue) => (scope.list || []).indexOf(viewValue) === -1;
        },
    };
});
