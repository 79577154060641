// Re-export some shared services for convenience of high-level UI code.
// Note: any low-level service code (i.e. anything provided in CoreModule) should NOT depend
// on this file, as doing so will often introduce circular dependencies.

export * from './docs.service';
export * from './requires-role.service';
export * from './tiles.service';
export * from './window';
export * from './service-broker.service';
export * from './keybroker.service';
export * from './service-categories.service';
