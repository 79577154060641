import {NgModule} from "@angular/core";
import {SessionLoggedOutComponent} from "./session-logged-out.component";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";

const sessionLoggedOutState = {
  name: "sessionLoggedOut",
  params: {
    expiredState: null, // Expired state- which has the hash we use to redirect the session to same page
    requiresRole: false, // A non logged-in user can view this state
  },
  component: SessionLoggedOutComponent,
};

@NgModule({
  declarations: [SessionLoggedOutComponent],
  imports: [
    UIRouterUpgradeModule.forChild({
      states: [sessionLoggedOutState],
    }),
  ],
  entryComponents: [SessionLoggedOutComponent],
})
export class SessionLoggedOutModule {
}

