import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { AuthScopes } from "@app/ajs-upgraded-providers";
import { DialogService } from "@app/components";
import { AuthService } from "../auth";
import { FEATURE_TOGGLES } from "../feature-toggles";
import { TenantCreateBody } from "./tenant.model";
import { TenantsService } from "./tenants.service";
import { TenantWizardComponent } from "./wizard/tenant.wizard.component";

interface Cards {
  title: string;
  svg: string;
  content: string;
  action: () => {},
  locator?: string;
}

export enum AccountTypeLabel {
  ServiceProvider = "Service Provider",
  Evaluation = "Evaluation",
}

@Component({
  templateUrl: "./tenants.html",
  styleUrls: ["./tenants.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TenantsComponent {

  cards: Cards[];

  constructor(private DialogService: DialogService,
              private TenantsApiService: TenantsService,
              private AuthService: AuthService,
              @Inject(AuthScopes) private AuthScopes: any,
              @Inject(FEATURE_TOGGLES) private FeatureToggles: any,
  ) {


    this.cards = [
      {
        title: "Service Provider",
        svg: "#card-virtualServiceProvider",
        content: "A tenant account for customers that want to manage other tenants.",
        action: this.openTenantWizard.bind(this, "service provider", AccountTypeLabel.ServiceProvider),
        locator: "add-service-provider",
      },
      {
        title: "Tenant",
        svg: "#card-evaluation",
        content: "A tenant account allowing customers to try and purchase Data Protection on Demand services.",
        action: this.openTenantWizard.bind(this, "subscriber", ''),
        locator: "add-evaluation",
      },
    ];
  }

  openTenantWizard(accountType: "service provider" | "subscriber", accountTypeLabel: AccountTypeLabel): void {
    const modal = this.DialogService.open<TenantWizardComponent>(TenantWizardComponent, {
      windowClass: 'modal-wide'
    });
    const wizard = modal.componentInstance;
    wizard.accountType = accountType;
    wizard.accountTypeLabel = accountTypeLabel;

    const sub = wizard.submit.subscribe((tenantInfo: TenantCreateBody) => {
      const progress = this.DialogService.progress("Creating Tenant...");
      this.TenantsApiService.create(tenantInfo)
      .finally(() => progress.close());

      sub.unsubscribe();
    });
  }

  hasTenants(): boolean {
    return this.TenantsApiService.getAll().length > 0;
  }

  isSpAdmin(): boolean {
    return this.AuthService.hasScope(this.AuthScopes.spadmin);
  }
}
