// file to hold common used short_codes for services where we have edge cases
export enum ServiceShortCode {
  p2pe = 'p2pe',
}

export enum ServiceStatus {
  Provisioning = 'PROVISIONING',
  Deprovisioning = 'DEPROVISIONING',
  Provisioned = 'PROVISIONED',
  ProvisioningFailed = 'PROVISIONING_FAILED',
  DeprovisioningFailed = 'DEPROVISIONING_FAILED',
  Deprovisioned = 'DEPROVISIONED',
  // static messages for popup dialogue
  PROVISIONING_MSG = "The service is being provisioned. It should be available shortly.",
  DEPROVISIONING_MSG = "The service is being deprovisioned. It should be removed shortly.",
  PROVISIONING_FAILED_MSG = "An unexpected error has occurred. Please contact",
  DEPROVISIONING_FAILED_MSG = "An unexpected error has occurred. Please contact",
}

export const cloudProviderRegionMap = {
  "gcp": {
    "label": "GCP",
    "regions": {
      "us-west1": "us-west1 (Oregon)",
      "us-central1": "us-central1 (Iowa)",
      "europe-west3": "europe-west3 (Frankfurt)",
      "us-east1": "us-east1 (South Carolina)",
    }
  }
}

export const progress_create_message = "Preparing Service...";
export const progress_delete_message = "Deleting Service…";
