<div [class.alert-dismissible]="isDismissible" class="alert {{class}} input-group border-0 p-0" role="alert">

    <div class="input-group-addon d-flex align-items-center rounded-left">
        <span class="input-group-text border-0">
            <svg class="icon-svg align-sub" role="button">
                <use [attr.xlink:href]="icon"></use>
            </svg>
        </span>
    </div>
    <div class="form-control">
        <!-- without this additional div all HTML tags inside the ng-content will go to new lines -->
        <div class="p-1">
            <ng-content></ng-content>
            <a *ngIf="isDismissible" href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
        </div>
    </div>

</div>
