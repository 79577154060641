import {Injector} from "@angular/core/core";
import {UserAgentSniffer} from "./user-agent-sniffer.service";
import {UIRouter} from '@uirouter/core';
import { AuthService } from "../auth";

/**
 * Registers a hook that enforces the unsupported-browser transitions.
 * Unsupported browsers must view the "unsupportedbrowser" state only, and cannot leave it.
 */
export function unsupportedBrowserHook(uiRouter: UIRouter, injector: Injector) {
  const transitionService = uiRouter.transitionService;

  const authService = injector.get(AuthService);
  const userAgentSniffer = injector.get(UserAgentSniffer);

  const unsupportedState = "unsupportedBrowser";

  const redirect = transition => {
    const $state = transition.router.stateService;
    const to = transition.to().name;

    if (userAgentSniffer.isBrowserSupported()) {
      return;
    }

    // At this point we know the browser is bad
    authService.reset();
    if (transition.from().name === unsupportedState) {
      // We're already in the unsupported state: skip all subsequent hooks so
      // that user cannot escape from this state using another transition.
      return false;
    }

    if (to !== unsupportedState) {
      return $state.target(unsupportedState);
    }
  };

  // Super high priority: nothing else in the app should show if browser is unsupported
  transitionService.onBefore({/*match all*/}, redirect, {priority: 999});
}
