import {Inject, Injectable} from '@angular/core';
import {WindowToken} from '@app/shared/services';

@Injectable()
export class UserAgentSniffer {
  ua: string;

  constructor(@Inject(WindowToken) private window: Window) {
    this.ua = window.navigator.userAgent;
  }

  isBrowserSupported(): boolean {
    const ua = this.ua;
    if (/Chrome|Firefox/.test(ua)) {
      // Chrome, Firefox, or Edge - OK
      // Opera - no
      return ua.indexOf('OPR') === -1
    } else if (/Safari/.test(ua)) {
      // Safari - OK
      return true;
    }
    // anything else - no
    return false;
  }
}
