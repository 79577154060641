import _module from "../_init";
import template from "./modal-createSubscribers.content.html";

/**
 * Modal dialog to create/edit a subscriber group.  The 'resolve' object
 * can have the following fields:
 *
 * currentGroup (optional) - if 'currentGroup' is set to a subscriber group entity,
 *   the modal will be presented in "edit" mode.  if no group is set, the modal
 *   will be presented in "add" mode.
 */
class CreateSubscribersGroupController {
   constructor() {
      this.descriptionMaxLength = 512;
   }

   $onInit() {
      this.noSubscriberGroups = this.resolve.noSubscriberGroups || false;

      if (this.resolve.currentGroup) {
         this.group = this.resolve.currentGroup;
         this.mode = "Edit";

      } else {
         this.group = {
            name: "",
            description: "",
         };
         this.mode = "Add";
      }
   }

    save() {
       this.close({ $value: this.group });
    }

    getHeaderLabel() {

      if (this.noSubscriberGroups) {
         return `You don't have a Subscriber Group yet.`;
      }

      return `${this.mode} Subscriber Group`;
   }
}

_module.component('createSubscriberGroup', {
   template,
   bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
   },
   controller: CreateSubscribersGroupController,
});
