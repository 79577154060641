<div class="page panel-width">
    <ngb-tabset class="main-tabs" [class.app-owner]="isAppOwnerOnly()" type="pills">
        <ngb-tab id="tabServicesTenant">
            <ng-template ngbTabTitle>
                <div data-locator="tab-services">View Services</div>
            </ng-template>
            <ng-template ngbTabContent>
                <div *ngIf="hasSynced" class="panel-width">
                    <section *ngIf="!isAppOwnerOnly()">
                        <services-table *ngIf="hasServices()"></services-table>

                        <div *ngIf="!hasServices()">
                            <header class="gem-header section-subheader">You haven’t added any services yet.
                            </header>
                            <div *ngIf="hasTiles">
                                Click on the ‘Add Service’ tab to deploy and manage services.
                            </div>
                            <div *ngIf="!hasTiles">
                                Visit the <a href="https://cloud.google.com/marketplace" externalLink>Google Cloud Marketplace</a> to add a service.
                            </div>
                        </div>
                    </section>

                    <div *ngIf="isAppOwnerOnly()">
                        <ngb-tabset type="pills">
                            <ngb-tab id="tabServices" *ngIf="hasServices()">
                                <ng-template ngbTabTitle>
                                    <div data-locator="tab-my-services">My Services</div>
                                </ng-template>
                                <ng-template ngbTabContent>
                                    <services-table></services-table>
                                </ng-template>
                            </ngb-tab>

                            <ngb-tab *ngIf="hasTiles" id="tabAddNew">
                                <ng-template ngbTabTitle><ng-container *ngTemplateOutlet="addServicesTitle"></ng-container></ng-template>
                                <ng-template ngbTabContent><ng-container *ngTemplateOutlet="addServicesContent"></ng-container></ng-template>
                            </ngb-tab>
                        </ngb-tabset>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="isTenantAdmin() && hasTiles" data-locator="tab-add" id="tabAdd">
            <ng-template ngbTabTitle><ng-container *ngTemplateOutlet="addServicesTitle"></ng-container></ng-template>
            <ng-template ngbTabContent><ng-container *ngTemplateOutlet="addServicesContent"></ng-container></ng-template>
        </ngb-tab>

        <ngb-tab *ngIf="hasTiles">
            <ng-template ngbTabTitle>
                <div data-locator="tab-configure-services">Configure Services</div>
            </ng-template>
            <ng-template ngbTabContent>
                <services-marketplace></services-marketplace>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>

</div>

<ng-template #clientPrompt let-ctx>
    <p>Your {{ctx.serviceType}} service has been added to Data Protection on Demand.</p>
    <p>Do you wish to create a Service Client to connect to this service? If you select "Not Now", you can create one later from
        the service detail page.</p>
</ng-template>

<ng-template #addServicesTitle>
    <div data-locator="tab-add-service">Add Service</div>
</ng-template>

<ng-template #addServicesContent>
    <div *ngIf="isSubscriptionApiDown">
        <header class="gem-header section-subheader">Service provisioning is currently not available. Please refresh the page or try again later.
        </header>
    </div>

    <ng-container *ngIf="!isSubscriptionApiDown">
        <div *ngIf="!hasServices()" class="mb-4">
            <div class="gem-header section-header color-primary">You haven't added any
                services yet.
            </div>
            <span class="no-resource-prompt">Click on a tile below to get started.</span>
            <hr class="mt-3 mb-4"/>
        </div>

        <tiles-create-view [serviceCategoriesStream]="this.serviceCategories" [tilesStream]="this.tiles$" (tileClick)="tileClick($event)">
        </tiles-create-view>
    </ng-container>
</ng-template>
