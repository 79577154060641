import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { DpodTableComponent } from "@app/components/dpod-table/dpod-table.component";
import { TenantSubscription } from "@app/features/gem-services/services/tenant-subscription/tenant-subscription.interface";
import { AuthService } from "@app/features/auth";
import { AuthScopes } from "@app/ajs-upgraded-providers";
import { ConfigToken } from "@dpod/gem-ui-common-ng";
import { DpodUiConfig } from "@app/core/dpod-ui-config";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import moment, { Duration } from "moment";
import { TermIso8601Pipe } from "@app/shared/pipes/term-iso8601.pipe";
import { DateFormatPipe } from "@app/shared/pipes/date-format.pipe";

export const columnNames = [
  'serviceType',
  'state',
  'number',
  'startDate',
  'endDate',
  'autoRenewalPeriod',
  'plan',
  'quantity',
];

@Component({
  selector: 'subscriptions-table',
  templateUrl: './subscriptions-table.component.html',
  styleUrls: ['./subscriptions-table.component.scss']
})

export class SubscriptionsTableComponent implements OnInit, AfterViewInit {
  /** Overrides the list of columns to show. */
  @Input() displayedColumns = columnNames.slice();
  @Input() tableData: TenantSubscription[];
  @Input() services;

  @ViewChild('table', { static: true }) private table: MatTable<TenantSubscription>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(DpodTableComponent, { static: true }) private dpodTable: DpodTableComponent;

  dataSource = new MatTableDataSource<TenantSubscription>();
  termIso8601Pipe = new TermIso8601Pipe();
  dateFormatPipe = new DateFormatPipe();

  constructor(private authService: AuthService,
              @Inject(AuthScopes) private scopes: any,
              @Inject(ConfigToken) public config: DpodUiConfig,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.dataSource.data = this.tableData || [];
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor.bind(this);
    this.dataSource.filterPredicate = this.filterPredicate.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tableData']) {
      this.dataSource.data = changes['tableData'].currentValue;
    }
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    // reset and set the sort by serviceType value
    this.sort.sort({ id: '', start: 'asc', disableClear: false });
    this.sort.sort({ id: 'serviceType', start: 'asc', disableClear: false });
    (this.sort.sortables.get('serviceType') as MatSortHeader)._setAnimationTransitionState({ toState: 'active' });
    this.changeDetectorRef.detectChanges();

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.dpodTable.paginator;
    this.table.renderRows();
  }

  ngOnDestroy() {
    this.sort.sort({id: '', start: 'asc', disableClear: false});
  }

  sortingDataAccessor(subscription: TenantSubscription, columnName: string): string|Date|number|Duration {
    switch (columnName) {
       case 'serviceType':
         return this.getServiceTypeName(subscription.serviceType).toLowerCase();
       case 'autoRenewalPeriod':
         return moment.duration(subscription.autoRenewalPeriod).abs() || '';
       case 'quantity':
         return this.getQuantity(subscription.features);
      case 'startDate':
        return this.dateFormatPipe.transform(subscription.startDate);
      case 'endDate':
        return this.dateFormatPipe.transform(subscription.endDate);
       default:
         // Otherwise the column name matches the field name
         return subscription[columnName];
    }
  }

  /**
   * See https://material.angular.io/components/table/api
   * @param subscription the subscription element considered
   * @param filter the string used to filter
   */
  filterPredicate(subscription: TenantSubscription, filter: string): boolean {
    // Reuse the sorting data accessor to provide the data to check the filter against.
    // If any column's sorting data matches the filter, return true.
    return this.displayedColumns.some(column => {
      const sortingData = (column === 'autoRenewalPeriod') ?
        this.termIso8601Pipe.transform(subscription.autoRenewalPeriod) :
        this.sortingDataAccessor(subscription, column);
      return typeof sortingData === 'string' && sortingData.toLowerCase().includes(filter.toLowerCase());
    });
  }

  onFilter(text: string) {
    this.dataSource.filter = text;
  }

  /**
   * @param features lists of subscriptions features
   * @returns Value of Quantity, if Quantity is available in features
   */
  getQuantity(features: any): string {
    if (features && features['quantity']) {
      return features['quantity']
    }
    return '';
  }

  /**
   * @param shortCode code corresponding to a service type
   * @returns Service Type Name associated with shortCode, or shortcode if Name is not available
   */
  getServiceTypeName(shortCode: string): string {
    return this.services[shortCode] || shortCode;
  }
}
