import { CommonModule, Location } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { LocationService } from '@app/ajs-upgraded-providers';
import { DpodUiConfig } from '@app/core/dpod-ui-config';
import { WindowToken } from '@app/shared/services';
import { ConfigToken } from '@dpod/gem-ui-common-ng';
import { environment } from '@env/environment';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from './auth.service';
import { AuthServiceHodor } from './auth.service.hodor';
import { MockAuthServiceProvider } from './auth.service.mock';
import { JwtDecode, JwtDecodeProvider } from './jwt-decode';
import { OAuth2ClientJS, OAuth2ClientJSProvider } from './oauth2-client-js';
import { SessionExpiryInterceptor } from './session-expiry.interceptor';
import { SessionExpiryService } from './session-expiry.service';
import { IAuthService } from './auth.interface';

@NgModule({
  imports: [
    CommonModule
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        (
          environment.mockAuth
            ? MockAuthServiceProvider
            : RealAuthServiceProvider
        ),
        JwtDecodeProvider,
        OAuth2ClientJSProvider,
        SessionExpiryService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SessionExpiryInterceptor,
          multi: true,
        },
      ]
    };
  }
}

const RealAuthServiceProvider: Provider = {
  provide: AuthService,
  useFactory: AuthServiceFactory,
  deps: [
    ConfigToken,
    Location,
    WindowToken,
    LocationService,
    OAuth2ClientJS,
    JwtDecode,
  ]
};

/**
 * Factory that constructs either the legacy implicit auth service or the new
 * session-cookie based (Hodor) one.
 */
export function AuthServiceFactory(
  config: DpodUiConfig,
  locationNg2: Location,
  window: Window,
  location: ng.ILocationService,
  oauth2: any,
  jwtDecode: any
): IAuthService {
  if (config.FF_USE_HODOR) {
    return new AuthServiceHodor(window, locationNg2);
  } else {
    return new AuthService(window, location, oauth2, jwtDecode);
  }
}
