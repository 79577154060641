<div class="card summary border-0">
    <div *ngIf="service" class="row">
        <div class="col-sm-8">
            <div class="card-box">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-4 text-right">Service Name</div>
                            <div class="col-8">
                                <strong>{{service.name}}</strong>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4 text-right">Salesforce User Name</div>
                            <div class="col-8">
                                <strong>{{service.username}}</strong>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4 text-right">Salesforce Instance URL</div>
                            <div class="col-8">
                                <strong>{{service.tenantInstance}}</strong>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4 text-right">Salesforce User ID</div>
                            <div class="col-8">
                                <strong>{{service.userId}}</strong>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4 text-right">Salesforce Organization ID</div>
                            <div class="col-8">
                                <strong>{{service.organizationId}}</strong>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4 text-right">Salesforce Display Name</div>
                            <div class="col-8">
                                <strong>{{service.displayName}}</strong>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>

        <div class="col-sm-4">
            <div class="card-box mb-2">
                <h6 class="color-primary">Tenant Secrets</h6>
                <hr/>
                <div class="row" *ngFor="let secret of secretsStatusListSorted()">
                    <div class="col-sm-6 text-nowrap">
                        <strong>{{secret.name}}</strong>
                    </div>
                    <div class="col-sm-6 text-right">
                        <div [ngSwitch]="secret.status.message">
                            <div *ngSwitchCase="'pending'" class="color-primary">
                                <spinner class="icon-svg"></spinner>
                                Generating...
                            </div>
                            <div *ngSwitchCase="'success'" class="color-success finished-state">
                                <svg class="icon-svg success align-top">
                                    <use xlink:href="#check"/>
                                </svg>
                                Done
                            </div>
                            <div *ngSwitchCase="'not generating'" class="color-disabled">
                                Not Generated
                            </div>
                            <div *ngSwitchDefault class="color-fail finished-state">
                                <svg class="icon-svg danger align-top">
                                    <use xlink:href="#warning"/>
                                </svg>
                                Failed
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="secretGenerationError()">
                        <span
                            class="color-fail">There was an error generating one or more of your tenant secrets.</span>
                <button class="fake-link-button d-inline" (click)="learnMoreAboutSecretsError()">Learn
                    More</button>
            </div>
        </div>
    </div>
</div>
