export interface ProtectVKey {
  id: string;
  uri: string;
  account: string;
  application: string;
  devAccount: string;
  createdAt: string;
  name: string;
  updatedAt: string;
  activationDate: string;
  state: ProtectVKeyState;
  usage: "blob";
  usageMask: number;
  meta: null; // unknown
  defaultIV: string;
  objectType: "Symmetric Key";
  version: number;
  algorithm: "AES";
  size: number;
  format: "raw";
  unexportable: boolean;
  undeletable: boolean;
}

export interface ProtectVKeyFormatted extends ProtectVKey {
  formattedUpdatedAt: string;
  formattedCreatedAt: string;
}

// a number of these states aren't used in dpod ui
export enum ProtectVKeyState {
  preActive = "Pre-Active",
  active = "Active",
  deactivated = "Deactivated",
  destroyed = "Destroyed",
  compromised = "Compromised",
  destroyedCompromised = "Destroyed Compromised"
}

export const keystoreEndpoint = "/keystore";

export const deleteServiceWarning = "By deleting this service, you will lose access to all encrypted virtual machines associated with this service.";
