<h5 class="mb-3">
    <svg class="icon-svg">
        <use xlink:href="#trash"/>
    </svg>
    Destroy Key?
</h5>


<form>
    <div class="row form-group">
        <div class="col-sm-12">
            <div class="alert alert-danger" role="alert">
                <svg class="icon-svg">
                    <use xlink:href="#warning" class="alert-text"/>
                </svg>
                <strong>Warning! This is an unrecoverable operation!</strong>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-sm-12">
            This key will be permanently destroyed, and the VM will not be able to access the volume associated with
            this key.
        </div>
    </div>

    <hr/>

    <div class="row form-group">
        <div class="col-sm-12">
            <p>Please type <strong>DESTROY</strong> to confirm.</p>
            <input id="destroy-input" name="destroy-input" class="form-control"
                   [(ngModel)]="destroyInput"
                   validation-icon
                   required autofocus>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="float-sm-left">
            <button class="btn btn-next" name="delete" type="button" (click)="destroy.emit({key: key})"
                    [disabled]="isDisabled()">Delete
            </button>
            <button class="btn btn-default" name="cancel" type="button" (click)="close.emit()">Cancel</button>
        </div>
    </div>

</form>
