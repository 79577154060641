import { Component, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

/**
 * For now, this is just an NG2 wrapper around the NG1 component gemSubscriberGroups.
 * Should eventually move the NG1 logic into this component.
 */
@Component({
  selector: 'gem-subscriber-groups',
  template: '',
})
export class SubscriberGroupsComponent extends UpgradeComponent {

  constructor(elementRef: ElementRef, injector: Injector) {
    super("gemSubscriberGroups", elementRef, injector);
  }

}
