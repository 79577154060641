<div class="dialog" data-locator="progress">
    <div class="modal-body">
        <div class="row">
            <div class="col-2">
                <div class="wrapper">
                    <spinner></spinner>
                </div>
            </div>
            <div class="col-10">
                <div class="wrapper">
                    <h5>{{text}}</h5>
                    <div class="progress-sub-text" *ngIf="subText">{{subText}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
