{{title}}

<div *ngIf="src" class="details-panel main-panel image-panel d-flex align-items-start">
    <img #previews [src]="src" *ngFor="let p of showPreviews" class="preview" width="{{p[0]}}" height="{{p[1]}}" alt="preview">
</div>

<div *ngIf="editing" class="d-flex align-items-center flex-wrap">
    <div class="d-flex align-items-center">
        <label class="m-0 upload-label">
            <input type="file" #fileInput class="form-control" [accept]="accept" (change)="fileChange($event)">
            <button class="form-control btn btn-sm btn-default upload-btn" name="upload" (click)="uploadClick
            ($event)
">{{uploadPrompt}}</button>
        </label>
        <div class="grid-icon icon-inside-icon ml-1 mr-1">
            <span class="grid-action" [ngClass]="{ 'fieldset-disabled': !this.file }">
                <svg class="icon-svg outer-icon"><use xlink:href="#action-box"/></svg>
                <svg class="icon-svg inner-icon" (click)="reset()"><use xlink:href="#trash"/></svg>
            </span>
        </div>
    </div>

    <span class="tip">{{tip}}</span>
</div>
