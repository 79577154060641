import { Attribute, Directive, forwardRef } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";

/**
 * By default, HTML will limit the maxlength of a field so that the user cannot type
 * This directive will invalidate a field if the number of characters exceeds the value given, but will allow the user to keep typing
 *
 * Usage:
 * <input maxlen="64" />
 */
@Directive({
  selector: "[maxlen]",
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxlenDirective), multi: true}
  ]
})
export class MaxlenDirective implements Validator {

  private _maxlen: number;

  constructor(@Attribute("maxlen") public maxlen: string) {
    this._maxlen = parseInt(maxlen, 10);
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (c.value && c.value.length > this._maxlen) {
      return {
        maxlen: true,
      };
    }

    return null;
  }

}
