import { AfterViewInit, Component, EventEmitter, Inject, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DpodTableComponent } from '@app/components/dpod-table/dpod-table.component';
import * as Roles from '@app/features/auth/roles.constants';
import { RequiresRoleService } from '@app/shared/services';
import { HSMonDemandClient } from "../cloudHSM.model";
import { AuthService } from "@app/features/auth";
import { HSMonDemandCreatedService } from "@app/features/gem-services/cloudHSM/cloudHSM.model";
import { ServiceShortCode } from "@app/features/gem-services/services.constants";

@Component({
  selector: 'cloud-hsm-clients-table',
  templateUrl: './cloudHSM-clients.table.component.html',
  styleUrls: ['./cloudHSM-clients.table.component.scss'],
})
export class CloudHSMClientsTableComponent implements AfterViewInit {
  displayedColumns = ['name', 'createdBy', 'createdAt', 'actions'];
  dataSource = new MatTableDataSource<HSMonDemandClient>();

  /** Clients to display in the table. */
  @Input() clients: HSMonDemandClient[];
  @Input() service: HSMonDemandCreatedService;

  @Output() create: EventEmitter<string> = new EventEmitter();
  @Output() download: EventEmitter<string> = new EventEmitter();
  @Output() delete: EventEmitter<string> = new EventEmitter();

  // Note: these ViewChilds will be null when the "empty state" is being shown
  // since no table is rendered.
  @ViewChild('table') private table: MatTable<HSMonDemandClient>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(DpodTableComponent) private dpodTable: DpodTableComponent;

  constructor(
    private requiresRoleService: RequiresRoleService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    if (!this.isSameSubscriberGroup()) {
      // Remove actions column
      this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
    }

    this.dataSource.data = this.clients;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor.bind(this);
  }

  ngAfterViewInit() {
    // Wait for AfterViewInit for the ViewChilds to be populated.
    if (this.table) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.dpodTable.paginator;
      this.table.renderRows();
    }
  }

  ngOnChanges(changes: SimpleChanges[]) {
    if (changes['clients']) {
      // clients changed
      this.dataSource.data = this.clients;
    }
  }

  isEmpty(): boolean {
    return this.dataSource.data.length === 0;
  }

  isAppOwner(): boolean {
    return this.requiresRoleService.hasRole(Roles.owner);
  }

  isSameSubscriberGroup(): boolean {
    return this.service.subscriberGroup === this.authService.getSubscriberGroupId()
  }

  sortingDataAccessor(client: HSMonDemandClient, columnName: string): string | Date {
    if (columnName === 'createdAt') {
      return new Date(client.createdAt); // need Date for chronological sort
    }
    return client[columnName]; // otherwise use the field with the same name as the column
  }

  onFilter(value: string) {
    this.dataSource.filter = value;
  }

  getHelpLink(): string {
    return this.service.serviceType === ServiceShortCode.p2pe ?
      '/dpod/services/ps_cloud_services/p2pe/' :
      '/dpod/services/luna_cloud_hsm/service/quick_start/index.html';
  }

}
