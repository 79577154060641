import { Inject, Injectable } from '@angular/core';
import { ConfigToken } from "@dpod/gem-ui-common-ng";
import { DpodUiConfig } from "@app/core/dpod-ui-config";
import { LinksData, PortalLink } from "@app/shared/services/portal-links";

@Injectable()
export class DocsService {
  // make this an instance variable as it is used in two places
  // const baseUrl = this.config.DOCS_BASE_URL ?? 'https://thalesdocs.com';
  constructor(@Inject(ConfigToken) public config: DpodUiConfig, @Inject(LinksData) private linksData: PortalLink[]) {
  }

  /**
   *  Takes the DOCS_BASE_URL and appends the path to it
   *  for dpod links, they should be prefixed with `/dpod` otherwise it'll link to other Thales products
   *
   *  Consider using `getDocsLinkByPortalId` if the ID exists in portal-links.json
   *
   *  @param [path] Path after the DOCS_BASE_URL, by default we will link to DPoD documentation
   *  @return absolute URL to documentation link
   */
  getDocsLink(path = '/dpod/'): string {
    // in local development, DOCS_BASE_URL will be undefined
    // passing in undefined to the URL function will throw an error
    // therefore we default to thalesdocs.com
    // this should only be an issue when developing and never on a public domain
    const baseUrl = this.config.DOCS_BASE_URL ?? 'https://thalesdocs.com';
    return new URL(path, baseUrl).href;
  }

  /**
   * Returns URL from the portal-links.json file by the Id specified there
   * @param docsPortalLinkId
   * @returns the full URL
   */
  getDocsLinkByPortalId(docsPortalLinkId: string): string {
    const link = this.linksData.find((item: PortalLink) => item.id === docsPortalLinkId);
    if (link) {
      return this.getDocsLink(link.url)
    }
    // if we do not find the matching URL in portal links,
    // we will throw an error in hopes that the UI tests will fail and we will be alerted of this
    // before going into production
    throw new Error(`Could not find documentation portal link: '${docsPortalLinkId}'`);
  }

  /**
   *  Takes the DOCS_BASE_URL and appends the changelog path to it.
   *
   *  @return absolute URL to changelog link
   */
  getChangelogLink(): string {
    const path = '/dpod/support/changelog/';
    // in local development, DOCS_BASE_URL will be undefined
    // passing in undefined to the URL function will throw an error
    // therefore we default to thalesdocs.com
    // this should only be an issue when developing and never on a public domain
    const baseUrl = this.config.DOCS_BASE_URL ?? 'https://thalesdocs.com';
    return new URL(path, baseUrl).href;
  }

}
