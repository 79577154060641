<h5>Delete Account for <span class="tenant-name-whitespace">{{verifyName}}</span>?</h5>
<form #deleteForm="ngForm">
    <div class="row form-group">
        <div class="col-sm-12">
            <div class="alert alert-danger" role="alert">
                <svg class="icon-svg">
                    <use xlink:href="#warning" class="alert-text" />
                </svg>
                <strong>Warning! This is an unrecoverable operation!</strong>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-sm-12">
            <span>Deleting this tenant will destroy its associated users, credentials, and clients.</span>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-sm-12">
            <span>You will not be able to delete this tenant until all of its associated services are deleted first.</span>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-sm-12">
            <span>Please confirm by entering this tenant's account name below.</span>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-8">
            <label for="tenantName" class="control-label">Tenant Account Name</label>
            <input id="tenantName" name="tenantName" class="form-control"
                #tenantNameField="ngModel"
                [(ngModel)]="enteredName"
                (input)="onTenantNameChange()"
                validation-debounce validation-icon required autofocus>
            <div *ngIf="tenantNameField.dirty" id="error-message-tenantName" class="text-danger error-message" role="alert">
                <div *ngIf="tenantNameField.errors && tenantNameField.errors['invalid']">Please enter the correct tenant account name</div>
            </div>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="float-sm-left">
            <button class="btn btn-next" name="delete" type="button"
                (click)="delete()"
                [disabled]="isDisabled()">Delete</button>
            <button class="btn btn-default" name="cancel" type="button" (click)="dismiss()">Cancel</button>
        </div>
    </div>

</form>
