import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { LinksData, PlacementsData, PortalLink, PortalLinkPlacement } from '@app/shared/services/portal-links';
import { StateService, Transition, TransitionService } from '@uirouter/core';

@Component({
  selector: 'gem-help-drawer',
  templateUrl: './help-drawer.component.html',
  styleUrls: ['./help-drawer.component.scss']
})
export class HelpDrawerComponent implements OnInit, OnDestroy {
  helpVisible = false;
  links: PortalLink[] = [];

  private unregister: Function;

  constructor(
    private transitionService: TransitionService,
    private stateService: StateService,
    @Inject(LinksData) private linksData: PortalLink[],
    @Inject(PlacementsData) private placements: PortalLinkPlacement[],
  ) { }

  ngOnInit() {
    this.unregister = this.transitionService.onSuccess({}, this.onTransition.bind(this), {priority: 100});
    this.links = this.getPlacementLinks(this.stateService.current.name);
  }

  ngOnDestroy() {
    if (this.unregister) {
      this.unregister();
    }
  }

  toggleVisible(): void {
    this.helpVisible = !this.helpVisible;
  }

  hasLinks(): boolean {
    return this.links.length > 0;
  }

  /**
   * Called whenever the UI router successfully transitions to a new router state.
   * Updates the placement links.
   *
   * @param transition The router transition
   */
  private onTransition(transition: Transition) {
    const to = transition.to();
    const placementName = to.name;
    this.links = this.getPlacementLinks(placementName);
  }

  private getPlacementLinks(placementName: string): PortalLink[] {
    const placement = this.placements.find(placement => placement.screen === placementName);

    if (placement) {
      return this.linksData.filter(link => placement.links.includes(link.id));
    }

    return [];
  }

}
