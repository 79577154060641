import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SharedModule } from '@app/shared';
import { configureUnverifiedState } from './unverified-user.hook';
import { UnverifiedComponent } from './unverified.component';
import { unverifiedStateName } from './unverified.constants';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states: [{
        name: unverifiedStateName,
        component: UnverifiedComponent,
      }],
      config: configureUnverifiedState,
    }),
  ],
  declarations: [UnverifiedComponent],
  entryComponents: [UnverifiedComponent],
})
export class UnverifiedModule { }
