"use strict";
import angularHaro from "../../components/angular-haro";
import serviceCardModule from "../../components/service-card";
import { usersEditModule } from "../../components/users-edit";
import { usersTableModule } from "../../components/users-table";

export default angular.module("app.spadminusers", [
    serviceCardModule,
    'angularjs-dropdown-multiselect', angularHaro,
    usersTableModule, usersEditModule,
    'ui.router',
]);
