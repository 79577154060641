export * from "./prompt-redirect.component";

// AngularJS compatibility
import { downgradeComponent } from "@angular/upgrade/static";
import * as angular from "angular";
import { PromptRedirectComponent } from "./prompt-redirect.component";

export default angular.module("dpaas.promptRedirect", [])
  .directive("promptRedirect", downgradeComponent({component: PromptRedirectComponent}))
  .name;
