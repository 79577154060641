<dpod-table (filter)="onFilter($event)">
    <ng-content></ng-content>

    <table #table mat-table matSort [dataSource]="dataSource">
        <caption class="d-none">List of Azure keys</caption>

        <!-- State column -->
        <ng-container matColumnDef="state">
            <th scope="col" class="state-column" mat-header-cell mat-sort-header *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let key" class="state-column">
                <span [ngSwitch]="key.state">
                    <span title="Active" *ngSwitchCase="'active'">
                        <svg class="icon-svg check align-middle">
                            <use xlink:href="#check" />
                        </svg>
                    </span>
                    <span title="Revoked" *ngSwitchCase="'revoked'">
                        <svg class="icon-svg revoke align-middle">
                            <use xlink:href="#revoke" />
                        </svg>
                    </span>
                </span>
            </td>
        </ng-container>

        <!-- Key ID column -->
        <ng-container matColumnDef="keyId">
            <th scope="col" class="key-id-column" mat-header-cell mat-sort-header *matHeaderCellDef>Key ID</th>
            <td mat-cell *matCellDef="let key" class="key-id-column">
                <span class="copy-column">{{key.id}}</span>
                <span title="Copy to Clipboard" class="icon-inside-icon copy">
                    <span class="grid-action" role="button" tabindex="0"
                          (click)="copy(key.id)">
                        <svg class="icon-svg outer-icon">
                            <use xlink:href="#action-box" />
                        </svg>
                        <svg class="icon-svg inner-icon">
                            <use xlink:href="#copy" />
                        </svg>
                    </span>
                </span>
            </td>
        </ng-container>

        <!-- Created At column -->
        <ng-container matColumnDef="createdAt">
            <th scope="col" class="created-at-column" mat-header-cell mat-sort-header *matHeaderCellDef>Created At</th>
            <td mat-cell *matCellDef="let key" class="created-at-column">
                {{ key.formattedCreatedAt }}
            </td>
        </ng-container>

        <!-- Key Vault URL column -->
        <ng-container matColumnDef="azureKeyId">
            <th scope="col" class="key-id-column" mat-header-cell mat-sort-header *matHeaderCellDef>Azure Key Identifier</th>
            <td mat-cell *matCellDef="let key" class="key-id-column">
                <span class="copy-column">{{ key.details.azureKeyId }}</span>
                <span title="Copy to Clipboard" class="icon-inside-icon copy">
                    <span class="grid-action" role="button" tabindex="0"
                          (click)="copy(key.details.azureKeyId)">
                        <svg class="icon-svg outer-icon">
                            <use xlink:href="#action-box" />
                        </svg>
                        <svg class="icon-svg inner-icon">
                            <use xlink:href="#copy" />
                        </svg>
                    </span>
                </span>
            </td>
        </ng-container>

        <!-- Rows definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let key; columns: displayedColumns;">
        </tr>
    </table>
</dpod-table>
