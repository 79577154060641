<div class="page panel-width">
    <div class="row">
        <div class="col-6">
            <!-- display breadcrumb -->
            <app-breadcrumb [prevLabel]="'Tenants'" [currLabel]="pristineTenant.name"></app-breadcrumb>
        </div>
        <div class="col-6">
            <div class="float-sm-right">
                <button data-locator="delete-tenant" class="btn btn-next" name="next" type="button" (click)="confirmDelete()">
                    Delete Tenant
                </button>
            </div>
        </div>
    </div>

    <ngb-tabset type="pills">
        <ngb-tab>
            <ng-template ngbTabTitle>
                <div data-locator="tab-info">Info</div>
            </ng-template>
            <ng-template ngbTabContent>
                <tenant-info [tenant]="mutableTenant" [saved]="saved" [tenantStatus]="tenantStatus"
                             [serviceAgreement]="serviceAgreement"
                             (save)="save($event)" (cancel)="cancel()"></tenant-info>
            </ng-template>
        </ngb-tab>

        <ngb-tab *ngIf="isTenantSubscriptionsAvailable(mutableTenant)">
            <ng-template ngbTabTitle>
                <div data-locator="tab-info">Subscriptions</div>
            </ng-template>
            <ng-template ngbTabContent>
                <tenant-subscriptions [tenantId]="tenantId" [sectionHeader]="'View Service Subscriptions'"></tenant-subscriptions>
            </ng-template>
        </ngb-tab>

        <ngb-tab>
            <ng-template ngbTabTitle>
                <div data-locator="tab-configure-services">Configure Services</div>
            </ng-template>
            <ng-template ngbTabContent>
                <tenant-marketplace [tenantId]="tenantId"></tenant-marketplace>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>

</div>
