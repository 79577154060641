import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthScopes } from "@app/ajs-upgraded-providers";
import { AuthService } from "@app/features/auth";
import { BackofficeService } from "@app/shared/services/backoffice.service";
import { TenantSubscription } from "@app/features/gem-services/services/tenant-subscription/tenant-subscription.interface";
import { ConfigToken } from "@dpod/gem-ui-common-ng";
import { DpodUiConfig } from "@app/core/dpod-ui-config";
import { TilesService } from "@app/shared/services";

@Component({
  selector: 'tenant-subscriptions',
  templateUrl: './tenant-subscriptions.component.html',
})
export class TenantSubscriptionsComponent implements OnInit {
  @Input() tenantId: string;
  @Input() sectionHeader: string;
  hasSynced = false; // we display nothing until we get the initial callback for services
  subscriptionsList: TenantSubscription[];
  services = {};

  constructor(private authService: AuthService,
              @Inject(AuthScopes) private scopes: any,
              private backofficeService: BackofficeService,
              private tilesService: TilesService,
              @Inject(ConfigToken) public config: DpodUiConfig
  ) { }

  ngOnInit(): void {
    // ZUORA feature - get subscriptions only if zuora flag is enabled
    const tenantUUID = this.tenantId;
    if (this.areSubscriptionsAvailable()) {
      this.backofficeService.listSubscriptions(tenantUUID).subscribe(result => {
        this.subscriptionsList = result;
        this.hasSynced = true;
      });

      this.tilesService.tiles().subscribe(result => {
        result.forEach(t => {
          this.services[t.shortCode] = t.name;
        });
      });
    }
  }

  // extra check to avoid calling services if user is not allowed
  areSubscriptionsAvailable(): boolean {
    if (this.config.FF_ENABLE_ZUORA) {
      return (this.tenantId && (this.authService.hasScope(this.scopes.spadmin) || this.authService.hasScope(this.scopes.operator)))  // SP/Op Tenant subscriptions section
        || (this.authService.hasScope(this.scopes.admin) || this.authService.hasScope(this.scopes.owner)); // Subscriptions Tab
    }
    return false;
  }

}
