import { Inject, Injectable } from "@angular/core";
import { IHttpResponse, IHttpService, IPromise } from "angular";
import { HttpService } from "@app/ajs-upgraded-providers";
import ServiceBase from "../../components/service/service-base";
import { KeysListResponse } from "../../features/gem-services/cloud/office365/keybroker.common";
import { AzureKeyFormatted } from "../../features/gem-services/cloud/office365/office365.common";
import { Haro } from "./haro.service";

const moment = require("moment");

export interface GetBrokerInfo {
  clientID: string;
  codeResponseAuthURL: string;
  callbackURL: string;
  authURL: string;
  tokenURL: string;
  debugLoginURL: string;
}

@Injectable()
export class KeybrokerService extends ServiceBase {

  constructor(@Inject(Haro) haro: any,
              @Inject(HttpService) private http: IHttpService) {
    super(haro, "/keybroker");
  }

  annotate(key: any) {
    const keyFormatted: AzureKeyFormatted = Object.assign({formattedCreatedAt: null}, key);
    keyFormatted.formattedCreatedAt = formatDate(key.createdAt);
    return keyFormatted;
  }

  doCreate(serviceId: string): Promise<IHttpResponse<KeysListResponse>> {
    return Promise.resolve(this.http.post(`${this.baseUrl}/brokers/${serviceId}/keys`, {}));
  }

  // override the creation as the key creation does not return header location (this is expected)
  create<T extends {serviceId: string}>(args: T) {
    return this.doCreate(args.serviceId)
      .then(() => this.resync(args, "resources"));
  }

  doResync<U extends KeysListResponse>(params = {
    serviceId: null,
  }): Promise<IHttpResponse<U>> {
    this.store.clear();
    return this.http.get(`${this.baseUrl}/brokers/${params.serviceId}/keys`, {
      params: {
        limit: -1,
      }
    }) as Promise<IHttpResponse<U>>;
  }

  doFetch(serviceId) {
    return Promise.resolve();
  }

  doDelete() {
    return Promise.resolve();
  }

  doSave() {
    return Promise.resolve();
  }

  getServiceInfo<U>(serviceId: string): IPromise<IHttpResponse<U>> {
    return this.http.get(`${this.baseUrl}/brokers/${serviceId}`);
  }

  /**
   * Going forward use this when getting Keybroker Info.  salesforce.service is using old way `getInfo`
   * @param {string} key    If supplied, will return the individual service information
   * @returns {Promise<IHttpResponse<GetBrokerInfo>>}
   */
  async getInfo(key?: string): Promise<IHttpResponse<GetBrokerInfo>> {
    const response: IHttpResponse<GetBrokerInfo> = await this.http.get(`${this.baseUrl}/info`);
    if (key) {
      return response.data[key];
    }

    return response;
  }

}

KeybrokerService.$inject = ["haro", "$http"];

// todo create a date service to do the formatting
function formatDate(date: string) {
  return moment(date).format("D-MMM-YYYY H:mm");
}
