import {Component, Input, forwardRef} from '@angular/core';
import {TenantCreateBody} from '../tenant.model';
import {WizardStep} from '@app/components';

@Component({
  selector: 'summary-step',
  templateUrl: './summary.step.component.html',
})
export class SummaryStep {
  @Input() tenant: TenantCreateBody;
  @Input() accountType: string;
  @Input() domainSuffix: string;
}
