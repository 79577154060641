export * from './requires-role.directive';

// AngularJS compatibility
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { RequiresRoleService } from '@app/shared/services';
const RequiresRoleDirectiveFactory = require('./requires-role.directive.ng1').RequiresRoleDirectiveFactory;

export default angular.module('gem.requiresRole', [])
  // Declaring the service and directive in AngularJS is simpler than introducing
  // a cross-framework dependency with downgrading.
  .service("RequiresRoleService", RequiresRoleService)
  .directive("requiresRole", RequiresRoleDirectiveFactory)
  .name;
