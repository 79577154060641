import {Component, forwardRef, Input} from "@angular/core";
import {WizardStep} from "@app/components";
import {HSMonDemandServiceObject} from "./cloudHSM.interface";

@Component({
  selector: "summary-step",
  templateUrl: "./summary.step.component.html",
  styleUrls: ["../cloudHSM.scss"],
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => SummaryStepComponent)}
  ]
})
export class SummaryStepComponent extends WizardStep {

  @Input() serviceName: string;

}
