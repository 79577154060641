export * from './feedback.component';

// AngularJs compatibility module
import { FeedbackComponent } from './feedback.component';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';

export default angular.module("gem.feedback", [])
  .directive("gemFeedback", downgradeComponent({component: FeedbackComponent}))
  .name;
