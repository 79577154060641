import { filter, map, mergeAll } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { IHttpService } from 'angular';
import { HttpService } from '@app/ajs-upgraded-providers';
import { DataStore } from '@app/components/service/data-store';
import { Haro } from './haro.service';
import { ServiceCategory, ServiceCategoryCollection } from "@app/shared/services/service-categories.interface";

/**
 * Service for the `/v1/service_categories` API.
 *
 */
@Injectable()
export class ServiceCategoriesService extends DataStore<ServiceCategory> {
  private baseUrl = '/v1/service_categories';

  // Emits all tiles that are returned by the server. Will replay the last Tile[] result
  // to any new subscribers. Note that this subject will emit Tiles for tenants other than
  // the one that the user is currently logged in to.
  private serviceCategoriesSubject$ = new ReplaySubject<ServiceCategory[]>(1);

  constructor(
    @Inject(HttpService) private http: IHttpService, // TODO remove AngularJS dependency when we've upgraded mock-data
    @Inject(Haro) haro: any,
  ) {
    super(haro);

    // When a set() happens in the DataStore, it emits on storeStream. Connect that to our
    // tilesSubject so that we emit too. Note that we don't care about the actual event
    // from storeStream here, it's just a trigger for us to emit all the tiles again.
    this.storeStream.subscribe(_ => {
      this.serviceCategoriesSubject$.next(this.getAll());
    });

    // Start fetching service categories
    this.list();
  }

  /**
   * Fetches all service categories from the server.  We do not need to pass tenant id as the service categories are not
   * unique per tenant
   * @returns A promise resolving to the service categories.
   */
  async list(): Promise<ServiceCategory[]> {
    const res = await this.http.get<ServiceCategoryCollection>(this.baseUrl);
    const {content} = res.data;
    content.forEach(t => this.set(t));
    const serviceCategories = this.getAll();
    this.serviceCategoriesSubject$.next(serviceCategories);
    return serviceCategories;
  }

  /**
   * @returns An observable that emits the tiles for the current tenant.
   */
  serviceCategories(): Observable<ServiceCategory[]> {
    return this.serviceCategoriesSubject$
  }

}
