<wizard [title]="'Add Key Broker for Azure'" [lastSlideClose]="false" [(currentStepIdx)]="step"
        (submit)="finish()">

    <terms-of-service-step name="Service Information" instructions="Service Information"></terms-of-service-step>

    <configure-step name="Configure your Service" [service]="service" [(azureSubscription)]="azureSubscription"
                    [accessToken]="accessToken" instructions="Give your service a name."></configure-step>

    <azure-details-step [o365Config]="o365Config" name="Configure your Azure details."
                        [azureSubscription]="azureSubscription" [accessToken]="accessToken" instructions=""></azure-details-step>

    <summary-step [service]="service" name="View Summary" [o365Config]="o365Config"
                  [azureSubscription]="azureSubscription"
                  instructions='Click the "Finish" button to complete the creation of the service.'></summary-step>

</wizard>
