import spadminusers from '../spAdminUsers';
import gemServices from "../gem-services";
import accounts from "../accounts/accounts.module";

import icons from "../../components/gem-icons";
import header from "../../components/header";
import helpDrawer from "../../components/help-drawer";
import footer from "../../components/footer";
import evaluationConvertFooter from "../eval-convert/evaluation-convert-footer";
import navbar from "../../components/navbar";
import placeholder from "../../components/loading-placeholder";
import gemTitle from "../../components/gem-title";

import routing from './routing.config';
import interceptors from "./interceptors";
import requiresRole from "../../components/requires-role";
import loginHook from "./login.hook";
import requiresRoleHook from "./requires-role.hook";
import homeHook from "./home.hook";
import stateHook from "./state.hook";
// these should be encapsulated inside gemServices, but the router needs
// access to a component inside this thing
// TODO: route this from within the feature
import gemServiceDetails from "../gem-services/details";

import {UrlService} from "@uirouter/core";

export default angular.module("tenantApplication", [header, helpDrawer, footer, navbar, icons, spadminusers, gemServices, gemServiceDetails,
    accounts, placeholder, requiresRole, gemTitle, evaluationConvertFooter,
    'ui.router', 'ui.router.upgrade',
])
    .config(routing)
    .config(interceptors)
    .run(loginHook)
    .run(requiresRoleHook)
    .run(homeHook)
    .run(stateHook)
    // https://github.com/ui-router/angular-hybrid/issues/39#issuecomment-309057814 (and below)
    // fixes an issue with routes and bootstrapping Angular
    .run(['$$angularInjector', $$angularInjector => {
        const url = $$angularInjector.get(UrlService);
        url.listen();
        url.sync();
    }])
    .run((RequiresRoleService, AuthService) => {
        // Wire up the RequiresRole service to the AuthService
        RequiresRoleService.setVerifier(AuthService.hasScope.bind(AuthService));
    });
