<h5 class="mb-3">
    <svg class="icon-svg" viewBox="0 0 64 64">
        <use xlink:href="#revoke"/>
    </svg>
    Revoke Key?
</h5>

<div class="row">
    <div class="col-sm-12">
        <p>
            The VM will be unable to access the volume associated with this key.
        </p>
        <p>
            You can access this data again by restoring the revoked key.
        </p>
    </div>
</div>

<div class="row no-gutters">
<button class="btn btn-next" data-locator="revoke-key" (click)="revoke.emit({ key: key })">Revoke Key</button>
<button class="btn btn-default" data-locator="cancel" (click)="close.emit()">Cancel</button>
</div>
