import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WindowToken } from '@app/shared/services';
import { AuthService } from './auth.service';
import { ConfigToken } from '@dpod/gem-ui-common-ng';
import { DpodUiConfig } from '@app/core/dpod-ui-config';

/**
 * Signs outgoing requests by adding the JWT in an Authorization header.
 * If the  then the request is prevented
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    @Inject(WindowToken) private window: Window,
    @Inject(ConfigToken) private config: DpodUiConfig,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const identity = this.authService.getIdentity();
    if (!identity) {
      // User is not logged in; delay all requests indefinitely. We expect this to be
      // just a temporary state while the AuthService is redirecting to the login page.
      return;
    }

    if (this.config.FF_USE_HODOR) {
      // Browser will attach session cookie automatically. Don't have to do anything
      return next.handle(request);
    }

    // Implicit flow
    const token = this.authService.getToken();
    if (this.isSameOrigin(request.url) && !request.headers.has('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        }
      });
    }

    return next.handle(request);
  }

  /**
   * @returns true if the URL is for the same same origin
   */
  isSameOrigin(url: string) {
    return new URL(url, window.location.href).origin === this.window.location.origin;
  }
}
