import { Location } from '@angular/common';
import { Inject } from '@angular/core';
import { WindowToken } from '@app/shared/services';
import { jwtCookieName, unverifiedScopeSuffix } from './auth.constants';
import { HodorJwtClaims, IAuthService, JWT } from './auth.interface';
import { getTenantId, isEmailVerified } from './auth.utils';
import * as AuthScopes from './roles.constants';
import ValidStates from './valid-states.constants';
import { getSubscriberGroupId } from "@app/features/auth/auth.utils";

/**
 * Authentication service to be used when Hodor is managing the session
 */
export class AuthServiceHodor implements IAuthService {
  private claims: HodorJwtClaims;
  private scopes: readonly string[] = [];
  private redirecting = false;

  constructor(
    @Inject(WindowToken) private window: Window,
    private location: Location,
  ) {
    const jwtCookie = window.document.cookie.split(';')
      .map(cookie => cookie.split('=', 2))
      .find(([name, _]) => name === jwtCookieName);

    if (!jwtCookie) {
      // This means we aren't logged in
      console.warn(`No ${jwtCookieName} cookie is set`);
      return;
    }

    this.claims = Object.freeze(JSON.parse(jwtCookie[1]));
    this.scopes = Object.freeze(this.claims.scope);
  }

  /**
   * Determine whether the user is allowed to view the given UI-router state. In other words,
   * check whether the user has any scope for which the state is valid.
   */
  isStateValid(state: string): boolean {
    return Object.keys(AuthScopes).some(scopeName => {
      const scope = AuthScopes[scopeName];
      return this.hasScope(scope) && (ValidStates[scopeName] || []).includes(state);
    });
  }

  /**
   * @returns Whether the user has verified their email address
   */
  isEmailVerified(): boolean {
    return isEmailVerified(this.scopes);
  }

  /**
   * @returns The claims obtained from the decoded access token
   */
  getIdentity(): JWT {
    if (this.claims) {
      // From the session-expiry code's point of view, this "JWT" (which is not
      // a real JWT, but only a subset) should never expire.
      return Object.assign({ exp: Number.MAX_SAFE_INTEGER, }, this.claims);
    }
    return null;
  }

  /**
   * @returns The id of the tenant that the user is logged into.
   */
  getTenantId(): string {
    return getTenantId(this.scopes);
  }

  /**
   * @returns The id of the subscriber group that the user belongs to.
   * null is returned if the user doesn't belong to a subscriber group (e.g. if the user is an SP Admin or Operator)
   */
  getSubscriberGroupId(): string {
    return getSubscriberGroupId(this.scopes);
  }

  /**
   * @returns `true` if the user has the given scope or an "unverified" variant thereof.
   */
  hasScope(scope: string): boolean {
    return this.scopes.includes(scope) || this.scopes.includes(scope + unverifiedScopeSuffix);
  }

  /** Sends the user to the logout page */
  logout() {
    if (this.redirecting) {
      return;
    }
    const uri = `/logout`;
    console.warn(`redirecting to logout page: ${uri}`);
    this.window.location.href = uri;
    this.redirecting = true;
  }

  /**
   * @param returnTo The $location path to restore after login (default: current path)
   *   e.g. `'/tenants'`
   */
  login(returnTo?: string) {
    if (this.redirecting) {
      return;
    }
    const url = new URL('/login', this.window.location.href);
    const hash = returnTo ? `!${returnTo}` : this.location.path(true).slice('#'.length);
    if (hash) {
      url.searchParams.set('return_hash', hash)
    }
    console.log(`redirecting to /login page: ${url}`)

    this.window.location.href = url.toString();
    this.redirecting = true;
  }

  /**
   * @returns Whether we've started redirecting the user to the login or logout page.
   */
  isRedirecting(): boolean {
    return this.redirecting;
  }

  /**
   * @deprecated Will be removed after implicit flow is gone
   */
  reset() { }

  /**
   * @deprecated Will be removed after implicit flow is gone
   */
  getToken(): string {
    throw new Error('Not implemented');
  }
}
