import { Component, Inject, NgZone, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { AuthScopes, LocationService, SalesforceService, State, VHsmApiService } from "@app/ajs-upgraded-providers";
import { DialogService } from "@app/components";
import { Error } from '@app/components/gem-dialogs/error/error-modal.component';
import { ServiceCategoriesService, TilesService } from "@app/shared/services";
import { KeybrokerService } from "@app/shared/services/keybroker.service";
import { NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { UrlService } from '@uirouter/core';
import { ILocationService } from "angular";
import { forkJoin, Observable, of } from "rxjs";
import { AuthService } from "../../auth";
import { FEATURE_TOGGLES } from "../../feature-toggles";
import { Tile, TileServicePlan } from "../../marketplace/tiles.interface";
import { redirectBrokerPrompt } from "../cloud/office365/keybroker.common";
import { Office365WizardComponent } from "../cloud/office365/wizard/office365-wizard.component";
import { ProtectVWizardComponent } from "../cloud/protectV/wizard/protectV-wizard.component";
import { redirectSalesforcePrompt } from '../cloud/salesforce/salesforce.common';
import { BrokerAuthErrors } from "../cloud/salesforce/salesforce.constants";
import { ServicesWizardComponent } from "../cloudHSM/wizard/cloudHSM-wizard.component";
import { ServiceCreationOtherWizard, ServiceCreationWizard } from "../cloudHSM/wizard/service.wizard-interface";
import { TileRedirectDialogComponent } from '../redirect/tile-redirect-dialog/tile-redirect-dialog.component';
import { AccountStatusService } from '@app/shared/services/account-status.service';
import { catchError, filter, first, mergeAll, take } from 'rxjs/operators';
import { SalesforceWizardComponent } from "@app/features/gem-services/cloud/salesforce/wizard/salesforce-wizard.component";
import { ConfigToken } from "@dpod/gem-ui-common-ng";
import { DpodUiConfig } from "@app/core/dpod-ui-config";
import { ServiceCategory } from "@app/shared/services/service-categories.interface";
import { BackofficeService } from "@app/shared/services/backoffice.service";
import { TenantSubscription } from "@app/features/gem-services/services/tenant-subscription/tenant-subscription.interface";
import { ServiceAgreementDetails } from "@app/features/tenant/tenant.model";
import { PurchaseDialogService } from "@app/shared/services/PurchaseDialogService";
import { CiphertrustWizardComponent } from "@app/features/gem-services/cloud/ciphertrust/wizard/ciphertrust-wizard/ciphertrust-wizard.component";
import { progress_create_message, ServiceStatus } from "@app/features/gem-services/services.constants";
import { AsyncServiceDialogComponent } from "@app/features/gem-services/dialogs/async-service-dialog.component";

const MODAL_CLASS = 'modal-wide';

// Returned by POST /v1/services. TODO extract to service.model
interface CreatedService {
  service_id: string;
}

// Models the return value of the service creation wizards. The wizards
// return a function that creates the service
type CreateServiceCallback = () => Promise<CreatedService>;

function doLater(fn: () => void) {
  setTimeout(fn, 0);
}

// todo the template for this component is doing too many different things.  refactor once we remove this Admin Service Create feature flag
@Component({
  selector: 'gem-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit, OnDestroy {
  timer: any;
  @ViewChildren(NgbTabset) private readonly tabset: QueryList<NgbTabset>;
  @ViewChild('clientPrompt', { static: true }) private clientPrompt: TemplateRef<any>;
  hasSynced = false; // we display nothing until we get the initial callback for services

  tiles$: Observable<Tile[]>;
  serviceCategories: Observable<ServiceCategory[]>;
  hasTiles: boolean;
  isSubscriptionApiDown = false;
  serviceAgreement: ServiceAgreementDetails;

  constructor(@Inject(LocationService) private $location: ILocationService, // TODO should use Location form angular/common instead
              private dialogService: DialogService,
              @Inject(SalesforceService) private salesforceService: any,
              @Inject(VHsmApiService) private api: any,
              private keybrokerService: KeybrokerService,
              private authService: AuthService,
              @Inject(AuthScopes) private scopes: any,
              @Inject(FEATURE_TOGGLES) private FeatureToggles: any,
              private tilesService: TilesService,
              @Inject(State) private stateService: any,
              private urlService: UrlService,
              @Inject(ConfigToken) public config: DpodUiConfig,
              // AccountStatus is injected here as an optimization. We know that service
              // tiles will need it, so it's better to start fetching early.
              private accountStatusService: AccountStatusService,
              private serviceCategoriesService: ServiceCategoriesService,
              private backofficeService: BackofficeService,
              private zone: NgZone,
              private purchaseDialogService: PurchaseDialogService
              ) {
    const services = this.api.getAll();
    this.getServiceInstance();
    if (services.length > 0) {
      this.selectActiveTab();
    } else {
      this.api.resync().then(this.selectActiveTab.bind(this));
    }
  }

  getServiceInstance() {
    this.zone.runOutsideAngular(() => {
      this.timer = setInterval(() => {
        this.zone.run(() => {
          this.api.resync();
        });
      }, 15000);
    });
  }

  ngOnInit() {
    this.tiles$ = this.tilesService.tiles().pipe(take(1));
    this.serviceCategories = this.serviceCategoriesService.serviceCategories();
    // ZUORA feature - get subscriptions only if zuora flag is enabled
    if (this.config.FF_ENABLE_ZUORA) {
      this.getServiceAgreement();
      const subscriptions$ = this.backofficeService.listSubscriptions().pipe(take(1),
        catchError(() => {
          // DPS-9641 - if the backoffice service is down for subscriptions, display message in Add Service tab that service provisioning not available
          this.isSubscriptionApiDown = true;
          return of(null);
        }));
      // forkJoin so that the UI is rendered only after both tiles and subscriptions are subscribed to
      forkJoin([this.tiles$, subscriptions$])
        .subscribe((tilesSubscriptions: [Tile[], TenantSubscription[]]) => {
          const tiles = tilesSubscriptions[0];
          const subscriptions = tilesSubscriptions[1];

          this.hasTiles = tiles.length > 0;
          this.tilesService.setTilesWithSubscriptionInfo(subscriptions);
          this.hasSynced = true;
        });
    } else {
      this.tiles$.subscribe(res => {
        this.hasTiles = res.length > 0;
        this.hasSynced = true;
      });
    }
    this.handleRedirectQueryParams();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  get tabsetList(): QueryList<NgbTabset> {
    return this.tabset;
  }

  /**
   * Special case for authorization of Salesforce keybroker and other services
   * that pass query params into DPOD
   */
  handleRedirectQueryParams() {
    // Remove parameters (if any) from the query string.
    // This work is in a timeout to avoid being undone by startUIRouter() in main.ts, which runs
    // after ngOnInit() during app startup
    doLater(() => {
      this.urlService.listen(false);
      this.$location.search({}).replace();

      doLater(() => this.urlService.listen(true));
    });

    // retrieve query string
    const { code, error, error_description, serviceType /*, tileId */ } = this.$location.search();
    if (!serviceType) {
      return;
    }

    // Wait for the Tile with the desired short code to get loaded,
    // then open the wizard
    this.tiles$.pipe(
      mergeAll(),
      filter(t => t.shortCode === serviceType),
      first(),
    ).subscribe(tile => {
      if (code && !error) {
        // user has authorized this service
        this.openCustomWizard(code, tile);
      } else if (error && error_description) {
        // user has declined authorizing this service
        this.errorAuthorizing(serviceType, { error, error_description });
      }
    });
  }

  /**
   * Determines what tab should be active, fires on init as well as when the number of services changes
   */
  selectActiveTab() {
    // The tabset gets created asynchronously so it will be undefined early in the lifecycle
    // once created it determines if services exist or not and then tries to go in order of what tab it would like to
    // go to
    setTimeout(() => {
      if (this.tabsetList) {
        if(this.hasServices()) {
          this.selectTab(['tabServices', 'tabServicesTenant']);
        } else {
          this.selectTab(['tabAdd', 'tabAddNew']);
        }
      }
    }, 0);
  }

  /**
   * Selects the first tab in the param arg
   * @param ids
   */
  selectTab(ids: string[]): void {
    for (const id of ids) {
      // digs into the array of arrays inside the tabs to see what tabs are available, if it finds the correct id
      // it returns that one
      const tab_set = this.tabsetList.find(tset => tset.tabs.find(tab => tab.id === id) !== undefined);
      if (tab_set) {
        tab_set.select(id);
        break;
      }
    }
  }

  isAppOwnerOnly() {
    return this.authService.hasScope(this.scopes.owner) && !this.authService.hasScope(this.scopes.admin);
  }

  isTenantAdmin() {
    return this.authService.hasScope(this.scopes.admin);
  }

  /**
   * Occurs when redirecting back from third party vendors, trying to reauthorize the user and the user has declined
   * @param {String}  serviceType     the type of vendor
   * @param {Object}  error           is an object returned from Salesforce, contains `error` and `error_description` (note: this may be different for future vendors)
   */
  errorAuthorizing(serviceType: string, error: Error) {
    switch (serviceType) {
      case "salesforce_key_broker": // todo this won't be viable with many different types of third party providers
        error.error = BrokerAuthErrors[error.error] || null; // bind a more descriptive error
        error.error_description = BrokerAuthErrors[error.error_description] || null; // bind a more descriptive error
        break;
      case "azure":
        // todo backend should return better errors
        error.error = BrokerAuthErrors["access_denied"];
        error.error_description = BrokerAuthErrors["end-user denied authorization"].join("  ");
        break;
    }
    this.dialogService.error(error);
  }

  /**
   * Custom wizard is for non-generic wizards like Salesforce and Azure.
   * After creating the service, the application navigates to the created service details page
   * @param code  the code is the token sent back from the third party which allows us to authenticate our request to them
   * @param tile  the tile is the object we have in our database
   */
  openCustomWizard<T extends ServiceCreationOtherWizard>(code: string, tile: Tile) {
    const component = this.getComponent(tile.shortCode);
    const modal = this.dialogService.open<T>(component, {
      windowClass: MODAL_CLASS,
    });
    const servicesWizard = modal.componentInstance;
    servicesWizard.tile = tile;
    servicesWizard.serviceType = tile.shortCode;
    servicesWizard.servicePlan = tile.shortCode; // same as serviceType
    servicesWizard.code = code;

    const sub = servicesWizard.submit.subscribe((creatorFn: () => Promise<CreatedService>) => {
      creatorFn().then(
        serviceInfo => this.navigateToServiceDetails(serviceInfo),
        error => error && this.dialogService.error(error)
      )
       .finally(() => sub.unsubscribe);
    });
  }

  /**
   * Opens the wizard to create a service.
   * After creating a service, the application navigates to the created service details page
   * @param info Gives the type of service to be created
   */
  openWizard(info: ServiceTileInfo) {
    const {tile, servicePlans} = info;
    const shortCode = tile.shortCode;
    const component: any = this.getComponent(shortCode);
    const modal = this.dialogService.open<ServiceCreationWizard>(component, {
      windowClass: MODAL_CLASS,
    });
    const servicesWizard = modal.componentInstance;
    servicesWizard.tile = tile;
    servicesWizard.serviceType = shortCode;
    servicesWizard.servicePlan = servicePlans;
    const sub = servicesWizard.submit.subscribe((creatorFn: () => Promise<any>) => {
      const progress = this.dialogService.progress(progress_create_message);
      creatorFn().then(async (serviceInfo) => {
        if (serviceInfo.status && serviceInfo.status === 202) {
          progress.close();
          this.openCreateDialog();
        }
        // must be ctaas only in lowercase, other ctaas_* are temporary and will be removed once ctaas is the
        // only short code for ciphertrust
        if (shortCode.toLowerCase() === 'ctaas' || shortCode.toLowerCase() === 'ctaas_lab'
          || shortCode.toLowerCase() === 'ctaas_staging' || shortCode.toLowerCase() === 'ctaas_prod') {
          // must be ctaas only, other ctaas_* are temporary and will be removed
          await this.navigateToServiceList();
        } else {
          this.navigateToServiceDetails(serviceInfo);
        }
      }).catch(error => error && this.dialogService.error(error))
        .finally(() => {
          progress.close();
          sub.unsubscribe();
        })
    });
  }

  openCreateDialog() {
    const ref = this.dialogService.open<AsyncServiceDialogComponent>(AsyncServiceDialogComponent);
    const modal = ref.componentInstance;
    modal.status = ServiceStatus.Provisioning;
  }

  /**
   * @param shortCode
   * @returns A component that implements ServicesWizardComponent
   */
  getComponent(shortCode: string): any {
    shortCode = shortCode.toLowerCase(); // will be removed once ctaas is the only short code for ciphertrust
    switch (shortCode) {
      case 'vm_keystore':
        return ProtectVWizardComponent;
      case 'azure':
        return Office365WizardComponent;
      case 'salesforce_key_broker':
        return SalesforceWizardComponent;
      case 'ctaas':  // must be ctaas only in lowercase, other ctaas_* are temporary and will be removed once ctaas is the only short code for ciphertrust
      case 'ctaas_lab':
      case 'ctaas_staging':
      case 'ctaas_prod':
        return CiphertrustWizardComponent;
    }
    // NOTE: the salesforce wizard is opened through a different code path so doesn't appear here

    // Return the generic HSM wizard otherwise
    return ServicesWizardComponent;
  }

  /**
   * Resync the data list and navigate the user to the service details screen
   * @param serviceInfo - contains the service_id for routing
   **/
  navigateToServiceDetails(serviceInfo){
    this.api.resync() // reload to display created service
      .then(() => {
        // showing new UI, go to the details page
        this.stateService.go("services.details", {
          id: serviceInfo.service_id,
          openDownloadClientBundleSection: true,
        });
      });
  }

  async navigateToServiceList(){
    await this.api.resync() // reload to display created service
    await this.stateService.go("services")
  }

  async createClient(serviceId) {
    return this.dialogService.entityFn(clientName => this.api.bind(serviceId, clientName),
      'gemCreateClient', null, 'Preparing Service Client...');
  }

  hasServices() {
    return this.api.getAll().length > 0;
  }

  async tileClick(t: Tile) {
    if (t.shortCode === 'salesforce_key_broker') {
      this.showSalesforceRedirect(t.id);
      return;
    } else if (t.shortCode === 'azure') {
      this.showOffice365Redirect(t.id);
      return;
    }
    // subscriptionInfo is only available for a tile if config.FF_ENABLE_ZUORA flag is true
    if (this.tilesService.isPurchaseRequiredForTile(t)) {
      // should open the purchase service pop up
      // pass the state of 1st subs as it would be either cancelled or expired if condition and list is ordered
      await this.purchaseDialogService.openDialog(t.shortCode, t.subscriptionInfo[0]?.state);
    } else if (t.serviceBrokerUrl) {
      // retrieve service plan for this service type
      this.tilesService.listServicePlans(t.id)
        .then((servicePlans: TileServicePlan[]) => {
          this.openWizard({
            servicePlans,
            tile: t,
          });
        });
    } else if (t.redirectionUrl) {
      // It's a redirection tile, not a provisionable tile
      this.openRedirectDialog(t);
    } else {
      this.openWizard({
        tile: t,
      });
    }
  }

  /**
   * Shows the redirection prompt dialog for the given `tile`
   */
  openRedirectDialog(tile: Tile) {
    const comp = this.dialogService.open<TileRedirectDialogComponent>(TileRedirectDialogComponent).componentInstance;
    comp.url = tile.redirectionUrl;
    comp.serviceType = tile.shortCode;
  }

  showSalesforceRedirect(tileId: string) {
    redirectSalesforcePrompt.call(this, {
      hostName: "Salesforce",
      goToText: "Go to Salesforce",
      redirectTitle: "Redirecting to Salesforce",
    }, {
      serviceType: "salesforce_key_broker",
      type: "service",
      tileId,
    });
  }

  showOffice365Redirect(tileId: string) {
    redirectBrokerPrompt.call(this, {
      hostName: "Azure",
      goToText: "Go to Azure",
      redirectTitle: "Redirect Notice",
      bodyText: "This action requires you to log in to the Azure environment. After you do, you will be redirected back to Data Protection On Demand.",
    }, {
      serviceType: "azure",
      type: "service",
      tileId,
    }, "azure");
  }

  /**
   * Gets a service agreement
   */
  getServiceAgreement() {
    if (!this.serviceAgreement) {
      this.backofficeService.getServiceAgreement(this.authService.getTenantId())
        .then(serviceAgreement => this.serviceAgreement = serviceAgreement)
        .catch(err => {
          if (err.status === 404) {
            this.serviceAgreement = null;
          }
        });
    }
  }

}

interface ServiceTileInfo {
  tile: Tile;
  servicePlans?: TileServicePlan[];
}
