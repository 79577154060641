import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

export type FileError = InvalidType | InvalidDimension;

interface InvalidType {
  type: string;
  target: ImgUploadComponent;
}

interface InvalidDimension {
  dimension: Dimension;
  target: ImgUploadComponent;
}

type Dimension = [number, number]; // width, height

interface ImageSpec {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
}

/**
 * Offers image upload and preview.
 */
@Component({
  selector: 'gem-img-upload',
  templateUrl: './img-upload.component.html',
  styleUrls: ['./img-upload.component.scss']
})
export class ImgUploadComponent {
  @Input() accept: string;   // Optional comma-separated list of MIME types
  @Input() editing: boolean;
  @Input() showPreviews: Dimension[];
  @Input() imageSpec: ImageSpec;
  @Input() src: string | SafeUrl;
  @Input() defaultSrc: string | SafeUrl;
  @Input() tip: string;
  @Input() title: string;
  @Input() uploadPrompt: string;

  @Output() invalid = new EventEmitter<FileError>(); // emits error on invalid file picked
  @Output() filePicked = new EventEmitter<File>();   // emits files picked

  @ViewChild('fileInput') fileInput: ElementRef;

  public file: File;           // holds the last File that was picked

  constructor(@Inject(DOCUMENT) private document: Document, private sanitizer: DomSanitizer) {
    this.filePicked.subscribe(f => this.file = f);
  }

  uploadClick() {
    this.fileInput.nativeElement.click();
  }

  fileChange(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (!files || !files.length || !files[0]) {
      return;
    }

    const file = files[0];
    if (!this.isValidType(file.type)) {
      this.emitInvalid({ type: file.type });
      return;
    }

    this.filePicked.emit(file);

    const reader = new FileReader();
    reader.onload = () => this.onFileRead(reader, file);
    reader.readAsDataURL(file);

    // Clear input, otherwise no event will fire if the user picks the same file again
    this.fileInput.nativeElement.value = null;
  }

  isValidType(t: string) {
    if (!this.accept) {
      return true;
    }
    return this.accept.split(/, ?/).includes(t);
  }

  // Called when the reader has finished reading the file, meaning reader.result
  // contains the data URI of file contents.
  onFileRead(reader: FileReader, file: File) {
    // We called readAsDataURL earlier so it must be a string
    const src = reader.result as string;

    // sanity check
    if (!src.startsWith("data:image/")) {
      this.emitInvalid({ type: file.type });
      return;
    }

    const img = this.document.createElement('img');
    img.onload = this.checkDimensions.bind(this, img);
    img.onerror = () => this.emitInvalid({ type: file.type });
    img.src = src;

    // Angular considers data:image/x-icon as unsafe, so convert to a trusted URL
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  checkDimensions(img: HTMLImageElement) {
    const spec = this.imageSpec;
    if (!spec) {
      return;
    }
    const width = img.naturalWidth, height = img.naturalHeight;
    if ( (spec.minWidth && width < spec.minWidth)    || (spec.maxWidth && width > spec.maxWidth)
      || (spec.minHeight && height < spec.minHeight) || (spec.maxHeight && height > spec.maxHeight)) {
      this.emitInvalid({ dimension: [width, height] });
    }
  }

  emitInvalid(event) {
    event.target = this;
    this.invalid.emit(event);
  }

  reset() {
    this.file = null;
    this.src = this.defaultSrc;
    this.fileInput.nativeElement.value = null;
  }
}
