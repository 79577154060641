<form #stepForm="ngForm">
    <div class="col form-group pl-0 pr-0 mb-0">
        <section class="details-panel">
            <div class="details-panel-section pb-sm-4">
                <div class="row">
                    <div class="col-sm-4">
                        <label for="tenantName" class="control-label">Tenant Account Name</label>
                        <input id="tenantName" type="text" name="tenantName" class="form-control"
                            [(ngModel)]="tenant.name" maxlen="64" #tenantName="ngModel"
                            [pattern]="'[^,]+'"
                            (input)="tenantNameChange($event.target.value)" required validation-icon>
                        <div *ngIf="tenantName.dirty" id="error-message-tenant-name" class="text-danger error-message" role="alert">
                            <div *ngIf="tenantName.errors?.pattern">Tenant account name cannot contain commas</div>
                            <div *ngIf="tenantName.errors?.maxlen">Maximum 64 characters</div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <label for="hostname" class="control-label">Hostname</label>
                        <br/>
                        <input id="hostname" type="text" name="hostname" class="form-control hostname" minlength="2" maxlen="24"
                            placeholder="Ex: TenantCompanyName" #hostname="ngModel"
                            [(ngModel)]="tenant.hostname" [pattern]="'^[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9]$'"
                               (input)="hostNameChange($event.target.value)" required>
                        <span class="domain">&#x200b;{{domainSuffix}}</span>
                        <div *ngIf="hostname.invalid" id="error-message-hostname" class="text-danger error-message" role="alert">
                            <div id="minCharMessage" name="minCharMessage"
                                 *ngIf="((hostname.dirty && !hostname.value) || hostname.errors?.minlength)">
                                Hostname must be a minimum of 2 characters
                            </div>
                            <div id="invalidHostname" *ngIf="!hostname.errors?.minlength
                                        && !hostname.errors?.maxlen
                                        && hostname.errors?.pattern">
                                Please enter a valid hostname
                            </div>
                            <div id="maxCharMessage" *ngIf="hostname.errors?.maxlen">Hostname must be a maximum of 24 characters</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="details-panel mb-0">
            <div class="details-panel-section">
                <div class="row">
                    <div class="col-sm-12 pb-2">
                        HSM on Demand Service Quota
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <gem-tenant-quota
                            name="quota"
                            [showUnlimitedToggle]="isCreatingServiceProvider()"
                            [maxQuota]="maxServiceQuota"
                            [(ngModel)]="tenant.serviceQuota"
                            required
                            ngDefaultControl
                            ></gem-tenant-quota>
                    </div>
                </div>
            </div>
        </section>
        <section class="row mt-2" *ngIf="showQuotaCount()">
            <div class="col-12 quota-count">
                You have set a total quota of <span class="num">{{tenantsApi.getProvisionedQuota()}}</span> HSMoD services across all tenants.
            </div>
        </section>
    </div>

</form>
