export interface FeatureToggles {
  [flag: string]: boolean | FeatureToggles;
}

const toggles: FeatureToggles = {
  // if set to true will have different OS downloads for HSMoD services
  // todo remove client bundle "roo"
  showNewClientBundleUI: false,
};

export default toggles;

