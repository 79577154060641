import { Component, forwardRef, Inject, Input } from "@angular/core";
import { WizardStep } from "@app/components";
import { ConfigToken } from "@dpod/gem-ui-common-ng";
import { DpodUiConfig } from "@app/core/dpod-ui-config";
import { TenantSubscription } from "@app/features/gem-services/services/tenant-subscription/tenant-subscription.interface";
import { TermsOfServiceMessages } from "@app/features/gem-services/cloudHSM/wizard/common/terms-of-service/terms-of-service.messages";
import { TenantSubscriptions } from "@app/features/gem-services/services/tenant-subscription/subscription.constants";

const termsOfService = require('../../../../../../static/dpod-tos.html').default;
const termsOfServiceCtaas = require('../../../../../../static/ctaas-tos.html').default;

@Component({
  selector: "terms-of-service-step",
  templateUrl: "./terms-of-service.step.component.html",
  styleUrls: ["../../../cloudHSM.scss"],
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => TermsOfServiceStepComponent)}
  ]
})
export class TermsOfServiceStepComponent extends WizardStep {

  termsOfService: string;
  @Input() serviceTypeName: string;
  @Input() subscriptionInfo: TenantSubscription[];
  @Input() serviceCode: string = '';

  constructor(@Inject(ConfigToken) public config: DpodUiConfig) {
    super();
  }

  ngOnInit(){
    // setting tos file
    this.termsOfService = this.serviceCode === 'ctaas' ? termsOfServiceCtaas : termsOfService;
  }


  getWizardTitle() {
    if (this.hasNoSubscriptionOrActiveTrialSubscription()) {
      return TermsOfServiceMessages.TRY_SERVICE_TITLE;
    }
    return TermsOfServiceMessages.CREATE_SERVICE_TITLE;
  }

  getTosInfo() {
    if (this.hasNoSubscriptionOrActiveTrialSubscription()) {
      return TermsOfServiceMessages.TRY_SERVICE_TEXT.replace('service_type', this.serviceTypeName);
    }
    return TermsOfServiceMessages.CREATE_SERVICE_TEXT;
  }

  hasNoSubscriptionOrActiveTrialSubscription() {
    const hasSubscriptions = !!this.subscriptionInfo && this.subscriptionInfo.length > 0;
    return !hasSubscriptions || (this.subscriptionInfo.some(s => s?.type === TenantSubscriptions.TYPE.TRIAL
      && s?.state === TenantSubscriptions.STATE.ACTIVE));
  }
}
