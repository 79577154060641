import _module from "../_init";
import template from "./modal-createUser.content.html";

/**
 * Modal dialog to create a user entity.  The 'resolve' object
 * can have the following fields:
 *
 * role (String, optional) - if 'role' is set, the role shown in the modal will be
 *   locked to the given role and cannot be edited. if not, the user's role can be
 *   selected.
 */
// TODO: refactor common code with CreateServiceGroupController?
// really, they're the same basic controller logic with different template -- take
// an entity, load the fields into the template, call "onSave" when the user says
// they're done. there are a few extra fields and logic related to roles here, but
// that's about it
class CreateUserController {
   constructor(SubscribersApi) {
      this.roles = [{
         name: "admin",
         label: "Administrator",
      }, {
         name: "user",
         label: "Application Owner",
      }, {
         name: "spadmin",
         label: "Service Provider Administrator",
      }];

      this.selectedGroup = [];
      this.subApi = SubscribersApi;
      this.subscriberGroupDropdownOptions = {
         enableSearch: true, idProperty: 'id', selectionLimit: 1, displayProp: 'name',
         showCheckAll: false, showUncheckAll: false, closeOnSelect: true, smartButtonMaxItems: 2,
      };
   }

   $onInit() {
       this.user = {
           givenName: "",
           familyName: "",
           username: "",
           password: "",
       };

       this.role = this.resolve.role || "";
       this.user.accountRole = this.role;

      if (this.role === 'user') {
         this.setupGroups();
      }
   }

    hasSubscriberGroup() {
      if (this.role === 'admin' || this.role === 'spadmin') {
         return true;
       }
       return this.selectedGroup.length > 0;
   }

   // get the current list of subgrps, and subscribe for any changes (on initial app load,
   // the subgrps might not have finished downloading from the server when this component
   // is loaded)
   setupGroups() {

      const mapGroups = () => {

          const groups = this.subApi.getAll();

          this.groups = groups.map(g => ({id: g.id, name: g.name}))
              .sort((a, b) => a.name > b.name);

          const sortedCreatedAt = angular.copy(groups);

          // sort by the most recently created group, so we can have it preselected
          sortedCreatedAt.sort((a, b) => new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime());

          this.selectedGroup = [sortedCreatedAt[0]];

      };

      mapGroups();
      this.subsub = this.subApi.subscribe(mapGroups);
   }

   $onDestroy() {
      if (this.subsub) {
         this.subsub.unsubscribe();
      }
   }

   // if adding a user, return the role's label (e.g. Add Administrator).
   // if editing a user, return the user's username (e.g. Edit 'chuck.d')
   getHeaderLabel() {
      const role = this.roles.find(r => r.name === this.role);
      return role ? role.label : "";
   }

   save() {
      if (this.role === 'user') {
         this.user.subscriberGroups = [this.selectedGroup[0].id];
      }

      this.close({$value: this.user});
   }
}

_module.component('createUser', {
   template,
   bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
   },
   controller: CreateUserController,
});
