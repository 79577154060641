import { Provider } from "@angular/compiler/src/core";
import { InjectionToken } from "@angular/core";

export const Haro = new InjectionToken<any>('haro');

/**
 * Wraps the haro library into an Angular provider
 */
export const HaroProvider: Provider = {
  provide: Haro,
  useFactory: haroFactory,
};

export function haroFactory(/*global: Window*/): any {
  return require('haro');
}
