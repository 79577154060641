<div class="details-panel">
    <div class="details-panel-section">
        <div class="row form-group">
            <div class="col-sm-3">
                <div class="row">
                    <div class="col">Service Name</div>
                </div>

                <div class="row">
                    <div class="col font-weight-bold">
                        {{service.name}}
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        Service Type
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">
                        {{service.formattedServiceType}}
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        Created
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">
                        {{service.formattedCreatedAt}}
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        Created By
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold word-break-break-all">
                        {{service.created_by_username}}
                    </div>
                </div>
            </div>

        </div>

        <div class="row form-group">

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        Azure Subscription
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">
                        {{azureInfo?.config.azureSubscriptionName}}
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        Azure Resource Group
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">
                        {{azureInfo?.config.azureResourceGroupName}}
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        Azure Key Vault
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold">
                        {{azureInfo?.config.azureKeyVaultName}}
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        Azure User ID
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold word-break-break-all">
                        {{azureInfo?.config.azureUserData.userPrincipalName}}
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="text-center details-panel main-panel updating-block" *ngIf="updatingKeysTable">
    <div class="details-panel-section">
        <spinner class="d-inline-block align-middle mr-3"></spinner>
        <h4 class="d-inline align-middle">Updating...</h4>
    </div>
</div>

<div *ngIf="!updatingKeysTable">
    <div class="row">
        <!-- offset where the search filter is in datatables -->
        <div class="col-sm-3 offset-sm-9 mb-4">
            <button *ngIf="isSameSubscriberGroup()" (click)="generateKey()" [disabled]="!canGenerateKeys()"
                class="btn btn-next float-right">
                Generate Key
            </button>
        </div>
    </div>

    <office365-keys-list [serviceId]="getServiceId()"></office365-keys-list>

</div>
