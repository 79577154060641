<span>
    <ng-container *ngIf="isServiceCredentials() ; else platform">
        These credentials will allow a client to perform the following operations on the service
        "<strong>{{serviceName}}</strong>":
    </ng-container>
    <ng-template #platform>
        These credentials will allow a client to perform the following operations:
    </ng-template>
</span>

<section class="details-panel mt-sm-3">
    <div class="details-panel-section requirements-section d-flex align-items-center">
        <ul class="perm-list list-unstyled mb-0">
            <li *ngFor="let p of permissions" class="mb-sm-3">
                <svg class="icon-svg mr-sm-2 align-text-bottom">
                    <use xlink:href="#check-round"/>
                </svg>
                {{p}}
            </li>
        </ul>

        <div *ngIf="isServiceCredentials()" class="gears-background" role="presentation"></div>
        <svg *ngIf="!isServiceCredentials()" class="clipboard-background" focusable="false">
            <use xlink:href="#clipboard"></use>
        </svg>
    </div>
</section>

<section class="details-panel">
    <div class="details-panel-section">
        <form #stepForm="ngForm">
            <div class="row form-group">
                <div class="col-sm-7">
                    <div class="form-inline">
                        <label for="creds" class="mr-sm-3">Credentials Name</label>
                        <input class="form-control col-sm-6" data-locator="credentials-name" id="creds"
                               name="credentialsNameField"
                               minlength="1"
                               maxlength="64"
                               validationIcon required
                               [ngModel]="credentialsName | replaceCharactersWith: ' ': '_'"
                               (ngModelChange)="credentialsNameChange.emit($event)"
                               (blur)="checkName()">
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
