import { AfterViewInit, Component, Inject, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DialogService } from '@app/components';
import { DpodTableComponent } from '@app/components/dpod-table/dpod-table.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { ClipboardService } from 'ngx-clipboard';
import { ApiClient, ApiClientSecret, ApiClientUI, CredentialType } from '../../../features/credentials/credentials.interface';
import { CredentialsService } from '../../../features/credentials/credentials.service';
import { HSMonDemandCreatedService } from '../../../features/gem-services/cloudHSM/cloudHSM.model';
import { RequiresRoleService, ServiceBrokerService } from '../../services';
import { CredentialsDetailsComponent } from './dialogs/modal-credentials-details';
import { RegeneratedClientSecretComponent } from './dialogs/regenerated-client-secret.component';
import { AuthService } from "@app/features/auth";

export const columnNames = [
  'name',
  'clientId',
  'serviceName',
  'createdAt',
  'createdBy',
  'actions',
];

@Component({
  selector: 'credentials-table',
  styleUrls: ['./credentials.component.table.scss'],
  templateUrl: './credentials.component.table.html',
})
export class CredentialsTable implements AfterViewInit, OnChanges {

  @Input() tabledata: ApiClientUI[] = [];
  @Input() type: CredentialType = CredentialType.Service;
  @Input() metadata: HSMonDemandCreatedService = {} as HSMonDemandCreatedService;
  @Input() showServiceName = false;
  @Input() editable: boolean;
  @Input() useServiceBroker = false;
  @Input() showClientID = true;
  @Input() description?: string;

  @ViewChild('table', { static: true }) private table: MatTable<ApiClientUI>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(DpodTableComponent, { static: true }) private dpodTable: DpodTableComponent;

  readonly CredentialType = CredentialType;
  displayedColumns = columnNames.slice();
  dataSource = new MatTableDataSource<ApiClientUI>();

  constructor(
    private dialogService: DialogService,
    private credentialsService: CredentialsService,
    private requiresRoleService: RequiresRoleService,
    public clipboard: ClipboardService,
    private serviceBrokerService: ServiceBrokerService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    if (this.editable === undefined) {
      this.editable = this.getEditableDefault();
    }
    if (!this.showClientID) {
      this.removeColumn('clientID');
    }
    if (!this.editable) {
      this.removeColumn('actions');
    }
    if (this.type !== 'Service' || !this.showServiceName) {
      this.removeColumn('serviceName');
    }

    this.dataSource.data = this.tabledata;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.dpodTable.paginator;
    this.table.renderRows();
  }

  ngOnChanges(cs: SimpleChanges) {
    if (cs['tabledata']) {
      this.dataSource.data = cs['tabledata'].currentValue;
    }
  }

  private removeColumn(name: string) {
    const index = this.displayedColumns.indexOf(name);
    if (index !== -1) {
      this.displayedColumns.splice(index, 1);
    }
  }

  getEditableDefault(): boolean {
    if (this.type === CredentialType.Service) {
      return this.isSameSubscriberGroup();
    }
    return true;
  }

  isSameSubscriberGroup(): boolean {
    if (this.metadata && this.metadata.subscriberGroup) {
      return this.metadata.subscriberGroup === this.authService.getSubscriberGroupId();
    }
    return false;
  }

  async resetSecret(c: ApiClient) {
    await this.dialogService.confirm({
      title: 'Reset Client Secret?',
      content: 'The current Client Secret will be revoked, and a new one will be generated. If currently in use, the old Client Secret may be valid for up to an hour.',
      yesLabel: 'Yes, Continue',
      noLabel: 'Cancel',
    });

    // TODO should have something like dialogService.withProgress(Promise) for this
    const progress = this.dialogService.progress('Resetting…');
    const secret: ApiClientSecret = await this.credentialsService.resetSecret(c.clientId)
      .finally(progress.close.bind(progress));

    const opts = { windowClass: 'modal-semiwide' };
    const comp = this.dialogService.open<RegeneratedClientSecretComponent>(RegeneratedClientSecretComponent, opts).componentInstance;
    comp.credentialName = c.name;
    comp.credentialType = this.type;
    comp.serviceName = this.metadata.name;
    comp.secret = secret.clientSecret;
  }

  async delete(c: ApiClient) {
    const ds = this.dialogService;
    await ds.confirm({
      title: 'Delete Credentials?',
      content: 'These credentials will be revoked. Any clients using these credentials will lose access to Data Protection On Demand.',
      yesLabel: 'Yes, Delete',
      noLabel: 'Cancel'
    });

    const progress = this.dialogService.progress('Deleting…');
    return this.makeDeleteRequest(c.clientId)
    .finally(progress.close.bind(progress));
  }

  async makeDeleteRequest(clientId: string): Promise<void> {
    if (this.useServiceBroker) {
      return this.serviceBrokerService.deleteBinding(this.metadata.service_id, clientId);
    } else {
      return this.credentialsService.delete(clientId);
    }
  }

  showCredentialsDetails(credentials: ApiClientUI) {
    let modal: NgbModalRef;

    switch (this.type) {
      case CredentialType.Platform:
      case CredentialType.Service:
      case CredentialType.SPAdmin:
        modal = this.dialogService.open<CredentialsDetailsComponent>(CredentialsDetailsComponent);
        break;
      default:
        console.error('passed in `type` does not match any modal type');
    }

    const modalInstance = modal.componentInstance as CredentialsDetailsComponent;
    modalInstance.credentials = cloneDeep(credentials);
    modalInstance.serviceName = this.metadata.name;
    modalInstance.type = this.type;
    modalInstance.close.subscribe((c: ApiClient) => {
      this.save(c);
      modal.close();
    });
  }

  copy(clientId: string) {
    this.clipboard.copyFromContent(clientId);
  }

  save(c: ApiClient) {
    // todo editing client credentials has been removed due to Service Broker not supporting it
  }

  onFilter(value: string) {
    this.dataSource.filter = value;
  }

}
