import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TenantCreateBody} from '../tenant.model';
import {WizardStep} from '@app/components';
import { RequiresRoleService } from '@app/shared/services';
import { TenantsService } from '../tenants.service';

const slugify = require("slugify");
import * as Roles from '../../../features/auth/roles.constants';

@Component({
  selector: 'account-info-step',
  templateUrl: './account-info.step.component.html',
  styleUrls: ['./account-info.step.component.scss'],
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => AccountInfoStep)}
  ]
})
export class AccountInfoStep extends WizardStep implements OnInit {
  @Input() tenant: TenantCreateBody;
  @Input() domainSuffix: string;
  @Input() accountType: string;
  @Input() maxServiceQuota: number;
  @Input() defaultServiceQuota: number;
  canHostNameUpdate = true;

  @ViewChild("stepForm", { static: true }) form: NgForm;

  constructor(
    public tenantsApi: TenantsService,
    private requiresRoleService: RequiresRoleService,
  ) {
    super();
  }

  ngOnInit() {
    slugify.extend({'_': '-'});
  }

  isCreatingServiceProvider() {
    return this.tenant.accountType === 'service provider';
  }

  showQuotaCount() {
    return !(this.requiresRoleService.hasRole(Roles.operator)) && this.tenantsApi.getAll().length > 0;
  }

  tenantNameChange(tenantName) {
    if (this.canHostNameUpdate) {
      this.tenant.hostname = tenantName ? slugify(tenantName, { remove: /[!@#$%^&*()+=`~:;"'<>,.?/{}[\]\\|]/g, lower: true }) : "";
    }
  }

  hostNameChange(hostName : string){
      //whenever the hostname is empty, indicate tenantNameChange method that host name can be updated
      this.canHostNameUpdate = !hostName;
  }
}
