import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WizardStep } from '@app/components';
import { TileSelection } from '@app/features/eval-convert/eval-convert.constants';
import { Tile } from '@app/features/marketplace/tiles.interface';
import { MBU, Plan } from "@app/features/tenant/tenant.model";

@Component({
  selector: 'eval-convert-services-component',
  styleUrls: ['./eval-convert.services.scss'],
  templateUrl: './eval-convert.services.component.html',
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => EvalConvertServicesComponent)}
  ]
})
export class EvalConvertServicesComponent extends WizardStep implements OnInit {
  tileSelection: MBU[] = [];
  selectedPlan: Plan;
  showQuantity: boolean = false;
  @Input() tiles: Tile[];
  @Input() selectedServiceType: string;
  @Input() servicePlans: Plan[];
  @Output() planSelectionChange: EventEmitter<Plan> = new EventEmitter<Plan>();
  @Output() tileSelectionChange: EventEmitter<MBU[]> = new EventEmitter<MBU[]>();

  // todo needed?  it's in WizardStep
  @ViewChild('stepForm', { static: true }) form: NgForm;

  constructor() {
    super();
  }

  onPlanSelect(plan) {
    this.selectedPlan = plan;
    this.checkQuantityReq(plan);
    this.planSelectionChange.emit(this.selectedPlan);
    if (this.servicePlans && this.servicePlans.length !== 1) {
      this.tileSelection[0]["planId"] = this.selectedPlan["id"];
    }
  }

  checkQuantityReq(plan) {
    this.showQuantity = plan["parameters"].hasOwnProperty("required") && plan["parameters"]["required"].includes("quantity");
  }

  initTile(){
    const tile = this.tiles?.find(t => t.shortCode === this.selectedServiceType);
    const defaultQuantity = this.showQuantity? 1 : null;
    if (tile) {
      const tileSel = {
        tile,
        quantity: defaultQuantity   // this should be 0 by default now, and when we display the option to change quantity then that change
        // will get updated to the selected quantity, but backend right now does not not seem ready to accept 0, so leaving 1 for now
      }
      this.addPreSelectedTile(tileSel)
      this.setValidity();
      this.tileSelectionChange.emit(this.tileSelection);
    }
  }

  ngOnInit() {
    if (this.servicePlans && this.servicePlans.length === 1) {
      this.onPlanSelect(this.servicePlans[0])
    }
    this.initTile();
    this.planSelectionChange.emit(this.selectedPlan);
  }

  addPreSelectedTile(tileSelection: TileSelection) {
    const mbu: MBU = EvalConvertServicesComponent.convertTileQuantityToMBU(tileSelection.tile, tileSelection.quantity, this.selectedPlan? this.selectedPlan["id"] : "");
    this.tileSelection.push(mbu);
  }

  setValidity() {
    let isValid = null;
    if (this.tileSelection.length === 0) {
      isValid = {'invalid': true};
    }

    if (this.form) {
      this.form.controls.tileSelection?.setErrors(isValid);
    }
  }

  selectedTile(obj: TileSelection): void {
    const tileIdx: number = this.tileSelection.findIndex(item => item.serviceType.id === obj.tile.id);

    if (obj.quantity > 0) {
      const mbu: MBU = EvalConvertServicesComponent.convertTileQuantityToMBU(obj.tile, obj.quantity, this.selectedServiceType? this.selectedPlan["id"] : "");

      if (tileIdx > -1) {
        this.tileSelection[tileIdx] = mbu;
      } else {
        this.tileSelection.push(mbu);
      }
    } else if (tileIdx > -1) {
      this.tileSelection.splice(tileIdx, 1);
    }

    this.setValidity();
    this.tileSelectionChange.emit(this.tileSelection);
  }


  // converts it into the format the backend is expecting
  private static convertTileQuantityToMBU(tile: Tile, quantity: number, planId: string): MBU {
    const {id, shortCode, name} = tile;
    return {
      serviceType: {
        id,
        shortCode,
        name,
      },
      planId,
      quantity,
    };
  }

}
