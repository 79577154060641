import { Component, Input, forwardRef } from '@angular/core';
import {TenantCreateBody} from '../tenant.model';
import {WizardStep} from '@app/components';

export const countriesRequiringStates = ['United States', 'Canada', 'Australia'];
@Component({
  selector: 'address-step',
  templateUrl: './address.step.component.html',
  providers: [{provide: WizardStep, useExisting: forwardRef(() => AddressStep)}]
})
export class AddressStep extends WizardStep {
  @Input() tenant: TenantCreateBody;
  stateRequired: boolean;

  // on change of the country we reset the state to empty/unselected
  countryChange() {
    this.tenant.billingAddress.state = undefined;
    this.stateRequired = countriesRequiringStates.includes(this.tenant.billingAddress.country);
  }
}
