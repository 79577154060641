// --------------------------------------------------------------------
// This might sound crazy, but these imports MUST come first.
// If you move them around, the whole application breaks.
// DO NOT RUN "Organize Imports" ON THIS FILE

import * as angular from 'angular';
import ng1App from './app/app';
// --------------------------------------------------------------------

import { enableProdMode, NgZone, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setAngularJSGlobal } from '@angular/upgrade/static';
import { UIRouter, UrlService } from "@uirouter/core";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

ng1App
  // Using AngularJS config block, call `deferIntercept()`.
  // This tells UI-Router to delay the initial URL sync (until all bootstrapping is complete)
  .config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()])
  .run(['$$angularInjector', ($$angularInjector: Injector) => {
    // Connect the UI router to the AngularJS injector. Doing this after the AngularJS portion
    // of the app has started up avoids a cyclic dependency when Protractor is involved.
    // see
    // https://github.com/ui-router/angular-hybrid/issues/39#issuecomment-309057814
    const url: UrlService = $$angularInjector.get(UrlService);
    url.listen();
    url.sync();
  }]);

setAngularJSGlobal(angular);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
.bootstrapModule(AppModule)
.catch(err => console.log(err));
