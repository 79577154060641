<div class="page panel-width" *ngIf="isSubscriptionsTabAvailable()">
    <ngb-tabset class="main-tabs" type="pills">
        <ngb-tab id="tabServiceSubscriptions">
            <ng-template ngbTabTitle>
                <div data-locator="tab-subscriptions">Subscriptions</div>
            </ng-template>
            <ng-template ngbTabContent>
                <tenant-subscriptions [sectionHeader]="'Service Subscriptions'"></tenant-subscriptions>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>
