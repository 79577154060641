// This is the AngularJS application
import * as angular from 'angular';
import { environment } from '@env/environment';
import CoreModule from './core/core.module';

import ajsMocksModule from './test';

// TODO convert imports below here
import auth from './features/auth';
import globalErrorDialog from './features/global-error-dialog';
import tenantApp from "./features/application";

// TODO move into styles section of angular.json, or
// use @imports in our main styles.css
// import 'bootstrap/dist/css/bootstrap.css';
// import '../buttons.scss';
// import '../dialogs.scss';

// TODO move into script section of angular.json
import 'jquery';
import 'tether';

import 'angular-animate/angular-animate.min';
import 'angular-sanitize/angular-sanitize.min';
import 'angular-messages';

import 'angular-bind-html-compile/angular-bind-html-compile';

const deps = [
  "ngAnimate",
  "ngSanitize",
  "ngMessages",
  "angular-bind-html-compile",
  globalErrorDialog,
  auth,
  tenantApp,
  CoreModule
];

if (environment.mockAjax || environment.fuzzAjax) {
  // Mock API responses by importing the mocks module
  deps.push(ajsMocksModule);
}

export default angular.module('app', deps);
