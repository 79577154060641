import { Inject, Injectable } from "@angular/core";
import { IHttpResponse, IHttpService, IPromise } from "angular";
import { HttpService } from "@app/ajs-upgraded-providers";
import {
  Office365AccessToken,
  Office365AuthenticatedUser,
  Office365CreateKeyVaultParams,
  Office365CreateKeyVaultResponse,
  Office365CreateResourceGroupParams,
  Office365CreateResourceGroupResponse,
  Office365KeyVaultInfo,
  Office365KeyVaultsResponse,
  Office365Location,
  Office365LocationsResponse,
  Office365ResourceGroupsResponse,
  Office365SubscriptionsResponse
} from "./office365.common";

const KEYBROKER_HEADER = "X-Keybroker-Token";

@Injectable()
export class Office365Service {

  private baseUrl = "/keybroker/azure";

  constructor(@Inject(HttpService) private http: IHttpService) {
  }

  getAuthenticatedUserInfo(accessToken: string): IPromise<IHttpResponse<Office365AuthenticatedUser>> {
    return this.http.get(`${this.baseUrl}/authenticated-user`, {
      headers: {
        [KEYBROKER_HEADER]: accessToken,
      },
    });
  }

  // Access Code/Token
  createAccessToken(accessCode: string): IPromise<IHttpResponse<Office365AccessToken>> {
    return this.http.post(`${this.baseUrl}/token`, {
      accessCode,
    });
  }

  // Subscriptions
  listSubscriptions(accessToken: string): IPromise<IHttpResponse<Office365SubscriptionsResponse>> {
    return this.http.get(`${this.baseUrl}/subscriptions`, {
      headers: {
        [KEYBROKER_HEADER]: accessToken,
      },
    });
  }

  // Resource Groups
  listResourceGroups(subscriptionId: string, accessToken): IPromise<IHttpResponse<Office365ResourceGroupsResponse>> {
    return this.http.get(`${this.baseUrl}/resource-groups`, {
      params: {
        subscriptionId,
      },
      headers: {
        [KEYBROKER_HEADER]: accessToken,
      },
    });
  }

  createResourceGroup(subscriptionId: string, resourceGroup: Office365CreateResourceGroupParams, accessToken: string)
    : IPromise<IHttpResponse<Office365CreateResourceGroupResponse>> {
    return this.http.post(`${this.baseUrl}/resource-groups`, resourceGroup, {
      params: {
        subscriptionId,
      },
      headers: {
        [KEYBROKER_HEADER]: accessToken,
      },
    });
  }

  // Key Vaults
  listKeyVaultsOfResourceGroup(subscriptionId: string, resourceGroupName: string, accessToken: string)
    : IPromise<IHttpResponse<Office365KeyVaultsResponse>> {
    return this.http.get(`${this.baseUrl}/resource-groups/${resourceGroupName}/key-vaults`, {
      params: {
        subscriptionId,
      },
      headers: {
        [KEYBROKER_HEADER]: accessToken,
      },
    });
  }

  createKeyVault(subscriptionId: string, resourceGroupName: string, keyVault: Office365CreateKeyVaultParams, accessToken: string)
    : IPromise<IHttpResponse<Office365CreateKeyVaultResponse>> {
    return this.http.post(`${this.baseUrl}/resource-groups/${resourceGroupName}/key-vaults`, keyVault, {
      params: {
        subscriptionId,
      },
      headers: {
        [KEYBROKER_HEADER]: accessToken,
      },
    });
  }

  // to be used to fetch individual key vault information
  getKeyVaultFromVaultNameAndSubscriptionId(subscriptionId: string, keyVaultName: string): IPromise<IHttpResponse<Office365KeyVaultInfo>> {
    return this.http.get(`${this.baseUrl}/${keyVaultName}`, {
      params: {
        subscriptionId,
      }
    });
  }

  // Locations
  listLocations(subscriptionId: string, accessToken: string): IPromise<IHttpResponse<Office365LocationsResponse>> {
    return this.http.get(`${this.baseUrl}/locations`, {
      params: {
        subscriptionId,
      },
      headers: {
        [KEYBROKER_HEADER]: accessToken,
      },
    }).then((response: IHttpResponse<Office365LocationsResponse>) => {
      const locationsList: Office365Location[] = response.data.value;
      // it does not come sorted by `displayName` automatically, sort it
      locationsList.sort((a, b) => {
        if (a.displayName > b.displayName) {
          return 1;
        }

        if (a.displayName < b.displayName) {
          return -1;
        }

        return 0;
      });
      response.data.value = locationsList;
      return response;
    });
  }

}

Office365Service.$inject = ["$http"];
