import { Injector, ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DialogService, DialogsModule } from '../components/gem-dialogs';
import { UsersApi } from "../features/accounts/api/usersApi.service";
import { AuthModule } from "../features/auth/auth.module";
import { FeatureTogglesProvider } from '../features/feature-toggles';
import { TenantsService } from "../features/tenant/tenants.service";
import {
  DocsService,
  RequiresRoleDefaultProvider,
  ServiceBrokerService,
  ServiceCategoriesService,
  WindowProvider
} from '../shared/services';
import { DownloadService } from "../shared/services/download.service";
import { GlobalErrorHandlerProvider } from "../shared/services/global-error-handler";
import { HaroProvider } from "../shared/services/haro.service";
import { KeybrokerService } from "../shared/services/keybroker.service";
import { TilesService } from "../shared/services/tiles.service";
import { GemToastService } from "@dpod/gem-ui-common-ng/toast";
import { ConfigProvider, ConfigToken } from '@dpod/gem-ui-common-ng';
import { AccountStatusService } from '@app/shared/services/account-status.service';
import { BackofficeService } from "@app/shared/services/backoffice.service";
import { EkmsApi } from "@app/features/linkage/ekmsApi.service";
import { PurchaseDialogService } from "@app/shared/services/PurchaseDialogService";

/*
 * Provides the singleton services that load when the application starts.
 * CoreModule is a pure services module with no declarations.
 * Import CoreModule in the root AppModule only. Never import CoreModule in any other module.
*/
@NgModule({
  imports: [
    AuthModule.forRoot(),
    DialogsModule,
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private injector: Injector,
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

  // https://angular.io/guide/ngmodule-faq#what-is-the-forroot-method
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AccountStatusService,
        ConfigProvider,
        DialogService,
        DocsService,
        DownloadService,
        FeatureTogglesProvider,
        GlobalErrorHandlerProvider,
        HaroProvider,
        RequiresRoleDefaultProvider,
        TilesService,
        WindowProvider,
        ServiceBrokerService,
        KeybrokerService,
        UsersApi,
        EkmsApi,
        TenantsService,
        GemToastService,
        BackofficeService,
        ServiceCategoriesService,
        PurchaseDialogService
      ]
    };
  }

}

// AngularJS compatibility module: can be removed when no AJS code uses the services below
export default angular.module('app.ng.core', [])
  .service("DocsService", downgradeInjectable(DocsService))
  .service("DownloadService", downgradeInjectable(DownloadService))
  .service("ServiceBrokerService", downgradeInjectable(ServiceBrokerService))
  .service("KeybrokerService", downgradeInjectable(KeybrokerService))
  .service("AccountStatusService", downgradeInjectable(AccountStatusService))
  .service("BackofficeService", downgradeInjectable(BackofficeService))
  .service("ConfigToken", downgradeInjectable(ConfigToken))
  .name;
