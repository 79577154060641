<dpod-table (filter)="onFilter($event)" [description]="description" [dataLength]="dataSource.data.length">

    <!-- Caller can provide extra elements to show above the table -->
    <ng-content></ng-content>

    <table #table mat-table matSort [dataSource]="dataSource">
        <caption class="d-none">List of credentials</caption>

        <!-- Name -->
        <ng-container matColumnDef="name">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let client" class="name-column">
               <a *ngIf="editable" class="gem-link" [attr.data-credential-name]="client.name" role="button" tabindex="0"
                    (click)="showCredentialsDetails(client)">
                    {{client.name}}
                </a>
                <span *ngIf="!editable">
                    {{client.name}}
                </span>
            </td>
        </ng-container>

        <!-- Client ID -->
        <ng-container matColumnDef="clientId">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Client ID</th>
            <td mat-cell *matCellDef="let client" class="clientId-column">
                <div class="client-id">{{client.clientId}}</div>
                <div title="Copy to Clipboard" class="icon-inside-icon copy">
                    <span class="grid-action" role="button" tabindex="0"
                            (click)="copy(client.clientId)">
                        <svg class="icon-svg outer-icon">
                            <use xlink:href="#action-box" />
                        </svg>
                        <svg class="icon-svg inner-icon">
                            <use xlink:href="#copy" />
                        </svg>
                    </span>
                </div>
            </td>
        </ng-container>

        <!-- Service Name column -->
        <ng-container matColumnDef="serviceName">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Service</th>
            <td mat-cell *matCellDef="let client" class="service-name-column">
                <a class="gem-link" [uiSref]="'services.details'" [uiParams]="{ id: data.serviceIds[0] }">
                    {{client.serviceName}}
                </a>
            </td>
        </ng-container>

        <!-- Created At column -->
        <ng-container matColumnDef="createdAt">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Created At</th>
            <td mat-cell *matCellDef="let client" class="created-at-column">
                {{ client.formattedCreatedAt }}
            </td>
        </ng-container>

        <!-- Created By column -->
        <ng-container matColumnDef="createdBy">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
            <td mat-cell *matCellDef="let client" class="created-by-column">
                {{ client.createdBy }}
            </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let client" class="actions-column pr-0">
                <div class="action-cell text-center">
                    <div *ngIf="type === CredentialType.Service" class='icon-inside-icon'>
                        <span title="Delete Credentials"
                            data-locator="delete"
                            class="grid-action"
                            role="button"
                            tabindex="0"
                            (click)="delete(client)">

                            <svg class="icon-svg outer-icon">
                                <use xlink:href="#action-box" />
                            </svg>
                            <svg class="icon-svg inner-icon">
                                <use xlink:href="#trash" />
                            </svg>

                        </span>
                    </div>

                    <div *ngIf="type !== CredentialType.Service" ngbDropdown class="gem-dropdown">
                        <span role="button" tabindex="0" class="grid-action" ngbDropdownToggle
                            id="creds-actions-{{client.id}}">
                            <svg class="icon-svg">
                                <use xlink:href="#dropdown" />
                            </svg>
                        </span>

                        <ul class="dropdown-menu gem" ngbDropdownMenu role="menu"
                            [attr.aria-labelledby]="'creds-actions-' + client.id">
                            <li class="dropdown-item reset-secret" (click)="resetSecret(client)">Reset Secret</li>
                            <li class="dropdown-item delete-creds" (click)="delete(client)">Delete</li>
                        </ul>
                    </div>
                </div>
            </td>
        </ng-container>

        <!-- Rows definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let client; columns: displayedColumns;">
        </tr>
    </table>
</dpod-table>
