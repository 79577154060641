export enum TenantAccountType {
  serviceProvider = "service provider",
  subscriber = "subscriber"
}

/** Convenience interface for extending from */
interface TenantBaseObject {
  accountType: TenantAccountType;
  accountStatus: string;
  companyName: string;
  departmentName: string;
  billingAddress: {
    streetAddress: string,
    city: string,
    state: string,
    country: string,
    zip: string,
  };
  hostname: string;
  name: string;
  serviceQuota: number;
}

/**
 * Model for the POST body that creates a tenant
 */
export interface TenantCreateBody extends TenantBaseObject {
  adminPassword: string;
  adminEmail: string;
  adminGivenName: string;
  adminFamilyName: string;
  adminPhoneNumber: string;
}

/**
 * Model for an existing tenant.
 */
export interface Tenant extends TenantBaseObject {
  admin?: TenantAdmin; // synthetic field
  clientId?: string;
  createdAt: string;
  id: string;
  identityZoneId?: string;
  loginUrl?: string;
  parent: string;
  spaceStatus: string;
  updatedAt: string;

  // These fields are commented out until premium tenants return
  // organizationGuid?: string;
  // organizationUrl?: string;
  // premium: boolean;
  // spaceGuid?: string;
  // spaceUrl?: string;
}

export interface TenantSettings {
  automaticTenantOnboarding: boolean;
}

export interface TenantAdmin {
  email: string;
  familyName: string;
  givenName: string;
  phoneNumber?: string;
}

export enum AgreementApprovalStatus {
  approved = "Approved",
  requested = "Requested",
  notRequested = "NotRequested"
}

export enum EvaluationStatus {
  evaluating = "Evaluating",
  expired = "Expired",
  inAgreement = "InAgreement",
  agreementEnded = "AgreementEnded",
}

/**
 The TenantAccountStatus represents the account status of the tenant, derived from the
 following dates: `creationDate`, `evaluationEndDate`, `agreementRequestedDate`, `agreementStartDate` and `agreementEndDate`.
 The response to dpod-ui will change, the keys for dates are optional
 If `agreementStartDate` is undefined or in the future, and `evaluationEndDate` is in the future -> Tenant status is "Evaluation".
 If `agreementStartDate` is undefined or in the future, and `evaluationEndDate` is in the past -> Tenant status is "Expired".
 If `agreementStartDate` is in the past -> Tenant is status is "Production" (paying).
 */
export interface TenantAccountStatus {
  tenantId: string;
  evaluationStartDate: string;
  evaluationEndDate: string;
  evaluationStatus: EvaluationStatus;
  agreementApprovalStatus: AgreementApprovalStatus;
  // the following are marked optional, we expect them to come back `undefined`
  // but we're coding in support for also returning null values
  agreementRequestDate?: string | null;
  agreementStartDate?: string | null;
  agreementEndDate?: string | null;
}

export interface TenantParentInfo {
  name: string; // parent account name (not hostname)
}

// getting a service agreement
export interface ServiceAgreementDetails {
  submission: {
    parentAdministrator: TenantAdmin;
    parentId: string;
    parentName: string;
    submittedDate: string;
    tenantAdministrator: TenantAdmin;
    tenantId: string;
    tenantName: string;
  }
  terms: ServiceAgreement;
}

// submitting a service agreement
export interface ServiceAgreement {
  duration: number,
  mbus: MBU[],
}

// the Minimum Billable Unit the Tenant Administrator has selected
export interface MBU {
  serviceType: {
    id: string;
    shortCode: string;
    name: string;
  },
  planId: string;
  quantity?: number | null;
}

// the Plan available for a service
export interface Plan {
  id: string,
  displayName: string,
  description: string,
  parameters: {}
}

// the Plans for a tenant's Initial DPoD Service Elections
export interface TenantPlans {
  [key: string] : string;
}
