import { Component, Inject } from '@angular/core';
import { WindowToken } from '@app/shared/services';
import { UsersApi } from '../accounts/api/usersApi.service';
import { AuthService } from '../auth';
import { GemToastService } from "@dpod/gem-ui-common-ng/toast";

enum State {
  INITIAL = 0,
  WORKING = 1,
  DONE = 2,
}

@Component({
  selector: 'gem-unverified',
  templateUrl: './unverified.component.html',
  styleUrls: ['./unverified.component.scss'],
})
export class UnverifiedComponent {
  States = State;
  state = State.INITIAL;
  pending: Promise<any>;

  constructor(
    private AuthService: AuthService,
    private UsersService: UsersApi,
    @Inject(WindowToken) private window: Window,
    public toastService: GemToastService,
  ) { }

  get email(): string {
    const id = this.AuthService.getIdentity();
    return id && id.email;
  }

  async resend() {
    this.state = State.WORKING;
    try {
      await this.UsersService.sendVerification(this.AuthService.getIdentity().user_id);
      this.toastService.success('Verification email successfully resent');
      this.state = State.DONE;
    } catch (e) {
      // Return to initial state so user can resend again
      this.state = State.INITIAL;
      throw e;
    }
  }
}
