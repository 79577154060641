import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { downgradeComponent } from "@angular/upgrade/static";
import { SharedModule } from "@app/shared";
import { TermsOfServiceModule } from "../../cloudHSM/wizard/common/terms-of-service";
import { AzureDetailsComponent } from "./details/azure-details.component";
import { Office365DetailsTableKeysComponent } from './details/office365-details.table.keys';
import { Office365Service } from "./office365.service";
import { AzureDetailsStepComponent } from "./wizard/azure-details.step.component";
import { ConfigureStepOffice365Component } from "./wizard/configure.step.component";
import { Office365WizardComponent } from "./wizard/office365-wizard.component";
import { SummaryStepOffice365Component } from "./wizard/summary.step.component";

@NgModule({
  declarations: [
    Office365WizardComponent,
    ConfigureStepOffice365Component,
    AzureDetailsStepComponent,
    SummaryStepOffice365Component,
    AzureDetailsComponent,
    Office365DetailsTableKeysComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TermsOfServiceModule,
  ],
  entryComponents: [
    Office365WizardComponent,
    AzureDetailsComponent,
  ],
  providers: [
    Office365Service,
  ]
})
export class Office365Module {
}

// required because gem-services details is still in AngularJS
export default require('./_init').default
  .directive("azureDetails", downgradeComponent({component: AzureDetailsComponent}))
  .service("Office365Service", Office365Service)
  .name;
