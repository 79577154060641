import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IHttpResponse, IPromise } from "angular";
import { DialogService } from "@app/components/gem-dialogs";
import { WizardComponent } from "@app/components/wizard-ng2";
import { ServiceBrokerService } from "@app/shared/services";
import { ServiceCreationWizard } from "../../../cloudHSM/wizard/service.wizard-interface";
import {
  Office365AccessToken,
  Office365AuthenticatedUser,
  Office365CheckboxOptions,
  Office365ConfigurationOptions,
  Office365CreateKeyVaultParams,
  Office365CreateKeyVaultResponse,
  Office365CreateResourceGroupResponse,
  Office365ServiceInstanceCreateParams,
  Office365Subscription
} from "../office365.common";
import { Office365Service } from "../office365.service";
import { Tile } from '@app/features/marketplace/tiles.interface';
import { progress_create_message } from "@app/features/gem-services/services.constants";

@Component({
  selector: "office365-wizard",
  templateUrl: "./office365-wizard.component.html",
})
export class Office365WizardComponent extends WizardComponent implements ServiceCreationWizard, OnInit {
  @Input() tile: Tile;
  @Input() servicePlan: string;
  @Input() serviceType: string;
  @Input() code: string;
  accessToken: string; // created from the `code`
  @Input() service = {
    name: "",
  };
  @Input() o365Config: Office365ConfigurationOptions = {
    groupRadio: Office365CheckboxOptions.new,
    keyVaultRadio: Office365CheckboxOptions.new,
    newKeyVaultName: "",
    newGroupName: "",
    formattedNewKeyVaultName: "",
    selectedGroup: null,
    location: null,
  };
  @Input() azureSubscription: Office365Subscription;

  constructor(activeModal: NgbActiveModal, private dialogService: DialogService, private serviceBrokerService: ServiceBrokerService, private office365Service: Office365Service) {
    super(activeModal);
  }

  ngOnInit() {
    this.createAccessToken(this.code).then(accessToken => this.accessToken = accessToken);
  }

  get step(): number {
    return this.currentStepIdx;
  }

  set step(step: number) {
    this.currentStepIdx = step;
  }

  finish(): void {
    const creatorFn = async () => {
      const progress = this.dialogService.progress(progress_create_message);
      const {subscriptionId, displayName: azureSubscriptionName} = this.azureSubscription;
      const {groupRadio, location, formattedNewKeyVaultName, newGroupName, keyVaultRadio, selectedGroup} = this.o365Config;
      const accessToken = this.accessToken;
      let locationDisplayName: string = location ? location.name : null;

      if (groupRadio === Office365CheckboxOptions.existing) {
        // user has picked an existing resource group, but we still require to send the `location` for creating a key vault
        // we get it from the name of the group from the selected resource group
        locationDisplayName = selectedGroup.location;
      }

      const response: IHttpResponse<Office365AuthenticatedUser> = await this.getAzureUserInfo(accessToken);
      const azureUserData: Office365AuthenticatedUser = response.data;

      let groupName: string;

      if (selectedGroup && selectedGroup.name && groupRadio === Office365CheckboxOptions.existing) {
        groupName = selectedGroup.name;
      } else {
          const response: IHttpResponse<Office365CreateResourceGroupResponse> = await this.office365Service.createResourceGroup(subscriptionId, {
            location: locationDisplayName,
            name: newGroupName,
          }, accessToken);

          groupName = response.data.name;
      }

      const keyVaultResponse: Office365CreateKeyVaultResponse = await this.vaultPromise(keyVaultRadio,
        subscriptionId, locationDisplayName, groupName, formattedNewKeyVaultName, accessToken);

      try {
        const {vaultUri} = keyVaultResponse.properties;
        const {name: skuName} = keyVaultResponse.properties.sku;
        const serviceInstanceCreateParams: Office365ServiceInstanceCreateParams = {
          name: this.service.name,
          serviceType: this.serviceType,
          servicePlan: "office365", // same as service type
          createParams: {
            token: accessToken,
            type: "azure", // supposedly not necessary but "doesn't hurt to have"
            config: {
              azureLocation: locationDisplayName,
              azureSubscriptionId: subscriptionId,
              azureSubscriptionName,
              azureSKUName: skuName,
              azureResourceGroupName: groupName,
              azureKeyVaultName: formattedNewKeyVaultName,
              azureKeyVaultUri: vaultUri,
              azureUserData,
            }
          }
        };

        return await this.serviceBrokerService.create(serviceInstanceCreateParams);
      } catch (e) {
        await this.dialogService.error(e);
      } finally {
        progress.close();
      }
    };
    this.close(creatorFn);
  }

  async getAzureUserInfo(accessToken: string): Promise<IHttpResponse<Office365AuthenticatedUser>> {
    return this.office365Service.getAuthenticatedUserInfo(accessToken);
  }

  // if `new`, makes a request to make a new Key Vault otherwise returns the selected key vault name
  vaultPromise(keyVaultRadio: string, subscriptionId: string, location: string, groupName: string,
               newKeyVaultName: string, accessToken: string): IPromise<Office365CreateKeyVaultResponse> {
    if (keyVaultRadio === Office365CheckboxOptions.new) {
      return this.createKeyVault(subscriptionId, groupName, {
        name: newKeyVaultName,
        location,
        properties: {},
      }, accessToken).then((response: IHttpResponse<Office365CreateKeyVaultResponse>) => response.data);
    }

    // todo when "existing" functionality is added we'll have to return information like `vaultUri`
    throw new Error("Currently do not support existing key vaults");
  }

  createAccessToken(accessCode: string): IPromise<string> {
    return this.office365Service.createAccessToken(accessCode).then((response: IHttpResponse<Office365AccessToken>) => response.data.token);
  }

  createKeyVault(subscriptionId, resourceGroupName: string, keyVault: Office365CreateKeyVaultParams, accessToken: string)
    : IPromise<IHttpResponse<Office365CreateKeyVaultResponse>> {
    return this.office365Service.createKeyVault(subscriptionId, resourceGroupName, keyVault, accessToken);
  }

}
