<div class="container">
    <div *ngIf="!errTitleText" class="d-flex flex-column align-items-center">
        <div class="mb-3 creating-service">
            <spinner class="spinner align-middle mr-2"></spinner>
            <div class="d-inline-block align-middle">Please wait while we are preparing your services…</div>
        </div>
    </div>
    <section *ngIf="errTitleText" class="alert err-alert" role="alert">
        <div class="err-exclamation creating-service">
            <div class="imp-alert">
                <img src="images/dpod-icon-important-alert-yellow.svg" alt="">
            </div>
            <div class="imp-alert">
                {{ errTitleText }}
            </div>
        </div>
        <div class="row">
            <p class="err-header"> {{ errSubtitleText }}</p>
        </div>
        <div class="row">
            <p class="err-msg"><span *ngIf="subscriptionExists">Please <a href="#" (click)="!!associate()">try again</a>. </span>
                <span> {{ errSubText }} <a href="mailto: dpondemand@thalesgroup.com">dpondemand@thalesgroup.com</a>.</span>
            </p>
        </div>
    </section>
</div>
