<form #wizForm="ngForm">
    <h4 class="dialog-title">{{title}}</h4>
    <div class="wizard-container">

        <steps [steps]="getStepNames()" [currentStepIdx]="currentStepIdx" *ngIf="showSteps"></steps>

        <div class="wizard-content-container">
            <div class="step-instructions" *ngIf="currentStep().instructions">
                <span>{{currentStep().instructions}}</span>
            </div>

            <!-- wizard page content goes here -->
            <ng-content></ng-content>
        </div>

        <div class="wizard-navigation-container row no-gutters" [hidden]="hideButtons">
            <div class="col-12">
                <div class="float-sm-left">
                    <button id="wizPrev" *ngIf="currentStep().previousButtonText" class="btn btn-next" name="next"
                            type="button" data-locator="wizard-prev-button"
                            (click)="gotoStep(currentStepIdx - 1)">{{currentStep().previousButtonText}}
                    </button>
                </div>
                <div class="float-sm-right">
                    <button id="wizNext" *ngIf="currentStep().nextButtonText" class="btn btn-next" name="next"
                            type="button" data-locator="wizard-next-button"
                            [disabled]="!isCurrentStepValid() || disableNextButton"
                            (click)="gotoStep(currentStepIdx + 1)">{{currentStep().nextButtonText}}
                    </button>
                </div>
            </div>

        </div>

    </div>
</form>
