import { InjectionToken, Provider } from '@angular/core';
import * as JwtDecodeImpl from 'jwt-decode';

export const JwtDecode = new InjectionToken('jwt-decode');

// Expose the jwt-decode library to Angular DI
export const JwtDecodeProvider: Provider = {
  provide: JwtDecode,
  useFactory: JwtDecodeImplFactory,
};

export function JwtDecodeImplFactory() {
  return JwtDecodeImpl;
}
