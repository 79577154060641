export function getAccountTypeLabel(accountType: string) {
   switch (accountType) {
      case "service provider": return "Service Provider";
      case "subscriber": return "Tenant";
   }
   return "";
}

export function getStatusLabel(tenant) {
   const status = getStatus(tenant);
   switch (status) {
      case "active": return "Active";
      case "deleting": return "Deleting";
      case "disabled": return "Disabled";
      case "failed": return "Failed";
      case "pending": return "Pending";
   }
   return "";
}

// Determine what status the tenant has
// @returns active|deleting|disabled|failed|pending
export function getStatus(tenant) {
   const accountStatus = tenant.accountStatus;
   const spaceStatus = tenant.spaceStatus;

   // If something happened with the tenant space, report that (unless we're deleting)
   if (accountStatus !== "deleting" && (spaceStatus === "pending" || spaceStatus === "failed")) {
      return spaceStatus;
   }

   // Otherwise use the account status (active|disabled|deleting)
   return tenant.accountStatus;
}
