import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VHsmApiService } from '@app/ajs-upgraded-providers';
import { DialogService } from '@app/components';
import { DpodUiConfig } from '@app/core/dpod-ui-config';
import { config } from '@dpod/gem-ui-common-ng';
import { HSMonDemandCreatedService } from '../cloudHSM.model';
import { HSMService } from '../cloudHSM.service';

const ConfigToken = config.ConfigToken;
const deleteConfirmAccessRevoked = "This client's access to your service will be revoked.";

/**
 * Tabbed view for the details of an individual Cloud HSM service.
 */
@Component({
  selector: 'cloudhsm-details-tabs',
  templateUrl: './cloudHSM-details-tabs.component.html',
})
export class CloudHSMDetailsTabsComponent implements OnChanges {

  @Input() service: HSMonDemandCreatedService;

  constructor(
    private dialogService: DialogService,
    @Inject(VHsmApiService) private vhsmApi: HSMService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.service && changes.service.currentValue) {
      this.service = changes.service.currentValue;
    } else {
      this.service = emptyService();
    }
  }

  addClient() {
    this.dialogService.entityFn(clientName => this.vhsmApi.bind(this.service.service_id, clientName),
      'gemCreateClient', { existingClients: this.service.clients }, 'Preparing Service Client...');
  }

  async deleteClient(name: string) {
    await this.dialogService.confirm({
      title: "Delete Service Client?",
      content: deleteConfirmAccessRevoked,
      yesLabel: "Delete",
      noLabel: "Cancel"
    });

    // if we got here, they confirmed the deletion
    const progress = this.dialogService.progress("Deleting Service Client...");
    try {
      const client = this.vhsmApi.getClientByName(this.service.service_id, name);
      await this.vhsmApi.unbind(this.service.service_id, client.id);
      this.service = await this.vhsmApi.get(this.service.service_id); // refetch the service
    } finally {
      progress.close();
    }
  }

  downloadClient(clientName) {
    const progress = this.dialogService.progress("Preparing Service Client...");
    this.vhsmApi.bind(this.service.service_id, clientName)
      .finally(progress.close.bind(progress));
  }

  getClients() {
    return this.service.clients || [];
  }
}

// Returns a placeholder service object so this component can render initially without errors
function emptyService() {
  return {
    service_id: ''
  } as HSMonDemandCreatedService;
}
