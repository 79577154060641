<ng-container *ngFor="let cat of tileCategories | keyvalue: sortServiceCategoriesByOrderNum">
    <section *ngIf="cat.value.tiles.length > 0">
        <header data-locator="category-name" class="gem-header section-subheader tile-category-header">{{cat.key}}</header>

        <div class="pt-2 pb-4 card-deck">
            <card-tile *ngFor="let tile of cat.value.tiles"
                       [cardTitle]="tile.name"
                       [disabled]="getTileDisplayState(tile) === 'disabled'"
                       [imageUrl]="tile.imageUrl"
                       [locator]="tile.shortCode"
                       [tooltip]="getTooltip(tile)"
            >

                <div content class="tile-content">
                    <p class="mb-0 tile-desc">{{tile.description}}</p>
                    <p class="mt-2 mb-0" *ngIf="tile.helpUrl">
                        <a [href]="tile.helpUrl" externalLink>More Info</a>
                    </p>
                </div>

                <div actions>
                    <!-- Operator/SP Admin: launching/disabling tiles for a tenant & its sub-tenants -->
                    <div *requiresRole="'dpod.tenant.spadmin,dpod.operator'" class="actions-no-purchase"
                         [ngSwitch]="getTileDisplayState(tile)">
                        <div *ngSwitchCase="'pending'">
                            <spinner [ngClass]="'d-inline-block align-middle small-spinner mr-2'"></spinner>
                            Launching…
                        </div>
                        <div *ngSwitchCase="'enabled'">
                            <svg class="icon-svg check align-bottom">
                                <use xlink:href="#check" />
                            </svg>
                            Service launched
                        </div>
                        <div *ngSwitchCase="'disabled'">
                            <button class="btn btn-next" (click)="onTileClick(tile)">Launch</button>
                        </div>
                        <div *ngSwitchCase="'locked'">
                            <span>Disabled by Administrator</span>
                        </div>
                    </div>

                    <!-- Tenant Admin: enabling/disabling tiles for their tenant -->
                    <div *requiresRole="'dpod.tenant.admin'" class="actions-no-purchase"
                         [ngSwitch]="getTileDisplayState(tile)">
                        <div *ngSwitchCase="'pending'">
                            <spinner [class]="'d-inline-block align-middle small-spinner mr-2'"></spinner>
                            <ng-container *ngIf="tile.enabled">Disabling…</ng-container>
                            <ng-container *ngIf="!tile.enabled">Enabling…</ng-container>
                        </div>

                        <div *ngSwitchCase="'enabled'" class="enabled-state color-primary d-flex align-items-center">
                            <toggleable-switch (click)="onTileClick(tile)" [(val)]="tile.enabled"></toggleable-switch>
                            <span class="pl-2">Service is enabled</span>
                        </div>

                        <div *ngSwitchCase="'disabled'" class="disabled-state color-disabled d-flex align-items-center">
                            <toggleable-switch (click)="onTileClick(tile)" [(val)]="tile.enabled"></toggleable-switch>
                            <span class="pl-2">Service is disabled</span>
                        </div>
                    </div>
                </div>
            </card-tile>
        </div>
    </section>
</ng-container>
