import { KeybrokerServiceInfo } from "@app/shared/services/keybroker.common";
// Used for Office365 Service Creation
import { ServiceInstanceCreateParams } from "@app/shared/services/service-broker.interface";
import { KeysListResponse } from "./keybroker.common";

export interface Office365ConfigurationOptions {
  groupRadio: Office365CheckboxOptions;
  keyVaultRadio: Office365CheckboxOptions;
  location: Office365Location;
  newKeyVaultName: string;
  formattedNewKeyVaultName: string;
  newGroupName: string;
  selectedGroup: Office365ResourceGroup; // pre-existing
}

export enum Office365CheckboxOptions {
  new = "new",
  existing = "existing"
}

export interface Office365SubscriptionsResponse {
  value: Office365Subscription[];
}

export interface Office365LocationsResponse {
  value: Office365Location[];
}

export interface Office365ResourceGroupsResponse {
  value: Office365ResourceGroup[];
}

export interface Office365KeyVaultsResponse {
  nextLink: string;
  value: Office365KeyVault[];
}

export interface Office365CreateResourceGroupParams {
  name: string;
  location: string;
}

export interface Office365CreateResourceGroupResponse {
  id: string;
  name: string;
  location: string;
  properties: {
    provisioningState: "Succeeded"
  };
}

export interface Office365CreateKeyVaultParams {
  name: string;
  location: string;
  properties?: {};
}

export interface Office365CreateKeyVaultResponse {
  id: string;
  name: string;
  type: string;
  location: string;
  properties: {
    sku: {
      family: string;
      name: string;
    },
    tenantId: string;
    vaultUri: string;
    accessPolicies: [{
      tenantId: string;
      objectId: string;
      permissions: {
        keys: Office365KeyPermissions[]
      }
    }]
  };
}

type Office365KeyPermissions =
  "encrypt"
  | "decrypt"
  | "wrapKey"
  | "unwrapKey"
  | "sign"
  | "verify"
  | "get"
  | "list"
  | "create"
  | "update"
  | "import"
  | "delete"
  | "backup"
  | "restore"
  | "recover"
  | "purge";

export interface Office365ResourceGroup {
  id: string;
  name: string;
  location: string;
  properties: {
    provisioningState: "Succeeded"; // haven't seen additional states
  };
}

export interface Office365KeyVault {
  id: string;
  name: string;
  type: string;
  location: string;
  properties: {
    sku: {
      family: string;
      name: string;
    };
    tenantId: string;
    enabledForDeployment: boolean;
    enabledForDiskEncryption: boolean;
    enabledForTemplateDeployment: boolean;
    vaultUri: string;
  };
}

export interface Office365Subscription {
  id: string;
  subscriptionId: string;
  displayName: string;
  state: "Enabled" | "Disabled";
  subscriptionPolicies: {
    locationPlacementId: string;
    quotaId: string;
    spendingLimit: "On" | "Off"; // only value I saw was "On", I'm guessing "Off" is another value
  };
  authorizationSource: string;
}

export interface Office365Location {
  id: string;
  name: string;
  displayName: string;
  subscriptionId: string;
  latitude: string;
  longitude: string;
}

interface AzureConfigParams {
  azureLocation: string;
  azureSubscriptionId: string;
  azureSubscriptionName: string;
  azureSKUName: string;
  azureResourceGroupName: string;
  azureKeyVaultName: string;
  azureKeyVaultUri: string;
  azureUserData: Office365AuthenticatedUser;
}

export interface Office365ServiceInstanceCreateParams extends ServiceInstanceCreateParams {
  createParams: {
    token: string;
    type: "azure";
    config: AzureConfigParams;
  };
}

export interface Office365AccessToken {
  token: string;
}

export interface Office365KeyVaultInfo {
  id: string;
  name: string;
  type: string;
  location: string;
  properties: {
    sku: {
      family: string;
      name: string;
    },
    tenantId: string;
    vaultUri: string;
    accessPolicies: [
      {
        tenantId: string;
        objectId: string;
        permissions: {}
      }]
  };
}

export interface Office365KeybrokerServiceInfo extends KeybrokerServiceInfo {
  config: AzureConfigParams;
  meta: {
    subscriber_group_id: string;
  };
}

export interface AzureKeysListResponse extends KeysListResponse {
  resources: AzureKey[];
}

export interface Office365AuthenticatedUser {
  objectId: string;
  displayName: string;
  employeeID: string;
  userPrincipalName: string;
  accountEnabled: boolean;
}

export interface AzureKey {
  id: string;
  uri: string;
  account: string;
  application: string;
  devAccount: string;
  createdAt: string;
  sfdcCreatedDate: string; // backend shouldn't be returning these, these are Salesforce related.  Value returned is `"0001-01-01T00:00:00Z",`
  sfdcModifiedDate: string; // same comment as above
  keyID: string;
  configID: string;
  state: "active"; // todo other states
  meta: {
    Service: string;
    BrokerId: string;
    BrokerType: string;
    subscriber_group_id: string;
  };
  details: {
    azureKeyId: string;
    azureKeyName: string;
    azureKeyAttributes: {
      exp: number;
      nbf: number;
      created: number;
      enabled: boolean;
      updated: number;

    }
  }
}

export interface AzureKeyFormatted extends AzureKey {
  formattedCreatedAt: string;
}

export const office365ResourceGroupRegex = /^[-\w\._\(\)]{1,90}$/;

// Rules from Azure are the following for Key Vault names
// "Vault name must be between 3-24 alphanumeric characters. The name must begin with a letter, end with a letter or digit, and not contain consecutive hyphens."
// we use a modified version of these rules because we prefix the key vault name.
// there are other validation rules for Key Vault but they are done in the components.
// this is used in one place but if it's to be reused elsewhere it should become a component itself
export const office365KeyVaultRegex = /^[a-zA-Z][a-zA-Z0-9-]{0,10}?[a-zA-Z0-9]?$/;
