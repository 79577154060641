import { convertStartState } from "@app/features/eval-convert/eval-convert.constants";

export class PurchaseState {
  static readonly PRODUCTION_CANCELLED_MESSAGE = 'Your service subscription is cancelled.';
  static readonly TRIAL_EXPIRED_MESSAGE = 'Your trial has expired.';
  static readonly CONTACT_TA = new PurchaseState('Please contact your Tenant Administrator to purchase this service.',
    undefined, undefined, undefined, 'Close');
  static readonly FILL_ISE = new PurchaseState('To purchase this service please submit the Initial Service Elections Form to your Service Provider.',
    'Complete Initial Service Elections', convertStartState, undefined, 'Cancel');
  static readonly WAIT_SP = new PurchaseState('Your Initial Service Elections form is awaiting approval. Please contact your Service Provider about the status of your form.',
    undefined, undefined, undefined, 'Close');
  static readonly FILL_ASE = new PurchaseState('To purchase this service please submit the Additional Service Elections form to your Service Provider.',
    'Complete Additional Service Elections', undefined, 'ase', 'Cancel');
  static readonly CONTACT_SALES = new PurchaseState(undefined, undefined, undefined, undefined, 'Ok');

  private constructor(public readonly bodyText: string,
                      public readonly confirmButtonText: string,
                      public readonly confirmButtonAction: string,
                      public readonly confirmButtonLink: string,
                      public readonly cancelButtonText: string,) {
  }
}
