import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import "../../features/accounts/api/usersApi.service";
import { SharedModule } from "../../shared";
import "./spAdminUsers.component";

import * as AuthScopes from '../auth/roles.constants';

@NgModule({
    declarations: [],
    imports: [
        SharedModule,
        CommonModule,
    ],
    entryComponents: [],
})
export class SpAdminUsersModule {
}

// TODO this whole module and routing can be moved to Angular+UIRouter2 now
export default require("./_init")
    .default
    .config(["$stateProvider", $stateProvider => {
      $stateProvider
        .state('spadminusers', {
          url: '/users',
          redirectTo: 'spadminusers.usersList',
          template: `<div ui-view=""></div>`,
        })
        .state('spadminusers.usersList', {
          url: '',
          params: {
            requiresRole: AuthScopes.spadmin,
          },
          component: 'spadminusers',
        })
        .state('spadminusers.spusersEdit', {
          url: '/{id}',
          params: {
            requiresRole: AuthScopes.spadmin,
            showSubscriberGroups: () => false,
          },
          component: 'usersEdit', // reuse the user editor
          resolve: {
            id: ['$stateParams', ($stateParams) => $stateParams.id]
          },
        });
    }])
    .name;
