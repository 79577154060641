// this can be removed when we no longer need to support AngularJS
import {EmailComponent} from "./email.component";
import {downgradeComponent} from "@angular/upgrade/static";
import {FormControl} from "@angular/forms";

// takes EmailComponent and returns it as a Email attribute Directive
function allowAttribute(componentFactory) {

  function link(scope, elem, attr, ngModel) {
    const emailDirective = new EmailComponent();
    ngModel.$validators.email = function (modelValue) {
      return !emailDirective.validate(new FormControl(modelValue));
    };
  }

  const wrapper = function ($compile, $injector, $parse) {
    const component = componentFactory($compile, $injector, $parse);
    component.restrict = "A";
    component.require = "ngModel";
    component.link = link;
    return component;
  };
  wrapper.$inject = ["$compile", "$injector", "$parse"];
  return wrapper;
}

export default allowAttribute(downgradeComponent({component: EmailComponent}));
