/*eslint-disable arrow-parens, comma-dangle, no-multi-spaces, no-shadow */

import { getAndCreateAccountStatusOnTenantPosition } from "@app/test/mock-data.tenants-helper";

const bundleZip = require('./fixtures/fake_client_bundle.zip'); // arraybuffer
const bannerImage = require('./fixtures/mock-banner.png'); // dataURI string
import { clone, cloneDeep, find } from 'lodash';
import MockServer from "./mock-server";
import {
  AgreementApprovalStatus,
  ServiceAgreementDetails,
  TenantAccountStatus,
  TenantAccountType,
} from "../features/tenant/tenant.model";
import { SalesforceServiceConfiguration } from "@app/features/gem-services/cloud/salesforce/salesforce.constants";
import { ServiceShortCode } from "@app/features/gem-services/services.constants";

const mockPDF = require('./fixtures/mock-letter-pdf.pdf');

declare const TextEncoder: any;

const angular = require('angular');

const module = angular.module('mock.data', []).run(["$httpBackend", $httpBackend => {
  // mock the various resources. the general strategy for each resource is:
  // 1. create a MockServer for the resource's root API path. give it a method to
  //    transform incoming POSTed data into a mock resource representation
  // 2. add any special route handlers that aren't set up by MockServer. PATCH
  //    should be done here, since handling of PATCH requests tends to be very
  //    resource-specific
  // 3. add some initial mocked data for the tests and local UI development
  mockImages($httpBackend);
  const tenants = mockTenants($httpBackend);
  mockBackoffice($httpBackend, tenants);
  mockUsers($httpBackend);
  mockGroups($httpBackend);
  const services = mockServices($httpBackend);
  mockSalesforceKeybroker($httpBackend);
  mockProtectV($httpBackend);
  mockClients($httpBackend, services);
  mockTiles($httpBackend, tenants);
  mockServiceBroker($httpBackend, services);
  mockKeyBroker($httpBackend);
  mockOffice365($httpBackend);
  mockSlingshot($httpBackend);
  mockServiceCategories($httpBackend);
}]);

const mockImages = $httpBackend => {
  // This route is needed because the banner image is now fetched via XHR
  $httpBackend.whenGET('/images/banner.png')
    .respond(() => [200, dataUriToBlob(bannerImage)]);
};

const mockServiceCategories = $httpBackend => {
  const serviceCategories = new MockServer('/v1/service_categories', (serviceCategory) => serviceCategory, $httpBackend);
  serviceCategories.add({"id":"39d360a1-a4d2-11e9-8bee-0ac4fcbb2ccc","name":"Encryption Services","orderNum":60});
  serviceCategories.add({"id":"2b20ec75-737f-4bfd-9d53-8a9aa8141cf9", "name":"Partner Services","orderNum":40});
  serviceCategories.add({"id":"39d30675-a4d2-11e9-8bee-0ac4fcbb2b86","name":"Luna Cloud HSM Services","orderNum":10});
  serviceCategories.add({"id":"39d360a1-a4d2-11e9-8bee-0ac4fcbb2b86","name":"CipherTrust Cloud Key Management Services","orderNum":20});
  serviceCategories.add({"id":"39d360a1-a4d2-11e9-8bee-0ac4fcbb2b87","name":"payShield Cloud Services","orderNum":60});
};

const mockUsers = $httpBackend => {
  // set up the mock users
  const apiPath = "/v1/users";
  let users;

  // Special user related routes beyond the generic ones added by the mock server
  $httpBackend.whenRoute("PATCH", `${apiPath}/:id/resetPassword`).respond(() => [201, {temporaryPassword: btoa(Math.random().toString()).slice(0, 16)}]);
  $httpBackend.whenRoute("PATCH", `${apiPath}/changePassword`).respond((method, url, data, headers, params) => [201, users.get(params.id)]);
  $httpBackend.whenRoute("POST", `${apiPath}/:id/resetMfaToken`).respond((method, url, data, headers, params) => [200]);
  $httpBackend.whenRoute("POST", `${apiPath}/:id/sendVerification`).respond((method, url, data, headers, params) => [200, users.get(params.id)]);


  users = new MockServer(apiPath, user => {
    const clonedUser = angular.copy(user);
    clonedUser.email = [clonedUser.username];
    return clonedUser;
  }, $httpBackend);

  // we start with manfred for testing "Change Password" purposes (logged in as that user)
  const userData = ["manfred", "abe", "bill", "chuck", "donald", "edgar", "fred", "george", "hector", "isabelle", "john", "kenny"];

  const createUser = (key, val) => {
    // key % 5 is so we can have a subscriberGroup that has no users
    const subGroup = key === 1 ? [] : [`00001111-2222-3333-44445555000${key % 5}`];
    return Object.seal({
      accountRole: key % 2 ? "admin" : "user",
      rootAdmin: !!(key % 2),
      createdAt: "2017-11-21T22:19:13Z",
      createdBy: `00001111-2222-3333-444455550003`,
      email: [`${val}@gem.com`],
      createdByUsername: "chuck@xyz.de",
      familyName: `${val}erson`,
      givenName: val,
      id: null,
      phoneNumber: [],
      subscriberGroups: subGroup,
      updatedAt: "2017-11-23T15:37:48Z",
      username: `${val}@xyz.de`,
    });
  };

  userData.forEach((user, index) => users.add(createUser(index, user)));
};

const mockGroups = $httpBackend => {
  // set up the mock subscriber groups
  const apiPath = "/v1/subscriber_groups";
  const groups = new MockServer(apiPath, group => ({
    name: group.name,
    description: group.description,
    createdAt: group.createdAt,
    updatedAt: group.createdAt,
    totalServices: group.totalServices || 0,
    totalUsers: group.totalUsers || 0,
    totalClients: group.totalClients || 0,
  }), $httpBackend);

  // Set the service & users count to 0 so groups can be deleted in the UI
  groups.add({name: "Manfred's Org", description: "sub group 1", totalServices: 0, totalUsers: 0});
  groups.add({name: "OrgName02", description: "sub group 2", totalServices: 0, totalUsers: 0});
  groups.add({name: "OrgName03", description: "sub group 3", totalServices: 0, totalUsers: 0});
  groups.add({name: "Users grp", description: "has users", totalServices: 0, totalUsers: 0});
};

const mockServices = $httpBackend => {
  let services;

  $httpBackend.whenRoute('DELETE', '/v1/services/:id/client/:clientId')
    .respond((method, url, data, headers, params) => services.unbind(params.id, params.clientId) ? [204] : [404]);

  // Set up the mock services.
  // Override the MockServer to not use `id` field (services have `service_id` instead)
  class MockServices extends MockServer {

    constructor(baseRoute, converter, $httpBackend) {
      super(baseRoute, converter, $httpBackend);
    }

    getKey() {
      return "service_id";
    }
  }

  services = new MockServices("/v1/services", service => ({
    name: service.name,
    createdAt: service.createdAt,
    created_by: service.created_by,
    created_by_username: service.created_by_username,
    serviceType: service.serviceType,
    subscriberGroup: service.subscriberGroup,
    partition_serial_number: service.partition_serial_number,
    device_type: services.currClientId % 2 === 0 ? 'cryptovisor_fips' : 'cryptovisor',
    dashboard_url: service.dashboard_url, // this is added for GET service_instances, we add it here because service_instances return services
    marketplace: service.marketplace, // this is added for GET service_instances, we add it here because service_instances return services
    clients: (service.clients || [])
      .map(c => ({
        name: c,
        id: services.nextClientId(),
        createdAt: dater(),
        created_by_username: service.created_by_username,
        created_by: service.created_by
      })),
    status: service.status,
    statusMessage: service.statusMessage,
  }), $httpBackend);

  services.currClientId = 1;
  services.nextClientId = function() {
    // start with a known UUIDv4 base and increment the id by one for each
    // new entity. this supports up to 9999 entities, which is hopefully enough?
    this.currClientId++;
    return this.idBase + (`000${this.currClientId}`).substr(-4);
  };

  services.getClient = function(serviceId, id) {
    const service = this.get(serviceId);
    return find(service.clients, c => c.id === id);
  };

  services.bind = function(id, name) {
    const service = this.get(id);
    if (service) {
      const newClient = {
        id: this.nextClientId(),
        name,
        createdAt: dater(),
      };
      service.clients.push(newClient);
      return newClient.id;
    }
    return null;
  };

  services.unbind = function(id, clientId) {
    const service = this.get(id);
    const client = this.getClient(id, clientId);
    if (service) {
      const index = service.clients.indexOf(client);
      if (index !== -1) {
        service.clients.splice(index, 1);
        return true;
      }
    }
    return false;
  };

  $httpBackend.whenRoute('PUT', '/v1/services/:id/client/')
    .respond((method, url, data, headers, params) => {
      const parsedData = JSON.parse(data);
      const id = services.bind(params.id, parsedData.name);
      return id ? [200, bundleZip, {
        'Location': `${services.baseRoute}/${id}/client/${id}`,
        'Content-Disposition': `attachment; filename=${parsedData.name}.zip`,
        'Content-Type': 'application/zip',
      }] : [404];
    });

  let i = 0;

  function dater() {
    const d = new Date();
    d.setMonth((i++ % 11));
    return d.toISOString();
  }

  /**
   * To set the month from high to low
   * Allows the services to appear on first page of table
   * */
  let date = 20;
  function descendingDate(i) {
    const d = new Date();
    d.setMonth(i);
    return d.toISOString();
  }

  services.add({
    name: "vikas_angular",
    subscriberGroup: '00001111-2222-3333-444455550003',
    created_by: "00001111-2222-3333-444455550002",
    created_by_username: 'ahab@pequod.com',
    createdAt: descendingDate(date--),
    clients: ["vikas_angular", "vikas_angular2", "vikas-angular3"],
    serviceType: "key_vault",
    servicePlan: "hsm_on_demand",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONED",
  });
  services.add({
    name: "service_no_clients",
    subscriberGroup: '00001111-2222-3333-444455550003',
    created_by: "00001111-2222-3333-444455550002",
    created_by_username: 'ahab@pequod.com',
    createdAt: descendingDate(date--),
    clients: [],
    serviceType: "key_vault",
    servicePlan: "hsm_on_demand",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONED",
  });
  services.add({
    name: "ctaas_deprovisioning",
    subscriberGroup: '00001111-2222-3333-444455550003',
    created_by: "00001111-2222-3333-444455550002",
    created_by_username: 'ahab@pequod.com',
    createdAt: descendingDate(date--),
    clients: ["ctaas_angular", "ctaas_angular2", "ctaas-angular3"],
    serviceType: "ctaas",
    servicePlan: "hsm_on_demand",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "DEPROVISIONING",
    statusMessage: "Deprovisioning in progress…It’s estimated to be done in 1min."
  });
  services.add({
    name: "ctaas_provisioning",
    subscriberGroup: '00001111-2222-3333-444455550003',
    created_by: "00001111-2222-3333-444455550002",
    created_by_username: 'ahab@pequod.com',
    createdAt: descendingDate(date--),
    clients: ["ctaas_angular", "ctaas_angular2", "ctaas-angular3"],
    serviceType: "ctaas",
    servicePlan: "hsm_on_demand",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONING",
    statusMessage: "Provisioning in progress…It’s estimated to be done in 1min."
  });
  services.add({
    name: "failed_angular",
    subscriberGroup: '00001111-2222-3333-444455550003',
    created_by: "00001111-2222-3333-444455550002",
    created_by_username: 'ahab@pequod.com',
    createdAt: descendingDate(date--),
    clients: ["failed_angular", "failed_angular2", "failed-angular3"],
    serviceType: "key_vault",
    servicePlan: "hsm_on_demand",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONING_FAILED",
    statusMessage: "Error: you can only provision one instance per cloud provider and region.",
  });
  services.add({
    name: "defailed_angular",
    subscriberGroup: '00001111-2222-3333-444455550003',
    created_by: "00001111-2222-3333-444455550002",
    created_by_username: 'ahab@pequod.com',
    createdAt: descendingDate(date--),
    clients: ["defailed_angular", "defailed_angular2", "defailed-angular3"],
    serviceType: "key_vault",
    servicePlan: "hsm_on_demand",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "DEPROVISIONING_FAILED",
    statusMessage: "Error: To delete this service, delete the key rings first.",
  });
  services.add({
    name: "My Salesforce Service",
    subscriberGroup: '00001111-2222-3333-444455550003',
    created_by: "00001111-2222-3333-444455550000",
    created_by_username: 'charles@dickens.com',
    createdAt: descendingDate(date--),
    clients: [],
    serviceType: "salesforce_key_broker",
    servicePlan: "base",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONED",
  });
  services.add({
    name: "radu_angular",
    subscriberGroup: '00001111-2222-3333-444455550002',
    created_by: "00001111-2222-3333-444455550003",
    created_by_username: 'currer@bell.co.uk',
    createdAt: descendingDate(date--),
    clients: ["r_angular", "r_angular2", "r_angular3"],
    serviceType: "pki_private_key_protection",
    servicePlan: "hsm_on_demand",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONED",
  });
  services.add({
    name: "Service Name 05 - this name is really long on purpose",
    subscriberGroup: '00001111-2222-3333-444455550003',
    created_by: "00001111-2222-3333-444455550003",
    created_by_username: 'currer@bell.co.uk',
    createdAt: descendingDate(date--),
    clients: ["client 1", "client 2", "client 3"],
    serviceType: "digital_signing",
    servicePlan: "hsm_on_demand",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONED",
  });
  services.add({
    name: "Service Name 06",
    subscriberGroup: '00001111-2222-3333-444455550005',
    created_by: "00001111-2222-3333-444455550000",
    created_by_username: 'charles@dickens.com',
    createdAt: dater(),
    clients: ["client 1", "client 2", "client 3"],
    serviceType: "oracle_tde_database",
    servicePlan: "hsm_on_demand",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONED",
  });
  services.add({
    name: "My VM Encryption Service",
    subscriberGroup: '00001111-2222-3333-444455550001',
    created_by: "00001111-2222-3333-444455550000",
    created_by_username: 'charles@dickens.com',
    createdAt: dater(),
    clients: [],
    serviceType: "vm_keystore",
    servicePlan: "base",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONED",
  });
  services.add({
    name: "My Azure Service",
    subscriberGroup: '00001111-2222-3333-444455550001',
    created_by: "00001111-2222-3333-444455550000",
    created_by_username: 'charles@dickens.com',
    createdAt: dater(),
    clients: [],
    serviceType: "azure",
    servicePlan: "azure",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONED",
    dashboard_url: "https://www.thalesdocs.com/",
  });
  services.add({
    name: "EKMS Service",
    subscriberGroup: '00001111-2222-3333-444455550001',
    created_by: "00001111-2222-3333-444455550000",
    created_by_username: 'charles@dickens.com',
    createdAt: dater(),
    serviceType: "ekms",
    servicePlan: "ekms",
    partition_serial_number: "0002312-1231-444",
    dashboard_url: "https://www.thalesdocs.com/",
    marketplace: "DPoD",
    status: "PROVISIONED",
  });
  services.add({
    name: "My P2PE Service",
    subscriberGroup: '00001111-2222-3333-444455550003',
    created_by: "00001111-2222-3333-444455550000",
    created_by_username: 'charles@dickens.com',
    createdAt: dater(),
    clients: [],
    serviceType: ServiceShortCode.p2pe,
    servicePlan: "p2pe",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONED",
  });
  services.add({
    name: "provisioning service external long name will be truncated",
    subscriberGroup: '00001111-2222-3333-444455550003',
    created_by: "00001111-2222-3333-444455550002",
    created_by_username: 'ahab@pequod.com',
    createdAt: dater(),
    clients: ["ctaas_angular", "ctaas_angular2", "ctaas-angular3"],
    serviceType: "ctaas",
    servicePlan: "hsm_on_demand",
    partition_serial_number: "0002312-1231-444",
    marketplace: "DPoD",
    status: "PROVISIONING",
    statusMessage: "Provisioning in progress…It’s estimated to be done in 1min.",
    dashboard_url: "https://www.thalesdocs.com/",
  });

  return services;
};

const mockProtectV = $httpBackend => {

  const protectVEndpoint = "/keystore";
  const createKey = (state, unexportable) => {
    return {
      "id": randomHexString(32),
      "uri": "dpod:85a6e469-337c-4ee5-8148-63b80a09f7ac-2xj:vault:keys:pv-13hgxrc6qfhlxfmbmbesam1eiwmkc-v0",
      "account": "dpod:85a6e469-337c-4ee5-8148-63b80a09f7ac-2xj:admin:accounts:85a6e469-337c-4ee5-8148-63b80a09f7ac-2xj",
      "application": "ncryptify:gemalto:admin:apps:dpod",
      "devAccount": "ncryptify:gemalto:admin:accounts:gemalto",
      "createdAt": "2018-06-08T18:12:52.730161Z",
      "name": `PV-13HGxrc6QfHLXfMBmbeSam1EIwmkc-${randomHexString(6)}`,
      "updatedAt": "2018-06-08T18:12:52.730161Z",
      "activationDate": "2018-06-08T18:12:52.708527Z",
      "state": state,
      "usage": "blob",
      "usageMask": 12,
      "meta": null,
      "defaultIV": "2201d23c59c6fb137bc504b2e0abd3f4",
      "objectType": "Symmetric Key",
      "version": 0,
      "algorithm": "AES",
      "size": 256,
      "format": "raw",
      "unexportable": unexportable,
      "undeletable": false
    };
  };

  const keys = [];
  keys.push(createKey("Active", false)); // `active`
  keys.push(createKey("Active", true)); // `revoked`
  keys.push(createKey("Destroyed", true)); //should not display in UI because `destroyed`
  keys.push(createKey("Destroyed", false)); // should not display in UI because `destroyed`

  const listObject = {
    total: 0,
    skip: 0,
    limit: 10,
    resources: [],
  };

  const protectVObject = cloneDeep(listObject);
  protectVObject.resources.push(...keys);

  $httpBackend.whenRoute("GET", `${protectVEndpoint}/keys2`)
    .respond((method, url, data, headers, params) => {
      const protectVObjectClone = cloneDeep(protectVObject);
      if (params.state && params.state !== "") {
        protectVObjectClone.resources = protectVObject.resources.filter(item => item.state === params.state);
      }

      return [200, protectVObjectClone];
    });

  $httpBackend.whenRoute("PATCH", `${protectVEndpoint}/keys2/:id`)
    .respond((method, url, data, headers, params) => {
      const parsedData = JSON.parse(data);
      const keyObject = protectVObject.resources.find(item => item.id === params.id);

      if (typeof parsedData.unexportable !== "undefined") {  // change the export state
        keyObject.unexportable = parsedData.unexportable;
      } else if (parsedData.deactivationDate) { // set key to be deactivated, this would normally be a date but for the mock sake we don't care
        keyObject.state = "Deactivated";
      }

      return [200, keyObject];
    });

  /* tslint:disable-next-line */
  new MockServer(protectVEndpoint, protectV => clone(protectV), $httpBackend);

  $httpBackend.whenRoute("POST", `${protectVEndpoint}/keys2/:id/destroy`)
    .respond((method, url, data, headers, params) => {
      const id = protectVObject.resources.findIndex(item => item.id === params.id);
      protectVObject.resources.splice(id);
      return [204, {}];
    });

};

const mockServiceBroker = ($httpBackend, services) => {

  // todo this is currently piggy backing off the `services`
  const serviceBrokerEndpoint = "/v1/service_instances";

  $httpBackend.whenRoute("GET", `${serviceBrokerEndpoint}/usageReport`)
    .respond((method, url, data, headers, params) => [200, mockReport(), {}]);

  $httpBackend.whenRoute("GET", `${serviceBrokerEndpoint}/usageBillingReport`)
    .respond((method, url, data, headers, params) => [200, mockReport(), {}]);

  $httpBackend.whenRoute("POST", serviceBrokerEndpoint)
    .respond((method, url, data, headers, params) => {
      const parsedData = JSON.parse(data);
      const id = services.add({
        name: parsedData.name,
        subscriberGroup: '00001111-2222-3333-444455550003',
        created_by: "00001111-2222-3333-444455550002",
        created_by_username: 'ahab@pequod.com',
        createdAt: "2018-05-02",
        clients: [],
        serviceType: parsedData.serviceType,
      });
      return [201, "", {location: `${serviceBrokerEndpoint}/${id}`}];
    });

  $httpBackend.whenRoute("PUT", `${serviceBrokerEndpoint}/:id/bindings`)
    .respond(() => {
      return [200, {
        credentials: {
          client_id: randomHexString(16),
          client_secret: randomHexString(16),
        },
      }];
    });

  $httpBackend.whenRoute("GET", `${serviceBrokerEndpoint}/:id`)
    .respond((method, url, data, headers, params) => {
      const service = services.get(params.id);
      return service ? [200, service] : 404;
    });

  $httpBackend.whenRoute("GET", serviceBrokerEndpoint)
    .respond(() => {
      return [200, services.getAll()];
    });

  $httpBackend.whenRoute("DELETE", `${serviceBrokerEndpoint}/:id`)
    .respond((method, url, data, headers, params) => {
      return [204, services.remove(params.id)];
    });

  /* tslint:disable-next-line */
  new MockServer(serviceBrokerEndpoint, serverBroker => clone(serverBroker), $httpBackend);

};

const mockKeyBroker = $httpBackend => {
  const keybrokerEndpoint = "/keybroker";

  /**
   * Generates a key for the Azure Key response
   * @param state
   * @param date
   */
  const genKey = (state = "active", date = "2019-12-25") => {
    return {
      id: `e51cba42-1560-4e25-a625-34bdcbd703d${(Math.floor(Math.random() * 1000) + 1)}`,
      uri: "dpod:21fd2542-3cdc-4433-99c0-s3297b43b738-8Rp:keybroker:azure_broker_key:e51cba42-1560-4e25-a625-34bdcbd703d0",
      account: "dpod:21fd2542-3cdc-4433-99c0-s3297b43b738-8Rp:admin:accounts:21fd2542-3cdc-4433-99c0-f3297b43b738-8Rp",
      application: "ncryptify:gemalto:admin:apps:dpod",
      devAccount: "ncryptify:gemalto:admin:accounts:gemalto",
      createdAt: `${date}T16:38:25.999136Z`,
      sfdcCreatedDate: "0001-01-01T00:00:00Z",
      sfdcModifiedDate: "0001-01-01T00:00:00Z",
      keyID: "f774740cc7c0e4b1f7eba1948b96eac07cf5b185d720c2153c30dd23d18cdc7e",
      configID: "aa92780c-c64d-49e5-b9d8-48a9b0cd2c64",
      state,
      meta: {
        "subscriber_group_id": "037355a2-a41d-42d0-9173-ef6ac24246da"
      },
      details: {
        azureKeyId: `https://dpod-l1mn22-demo111.vault.azure.net/keys/DPODxtAKh6ZvHXnpmmpsD/3a824da0199948${(Math.floor(Math.random() * 1000) + 1)}`,
        azureKeyName: "DPODxtAKh6ZvHXnpmmpsD",
        azureKeyAttributes: {
          exp: 0,
          nbf: 0,
          created: 1575650818,
          enabled: true,
          updated: 1575650818,
          recoveryLevel: "Purgeable"
        }
      }
    };
  };

  const listKeys = [genKey("active", "2017-01-05"), genKey("active")];

  $httpBackend.whenRoute('GET', `${keybrokerEndpoint}/info`)
    .respond((/*method, url, data, headers, params*/) => {
      const response = {
        azure: {
          codeResponseAuthURL: "http://localhost:8080/#!/services?code=23452354234&serviceType=azure",
        },
      };
      return [200, response];
    });

  $httpBackend.whenRoute('GET', `${keybrokerEndpoint}/brokers/:id/keys`)
    .respond((/*method, url, data, headers, params*/) => {
      return [200, {
        resources: listKeys,
      }];
    });

  $httpBackend.whenRoute('POST', `${keybrokerEndpoint}/brokers/:id/keys`)
    .respond((/*method, url, data, headers, params*/) => {
      const key = genKey("active");
      listKeys.push(key);
      return [201, key];
    });

  $httpBackend.whenRoute('GET', `${keybrokerEndpoint}/brokers/:id`)
    .respond((/*method, url, data, headers, params*/) => {
      return [200, {
        config: {
          azureLocation: "Gatineau",
          azureSubscriptionId: "123subId",
          azureSubscriptionName: "subname",
          azureSKUName: "skuname",
          azureResourceGroupName: "resource-group-name",
          azureKeyVaultName: "key-vault-name",
          azureKeyVaultUri: "https://azure.example",
          azureUserData: {
            objectId: "123",
            displayName: "Bill Nye",
            employeeID: "123emp",
            userPrincipalName: "bill.nye@example.com",
            accountEnabled: true,
          },
        },
        meta: {
          subscriber_group_id: "123"
        },
      }];
    });

};

const mockSlingshot = $httpBackend => {
  const slingshotEndpoint = "/v1/signup";
  $httpBackend.whenRoute("GET", `${slingshotEndpoint}/status`)
    .respond((/*method, url, data, headers, params*/) => {
      const statusObj = {
        "accounts": [
          {
            "id": "9ada7443-0db1-4999-98a3-8019f6ffe373",
            "upstream_id": "string",
            "tenant_ids": [
              "string"
            ],
            "upstream_name": "string",
            "marketplace": "GOOGLE",
            "approvals": [
              {
                "id": "string",
                "name": "string",
                "reason": "string",
                "state": "STATE_UNSPECIFIED",
                "created_at": "2020-09-05T17:13:51.965Z",
                "updated_at": "2020-09-05T17:13:51.965Z"
              }
            ],
            "state": "OTHER",
            "reason": "string",
            "created_at": "2020-09-05T17:13:51.965Z",
            "updated_at": "2020-09-05T17:13:51.965Z",
            "deleted_at": "2020-09-05T17:13:51.965Z"
          }
        ]
      };
      return [200, statusObj];
    });

  $httpBackend.whenRoute("PATCH", `${slingshotEndpoint}/link`)
    .respond(() => {
      return [200, {
        "accounts": [
          {
            "id": "9ada7443-0db1-4999-98a3-8019f6ffe373",
            "upstream_id": "string",
            "tenant_ids": [
              "string"
            ],
            "upstream_name": "string",
            "marketplace": "GOOGLE",
            "approvals": [
              {
                "id": "string",
                "name": "string",
                "reason": "string",
                "state": "STATE_UNSPECIFIED",
                "created_at": "2020-09-05T17:13:51.965Z",
                "updated_at": "2020-09-05T17:13:51.965Z"
              }
            ],
            "state": "LINKED",
            "reason": "string",
            "created_at": "2020-09-05T17:13:51.965Z",
            "updated_at": "2020-09-05T17:13:51.965Z",
            "deleted_at": "2020-09-05T17:13:51.965Z"
          }
        ],
        "entitlementCount":1
      }];
    });

}

const mockOffice365 = $httpBackend => {

  const azureEndpoint = "/keybroker/azure";

  $httpBackend.whenRoute("POST", `${azureEndpoint}/token`)
    .respond((/*method, url, data, headers, params*/) => {
      return [200, {
        token: "hellothere",
      }];
    });

  // get authenticated Azure user info
  $httpBackend.whenRoute("GET", `${azureEndpoint}/authenticated-user`)
    .respond((/*method, url, data, headers, params*/) => {
      const userObj = {
        objectId: "123",
        displayName: "Bill Nye",
        employeeID: "123emp",
        userPrincipalName: "bill.nye@example.com",
        accountEnabled: true,
      };

      return [200, userObj];
    });

  // list subscriptions
  $httpBackend.whenRoute("GET", `${azureEndpoint}/subscriptions`)
    .respond((/*method, url, data, headers, params*/) => {
      const listSubscriptonGroups = {
        value: [{
          "id": "/subscriptions/2ddd7175-b83b-45e9-a9b6-25275fa70652",
          "subscriptionId": "2ddd7175-b83b-45e9-a9b6-25275fa70652",
          "displayName": "Free Trial",
          "state": "Enabled",
          "subscriptionPolicies": {
            "locationPlacementId": "Public_2014-09-01",
            "quotaId": "FreeTrial_2014-09-01",
            "spendingLimit": "On"
          },
          "authorizationSource": "Legacy"
        }]
      };

      return [200, listSubscriptonGroups];
    });


  // list key vaults of an Azure resource group
  $httpBackend.whenRoute("GET", `${azureEndpoint}/resource-groups/:id/key-vaults`)
    .respond((/*method, url, data, headers, params*/) => {
      const keyVaults = {
        nextLink: "http://google.ca",
        value: [{
          "id": "/subscriptions/2ddd7175-b83b-45e9-a9b6-25275fa70652/resourceGroups/sfnettest1/providers/Microsoft.KeyVault/vaults/sfntkeyvault1",
          "name": "sfntkeyvault1",
          "type": "Microsoft.KeyVault/vaults",
          "location": "centralus",
          "properties": {
            "sku": {
              "family": "A",
              "name": "Standard"
            },
            "tenantId": "37d0a9db-7c46-4096-bfe3-1add5b495d6d",
            "enabledForDeployment": false,
            "enabledForDiskEncryption": false,
            "enabledForTemplateDeployment": false,
            "vaultUri": "https://sfntkeyvault1.vault.azure.net/"
          }
        }]
      };

      return [200, keyVaults];
    });

  // create key vault inside resource group
  $httpBackend.whenRoute("POST", `${azureEndpoint}/resource-groups/:id/key-vaults`)
    .respond((/*method, url, data, headers, params*/) => {
      const response = {
        id: "123",
        name: "123",
        type: "123",
        location: "centralus",
        properties: {
          sku: {
            family: "123",
            name: "123",
          },
          tenantId: "213",
          vaultUri: "https://sfntkeyvault1.vault.azure.net/",
          accessPolicies: [],
        }
      };

      return [201, response];
    });

  // list resource groups
  $httpBackend.whenRoute("GET", `${azureEndpoint}/resource-groups`)
    .respond((/*method, url, data, headers, params*/) => {
      const listResourceGroups = {
        value: [{
          "id": "/subscriptions/2ddd7175-b83b-45e9-a9b6-25275fa70652/resourceGroups/sfnettest1",
          "name": "sfnettest1",
          "location": "centralus",
          "properties": {
            "provisioningState": "Succeeded"
          }
        }]
      };

      return [200, listResourceGroups];
    });

  // list of Azure locations (countries)
  $httpBackend.whenRoute("GET", `${azureEndpoint}/locations`)
    .respond((/*method, url, data, headers, params*/) => {
      const listResourceGroups = {
        value: [{
          "id": "/subscriptions/2ddd7175-b83b-45e9-a9b6-25275fa70652/locations/eastasia",
          "name": "toontown",
          "displayName": "Toon Town",
          "subscriptionId": "",
          "latitude": "11.111",
          "longitude": "22.222"
        }, {
          "id": "/subscriptions/2ddd7175-b83b-45e9-a9b6-25275fa70652/locations/eastasia",
          "name": "acmeacresuniversity",
          "displayName": "Acme Acres University",
          "subscriptionId": "",
          "latitude": "11.111",
          "longitude": "22.222"
        }]
      };

      return [200, listResourceGroups];
    });

  // create resource group
  $httpBackend.whenRoute("POST", `${azureEndpoint}/resource-groups`)
    .respond((/*method, url, data, headers, params*/) => {
      return [200, {}];
    });

};

// keybroker although uses the MockServer class, it's set up differently than typical /v1 endpoints
const mockSalesforceKeybroker = $httpBackend => {

  const salesforceEndpoint = '/keybroker/salesforce-keybroker';

  const configsGet: SalesforceServiceConfiguration = {
    'id': '89d34454-1403-4305-b151-9601f62e768e',
    'uri': 'dpod:https-kb-demo-uaa-system-cicada-dpsas-io-oauth-token:keybroker:salesforce_config:kb07',
    'account': 'dpod:https-kb-demo-uaa-system-cicada-dpsas-io-oauth-token:admin:accounts:https-kb-demo-uaa-system-cicada-dpsas-io-oauth-token',
    'application': 'ncryptify:gemalto:admin:apps:dpod',
    'devAccount': 'ncryptify:gemalto:admin:accounts:gemalto',
    'createdAt': '2017-10-03T21:09:35.367527Z',
    'name': 'kb007',
    'updatedAt': '2017-10-03T21:09:58.786866Z',
    'username': 'salesforce-username@gemalto.com',
    'displayName': 'my display name',
    'email': 'email-logged-into-salesforce@gemalto.com',
    'organizationId': '00D1I000000o0yuUAA',
    'userId': '0051I000000N4thQAC',
    'tenantInstance': 'na73.salesforce.com',
    'codeResponseAuthURL': 'https://login.salesforce.com/services/oauth2/authorize?response_type=code' +
      '&client_id=3MVG9zlTNB8o8BA3oIv_HCHXOBFmY7h8RLuqZDZbQxUMKQWnqOFFt9JNbdSLBTu_ZMO4NYsE_KDq6xMRloCHo' +
      '&redirect_uri=https://keybroker.apps.cicada.dpsas.io/callback',
    'tokenResponseAuthURL': 'https://login.salesforce.com/services/oauth2/authorize?response_type=token' +
      '&client_id=3MVG9zlTNB8o8BA3oIv_HCHXOBFmY7h8RLuqZDZbQxUMKQWnqOFFt9JNbdSLBTu_ZMO4NYsE_KDq6xMRloCHo' +
      '&redirect_uri=https://keybroker.apps.cicada.dpsas.io/callback',
    'byokCertName': 'dpod_byok_kKRduf',
    'byokCert': '-----BEGIN CERTIFICATE-----\nMIIGfjCCBGagAwI3vRdQcnMDA==\n-----END CERTIFICATE-----\n',
  };

  const baseSecret = {
    uri: 'dev-portal:james:keybroker:sales_force_tenant_secrets:3807f84c-2ba0-4cf2-b388-624242b2776f',
    account: 'dev-portal:james:admin:accounts:james',
    application: 'ncryptify:gemalto:admin:apps:dev-portal',
    devAccount: 'ncryptify:gemalto:admin:accounts:gemalto',
    createdAt: '2017-09-13T17:30:34.395024Z',
    sfdcSecretID: '02Gf40000000mrhEAA',
    keyID: 'cfba4797-9dbe-4198-8a9e-cb10e032a48e',
    configID: 'de279a45-7f63-4e6e-946f-1b1ab7d4938e',
    sfdcCreatedDate: '2017-09-13T17:30:34.000+0000',
    sfdcCreatedByUsername: 'appowner1+longemailonpurpose@example.com',
    sfdcModifiedDate: '2017-10-16T17:30:34.000+0000',
    sfdcModifiedByUsername: 'appowner@example.com',
    sfdcSecretVersion: 5,
    sfdcSource: "USER", // this is supposed to be changing on either backend or frontend
    sfdcType: "DeterministicData",
    state: 'active',
    wrappedSecret: 'CgMyMDgSENUBaZ96fGoPIn01+Q0ppr0aMK/3L0ZbWymlnobaE0IXDWPs40nWBCjiYU7/AnsAi6iVuiPYyd2XwJI6JQDLTYSxpw==',
  };

  // keybroker object
  const listObject = {
    total: 0,
    skip: 0,
    limit: 10,
    resources: [],
  };

  const infoGet = {
    "connectedAppCallbackURL": "https://keybroker.apps.randomAnimal.dpsas.io/callback",
    "connectedAppClientID": "secretClientId",
    "codeResponseAuthURL": "https://login.salesforce.com/services/oauth2/authorize?response_type=code" +
      "&client_id=secretClientId&redirect_uri=https://keybroker.apps.randomAnimal.dpsas.io/callback",
    "tokenResponseAuthURL": "https://login.salesforce.com/services/oauth2/authorize?response_type=token" +
      "&client_id=secretClientId&redirect_uri=https://keybroker.apps.randomAnimal.dpsas.io/callback",
  };

  const rotationPolicy = (secretType) => {
    // the reason for this is to test the UI functionality that the "warning" banner will reappear if the user selects anything greater than 12 months
    const dateYearAgo = new Date();
    dateYearAgo.setFullYear(dateYearAgo.getFullYear() - 1);
    return {
      "id": "5ffec58d-1da3-46bf-b3ca-0c7f191b8" + (Math.floor(Math.random() * 1000) + 1),
      "uri": "dpod:b98db09d-1b27-46fd-a77f-f108768e0124-Ojk:keybroker:salesforce_rotation_policy:data-policy-acme-corp",
      "account": "dpod:b98db09d-1b27-46fd-a77f-f108768e0124-Ojk:admin:accounts:b98db09d-1b27-46fd-a77f-f108768e0124-Ojk",
      "application": "ncryptify:gemalto:admin:apps:dpod",
      "devAccount": "ncryptify:gemalto:admin:accounts:gemalto",
      "createdAt": "2018-01-09T17:51:36.845642Z",
      "name": "567566556-Data-VpSTnDbK",
      "updatedAt": "2018-01-09T17:51:36.845642Z",
      "configID": "5eeed61e-1d97-4875-bacd-fca048ea43a9",
      "secretType": secretType,
      "intervalType": "month",
      "intervalValue": 6,
      "active": true,
      "meta": {},
      "lastError": "Last time we tried to rotate keys, stuff just broke!",
      "lastSecretDate": dateYearAgo.toISOString(),
      "lastSecretID": "d1586b1a-572e-4777-a108-2cc304c41846",
      "nextSecretDate": "2018-06-26T17:43:58.923423Z",
    };
  };

  const rotationPoliciesObject = cloneDeep(listObject);
  const tenantSecretsObject = cloneDeep(listObject);

  $httpBackend.whenRoute('GET', `${salesforceEndpoint}/info`)
    .respond(() => [200, infoGet]);

  const states = ['destroyed', 'revoked', 'archived', 'active'];

  // Creates a new config
  $httpBackend.whenRoute('POST', `${salesforceEndpoint}/configs`)
    .respond(() => [201, configsGet]);

  // Returns the configuration resource
  $httpBackend.whenRoute('GET', `${salesforceEndpoint}/configs/:id`)
    .respond(() => [200, configsGet]);

  // List all the salesforce configs belonging to the tenant
  $httpBackend.whenRoute('GET', `${salesforceEndpoint}/configs`)
    .respond(() => {
      tenantSecretsObject.resources.length = 0;
      tenantSecretsObject.resources.push(configsGet);
      return [200, tenantSecretsObject];
    });

  // Updates the config properties
  $httpBackend.whenRoute('PATCH', `${salesforceEndpoint}/configs/:id`)
    .respond(() => [201, configsGet]);

  // Deletes the config. Does not delete the keys (i.e. Tenant Secrets)
  $httpBackend.whenRoute('DELETE', `${salesforceEndpoint}/configs/:id`)
    .respond(() => [204, '']);

  // retrieve rotation policies
  $httpBackend.whenRoute('GET', `${salesforceEndpoint}/rotation-policies`)
    .respond(() => [200, rotationPoliciesObject]);

  // retrieve a specific rotation policy
  $httpBackend.whenRoute('GET', `${salesforceEndpoint}/rotation-policies?config=:id`)
    .respond(() => [200, rotationPoliciesObject]);

  // create a new rotation policy
  $httpBackend.whenRoute('POST', `${salesforceEndpoint}/rotation-policies`)
    .respond((method, url, data, /*headers, params*/) => {
      const parsedData = JSON.parse(data);
      const newRotationPolicy = rotationPolicy(parsedData.secretType);
      newRotationPolicy.secretType = parsedData.secretType;
      newRotationPolicy.intervalType = parsedData.intervalType;
      newRotationPolicy.intervalValue = parsedData.intervalValue;
      rotationPoliciesObject.resources.push(newRotationPolicy);
      return [201, newRotationPolicy];
    });

  // update a rotation policy
  $httpBackend.whenRoute('PATCH', `${salesforceEndpoint}/rotation-policies`)
    .respond((method, url, data, /*headers, params*/) => {
      const parsedData = JSON.parse(data);
      // we don't send the secretType, we match by id
      const rotationPolicy = rotationPoliciesObject.resources.find(item => item.id === parsedData.id);
      rotationPolicy.active = parsedData.active;
      rotationPolicy.createdAt = new Date().toISOString(); // just need it to update for the UI
      return [201, rotationPolicy];
    });

  // used to get individual secrets
  $httpBackend.whenRoute('GET', `${salesforceEndpoint}/tenant-secrets/:id`)
    .respond((method, url) => {
      const urlArr = url.split('/');
      const id = urlArr[urlArr.length - 1];
      return [200, tenantSecretsObject.resources.filter(sec => sec.id === id)[0]];
    });

  // catch all for tenant-secrets endpoint with queryString
  // this overrides the MockServer class GET
  $httpBackend.whenRoute('GET', `${salesforceEndpoint}/tenant-secrets`)
    .respond((method, url, data, headers, params) => {

      const filteredTenantSecretsObject = clone(tenantSecretsObject);

      if (params.state) {
        filteredTenantSecretsObject.resources = filteredTenantSecretsObject.resources.filter(secret => secret.state === params.state);
      }

      if (params.sfdcType) {
        filteredTenantSecretsObject.resources = filteredTenantSecretsObject.resources.filter(secret => secret.sfdcType === params.sfdcType);
      }

      return [200, filteredTenantSecretsObject];
    });

  const salesforceService = new MockServer(salesforceEndpoint, service => clone(service), $httpBackend);

  $httpBackend.whenRoute('POST', `${salesforceEndpoint}/tenant-secrets/:id/:state`)
    .respond((method, url, data, headers, params) => {
      const tenantSecret = tenantSecretsObject.resources.find(obj => obj.id === params.id); // because we can't use a get on the salesforceService
      tenantSecret.state = params.state === 'revoke' ? 'revoked' : 'archived';
      salesforceService.save(tenantSecret.id, tenantSecret);
      return [201, tenantSecret];
    });

  // Creates a new tenant-secret
  // this overrides the MockServer class POST
  $httpBackend.whenRoute('POST', `${salesforceEndpoint}/tenant-secrets`)
    .respond((method, url, data, /*headers, params*/) => {
      const parsedData = JSON.parse(data);
      if (parsedData.sfdcType === "Analytics") { // testing purposes
        return [400, {
          code: 9,
          codeDesc: "NCERRInvalidParamValue",
          message: "Salesforce: Only 1 new Analytics secret may be created in any 24-hour period.",
        }];
      }

      // creates the new tenant-secret
      const returnedSecret = angular.copy(baseSecret);

      // find sfdcType and find active version
      // to simulate the "active" tenant secret becoming "archived", we'll update it here
      const tenantSecret = tenantSecretsObject.resources.find(obj =>
        obj.state === 'active' && obj.sfdcType === parsedData.sfdcType); // because we can't use a get on the salesforceService
      if (tenantSecret) {
        tenantSecret.state = 'archived';
        salesforceService.save(tenantSecret.id, tenantSecret);
        returnedSecret.sfdcSecretVersion = tenantSecret.sfdcSecretVersion + 1; // get the previous version number and bump it
      } else {
        returnedSecret.sfdcSecretVersion = 1;
      }

      returnedSecret.sfdcType = parsedData.sfdcType;
      tenantSecretsObject.resources.push(returnedSecret);
      const newId = salesforceService.add(returnedSecret);
      return [201, salesforceService.get(newId)];
    });

  tenantSecretsObject.resources.length = 0;

  for (let state = states.length - 1; state >= 0; state--) {
    const newSecret = angular.copy(baseSecret);
    newSecret.state = states[state];
    newSecret.sfdcSecretVersion = state + 1;
    const newId = salesforceService.add(newSecret);
    tenantSecretsObject.resources.push(salesforceService.get(newId));
  }

  salesforceService.setupRoutes($httpBackend);
};

function mockClients($httpBackend, mockServices) {
  const apiClientsEndpoint = "/v1/credentials/clients";
  let mockClientsServer;

  $httpBackend.whenRoute("PATCH", `${apiClientsEndpoint}/:id`)
    .respond((method, url, data, headers, query) => {
      const id = query.id;
      const updateParams = JSON.parse(data);
      const updatedClient = Object.assign(mockClientsServer.get(id), updateParams);
      mockClientsServer.save(id, updatedClient);
      return [200, mockClientsServer.get(id)];
    });

  $httpBackend.whenRoute("POST", `${apiClientsEndpoint}/:id/resetSecret`)
    .respond((/*method, url, data*/) => {
      return [200, {clientSecret: randomHexString(16)}];
    });

  // Override the MockServer default POST behavior. Unlike other create APIs, POST /v1/credentials/clients
  // returns the entity in the response body, not just a location header.
  $httpBackend.whenRoute("POST", `${apiClientsEndpoint}`)
    .respond((method, url, data) => {
      const id = mockClientsServer.add(JSON.parse(data));
      const obj = mockClientsServer.get(id);
      // This POST response also includes the secret (not stored in DB)
      obj.clientSecret = randomHexString(16);
      obj.createdBy = 'you@whatever.com';
      return [200, obj];
    });

  function apiKeyConverter(postedClient /*APIKeyCreateParams*/) {
    const gettedApiKey = clone(postedClient);
    gettedApiKey.clientId = fakeUUID();
    return gettedApiKey;
  }

  // Override to use clientId field as the unique id
  class MockClientsServer extends MockServer {
    constructor() {
      super(apiClientsEndpoint, apiKeyConverter, $httpBackend);
    }

    getKey() {
      return "clientId";
    }
  }

  mockClientsServer = new MockClientsServer();

  mockClientsServer.add({
    name: 'Platform_creds_0',
    role: 'appowner',
    clientId: fakeUUID(),
    createdBy: 'Carl'
  });
  // Create a service API client for all our existing services
  mockServices.getAll().content
    .forEach((service, i) => {
      mockClientsServer.add({
        name: `srv_creds_${i}`,
        role: 'service',
        clientId: randomHexString(),
        createdBy: service.created_by_username,
        serviceIds: [service.service_id],
      });
    });
}

function mockBackoffice($httpBackend, tenants) {

  // will be prefilled on fetch/fetchall
  let accountStatuses: Map<string, TenantAccountStatus> = new Map();

  // take all tenants and generate account statuses for these tenants (except the last one)
  const allTenants = tenants.getAll();
  allTenants.content.forEach((t, index) => {
    // first check if we already have a tenant account status for this tenant
    let accountStatus = accountStatuses.get(t.id);

    // if is last tenant, return no status to mimic a legacy tenant
    const isLastTenant: boolean = allTenants.content[allTenants.content.length - 1].id === t.id;

    if (!accountStatus && !isLastTenant) {
      accountStatus = getAndCreateAccountStatusOnTenantPosition(t.id, index);
      accountStatuses.set(t.id, accountStatus)
    }
  });


  // get account status for one tenant
  $httpBackend.whenRoute("GET", "/v1/backoffice/accountStatuses/:id")
    .respond((method, url, data, headers, params) => {
      // if a tenant admin is logged in, they will get their own account status by ID
      // for mocking purposes, if the user is making a request about their own UUID, we'll check to see if it starts with the tuid it is assigned with
      // if we're searching for that one, we'll ensure we generate an eval status otherwise the footer will not display
      if (params.id.startsWith('tuid')) {
        // index (tenantPos) does not matter here, we're setting it independently
        // for mocking, we'll say the logged in user has not send their purple packet so we can display the eval footer
        const accountStatus = getAndCreateAccountStatusOnTenantPosition(params.id, 0, 'evaluatingNotRequested');
        accountStatuses.set(params.id, accountStatus);
      }

      const accountStatus = accountStatuses.get(params.id);

      if (accountStatus) {
        return [200, accountStatus];
      }

      return [404, ""];
    });

  // list children account status
  $httpBackend.whenRoute("GET", "/v1/backoffice/accountStatuses")
    .respond((method, url, data, headers, params) => {
      // takes the account statuses map and creates a flat array out of it
      const flatAccountStatuses = Array.from(accountStatuses).filter(item => item !== undefined).map(i => i[1]);
      return [200, flatAccountStatuses];
    });

  $httpBackend.whenRoute("GET", "/v1/backoffice/subscriptions")
    .respond((method, url, data, headers, params) => {
      const subscriptions = [
        {
          "marketplaceName": "DPoD",
          "serviceType": "key_vault",
          "type": "PRODUCTION",
          "state": "ACTIVE",
          "autoRenewal": false,
          "startDate": "2018-02-01",
          "endDate": "2018-03-01",
          "plan": "025-000017-001 Uncommitted – Monthly usage in Arrears"
        },
        {
          "marketplaceName": "DPoD",
          "serviceType": "digital_signing",
          "type": "TRIAL",
          "state": "EXPIRED",
          "autoRenewal": false,
          "startDate": "2018-02-01",
          "endDate": "2018-03-01",
          "plan": ""
        },
        {
          "marketplaceName": "DPoD",
          "serviceType": "hyperledger",
          "type": "PRODUCTION",
          "state": "ACTIVE",
          "autoRenewal": true,
          "autoRenewalPeriod": "P3M",
          "startDate": "2018-02-01",
          "endDate": "2018-03-01",
          "plan": "025-000017-001 Uncommitted – Monthly usage in Arrears"
        },
        {
          "marketplaceName": "DPoD",
          "serviceType": "digital_signing",
          "type": "TRIAL",
          "state": "ACTIVE",
          "autoRenewal": true,
          "autoRenewalPeriod": "P1M",
          "startDate": "2022-06-15",
          "endDate": "2022-07-15",
        },
        {
          "marketplaceName": "DPoD",
          "serviceType": "pki_private_key_protection",
          "type": "PRODUCTION",
          "state": "ACTIVE",
          "autoRenewal": true,
          "autoRenewalPeriod": "P30D",
          "startDate": "2021-02-15",
          "endDate": "2021-03-16",
          "plan": "025-000017-001 Term Commitment Monthly in arrears with Overage (with evergreen)",
          "features": {"quantity": 3}
        },
        {
          "marketplaceName": "Google",
          "serviceType": "ekms",
          "type": "PRODUCTION",
          "state": "ACTIVE",
          "autoRenewal": true,
          "autoRenewalPeriod": "P24M",
          "startDate": "2021-03-12",
          "endDate": "2021-10-12",
          "plan": "025-000017-001 Professional – Monthly usage in Arrears"
        }
        ]
      return [200, subscriptions];
    })
  $httpBackend.whenRoute("GET", "/v1/backoffice/products/ctaas")
    .respond((method, url, data, headers, params) => {
      const servicePlans = {
        'plans': [
          {
            "id": "8ac6881f850fc05e0185162867e57aaa",
            "displayName": "Emerging",
            "description": "Includes 1 Connection Pack (mix of up to 4 Flex and/or 1 Cloud connections) and 5,000 API calls",
            "parameters": { }
          },
          {
            "id": "8ac6881f850fc05e0185162867e57bbb",
            "displayName": "Starting",
            "description": "Includes 10 Connection Packs (mix of up to 40 Flex and/or 10 Cloud connections) and 10,000 API calls",
            "parameters": { }
          },
          {
            "id": "8ac6881f850fc05e0185162867e57ccc",
            "displayName": "Growing",
            "description": "Includes 50 Connection Packs (mix of up to 200 Flex and/or 50 Cloud connections) and 1,000,000 API calls",
            "parameters": { }
          },
          {
            "id": "8ac6881f850fc05e0185162867e57ddd",
            "displayName": "Established",
            "description": "Includes 500 Connection Packs (mix of up to 2000 Flex and/or 500 Cloud connections) and 2,500,000 API calls",
            "parameters": { }
          }
        ]
      }
      return [200, servicePlans];
    });
  $httpBackend.whenRoute("GET", "/v1/backoffice/products/digital_signing")
    .respond((method, url, data, headers, params) => {
      const servicePlans = {
        "plans":[{
          "id": "8ac6881f850fc05e0185162867e57cdb",
          "name": "025-000017-001: LUNA CLOUD HSM,MONTHLY WITH OVERAGE",
          "displayName": "MONTHLY WITH OVERAGE",
          "description": "Monthly Recurring Charge (Recurring)",
          "parameters": {
            "$schema": "http://json-schema.org/draft-04/schema#",
            "properties": {
              "quantity": {
                "title": "Number of committed service instances",
                "description": "The account will be billed at minimum for this number of service instances.",
                "type": "integer",
                "default": 1,
                "minimum": 1,
                "maximum": 255
              },
            },
            "type": "object",
            "required": [
              "quantity",
            ]
          }
        }]
      }
      return [200, servicePlans];
    });

  $httpBackend.whenRoute("GET", "/v1/backoffice/tos/:id")
    .respond((method, url, data, headers, params) => {
      return [200, mockPDF, {
        'Content-Type': 'application/pdf',
      }];
    });

  $httpBackend.whenRoute("POST", "/v1/backoffice/serviceAgreements")
    .respond((method, url, data, headers, params) => {
      let accountStatus = accountStatuses.get(params.id);

      if (!accountStatus) {
        accountStatus = getAndCreateAccountStatusOnTenantPosition(params.id, 0, "evaluatingRequested");
        accountStatuses.set(params.id, accountStatus);
      }

      return [200, ""];
    });

  // service provider accepting a service agreement
  $httpBackend.whenRoute("PATCH", "/v1/backoffice/serviceAgreements/:id")
    .respond((method, url, data, headers, params) => {
      let accountStatus = accountStatuses.get(params.id);
      accountStatus.agreementApprovalStatus = AgreementApprovalStatus.approved;
      accountStatuses.set(params.id, accountStatus);
      return [200, ""];
    });

  // service provider rejecting a service agreement
  $httpBackend.whenRoute("DELETE", "/v1/backoffice/serviceAgreements/:id")
    .respond((method, url, data, headers, params) => {
      let accountStatus = accountStatuses.get(params.id);
      accountStatus.agreementApprovalStatus = AgreementApprovalStatus.notRequested;
      accountStatuses.set(params.id, accountStatus);
      return [204, ""];
    });

  $httpBackend.whenRoute("GET", "/v1/backoffice/serviceAgreements/:id")
    .respond((method, url, data, headers, params) => {
      let accountStatus = accountStatuses.get(params.id);

      if (!accountStatus) {
        // if the account status does not exist for mock data, it's probably because the mock data hasn't been properly populated yet
        const index = tenants.getAll().content.findIndex(item => item.id === params.id);

        accountStatus = getAndCreateAccountStatusOnTenantPosition(params.id, index);
        accountStatuses.set(params.id, accountStatus);
      }

      if (accountStatus.agreementApprovalStatus !== AgreementApprovalStatus.notRequested) {
        const serviceAgreement: ServiceAgreementDetails = {
          submission: {
            parentAdministrator: {
              email: null,
              familyName: null,
              givenName: null,
            },
            parentId: null,
            parentName: null,
            submittedDate: null,
            tenantAdministrator: {
              email: null,
              familyName: null,
              givenName: null,
            },
            tenantId: null,
            tenantName: null,
          },
          terms: {
            duration: 24,
            mbus: [
              {
                quantity: 6,
                planId: "8ac6881f850fc05e0185162867e57cdb",
                serviceType: {
                  id: "00001111-2222-3333-444455550001",
                  name: "HSM on Demand",
                  shortCode: "key_vault"
                },
              },
              {
                quantity: 4,
                planId: "8ac6881f850fc05e0185162867e57cdb",
                serviceType: {
                  id: "00001111-2222-3333-444455550001",
                  name: "Salesforce",
                  shortCode: "salesforce_key_broker"
                },
              }
            ]
          }
        };

        return [200, serviceAgreement];
      }

      return [404, ""];
    });

}

function mockTenants($httpBackend) {
  // Admin info lives under a special sub-route. Register this first to prevent the MockServer
  // from stealing it.
  $httpBackend.whenRoute("GET", "/v1/tenants/:id/admin")
    .respond((method, url, data, headers, params) => {
      const tenant = tenants.get(params.id);
      // Uncomment this to return a 500 error on a newly-created tenant. This simulates
      // a bug in real tenant creation.
      // const now = new Date();
      // const createdAt = new Date(tenant.createdAt);
      // if (now - createdAt < 20*1000) {
      //    console.log('simulating 500 error for tenant ' + tenant.id);
      //    return [500, {
      //       "status":500,
      //       "error":"Internal Server Error",
      //       "message":"Request processing failed"
      //    }];
      // }
      if (!tenant) {
        return [404];
      }

      const suffix = params.id.split("-").pop().slice(-4);
      const adminInfo = {
        givenName: "Admin",
        familyName: `Name${suffix}`,
        phoneNumber: `+1 613723${suffix}`,
        email: `admin@${tenant.hostname}.com`,
      };
      return [200, adminInfo];
    });

  $httpBackend.whenRoute("PATCH", "/v1/tenants/:id/admin/reset")
    .respond(() => [201, {temporaryPassword: "TEMPORARY_PASSWORD"}]);

  $httpBackend.whenRoute("POST", "/v1/tenants/:id/admin/resetMfaToken")
    .respond(() => [200]);

  $httpBackend.whenRoute("GET", "/v1/tenants/usageReport")
    .respond((method, url, data, headers, params) => [200, mockReport(), {}]);

  $httpBackend.whenRoute("GET", "/v1/tenants/servicesSummaryFile")
    .respond((method, url, data, headers, params) => [200, mockReport(), {}]);

  $httpBackend.whenRoute("GET", "/v1/tenants/:id/logo")
    .respond((method, url, data, headers, params) => {
      const tenant = tenants.get(params.id);
      if (!tenant || !(tenant.__logo)) {
        return [404];
      }
      return [200, tenant.__logo];
    });

  $httpBackend.whenRoute("PUT", "/v1/tenants/:id/logo")
    .respond((method, url, data, headers, params) => {
      // Store logo as hidden property on tenant object so it won't appear in tenant's JSON representation
      tenants.getAll().content.forEach(tenant => {
        Object.defineProperty(tenant, '__logo', {enumerable: false});
      });
      return [200];
    });

  $httpBackend.whenRoute("GET", "/v1/tenants/settings")
    .respond((method, url, data, headers, params) => {
      const tenantSettings = {
        automaticTenantOnboarding: true
      };
      return [200, tenantSettings];
    });

  $httpBackend.whenRoute("GET", "/v1/tenants/parent")
    .respond((method, url, data, headers, params) => {
      return [200, {
        name: "Pepsi Cola",
      }];
    });

  // Tenants info
  const tenants = new MockServer("/v1/tenants", postedTenant => clone(postedTenant), $httpBackend);
  [
    {
      // TODO evaluation tenants need a company name?
      name: "TenantName 1",
      billingAddress: {
        city: "Ottawa",
        country: "Canada",
      },
      accountType: TenantAccountType.subscriber,
      accountStatus: "active",
      hostname: "xyz",
      spaceStatus: "active",
      serviceQuota: 5,
      createdAt: "2017-08-07T22:02:08.253Z",
      updatedAt: "2017-08-07T22:07:41.104Z",
    },
    {
      name: "TenantName 3",
      billingAddress: {
        streetAddress: "1400 E 56th St",
        city: "Los Angeles",
        //state: "California",
        //country: "United States",
        zip: "90210",
      },
      accountType: TenantAccountType.subscriber,
      accountStatus: "active",
      hostname: "tenant3",
      spaceStatus: "active",
      serviceQuota: 5,
      createdAt: "2017-08-07T22:02:08.253Z",
      updatedAt: "2017-08-07T22:07:41.104Z",
    },
    {
      name: "Company XYZ",
      billingAddress: {
        streetAddress: "123 Fake Street",
        city: "Hamburg",
        state: "Hamburg",
        country: "Germany",
        zip: "21073",
      },
      accountType: TenantAccountType.subscriber,
      accountStatus: "disabled",
      hostname: "tenant2",
      spaceStatus: "active",
      serviceQuota: 5,
      createdAt: "2017-08-07T22:02:08.253Z",
      updatedAt: "2017-08-07T22:07:41.104Z",
    },
    {
      name: "PendingTenant",
      billingAddress: {
        streetAddress: "165 R. Recife",
        city: "Salvador",
        state: "Bahia",
        country: "Brazil",
        zip: "13904",
      },
      accountType: TenantAccountType.subscriber,
      accountStatus: "active",
      hostname: "tenant4",
      spaceStatus: "pending",
      serviceQuota: 5,
      createdAt: "2017-05-07T22:02:08.253Z",
      updatedAt: "2017-07-07T22:07:41.104Z",
    },
    {
      name: "FailTenant",
      billingAddress: {
        streetAddress: "243 Shrew Ln",
        city: "Bugsworth",
        state: "West Midlands",
        country: "United Kingdom",
        zip: "EM1X 2V3",
      },
      accountType: TenantAccountType.subscriber,
      accountStatus: "active",
      hostname: "tenant4",
      spaceStatus: "failed",
      serviceQuota: 5,
      createdAt: "2017-05-07T22:02:08.253Z",
      updatedAt: "2017-07-07T22:07:41.104Z",
    },
    {
      name: "DeletingTenant",
      billingAddress: {
        streetAddress: "264 Main St",
        city: "Ogdensburg",
        state: "New York",
        country: "United States",
        zip: "57681",
      },
      accountType: TenantAccountType.subscriber,
      accountStatus: "deleting",
      hostname: "tenant4",
      spaceStatus: "failed",
      serviceQuota: 5,
      createdAt: "2017-05-07T22:02:08.253Z",
      updatedAt: "2017-07-07T22:07:41.104Z",
    },
    {
      name: "TenantName 6",
      accountType: TenantAccountType.serviceProvider,
      accountStatus: "active",
      hostname: "tenant3",
      spaceStatus: "active",
      serviceQuota: 0x7fffffff,
      createdAt: "2017-08-07T22:02:08.253Z",
      updatedAt: "2017-08-07T22:07:41.104Z"
    },
  ].forEach(tenants.add.bind(tenants));

  return tenants;
}

function mockTiles($httpBackend, mockTenants) {
  const tilesEndpoint = "/v1/tiles";

  // example response for HSMOD service
  const hsmTileResponse = [
    {
      "id": "35457c40-31c2-4357-beea-6c1d254d2177",
      "name": "single_hsm",
      "description": "Standalone Luna SA",
      "schemas": {
        "service_instance": {
          "create": {
            "parameters": {
              "additionalProperties": false,
              "$schema": "http://json-schema.org/draft-04/schema#",
              "type": "object",
              "required": [
                "tenantId",
                "serviceType",
                "sguid"
              ],
              "properties": {
                "tenantId": {
                  "type": "string",
                  "format": "uuid",
                  "description": "Billing account number used to charge the use of service."
                },
                "deviceType": {
                  "description": "Device type to be used for provisioning a partition.",
                  "default": "cryptovisor",
                  "type": "string",
                  "enum": [
                    "cryptovisor",
                    "cryptovisor_fips"
                  ]
                },
                "serviceType": {
                  "type": "string",
                  "description": "The type of service."
                },
                "serviceVersion": {
                  "format": "string",
                  "description": "The version of the service (Default to 1.0.0)"
                },
                "sguid": {
                  "type": "string",
                  "format": "uuid",
                  "description": "The subscriber group id."
                }
              }
            }
          }
        },
        "service_binding": {
          "create": {
            "parameters": {
              "$schema": "http://json-schema.org/draft-04/schema#",
              "type": "object"
            }
          }
        }
      }
    }
  ];
  // example response for CTAAS service
  const ctaasTileResponse = [
    {
      "id": "5ba8f815-4c1d-4a75-a95e-abe219b10bc0",
      "name": "Tenant",
      "description": "Create a new tenant",
      "metadata": {},
      "free": false,
      "bindable": false,
      "schemas": {
        "service_instance": {
          "create": {
            "parameters": {
              "$schema": "http://json-schema.org/draft-04/schema#",
              "properties": {
                "name": {
                  "description": "Tenant account name.  Optional: if not specified, a tenant name will be derived.  If specified, it must be unique.",
                  "type": "string"
                },
                "cluster": {
                  "description": "Pick the cluster to create the tenant on.",
                  "type": "string",
                  "enum": [
                    "gcp-us-west1",
                    "gcp-us-east1",
                    "gcp-europe-west3",
                    "gcp-eu-east2",
                    "gcp-us-central1",
                    "aws-us-east1",
                    "aws-eu-west1",
                    "azure-eu-west3",
                    "azure-us-east4"
                  ]
                },
                "initial_admin_password": {
                  "description": "Initial password for the tenant admin user. This needs to be changed at the first login.",
                  "type": "string"
                },
                "tenant_rot_anchor": {
                  "description": "Optional Root Of Trust (RoT) anchor.  The default, 'hsmod', uses an HSM partition as an RoT.  Use 'softkek' to use a software RoT.",
                  "type": "string",
                  "default": "hsmod",
                  "enum": [
                    "softkek",
                    "hsmod"
                  ]
                }
              },
              "type": "object",
              "required": [
                "cluster",
                "initial_admin_password"
              ]
            }
          }
        }
      }
    }
  ]

  // To avoid having to implement a real mocked Tenant -> Tiles DB we're faking it
  // here by using the same set of tile data, and giving them tenant-specific IDs
  // on the fly by concatenating the tenant's id on.
  function tilesForTenant(tenantId) {
    const ts = cloneDeep(tilesMockServer.getAll());
    ts.content.forEach(tile => tile.id = `${tenantId}!!${tile.id}`);
    return ts;
  }

  // return the plan for a specific tile
  $httpBackend.whenRoute("GET", `${tilesEndpoint}/:id/plan`)
    .respond((method, url, data, headers, params) => {
      const [_, tileId] = params.id.split('!!');
      // return ctaasTileResponse for ctaas tile
      if (tilesMockServer.mapTiles[tileId] === "ctaas") {
        return [200, ctaasTileResponse];
      }
      // return hsmTileResponse
      return [200, hsmTileResponse];
    });

  $httpBackend.whenRoute("GET", `${tilesEndpoint}`)
    .respond((method, url, data, headers, params) => {
      // tenantId passed to this request is optional
      // not passing a tenantId acts as if the server knows the users tenantId
      const tenantId = params.tenantId ? params.tenantId : fakeUUID();
      return [200, tilesForTenant(tenantId)];
    });

  $httpBackend.whenRoute("PATCH", `${tilesEndpoint}/:id`)
    .respond((method, url, data, headers, params) => {
      // FIXME this affects the tile enablement in ALL tenants
      const [tenantId, tileId] = params.id.split('!!');
      const tile = tilesMockServer.get(tileId);
      if (!tile) {
        return [404];
      }

      // TODO need json-patch library to implement this properly
      const patchOperations = JSON.parse(data);
      patchOperations.forEach(change => {
        if (change.op !== 'replace') {
          throw new Error(`Unsupported JSON patch: ${change.op}`);
        }
        change.path.split('/').filter(s => !!s).reduce((object, property, i, array) => {
          if (i === array.length - 1) {
            return object[property] = change.value;
          }
          return object[property];
        }, tile);
      });
      tilesMockServer.save(tileId, tile);
      tile.id = params.id;
      return [200, tile];
    });

  // This is a no-op right now since tiles have no POST
  const tileConverter = postedTile => clone(postedTile);
  const tilesMockServer = new MockServer(tilesEndpoint, tileConverter, $httpBackend);

  // todo we can probably cut this down and only have a few tiles with subtle differences for testing
  // most of these are just to mimic what is in the backend but doesn't really help for testing purposes since it's hard to differentiate
  const tilesDB /*: Partial<TileInternal[]>*/ = [
    {
      name: "HSM on Demand",
      description: "Set up and access an HSM on Demand service for your organization's cryptographic operations.",
      shortCode: "key_vault",
      enabled: true,
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "HSM on Demand for PKI Private Key Protection",
      description: "Secure private keys belonging to Certificate Authorities responsible for establishing a PKI trust hierarchy.",
      shortCode: "pki_private_key_protection",
      enabled: true,
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "HSM on Demand for Digital Signing",
      description: "Digitally sign software and firmware packages or electronic documents in order to ensure the integrity of the sender.",
      shortCode: "digital_signing",
      enabled: false,
      helpUrl: "https://example.org",
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "HSM on Demand for Oracle TDE Database",
      description: "Ensure that Oracle TDE data encryption keys are encrypted with a master key that resides within the HSM for optimal performance and scalability.",
      shortCode: "oracle_tde_database",
      enabled: false,
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "Salesforce Key Broker",
      description: "Create tenant secrets for Salesforce and manage your keys and security policies in concert with Salesforce Shield across their lifecycle.",
      shortCode: "salesforce_key_broker",
      enabled: true,
      categoryName: "CipherTrust Cloud Key Management Services",
      imageUrl: "/assets/service_types/salesforce_key_broker.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "New CipherTrust Tile",
      description: "Create tenant secrets for Salesforce and manage your keys and security policies in concert with Salesforce Shield across their lifecycle.",
      shortCode: "ctaas",
      enabled: true,
      categoryName: "CipherTrust Cloud Key Management Services",
      imageUrl: "/assets/service_types/ctaas.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "VM Encryption",
      description: "Securely store and manage the encryption key life cycle for virtual machine encryption.",
      shortCode: "vm_keystore",
      enabled: true,
      categoryName: "Encryption Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "HSM on Demand for Hyperledger",
      description: "Secure your blockchain artifacts using encryption keys stored within an HSMoD service.",
      shortCode: "hyperledger",
      enabled: true,
      helpUrl: "https://example.org",
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "Key Broker for Azure",
      description: "Generate and manage Microsoft Azure Resource Management Group key pairs using a Key Management on Demand service.",
      shortCode: "azure",
      enabled: true,
      helpUrl: "https://example.org",
      categoryName: "CipherTrust Cloud Key Management Services",
      imageUrl: "/assets/service_types/azure.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "HSM on Demand for CyberArk",
      description: "Secure CyberArk Privileged Access Security Solution’s top-level encryption key within an HSM.",
      shortCode: "cyberark_digital_vault",
      enabled: true,
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "HSM on Demand for Java Code Signer",
      description: "Sign Java artifacts using an encryption key generated on an HSM.",
      shortCode: "java_code_sign",
      enabled: true,
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "HSM on Demand for Microsoft ADCS",
      description: "Secure your Microsoft Root Certificate Authority (CA) signing key in an HSM on Demand service.",
      shortCode: "ms_adcs",
      enabled: true,
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "HSM on Demand for Microsoft Authenticode",
      description: "Generate and secure your Microsoft Authenticode certificates on an HSM on Demand service.",
      shortCode: "ms_authenticode",
      enabled: true,
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "HSM on Demand for Microsoft SQL Server",
      description: "Off-load Microsoft SQL Server crypto operations to an " +
        "HSM on Demand service to improve performance and security.  on Demand service to improve performance " +
        "and security on Demand service to improve performance and security on Demand service to improve performance " +
        "and security on Demand service to improve performance and security",
      shortCode: "ms_sql_server",
      enabled: true,
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      helpUrl: "https://example.org/help",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    },
    {
      name: "KeyFactor redirection tile",
      description: "KeyFactor factors the keys using key factoring technologies",
      shortCode: "keyfactor",
      redirectionUrl: "https://example.org/redirection",
      enabled: true,
      categoryName: "Luna Cloud HSM Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      /*serviceBrokerUrl: undefined,*/ // the redirection tile will not have a serviceBrokerUrl, so do not add one
    },
    {
      name: "P2PE",
      description: "Provides Point to Point encryption, decryption, key management, and key distribution services.",
      shortCode: ServiceShortCode.p2pe,
      redirectionUrl: null,
      enabled: true,
      categoryName: "payShield Cloud Services",
      imageUrl: "/assets/service_types/key_vault.svg",
      serviceBrokerUrl: "https://dpod-dev-ci-cr.apps.chimera.dpod.live",
    }
  ];
  tilesDB.forEach(d => tilesMockServer.add(d));
}

function mockReport() {
  return new TextEncoder('utf-8').encode(`"Mock Report"\n`);
}

// https://stackoverflow.com/a/12300351/3394770
function dataUriToBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], {type: mimeString});
}

/**
 * @returns a string of random bytes in the form 8-4-4-4-12
 * Note: the returned strings are not real RFC-compliant UUIDs
 */
function fakeUUID() {
  const middle = Array(3).fill(4).map(randomHexString); // 3-element array of 4-byte hex strings
  return `${randomHexString(8)}-${middle.join("-")}-${randomHexString(12)}`;
}

function randomArrValue<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

function randomHexString(length = 8) {
  let result = '';
  while (result.length < length) {
    result += Math.random().toString(16).slice(2);
  }
  return result.substr(length);
}

export default module.name;
