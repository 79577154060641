<wizard [title]="modalTitle" (submit)="finish()">

    <terms-of-service-step name="Service Information" instructions="Service Information"
                           [serviceTypeName]="serviceTypeName" [serviceCode]="'ctaas'">
    </terms-of-service-step>

    <ciphertrust-configure name="Configure" instructions="Configure Instructions"
                           [servicePlan]="servicePlan"
                           (serviceName)="serviceName = $event"
                           (cloudProvider)="cloudProvider = $event"
                           (cloudRegion)="cloudRegion = $event"
                           (userName)="userName = $event"
                           (adminPassword)="adminPassword = $event">
    </ciphertrust-configure>

    <ciphertrust-summary name="Summary" instructions="Summary"
                         [serviceName]="serviceName"
                         [cloudProvider]="cloudProvider"
                         [cloudRegion]="cloudRegion"
                         [userName]="userName"
                         [adminPassword]="adminPassword"></ciphertrust-summary>
</wizard>
