import {Component, Inject, OnInit, OnDestroy, QueryList, ViewChild, ViewChildren, Input} from '@angular/core';



import {TenantSettings} from "../../tenant/tenant.model";
import {TenantsService} from "../../tenant/tenants.service";

@Component({
  selector: 'gem-tenant-registration',
  templateUrl: './tenant-registration.component.html',
  styleUrls: ['./tenant-registration.component.scss']
})
export class TenantRegistrationComponent implements OnInit {
  tenantSettings: TenantSettings;

  constructor(private tenantsApi: TenantsService) {
  }

  ngOnInit(): void {
    this.tenantsApi.getTenantSettings()
      .then(ts => this.tenantSettings = ts)
      .catch(err => {
        if (err.status !== 404) {
          throw err;
        }
      });
  }

  toggleTenantRegistration(): void {
    this.tenantSettings.automaticTenantOnboarding = !this.tenantSettings.automaticTenantOnboarding;
    this.tenantsApi.updateTenantSettings(this.tenantSettings);
  }

}
