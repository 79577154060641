import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {IKeyRotationPolicy, IntervalType} from "./key-broker.interface";
import {sfdcTypes} from "../salesforce.constants";
import {downgradeComponent} from "@angular/upgrade/static";
import {KeyRotationComponent} from "./key-rotation.component";
import {DatePipe} from "@angular/common";
import {timer} from 'rxjs';

/**
 * Key Rotation Policy Component
 *
 * Presentational component.  This is due in part to that this may be reused in the future
 * Has the ability to turn on/off, set a month/year and make an API call to the parent component
 *
 */
@Component({
  selector: "key-rotation-policy",
  styleUrls: ['./key-rotation-policy.component.scss'],
  templateUrl: "./key-rotation-policy.component.html",
})
export class KeyRotationPolicyComponent extends KeyRotationComponent implements OnChanges {

  @Input() rotationPolicy: IKeyRotationPolicy = {
    id: null,
    uri: null,
    account: null,
    application: null,
    devAccount: null,
    createdAt: null,
    name: null,
    updatedAt: null,
    configID: null,
    secretType: null,
    intervalType: IntervalType.month,
    intervalValue: 6,
    active: false,
    meta: {},
    lastSecretDate: null,
    lastSecretID: null,
    nextSecretDate: null,
  };

  // default name string if rotation policy has not been created
  @Input() secretType: string;

  // reword this variable after
  selectedRotationPolicy: IKeyRotationPolicy;

  rotationPolicySaved = false;

  private hasBeenSaved = false;

  constructor(datePipe: DatePipe) {
    super(datePipe);
    this.selectedRotationPolicy = Object.assign({}, this.rotationPolicy);
  }

  ngOnChanges(changes: SimpleChanges) {

    this.rotationPolicySaved = false;
    this.editing = false;

    if (this.hasBeenSaved) {
      this.rotationPolicySaved = true;
    }

    if (this.rotationPolicy && this.rotationPolicy.id !== null) {
      this.selectedRotationPolicy = this.rotationPolicy;
      this.enabled = this.selectedRotationPolicy.active;
    }
  }

  // returns UI friendly secretType
  getSecretType(): string {
    const secretType: string = this.selectedRotationPolicy.secretType !== null ? this.selectedRotationPolicy.secretType : this.secretType;
    return sfdcTypes[secretType];
  }

  hasRotationPolicies() { // can be renamed once parent class removed, there is only one rotation policy
    return this.selectedRotationPolicy.id !== null;
  }

  savePolicy(rotationPolicy: IKeyRotationPolicy) {
    // if has never been set before, we need to retrieve the secretType
    if (rotationPolicy.id === null) {
      rotationPolicy.secretType = this.secretType;
      rotationPolicy.active = true;
    }

    this.hasBeenSaved = true;
    super.savePolicy(rotationPolicy);
  }

}
