/**
 * Tenant Secrets state
 * All these states are returned from Keybroker.  Salesforce does not have a `revoked` state on their end.
 */
export const salesforceState = {
    active: 'active', // can be used to encrypt and decrypt new or existing data.
    archived: 'archived', // can’t encrypt new data. Can be used to decrypt data previously encrypted with this key when it was active.
    revoked: 'revoked', // a revoked secret is one where it is DESTROYED on Salesforce's end but Keybroker has saved the exported key.
    destroyed: 'destroyed', // a destroyed secret is similar to revoked state, except that Keybroker CANNOT import it.  The key does not exist in Keybroker.
};

export interface SalesforceServiceConfiguration {
  id: string;
  uri: string;
  account: string;
  application: string;
  devAccount: string;
  createdAt: string;
  name: string;
  updatedAt: string;
  username: string;
  displayName: string;
  email: string;
  organizationId: string;
  userId: string;
  tenantInstance: string;
  codeResponseAuthURL: string;
  tokenResponseAuthURL: string;
  byokCertName: string;
  byokCert: string;
}

/**
 * A collection of errors and error_descriptions that are sent back, but more descriptive
 */
export const BrokerAuthErrors = {
    "access_denied": "Not Authorized",
    "end-user denied authorization": ["Data Protection on Demand is not authorized with this account, " +
    "so this Key Broker service cannot be deployed.", "Click on the Key Broker tile and be sure to authorize " +
    "Data Protection on Demand to properly deploy this service."],
};

export function lastRotationErrorMessageFn(lastError) {
    return `The following error occurred during the last automated rotation attempt:
<div class="alert alert-light-gray mt-3 mb-0 word-break-break-all" role="alert"><strong>${lastError}</strong></div>`;
}

// used in the UI to display the "not error" status of a generating secret
export const notSecretErrorStatusMessages = ["pending", "not generating", "success"];

// used in many spots to generate forms, dropdowns, types in wizard
// salesforce has no endpoint to retrieve available secret types
// to add a new secret type, add it here by (salesforce value, display name)
// important to be sorted alphabetically by value
export const sfdcTypes = {
  "Analytics": "Analytics",
  "Data": "Data",
  "DeterministicData": "Deterministic",
  "SearchIndex": "Search Index",
};
