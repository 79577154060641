import { PromptRedirectComponent } from "@app/components/prompt-redirect";
import { GetBrokerInfo } from "@app/shared/services/keybroker.service";

// todo this is more keybroker in general and not related to office365, it should be moved

export interface RedirectParams {
  host?: string;
  hostButtonText?: string;
}

export interface URLQueryParams {
  [key: string]: string;
}

export interface KeysListResponse {
  skip: number;
  limit: number;
  total: number;
  resources: BaseKey[];
}

interface BaseKey {
  state: string;
}

export function redirectBrokerPrompt(redirectComponentParams: RedirectParams = {}, urlParams: URLQueryParams = {}, brokerKey: string): Promise<any> {
    return this.keybrokerService.getInfo(brokerKey)
      .then(configResponse => showRedirectModal.call(this, configResponse, redirectComponentParams, urlParams))
      .catch(error => this.dialogService.error(error));
}

export function showRedirectModal(configResponse: GetBrokerInfo, redirectComponentParams: RedirectParams = {}, urlParams: URLQueryParams = {}) {
  let tenantName = this.$location.host().match(/^([^.]*)/)[1]; // retrieve tenant name, the keybroker callback requires this

  if (tenantName === "localhost") { // the keybroker redirect needs an actual host, or the redirect will break
    tenantName = "dpod-dev-master-dpod-ui"; // todo we should figure out a better solution to this
    console.warn("Keybroker redirect does not work with localhost, using dpod-dev-master for keybroker callback.  Please remember to manually change your URL back");
  }

  const appendedParams = encodeURIComponent(Object.keys(urlParams).map(k => `${k}=${urlParams[k]}`).join('&')); // this takes an object and creates a encode URI query string
  const website = `${configResponse.codeResponseAuthURL}&state=hostname%3D${tenantName}%26${appendedParams}`; // website to go to to authorize, this returns the `code`

  const modal = this.dialogService.open(PromptRedirectComponent);
  const modalInstance = Object.assign(modal.componentInstance as PromptRedirectComponent, redirectComponentParams);
  modalInstance.website = website;
  modalInstance.close.subscribe(() => modal.close());
}
