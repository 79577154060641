import {Component, Inject} from '@angular/core';
import {DialogService} from '@app/components';
import { TenantsService } from '../tenant/tenants.service';

@Component({
  selector: 'summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./reports.scss'],
})
export class SummaryReportComponent {

  constructor(
    private tenantsApi: TenantsService,
    private dialogService: DialogService) { }

  generateSummary() {
    const progress = this.dialogService.progress('Generating Report...');
    return this.tenantsApi.getServicesSummaryFile()
      .catch(this.dialogService.error.bind(this.dialogService))
      .finally(() => progress.close());
  }
}
