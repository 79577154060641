import { Component, EventEmitter, forwardRef, Input, OnChanges, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WizardStep } from '@app/components';
import { Tile } from '@app/features/marketplace/tiles.interface';
import { MBU, Plan, ServiceAgreement } from "@app/features/tenant/tenant.model";
import { BackofficeService } from "@app/shared/services/backoffice.service";

@Component({
  selector: 'eval-convert-summary-component',
  styleUrls: ['./eval-convert-summary.scss'],
  templateUrl: './eval-convert-summary.component.html',
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => EvalConvertSummaryComponent)}
  ]
})
export class EvalConvertSummaryComponent extends WizardStep implements OnChanges {
  @Input() readonly tiles: Tile[] = [];
  @Input() tileSelection: MBU[] = [];
  @Input() readonly termSelection: number;
  @Input() planSelection: Plan;
  @Input() step: number;
  @Output() serviceAgreementForSubmission: EventEmitter<ServiceAgreement> = new EventEmitter<ServiceAgreement>();
  accepted = false;

  // the DOM element binding
  pdfSource = null;
  // the PDF content stored until we need to display it
  pdfContentData = null;

  // Maps a tile's shortcode to its imageUrl
  private imageUrls: Map<string, string> = new Map();

  constructor(
    private sanitizer: DomSanitizer,
    private backofficeService: BackofficeService,
  ) {
    super();
    this.backofficeService.getTermsOfServicePDF()
      .then(content => this.pdfContentData = content.data);
  }

  ngOnChanges() {
    if (this.tiles) {
      this.tiles.forEach(tile => this.imageUrls.set(tile.shortCode, tile.imageUrl));
    }
  }

  /**
   * Only display the PDF if on Step 2 and assign the PDF content if it is null
   * if you change this method, ensure to test in Chrome, Firefox and Safari
   * We use user agents to determine browser here
   * Chrome browser has both 'Chrome' and 'Safari' user agents
   * Safari browser has only 'Safari' user agent
   */
  displayPDF(): boolean {
    if (this.step === 2) {
      if (this.pdfSource === null) {
        const blob = new Blob([this.pdfContentData], {type: 'application/pdf'});
        const isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
        let url = URL.createObjectURL(blob);
        // the extra params do not allow Safari to render the pdf, so don't add them
        // the extra params have no effect with/without them in Firefox
        url = isSafari ? url : url + "#view=FitH,top&toolbar=0&statusbar=0&navpanes=0&messages=0";
        this.pdfSource = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        // the below revoke doesn't work for Safari
        if(!isSafari){
          // having this in a timeout fixes an issue with Firefox where the object URL is lost before the PDF renders
          setTimeout(() => URL.revokeObjectURL(url), 0);
        }
      }

      return true;
    }

    // if you don't set it to null, going back and forward between steps will break the PDF in Firefox
    this.pdfSource = null;
    return false;
  }

  /**
   * controls whether the service agreement can be submitted or not by disabling the ToS checkbox
   */
  checkboxEnabled(): boolean {
    // the reason we check for pdfContent as well is because the `bypassSecurityTrustResourceUrl` will set the `pdfSource`
    // to a broken PDF and the service agremeent can still be submitted
    return this.pdfContentData !== null && this.pdfSource !== null;
  }

  /**
   * Given an MBU object, returns the imageUrl for the MBU's tile
   */
  getImageUrl(mbu: MBU): string {
    return this.imageUrls.get(mbu.serviceType.shortCode);
  }

}
