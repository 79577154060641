<div class="dialog">
   <div class="modal-body row">
      <h5>{{title}}</h5>

      <div #container>
         <template #contentSlot></template>
      </div>

   </div>
   <div class="row no-gutters">
      <div class="float-sm-left">
         <button class="btn btn-next" name="next" type="button" (click)="close()">{{yesLabel}}</button>
         <button class="btn btn-default" name="cancel" type="button" (click)="dismiss()">{{noLabel}}</button>
      </div>
   </div>
</div>

<!-- template used for static text -->
<ng-template #static let-context>{{context.text}}</ng-template>
