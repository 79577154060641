<alert-with-icon id="non-fips-warning" [icon]="'#bang-triangle'" [class]="'alert-custom-warning'">
    <strong>Once provisioned, use the "Log in with DPoD" button on the CipherTrust Data Security Platform log in page.</strong>
</alert-with-icon>
<div class="row">
    <div class="col form-group">
        <div class="details-panel">
            <div class="details-panel-section">
                <div class="row mb-4">
                    <div class="col-sm-4">
                        Service Name
                    </div>
                    <div class="col-sm-8 text-right">
                        <strong>{{ serviceName }}</strong>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-sm-4">
                        Cloud Provider
                    </div>
                    <div class="col-sm-8 text-right">
                        <strong>{{ cloudProvider.label }}</strong>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-sm-4">
                        Cloud Region
                    </div>
                    <div class="col-sm-8 text-right">
                        <strong>{{ cloudRegion.label }}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
