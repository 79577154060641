<ng-container *ngIf="!temporaryPassword">
    <h5>Reset User Password?</h5>
    <p>
        Data Protection on Demand will generate a new password for this user. This user will be required to change their
        password the next time they log in.
    </p>

    <div class="row no-gutters">
        <div class="float-sm-left">
            <button class="btn btn-next" name="create" type="button" (click)="doReset()">Reset</button>
            <button class="btn btn-default" name="cancel" type="button" (click)="self.dismiss()">Cancel</button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="temporaryPassword">
    <div class="reset-user">
        <h5>Reset User Password</h5>

        <alert-with-icon [icon]="'#info-round'" [class]="'alert-custom-info'">
            A temporary password has been generated below. This user will be required to change it the next time they log
            in.
        </alert-with-icon>
        <hr />

        <h6>Temporary Password</h6>
        <h4 id="temp-password">{{temporaryPassword}}</h4>

        <hr />
        <div class="row no-gutters">
            <div class="float-sm-left">
                <button class="btn btn-next" name="next" type="button" (click)="self.dismiss()">Close</button>
            </div>
        </div>
    </div>
</ng-container>
