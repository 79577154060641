import { Inject, Injectable } from '@angular/core';
import { Q } from '../../ajs-upgraded-providers';
import { DialogService } from './dialog.service';

@Injectable()
export class DialogServiceMock {
    $q: any;

    constructor(@Inject(Q) $q) {
      this.$q = $q;
    }

    openAJS() {
      return this.$q.resolve();
    }

    open() {
        return {
            componentInstance: {},
            result: Promise.resolve()
        };
    }

    confirm() {

    }

    progress() {
        return {
            close: () => {},
        };
    }

    error() {
        return this.confirm();
    }

    entityFn() {
    }

    close() {}

    dismissAll() {}
}

export const MockDialogServiceProvider = { provide: DialogService, useClass: DialogServiceMock };

// Export the legacy AngularJS module for compatibility
import * as angular from 'angular';
export default angular.module('dpaas.dialogServiceMock', [])
    .service('DialogService', DialogServiceMock)
    .name;
