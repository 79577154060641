import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { WizardStep } from '@app/components';
import { AvailableTerms } from '@app/features/eval-convert/eval-convert.constants';

@Component({
  selector: 'eval-convert-terms-component',
  styleUrls: ['./eval-convert.terms.scss'],
  templateUrl: './eval-convert.terms.component.html',
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => EvalConvertTermsComponent)}
  ]
})
export class EvalConvertTermsComponent extends WizardStep {

  @Input() termSelection: number;
  @Output() termSelectionChange: EventEmitter<number> = new EventEmitter<number>();

  checked(val: string) {
    const parsedVal: number = parseInt(val, 10);
    this.termSelection = parsedVal;
    this.termSelectionChange.emit(parsedVal);
  }

  getChecked(num: number): boolean {
    return this.termSelection === num;
  }

  getAvailableTermsSelection(): number[] {
    return Object.values(AvailableTerms).sort(numerically).reverse();
  }
}

function numerically(a: number, b: number) {
  return a - b;
}
