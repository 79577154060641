import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

/**
 * Uses moment package to convert a date into a different format string
 * example usage: | momentConvertDate: "D-MMM-YYYY H:mm"}}
 * has default conversion string if nothing is passed
 */
@Pipe({name: 'momentConvertDate'})
export class MomentConvertDatePipe implements PipeTransform {
  transform(value: string, exponent = "D-MMM-YYYY", inputFormat?: string): string {
    if (value !== null) {
      return moment(value, inputFormat || moment.ISO_8601).format(exponent);
    }

    return "";
  }
}
