import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { ServiceDetailsComponent } from './details/service-details.component';
import { ServicesComponent } from './services/services.component';

import * as AuthScopes from '../auth/roles.constants';

export const servicesState: Ng2StateDeclaration = {
  name: 'services',
  url: '/services',
  redirectTo: 'services.list',
};

export const servicesListState: Ng2StateDeclaration = {
  name: 'services.list',
  // `error` and `error_description used for error handling from third party'
  url: '?code&error&error_description&serviceType',
  params: {
    requiresRole: `${AuthScopes.admin},${AuthScopes.owner}`,
  },
  component: ServicesComponent,
  reloadOnSearch: false,
};

export const serviceDetailsState: Ng2StateDeclaration = {
  name: 'services.details',
  // `error` and `error_description used for error handling from third party
  url: '/{id}?code&error&error_description&serviceType',
  params: {
    requiresRole: `${AuthScopes.admin},${AuthScopes.owner}`,
    openDownloadClientBundleSection: null,
    id: null,
    type: null,
  },
  component: ServiceDetailsComponent,
  resolve: [
    {
      token: 'service_id',
      deps: [Transition],
      resolveFn: getIdParam,
    },
    {
      token: 'openDownloadClientBundleSection',
      deps: [Transition],
      resolveFn: getBundleParam,
    }
  ],
  reloadOnSearch: false,
};

export function getIdParam(t: Transition) {
  return t.params().id;
}

export function getBundleParam(t: Transition) {
  return t.params().openDownloadClientBundleSection;
}
