<header class="details-header d-flex">
    <span class="gem-header panel-header">Application Logo</span>

    <div *ngIf="!editing" class="ml-auto">
        <gem-feedback text="Changes Saved!" [trigger]="isSaved()"></gem-feedback>

        <button class="btn btn-next btn-sm" type="button" name="edit" (click)="edit()">
            Change Logo
        </button>
    </div>
    <div *ngIf="editing" class="ml-auto">
        <button class="ml-auto btn btn-next btn-sm" type="button" name="save" (click)="save()" [disabled]="!filePicked() || isSaving()">
            Save Changes
        </button>
        <button class="ml-2 btn btn-default btn-cancel btn-sm" name="cancel" type="button" (click)="cancel()" [disabled]="isSaving()">
            Cancel
        </button>
    </div>
</header>
<div class="details-panel-section">
    <div class="row">
        <div class="col-sm-6">
            <gem-img-upload #logoUploader [title]="'Login Page &amp; Application Header'" accept="image/png"
                uploadPrompt="Upload Logo" tip="240 × 96, PNG format. Transparency recommended."
                [src]="initialLogo"
                [defaultSrc]="initialLogo"
                [editing]="editing"
                [imageSpec]="{minWidth: 240, maxWidth: 240, minHeight: 96, maxHeight: 96}"
                [showPreviews]="[[240,96], [120,48]]"
                (invalid)="showImageError($event, 'logo')">
            </gem-img-upload>
        </div>
    </div>
</div>
