import { Component, Input, OnInit } from '@angular/core';
import { WizardComponent } from "@app/components";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Tile, TileServicePlan } from "@app/features/marketplace/tiles.interface";
import { ServiceCreationWizard } from "@app/features/gem-services/cloudHSM/wizard/service.wizard-interface";
import { ServiceBrokerService } from "@app/shared/services";

@Component({
  selector: 'app-ciphertrust-wizard',
  templateUrl: './ciphertrust-wizard.component.html',
  styleUrls: ['./ciphertrust-wizard.component.scss']
})
export class CiphertrustWizardComponent extends WizardComponent implements OnInit, ServiceCreationWizard {
  @Input() tile: Tile;
  @Input() servicePlan: TileServicePlan[];
  @Input() serviceType: string;

  serviceName: string;
  cloudProvider: { label:string, code:string } = { label:'', code:'' };
  cloudRegion: { label:string, code:string } = { label:'', code:'' };
  userName: string;
  adminPassword: string;
  serviceTypeName: string;
  modalTitle: string;

  constructor(activeModal: NgbActiveModal, private serviceBrokerService: ServiceBrokerService) {
    super(activeModal);
  }

  ngOnInit() {
    this.serviceTypeName = (this.tile && this.tile.name) || this.serviceType;
    this.modalTitle = `Add ${this.serviceTypeName}`;
  }

  finish(): void {
    const creatorFn = () => {
      const createParams = {
        cluster: (`${this.cloudProvider.code}-${this.cloudRegion.code}`).toLowerCase()
      };

      const servicePlan = "Tenant";
      // One of {tileId, servicePlan} or {serviceType} is required
      return this.serviceBrokerService.create({
        name: this.serviceName,
        tileId: this.tile.id,
        servicePlan,
        serviceType: this.serviceType,
        createParams,
      })
    };
    this.close(creatorFn);
  }
}
