import template from "../templates/modal-generate-multiple-secret-types.html";

class GenerateMultipleSecretTypesController {

    $onInit() {
        this.secrets = ["Data"];
    }

    generateButtonText() {
        const selectedSecrets = this.numberOfSecretsSelected();
        let text = `Generate ${selectedSecrets} Secret`;

        if (selectedSecrets !== 1) {
            text += "s";
        }

        return text;
    }

    generateSecrets() {
        this.close({$value: this.secrets});
    }

    numberOfSecretsSelected() {
        return this.secrets.length;
    }

}

export default {
    template,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
    controller: GenerateMultipleSecretTypesController,
};
