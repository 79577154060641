import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "toggleable-switch",
  templateUrl: "./toggleable-switch.component.html",
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: ToggleableSwitchComponent, multi: true}
  ],
  styleUrls: ["./toggleable-switch.component.scss"],
})
export class ToggleableSwitchComponent {

  @Input() val;
  @Output() valChange: EventEmitter<void> = new EventEmitter<void>();

}
