<div class="details-panel">

    <div [ngClass]="{ 'open': open }"
         class="details-panel-section">
        <svg (click)="open = !open" data-locator="download-section-toggle" class="chevron icon-svg">
            <use xlink:href="#chevron"></use>
        </svg>
        <span (click)="open = !open"
              class="gem-header panel-header color-primary align-middle">Download client for service initialization</span>
    </div>

    <div [ngClass]="{ 'open': open }" class="content">
        <div class="details-panel-section ">
            <ngb-tabset class="main-tabs" type="pills">
                <ngb-tab id="windows" title="Windows">

                    <ng-template ngbTabContent>

                        <div class="row">
                            <div class="col">
                                <div class="step">Step 1</div>
                                <p>
                                <span class="fake-link" data-locator="windows-download" href="#"
                                      (click)="downloadClient('windows')">Download client
                                    for Windows</span>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="step">Step 2</div>
                                <p>
                                    After the download completes, right click on the .exe file and select <strong
                                    class="font-italic">Run as administrator</strong>.
                                    Follow the onscreen prompts. Upon completion the service will be initialized and
                                    ready
                                    for use.
                                </p>
                            </div>
                        </div>

                    </ng-template>
                </ngb-tab>

                <ngb-tab id="linux" title="Linux">
                    <ng-template ngbTabContent>

                        <div class="row">
                            <div class="col">
                                <div class="step">Step 1</div>
                                <p>
                                <span class="fake-link d-inline" data-locator="linux-download" href="#" (click)="downloadClient('linux')">Download client
                                    for Linux</span>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="step">Step 2</div>
                                <p>
                                    Extract the files and follow the installation instructions <a
                                    href="{{ 'installing_a_linux_service_client' | docLinkById }}"
                                    externalLink
                                    class="gem-link d-inline">here</a>.
                                </p>
                            </div>
                        </div>

                    </ng-template>
                </ngb-tab>

            </ngb-tabset>
        </div>
    </div>
</div>
