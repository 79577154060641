<wizard [title]="getTitle()"
        [lastSlideClose]="true"
        [(currentStepIdx)]="step"
        (submit)="finish()">

    <review-permissions-step name="Review Permissions"
                             [instructions]="'Review permissions and optionally enter a name.'"
                             [serviceName]="serviceName"
                             [credentialType]="credentialType"
                             [permissions]="getPermissions()"
                             [(credentialsName)]="credentialsName"
                             (reset)="credentialsName = defaultName()"
    >
    </review-permissions-step>

    <wizard-step name="Summary"
                 [instructions]="getSummaryInstructions()">
        <div class="mb-sm-3">The Client ID will be stored here and in the Credentials table.</div>

        <service-credential-summary
            [serviceName]="serviceName"
            [credentialType]="credentialType"
            [serviceType]="serviceType"
            [credentialsName]="createdCreds.name"
            [apiClientId]="createdCreds.clientId"
            [apiClientSecret]="createdCreds.clientSecret"

        >
        </service-credential-summary>
    </wizard-step>
</wizard>
