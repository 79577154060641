import { Inject, Injectable } from "@angular/core";
import { HttpService, Q } from "@app/ajs-upgraded-providers";
import { Haro } from "@app/shared/services/haro.service";
import { MarketPlaceAccounts } from "@app/features/linkage/ekms.model";
import { IHttpResponse } from "angular";

/**
 * This class provide methods to call Slingshot APIs.
 * Currently we call /status and /link.
 */
@Injectable()
export class EkmsApi {

  private baseUrl = "/v1/signup";
  constructor(
    @Inject(Haro) haro,
    @Inject(HttpService) private http: any,
  ) {}

  /**
   * Retrieves info about signup status of the user logged in
   * @returns The signup status
   */
  async getStatus(): Promise<IHttpResponse<MarketPlaceAccounts>> {
    return this.http.get(`${this.baseUrl}/status`);
  }

  /**
   * Links tenant account with an external marketplace (currently Google)
   */
  async linkAccount(marketplaceAccountId: string): Promise<MarketPlaceAccounts> {
    const state = 'LINKED';
    return this.http.patch(`${this.baseUrl}/link/${marketplaceAccountId}`, {
      state
    })
      .then(response => response.data)
      .catch(response => { throw response.data; });

  }
}
