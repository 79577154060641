import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProtectVKeyFormatted, ProtectVKeyState } from "../../protectV.constants";

@Component({
  selector: "protectv-key-revoke-modal",
  styleUrls: ['./modal-protectV.scss'],
  templateUrl: "./modal-protectV-key-revoke.component.html",
})
export class ProtectVKeysRevokeModalComponent {

  @Input() key: ProtectVKeyFormatted;
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() revoke: EventEmitter<{ key: ProtectVKeyFormatted}> = new EventEmitter();

}
