/**
 * This run block registers a Transition Hook which protects states that need authentication.
 * The hook runs when navigating to a state that requires authentication.
 *
 * It redirects to the login page when one of these is true:
 * - the user is not logged in
 * - the user's token has expired and this is the initial app load (i.e. transitioning
 *   from the empty state)
 *
 * It redirects to the "sessionLoggedOut" state when:
 * - the user's token has expired and this is NOT the initial app load
 */
export default function loginHook($transitions, AuthService, $injector) {
    "ngInject";

    // Loaded lazily to avoid bootstrapping problems
    function getSessionExpiry() {
        return $injector.get('SessionExpiry');
    }

    // This matcher activates when the destination state needs login. Currently all states
    // require login by default. If a state does NOT require login, it must opt out by
    // declaring `requiresRole: false` in its params.
    // TODO: Params are for state inputs, not metadata. requiresRole should probably
    // go in state.data.
    const matcher = {
        to: state => {
            const params = state.params;
            return !params.requiresRole || params.requiresRole.value() !== false;
        },
    };

    const redirect = transition => {
        const $state = transition.router.stateService;
        const isSessionExpired = getSessionExpiry().isSessionEnded();
        const stateHref = $state.href(transition.to().name, transition.params());
        const returnToPath = (stateHref || "").slice("#!".length);

        if (!AuthService.getIdentity() || (isSessionExpired && transition.from().name === "")) {
            // User either hasn't logged in, or is loading the app with an expired token.
            // Redirect to the login page.
            AuthService.login(returnToPath);
            return transition.abort();
        }

        if (isSessionExpired && transition.to().name !== "sessionLoggedOut") {
            return $state.target("sessionLoggedOut", {expiredState: returnToPath}, { location: false });
        }
    };

    // Register the hook with the TransitionsService
    $transitions.onBefore(matcher, redirect, {priority: 100});

    $transitions.onBefore({/* match all*/}, transition => {
        if (AuthService.isRedirecting()) {
            // Block all transitions while the page is unloading
            return transition.abort();
        }
    }, {priority: 100});
}
