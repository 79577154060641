<footer *ngIf="isDisplayed()">

    <div *ngIf="hasIngramParent()">
        <span *ngIf="!hasSubmittedAgreement()">
            You are using a free evaluation version. Contact your Service Provider to become a subscriber.
        </span>

        <strong *ngIf="hasSubmittedAgreement()">
            Your Initial Service Elections have been submitted and are awaiting approval from your Service Provider.
        </strong>
    </div>

    <div *ngIf="!hasIngramParent()">
        You are using a free, 30 day evaluation version of Thales Data Protection on Demand.

        <button *ngIf="!hasSubmittedAgreement()" (click)="convertEvalButton()">
            Become a subscriber now
        </button>

        <strong *ngIf="hasSubmittedAgreement()">
            Your Initial Service Elections have been submitted and are awaiting approval from {{parentName}}.
        </strong>
    </div>

</footer>
