import { Component, TemplateRef, ViewChild } from "@angular/core";
import { DialogService } from "@app/components/gem-dialogs";
import { TilesService } from "@app/shared/services";
import { Observable } from "rxjs";
import { Tile } from "../../../marketplace/tiles.interface";
import { ServiceCategoriesService } from "@app/shared/services/service-categories.service";
import { ServiceCategory } from "@app/shared/services/service-categories.interface";

@Component({
  selector: "services-marketplace",
  templateUrl: "./marketplace.component.html",
})
export class ServicesMarketplaceComponent {

  tiles: Observable<Tile[]>;
  serviceCategories: Observable<ServiceCategory[]>;

  @ViewChild('disable', { static: true }) disablePrompt: TemplateRef<any>;
  @ViewChild('enable', { static: true })  enablePrompt: TemplateRef<any>;

  constructor(private TilesService: TilesService, private ServiceCategoryService: ServiceCategoriesService,
    private DialogService: DialogService,
  ) {
    this.tiles = this.TilesService.tiles();
    this.serviceCategories = this.ServiceCategoryService.serviceCategories();
  }

  tileClick(tile: Tile): void {
    const ctx = { tileName: tile.name };
    if (tile.enabled) {
      this.DialogService.confirm({
        title: "Disable Service?",
        content: this.disablePrompt,
        context: ctx,
        yesLabel: "Disable Service",
        noLabel: "Cancel"
      }).then(() => this.TilesService.setEnabled(tile.id, false));
    } else {
      this.DialogService.confirm({
        title: "Enable Service?",
        content: this.enablePrompt,
        context: ctx,
        yesLabel: "Enable Service",
        noLabel: "Cancel"
      }).then(() => this.TilesService.setEnabled(tile.id, true));
    }
  }

}
