<div class="details-panel-section">
        <header class="gem-header sub-header">{{header}}</header>
        <p>
            <span class="no-resource-prompt">{{tagline}}</span>
        </p>

        <div id="reports-fields" class="row no-gutters">
            <div class="col-auto dropdowns">
                <!--
                Note: the component model expects selected month and year to be numbers, which is
                why we use `ngValue` here. If we bound `value` instead, we'd get strings and the
                model would be corrupted.
                -->
                <select id="month-drop" class="form-control" [(ngModel)]="selectedMonth">
                    <option *ngFor="let month of months" [ngValue]="month.key">
                        {{month.value}}
                    </option>
                </select>
            </div>
            <div class="col-auto dropdowns">
                <select id="year-drop" class="form-control" (change)="onYearSelect()" [(ngModel)]="selectedYear">
                    <option *ngFor="let year of years" [ngValue]="year">{{year}}</option>
                </select>
            </div>
            <div class="generate">
                <button data-locator="generate-monthly-report" id="generate-btn" class="btn btn-next" name="generate" type="button" (click)="generate()">
                    Generate Report
                </button>
            </div>
        </div>
</div>
