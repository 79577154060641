import { Component } from '@angular/core';
import { TransitionService } from '@uirouter/core';

@Component({
  selector: 'gem-accounts',
  templateUrl: './accounts.component.html',
})
export class AccountsComponent{

  showHeaderTabs = true;
  readonly SHOW_HEADER_TAB_STATES = ['accounts.users.list', 'accounts.subscribers.list'];

  constructor(private transitionService: TransitionService) {
    transitionService.onSuccess({to:'accounts.**'}, this.onTransitionSuccess);
  }

  /**
   *  This method refers the above readonly fields to determine whether to show or hide the header tabs
   *  Show header tabs for list pages - Users List, Subscriber Groups List
   *  Hide header tabs for details pages - User Details, Subscriber Group details
   * */
  onTransitionSuccess = (transition) => {
    this.showHeaderTabs = this.SHOW_HEADER_TAB_STATES.includes(transition.to().name);
  }
}
