import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { DialogService, WizardStep } from '@app/components';
import {
  notSecretErrorStatusMessages,
  SalesforceServiceConfiguration
} from '@app/features/gem-services/cloud/salesforce/salesforce.constants';
import { SecretStatus, learnMoreAboutSecretsError } from '@app/features/gem-services/cloud/salesforce/salesforce.common';

@Component({
  selector: 'summary-step',
  templateUrl: './summary.step.component.html',
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => SummaryStepSalesforceComponent)}
  ]
})
export class SummaryStepSalesforceComponent extends WizardStep implements OnInit {

  @Input() service: SalesforceServiceConfiguration | null = null;
  @Input() secretsStatusList: SecretStatus[] = [];
  learnMoreAboutSecretsError;

  constructor(private dialogService: DialogService /* is in use by the learnMoreAboutSecretsError*/) {
    super();
  }

  secretGenerationError() {
    return this.secretsStatusList.find(item => !notSecretErrorStatusMessages.includes(item.status.message));
  }

  ngOnInit() {
    this.learnMoreAboutSecretsError = learnMoreAboutSecretsError.bind(this, this.secretsStatusList);
  }

  /**
   * Sorts the secrets alphabetically by secret name
   */
  secretsStatusListSorted(): SecretStatus[] {
    return this.secretsStatusList.sort((a: SecretStatus, b: SecretStatus) => {
      if (a.name > b.name) {
        return 1;
      }

      if (a.name < b.name) {
        return -1;
      }

      return 0;
    });
  }

}
