import { NgModule } from '@angular/core';
import { TenantDetailsServiceElectionsComponent } from "@app/features/tenant/details/elections/tenant-details-service-elections.component";
import { TenantDetailsServiceElectionsServiceListComponent } from "@app/features/tenant/details/elections/tenant-details-service-elections.service-list.component";
import { SharedModule } from '@app/shared';
import { AutoTrimModule, GemUiCommonModule, MinMaxModule } from '@dpod/gem-ui-common-ng';
import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { UIRouterUpgradeModule } from "@uirouter/angular-hybrid";
import { operator, spadmin } from '../auth/roles.constants';
import { DeleteTenantModalComponent } from './delete-tenant-modal/delete-tenant-modal.component';
import { TenantDetailsComponent } from './details/tenant-details.component';
import { TenantInfoComponent } from './details/tenant-info.component';
import { TenantMarketplaceComponent } from './details/tenant-marketplace.component';
import { TenantQuotaComponent } from './tenant-quota';
import { TenantsTableComponent } from './tenants-table/tenants-table.component';
import { TenantsComponent } from "./tenants.component";
import { AccountInfoStep } from './wizard/account-info.step.component';
import { AddressStep } from './wizard/address.step.component';
import { AdminUserStep } from './wizard/admin-user.step.component';
import { SummaryStep } from './wizard/summary.step.component';
import { TenantWizardComponent } from './wizard/tenant.wizard.component';
import { TenantSubscriptionsComponent } from "@app/features/tenant/details/tenant-subscriptions.component";

export const tenantStates: Ng2StateDeclaration[] = [
  {
    url: "/tenants",
    name: "tenants",
    redirectTo: 'tenants.list',
  },
  {
    name: "tenants.list",
    params: {
      requiresRole: `${operator},${spadmin}`,
    },
    component: TenantsComponent,
  },
  {
    name: "tenants.details",
    url: '/{id}',
    params: {
      requiresRole: `${operator},${spadmin}`,
    },
    component: TenantDetailsComponent,
    resolve: [{
      token: 'tenant_id',
      deps: [Transition],
      resolveFn: getIdParam,
    }],
  },
];

export function getIdParam(t: Transition) {
  return t.params().id;
}

@NgModule({
  declarations: [
    AccountInfoStep,
    AddressStep,
    AdminUserStep,
    SummaryStep,
    TenantDetailsComponent,
    TenantInfoComponent,
    TenantMarketplaceComponent,
    TenantQuotaComponent,
    TenantWizardComponent,
    TenantsComponent,
    TenantsTableComponent,
    TenantDetailsServiceElectionsComponent,
    TenantDetailsServiceElectionsServiceListComponent,
    DeleteTenantModalComponent,
    TenantSubscriptionsComponent,
  ],
  exports: [TenantSubscriptionsComponent],
  imports: [
    GemUiCommonModule,
    AutoTrimModule,
    MinMaxModule,
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states: tenantStates,
    }),
  ],
  entryComponents: [
    TenantsComponent,
    TenantWizardComponent,
    TenantDetailsComponent,
    DeleteTenantModalComponent,
  ]
})
export class TenantModule {
}
