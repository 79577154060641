import {Component, EventEmitter, Input, Output, SimpleChanges, OnChanges} from '@angular/core';

/**
 * pretty radio buttons with some minor animations and a companion label
 * ex.
 * <radio-button (check)="checked($event)" value="24" name="term" text="24 Months"></radio-button>
 * <radio-button (check)="checked($event)" value="36" name="term" text="36 Months"></radio-button>
 */
@Component({
  selector: 'radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.scss'],
  providers: []
})
export class RadioButtonComponent implements OnChanges {

  @Input() id: string;
  @Input() name: string;
  @Input() text?: string;
  @Input() value: string;
  @Input() checked = false;
  @Output() check: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checked) {
      this.checked = changes.checked.currentValue === "true";
    }
  }

  valChange(e) {
    this.check.emit(e);
  }

}
