import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { downgradeComponent } from "@angular/upgrade/static";
import { SharedModule } from "@app/shared";
import { CredentialsListModule } from "@app/shared/components/credentials-table";
import { TermsOfServiceModule } from "../../cloudHSM/wizard/common/terms-of-service";
import { ProtectVKeysDestroyModalComponent } from "./details/modal/modal-protectV-key-destroy.component";
import { ProtectVKeysDetailsModalComponent } from "./details/modal/modal-protectV-key-details.component";
import { ProtectVKeysRestoreModalComponent } from "./details/modal/modal-protectV-key-restore.component";
import { ProtectVKeysRevokeModalComponent } from "./details/modal/modal-protectV-key-revoke.component";
import { ProtectVDetailsComponent } from "./details/protectV-details.component";
import { ProtectVDetailsTableKeysComponent } from "./details/protectV-details.table.keys";
import { ConfigureStepComponent } from "./wizard/configure.step.component";
import { ProtectVWizardComponent } from "./wizard/protectV-wizard.component";
import { SummaryStepComponent } from "./wizard/summary.step.component";

@NgModule({
  declarations: [
    ProtectVWizardComponent,
    ConfigureStepComponent,
    SummaryStepComponent,
    ProtectVDetailsComponent,
    ProtectVDetailsTableKeysComponent,
    ProtectVKeysDetailsModalComponent,
    ProtectVKeysRevokeModalComponent,
    ProtectVKeysRestoreModalComponent,
    ProtectVKeysDestroyModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TermsOfServiceModule,
    CredentialsListModule,
  ],
  entryComponents: [
    ProtectVWizardComponent,
    ProtectVDetailsComponent,
    ProtectVKeysDetailsModalComponent,
    ProtectVKeysRevokeModalComponent,
    ProtectVKeysRestoreModalComponent,
    ProtectVKeysDestroyModalComponent,
  ],
})
export class ProtectVModule {
}

// required because gem-services details is still in AngularJS
export default require('./_init').default
  // todo one of these could be removed when we decide what to do with the naming issue
  .directive("protectvDetails", downgradeComponent({component: ProtectVDetailsComponent}))
  .directive("vmKeystoreDetails", downgradeComponent({component: ProtectVDetailsComponent}))
  .name;
