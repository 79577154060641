import { Pipe, PipeTransform } from '@angular/core';
import moment from "moment";

/**
 * Formats a string with a date as a "DD-MMM-YYYY".
 *
 * Example of usage:
 * ```html
 *  <div> {{ subscription.endDate | dateFormat }} </div>
 * ```
 **/

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(date:string): string {
    if (date) {
      return moment(date).format("DD-MMM-YYYY");
    }
    return '';
  }

}
