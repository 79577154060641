import { Component, Inject, Input } from "@angular/core";
import { VHsmApiService } from "@app/ajs-upgraded-providers";
import { DialogService } from "@app/components";
import { HSMonDemandCreatedService } from "../cloudHSM.model";
import { HSMService } from "../cloudHSM.service";

/**
 * HSMoD service details windows/linux download component
 */
@Component({
  selector: "download-client-details",
  styleUrls: ["./download-client-details.component.scss"],
  templateUrl: "./download-client-details.html",
})
export class DownloadClientDetailsComponent {

  @Input() service: HSMonDemandCreatedService;
  @Input() open = false; // if true, the download section will render opened

  constructor(
    private DialogService: DialogService,
    @Inject(VHsmApiService) private VHsmApiService: HSMService,
  ) { }

  downloadClient(clientType: "windows" | "linux"): void {
    this.DialogService.entityFn(clientName => {
        return this.VHsmApiService.bind(this.service.service_id, clientName, clientType)
          .then(() => this.VHsmApiService.resync());
      },
      "gemCreateClient", {
        existingClients: this.service.clients
      }, "Preparing Service Client...");
  }

}
