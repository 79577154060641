/*eslint-disable angular/window-service, angular/document-service*/
import { difference } from "lodash";


import { DataStore } from "./data-store";
import { filter, map } from 'rxjs/operators';

interface ResyncParams {
  [key: string]: any;
}

type HaroConstructor = () => any;

// TODO move into ServiceBase as generic type parameter.
type T = any;

type Id = string;

/**
 * Abstract base class for implementing a CRUD service based on DataStore and Observable.
 *
 * Provides the following API for interacting with the server:
 *  - resync()
 *  - create()
 *  - fetch()
 *  - delete()
 *  - save()
 *
 * When extending this class, the following methods should be implemented to perform the
 * actual HTTP requests:
 *  - doCreate()
 *  - doFetch()
 *  - doSave()
 *  - doDelete()
 *  - doResync()
 *
 * Since this class extends DataStore, the public API defined there is also available:
 * `get()`, `getAll()`, etc.
 */
export default abstract class ServiceBase extends DataStore<T> {
  protected haro: any;  // TODO types for Haro
  protected baseUrl: string;
  private syncPromise: Promise<any>;

  constructor(haroFactory: any, baseUrl: string) {
    super(haroFactory);
    this.baseUrl = baseUrl;
  }

  /**
   * Hook for customizing an object before it gets added to the data store.
   * Subclasses may use this to add synthetic fields, etc.
   * @param {object} obj The data object obtained from the server
   * @returns {object} The object to add to the data store
   */
  protected annotate(obj: any): T {
    return obj;
  }

  // Note: some signatures are typed as ...args: any[] because the subclasses make
  // the decision about what params to accept, not us.

  create(...args: any[]) {
    return this.doCreate(...args).then(response => {
      if (response.status === 201) {
        return this.fetch(response.headers("Location"));
      } else if (response.status === 202) {
        return response;
      }
    }).catch(response => { throw response.data; });
  }

  /**
   * @param id The id of the entity to delete
   */
  delete(id: Id): Promise<any>  {
    return this.doDelete(id).then(response => {
      this.store.del(id);
      return response;
    }).catch(error => {
      if (error.status == 400){
        console.log("Error while deleting record" + id +" error:"+ error.status)
        throw error
      }
    });
  }

  save(...args: any[]) {
    return this.doSave(...args)
    .then(response => this.set(this.annotate(response.data)))
    .catch(response => { throw response.data; }); // unwrap response
  }

  fetch(...args: any[]) {
    return this.doFetch(...args)
    .then(response => this.set(this.annotate(response.data)))
    .then(record => record[1]);
  }

  /**
   * Fetches a list of all the resources from the server, and resyncs the data store with
   * this data. All fetched keys are added to the store. Any old keys that are no longer
   * on the server are removed from the store.
   *
   * @param {object}      params        optional params to send when resyncing
   * @param {string}      contentKey    if the content array is something else, you can specify with this ex. with keybroker
   * @returns {promise}                 resolved with a list of all the resources fetched from the server
   */
  resync(params: ResyncParams = {}, contentKey = 'content') {

    // cache the sync promise until it's resolved/rejected, so that we don't have
    // multiple resyncs on the go at once
    this.syncPromise = this.syncPromise || this.doResync(params)
    .then(response => {
      const idField = this.getKey();
      const keys = this.store.map(key => key[idField]);
      const newKeys = response.data[contentKey].map(g => g[idField]);
      const toRemove = difference(keys, newKeys);

      // update or add all the incoming keys, then remove the ones that aren't in the
      // dataset we got from the server

      response.data[contentKey].forEach(g => this.set(this.annotate(g)));
      toRemove.forEach(key => this.store.del(key));

      console.log(`Resynced ${newKeys.length} ${this.baseUrl} elements from the server`);

      return this.getAll();
    })
    .finally(() => this.syncPromise = null);
    return this.syncPromise;
  }

  /**
   * Subscribe for changes to a resource with the given id.
   * TODO make this compatible with super.subscribeTo()
   *
   * @param {string} id - The id of the resource to subscribe to
   * @param {function} fn - Called when the resource changes. The function is passed the modified
   *   resource object as an immutable argument.
   * @param {boolean} resync - will make an API call, and call the callback function
   *   TODO this makes the function incompatible with the superclass
   * @returns {Subscription}
   */
  subscribeTo(id, fn, resync = false) {
    const record = this.get(id);

    if (record) { // if record exists, we immediately push it
      fn(record);
    }

    if (resync) {
      this.fetch(`${this.baseUrl}/${id}`).then(fn, () => fn(undefined));
    }

    return this.storeStream.pipe(
      filter(data => data[0] === id),
      map(data => data[1])
    )
    .subscribe(fn);
  }

  // The doXXXX methods must be implemented by subclasses.
  // Each returns {Promise<Response>}
  abstract doCreate(...args: any[]): Promise<any>;

  abstract doDelete(id: string): Promise<any>;

  abstract doSave(...args: any[]): Promise<any>;

  abstract doFetch(...args: any[]): Promise<any>;

  abstract doResync(params: ResyncParams): Promise<any>;
}
