import { Component, OnInit } from '@angular/core';
import { DialogService } from '@app/components/gem-dialogs';
import { ResetMfaDialogComponent } from '@app/shared/components/reset-mfa-dialog/reset-mfa-dialog.component';
import { UsersApi } from '../../features/accounts/api/usersApi.service';
import { AuthService } from '../../features/auth';
import * as Roles from '../../features/auth/roles.constants';
import { MetricsService } from '@dpod/gem-ui-common-ng';
import { AccountStatusService } from "@app/shared/services/account-status.service";
import { EvaluationStatus, TenantAccountStatus } from "@app/features/tenant/tenant.model";
import { TenantsService } from "@app/features/tenant/tenants.service";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { EXTERNAL_LINKS } from "@app/shared/external-urls.config";
import { ClipboardService } from "ngx-clipboard";
import { GemToastService } from "@dpod/gem-ui-common-ng/toast";

interface MenuItem {
  text: string;
  action: () => void;
  locator: string;
  requiredRoles? : string
}

@Component({
  selector: 'gem-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userName = '';
  userRole = '';
  parentName = '';
  tenantId = '';
  isSubscriber = false;
  readonly MENU_OPTIONS : MenuItem[] = [
    {
      text: "Change Password",
      action: this.changePassword.bind(this),
      locator: 'change-password',
    },
    {
      text: "Reset MFA Token",
      action: this.resetMfaToken.bind(this),
      locator: 'reset-mfa',
    },
    {
      text: "Add Service Elections",
      action: this.navigateToServiceElections.bind(this),
      locator: 'add-service-elections',
      requiredRoles : Roles.admin
    },
    {
      text: "Sign Out",
      action: this.authService.logout.bind(this.authService),
      locator: 'logout',
    },
  ];

  constructor(
    private authService: AuthService,
    private usersApi: UsersApi,
    private dialogService: DialogService,
    private metrics: MetricsService,
    private accountStatusService: AccountStatusService,
    private tenantsService: TenantsService,
    public clipboard: ClipboardService,
    public toastService: GemToastService,
  ) { }

  ngOnInit() {
    this.setIdentity();
    // If user role is Tenant Admin, get Account Status
    if(this.authService.hasAnyScope(Roles.admin)){
      const accountStatus$ = this.accountStatusService.getAccountStatus();
      accountStatus$.pipe(switchMap(accountStatus => {
        // get the Menu Options for role -> Tenant Admin and evaluation status -> 'Subscriber'
        // The parent Name is required only if evaluation status is 'Subscriber'
        if(this.isSubscriberTenant(accountStatus)){
          this.isSubscriber = true; // this property is required to filter menu options
          return this.tenantsService.getParentTenantInfo();
        }
        return of(null);
      })).subscribe(parent =>{
        if(parent){
          this.parentName = parent.name;
        }
      })
    }

  }

  copyToClipboard(e:Event) {
    e.stopPropagation()  // When we click on clipboard icon, it should not open the menu items. For this, we need stopPropagation here
    this.toastService.success('Tenant ID copied to clipboard');
  }

  setIdentity() {
    const identity = this.authService.getIdentity();
    if (!identity) {
      return;
    }
    this.userName = identity.email;
    this.userRole = this.getRoleLabel();
    this.tenantId = this.authService.getTenantId()

    // Use the email as the unique id that identifies users for metrics purposes
    this.metrics.identify(identity.email);
  }

  changePassword() {
    const identity = this.authService.getIdentity();
    if (!identity) {
      throw new Error('You must be logged in to change your password.'); // should not happen
    }
    this.dialogService.entityFn(result => {
      const {oldPassword, newPassword} = result;
      return this.usersApi.changePassword(oldPassword, newPassword);
    }, 'changeUserPassword', null /*resolves*/, 'Changing password...');
  }

  resetMfaToken() {
    const modal = this.dialogService.open<ResetMfaDialogComponent>(ResetMfaDialogComponent, {windowClass: 'mfa-modal'}).componentInstance;
    modal.userId = this.authService.getIdentity().user_id;
    modal.tenantId = this.authService.getTenantId();
  }

  getRoleLabel(): string {
    const authService = this.authService;
    const scopes = Roles;
    switch (true) {
      case authService.hasScope(scopes.admin):
        return "Tenant Administrator";
      case authService.hasScope(scopes.owner):
        return "Application Owner";
      case authService.hasScope(scopes.spadmin):
        return "Service Provider Admin";
      case authService.hasScope(scopes.operator):
        return "DPoD Operator";
    }
    return ""; // should not happen
  }

  /**
   * Open the Add Service Elections URL in a new tab
   * Pass TenantId and ParentName as query params
   **/
  navigateToServiceElections() : void{
    const tenantId = this.authService.getTenantId();
    const {baseUrl, addServiceElectionsUrl} = EXTERNAL_LINKS.SERVICE_ELECTIONS;
    window.open(`${baseUrl}${addServiceElectionsUrl}?tenantID=${tenantId}&spParentName=${this.parentName}`,"_blank")
  }

  /**
   * Filter the menu options based on role if required
   * */
  getUserActions() : MenuItem[] {
    return this.MENU_OPTIONS.filter(option => {
      // if no role is specified, the option is allowed for all roles
      if(!option.requiredRoles){
        return true;
      }
      // only Tenant Admin requires a role check along with account status
      if(this.authService.hasScope(option.requiredRoles)){
        return this.isSubscriber;
      }
    })
  }

  /**
   *  A Tenant is a 'Subscriber' if the evaluation status is either 'InAgreement' or 'Expired'
   * */
  isSubscriberTenant(accountStatus: TenantAccountStatus): boolean{
    return accountStatus.evaluationStatus === EvaluationStatus.inAgreement ||
      accountStatus.evaluationStatus === EvaluationStatus.agreementEnded;
  }
}
