import { Injector } from "@angular/core";
import { TransitionHookFn, UIRouter } from "@uirouter/core";
import { AuthService } from "../auth";
import { unverifiedStateName } from './unverified.constants';
/**
 * Registers a transition hook with the router service that handles users who have not verified
 * their email address. These users must view the "unverified user" state and cannot leave it.
 */
export function configureUnverifiedState(uiRouter: UIRouter, injector: Injector) {
  const redirect: TransitionHookFn = transition => {
    const authService = injector.get(AuthService);
    if (authService.isEmailVerified()) {
      return;
    }

    const stateService = transition.router.stateService;
    const to = transition.to().name;

    if (transition.from().name === unverifiedStateName) {
      // You can never leave
      return false;
    }

    if (to !== unverifiedStateName) {
      return stateService.target(unverifiedStateName);
    }
  };

  uiRouter.transitionService.onBefore({ /* match all */ }, redirect, { priority: 100 });
}
