import { NgModule } from "@angular/core";
import { NgHybridStateDeclaration, UIRouterUpgradeModule } from "@uirouter/angular-hybrid";
import { SharedModule } from "@app/shared";
import { SubscriptionsComponent } from "./subscriptions.component";
import { HttpClientModule } from "@angular/common/http";

import * as AuthScopes from '../auth/roles.constants';
import { TenantModule } from "@app/features/tenant";

const subscriptionsStates: NgHybridStateDeclaration[] = [
  {
    component: SubscriptionsComponent,
    name: "subscriptions",
    url: "/subscriptions",
    params: {
      requiresRole: `${AuthScopes.owner},${AuthScopes.admin}`,
    },
  },
];

@NgModule({
  declarations: [SubscriptionsComponent],
  imports: [
    HttpClientModule,
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states: subscriptionsStates,
    }),
    TenantModule,
  ],
  providers: []
})
export class SubscriptionsModule {
}
