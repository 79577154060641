import accountsModule from "./_init.js";
import template from "./users.component.html";
import "../../components/gem-dialogs/dialog.service";
import "./dialogs/modal-createUser.component";
import "./api/usersApi.service";

export default class UsersController {
   constructor($scope, DialogService, UsersApi, SubscribersApi) {
      this.DialogService = DialogService;
      this.api = UsersApi;

      this.numGroups = SubscribersApi.getAll().length;
      this.subsub = SubscribersApi.subscribe(() => {
         this.numGroups = SubscribersApi.getAll().length;
      });

      this.subscriberApi = SubscribersApi;

      this.cards = [
         {
            title: "Application Owner",
            svg: "#user",
            locator: "add-app-owner",
            content: "Provisions and consumes Services made available by Administrator users.",
            action: this.openWizard.bind(this, 'user'),
         },
         {
            title: "Administrator",
            svg: "#admin-user",
            locator: "add-tenant-admin",
            content: "Manages Subscriber Groups, Users, Keys, Service Reports and configures the Marketplace for Application Owner users.",
            action: this.openWizard.bind(this, 'admin'),
         },
      ];
   }

   $onDestroy() {
      this.subsub.unsubscribe();
   }

    openWizard(role) {
        if (role === 'user' && !this.hasSubGroups()) { // currently no subscriber groups, we want to have the subscriber group modal popup
            this.DialogService.openAJS('createSubscriberGroup', {
                noSubscriberGroups: () => true,
            }).then(entity => this.createSubscriberGroupDialog(entity, role));
            return; // prevent both from opening
        }

        this.createUserDialog(role);
    }

    createSubscriberGroupDialog(entity, role) {
        const progress = this.DialogService.progress("Preparing Subscriber Group...");
        return this.subscriberApi.create(entity)
            .then(() => this.subscriberApi.resync())
            .then(() => this.createUserDialog(role), error => this.DialogService.error(error))
            .finally(() => progress.close());
    }

    createUserDialog(role) {
        this.DialogService.openAJS('createUser', {
            role: () => role,
        }).then(entity => this.createUser(entity));
    }

   hasData() {
      return this.api.getAll().length > 0;
   }

   hasSubGroups() {
      return this.numGroups > 0;
   }

    createUser(entity) {
        const progress = this.DialogService.progress("Preparing User...");
        return this.api.create(entity)
            .catch(error => this.DialogService.error(error))
            .finally(() => progress.close());
    }
}

accountsModule.component("gemUsers", {
   controller: UsersController,
   template,
});

