import { EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { forkJoin, Observable, Subscription } from 'rxjs';
import * as Roles from '../../../../features/auth/roles.constants';
import { Tile } from '@app/features/marketplace/tiles.interface';
import { RequiresRoleService, TilesService } from '../../../services';
import { ServiceCategory } from "@app/shared/services/service-categories.interface";
import { debounceTime, take } from "rxjs/operators";
import { ConfigToken } from "@dpod/gem-ui-common-ng";
import { DpodUiConfig } from "@app/core/dpod-ui-config";
import { TenantSubscriptions } from "@app/features/gem-services/services/tenant-subscription/subscription.constants";
import { Subject } from "rxjs/internal/Subject";

// This is a superclass for both tiles-create-view and tiles-configure-view components.
// Eventually tiles-configure-view may have to diverge to comply with UX prototype.
export class AbstractTilesView implements OnInit, OnDestroy {
  @Input() tilesStream: Observable<Tile[]>;
  @Input() serviceCategoriesStream: Observable<ServiceCategory[]>;

  @Output() tileClick = new EventEmitter<Tile>();

  sub: Subscription;
  tileCategories: Map<string, { orderNum:  number, tiles: Tile[]}> = new Map();
  private addServiceButtonClicked = new Subject<Tile>();
  loading: boolean = false; // determines the display of the button spinner
  private serviceCategories: ServiceCategory[] = [];

  constructor(private requiresRoleService: RequiresRoleService,
              public tilesService: TilesService,
              @Inject(ConfigToken) public config: DpodUiConfig) {
  }

  ngOnInit() {
    // doesn't call tilesChanged until both tiles and service categories have populated data
    // otherwise it won't be able to display things correctly
    const tiles$ = this.tilesStream.pipe(take(1));
    const serviceCategories$ = this.serviceCategoriesStream.pipe(take(1));
    // because we only `take(1)` the stream is immediately destroyed and therefore once it fires we don't
    // have to worry about cleaning up the stream
    forkJoin([tiles$, serviceCategories$])
      .subscribe((tilesAndServiceCategories: [Tile[], ServiceCategory[]]) => {
        // since we don't expect service categories to change on the fly, we save them to the instance to be able
        // to be reused when tiles change
        this.serviceCategories = tilesAndServiceCategories[1];
        this.sub = this.tilesStream.subscribe(tiles => {
          // getTilesWithSubscriptionInfo will only return tiles if Zuora was enabled,
          // otherwise it returns nothing so use the original tiles array
          const allTiles = this.tilesService.getTilesWithSubscriptionInfo() || tiles;
          this.tilesChanged(allTiles);
        });
      });
    // to prevent multiple calls due to multiple clicks, it delays submitting for 400ms
    this.addServiceButtonClicked.pipe(debounceTime(400)).subscribe((t: Tile) => {
        this.tileClick.emit(t); // submit action for Try/Create Service
        this.loading = false;
      }
    );
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
    this.addServiceButtonClicked && this.addServiceButtonClicked.unsubscribe();
  }

  tilesChanged(tiles: Tile[]) {
    this.tileCategories = new Map<string, { orderNum:  number, tiles: Tile[]}>();
    const serviceCategories = this.serviceCategories;
    tiles.forEach(t => {
      const {categoryName} = t;
      if (this.tileCategories.has(categoryName)) {
        this.tileCategories.get(categoryName).tiles.push(t);
      } else {
        // append serviceCategory orderNum
        const category = serviceCategories.find(sc => sc.name === categoryName);
        this.tileCategories.set(categoryName, {
          orderNum: category.orderNum,
          tiles: [t],
        });
      }
    });

    // proceeds to sort the tiles inside the categories
    this.tileCategories.forEach(t => {
      return t.tiles.sort(tileCompare);
    });
  }

  getTileDisplayState(tile: Tile): TileDisplayState {
    if (tile.pendingAction) {
      return "pending";
    }

    if (this.requiresRoleService.hasRole([Roles.spadmin, Roles.operator])) {
      // Currently, tiles always appear as 'launched' to the Operator and SP Admin
      // should revisit this
      return "enabled";
    }

    // TODO locked state if the tile was disabled from above
    return tile.enabled ? "enabled" : "disabled";
  }

  getTooltip(t: Tile): string {
    return t.enabled ? "" : "This tile has been disabled by your Administrator.";
  }

  /**
   * Performs the primary action on the tile (opening the provisioning wizard or the
   * redirection prompt).
   */
  async onTileClick(t: Tile) {
    this.loading = true;
    // adds debounce time before performing action
    this.addServiceButtonClicked.next(t);
  }

  /**
   * Does a sort of service categories by the key/value mapping from the template
   * @param a
   * @param b
   */
  sortServiceCategoriesByOrderNum(a, b) {
    return a.value.orderNum - b.value.orderNum;
  }

  /**
   * Returns the text to be displayed for the button in the tile based on subscription info.
   * @param tile
   * @returns a label for the button in the tile
   */
  getTileBtnLabel(tile: Tile) {
    const hasSubscriptions = !!tile && !!tile.subscriptionInfo && tile.subscriptionInfo.length > 0;
    // either no subscriptions or an active trial. Checks first as the list is ordered and no TRIAL/PROD mix on it
    if (!hasSubscriptions || (tile.subscriptionInfo[0]?.type === TenantSubscriptions.TYPE.TRIAL
        && tile.subscriptionInfo[0]?.state === TenantSubscriptions.STATE.ACTIVE)) {
      return 'try service';
    }
    return 'create service';
  }
}

type TileDisplayState = "pending" | "enabled" | "disabled" | "locked";

function tileCompare(a: Tile, b: Tile) {
  return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
}
