import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, } from '@angular/core';
import { WizardStep } from '@app/components';

/**
 * Inline wizard that allows embedded wizards into the page
 */
@Component({
  selector: 'wizard-inline',
  templateUrl: './wizard-inline.component.html',
  styleUrls: ['./wizard-inline.component.scss'],
  providers: []
})
export class WizardInlineComponent implements AfterContentInit {
  @Input() title: string;
  @Input() showSteps = true;


  // Allows the ability to hide the buttons, good for waiting for async responses
  @Input() hideButtons = false;

  /** Emits an event when the wizard is closing due to completion. Delivers the result object (if any).
   */
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  // two way binding for current step allows parent component to control the step
  @Input() currentStepIdx = 0;
  @Output() currentStepIdxChange = new EventEmitter<number>();
  @Input() disableNextButton = false;

  // step model
  private steps: WizardStep[] = [];

  // This holds the transcluded step child elements
  @ContentChildren(WizardStep, {descendants: true})
  public stepChildren: QueryList<WizardStep>;

  // This runs after our <ng-content> children have initialized
  ngAfterContentInit() {
    this.steps = this.stepChildren.toArray();
    this.gotoStep(this.currentStepIdx, true);
  }

  currentStep() {
    return this.steps[this.currentStepIdx];
  }

  getStepNames(): string[] {
    return this.steps.map(item => item.name);
  }

  gotoStep(newStepIdx, isInit = false) {
    const stepCount = this.steps.length;
    if (newStepIdx === stepCount && !isInit) {
      this.submit.emit(); // wizard is closing
    } else {
      this.currentStepIdx = newStepIdx;
      // tell the steps whether they are selected
      this.steps.forEach((step, i) => {
        step.active = (newStepIdx === i);
      });
      this.currentStepIdxChange.emit(newStepIdx);
    }
  }

  isCurrentStepValid() {
    const form = this.currentStep().stepForm;
    return !form || form.valid;
  }

  getSteps() {
    return this.steps;
  }

}
