import detailsView from "./_init";
import "@app/components/gem-dialogs/dialog.service";

import template from "./templates/details-main.html";

import {deleteServiceWarning as protectVDeleteServiceWarning} from "../cloud/protectV/protectV.constants";
import {deleteServiceWarning as cloudHSMDeleteServiceWarning} from "../cloudHSM/cloudHSM.constants";

import { DeleteServiceDialogComponent } from "../dialogs/delete-service-dialog.component";

import {ServiceShortCode} from "../services.constants";

class DetailsMainController {

   constructor($scope, $compile, $state, VHsmApiService, DialogService, ServiceBrokerService, ConfigToken, AuthService) {
      this.$state = $state;
      this.serviceBrokerService = ServiceBrokerService;
      this.DialogService = DialogService;
      this.$compile = $compile;
      this.vHsmApiService = VHsmApiService;
      this.authService = AuthService;
      this.config = ConfigToken;

      // needed to trigger a digest cycle after subscription updates
      this.$scope = $scope;
   }

   $onInit() {
       // copy the object to make sure we get a new reference; an object that's
       // updated in-place doesn't trigger $onChanges in the subcomponents
       this.service = angular.copy(this.serviceBrokerService.get(this.id));

       let subcomponentLoaded = false; // prevents the subcomponent from being loaded multiple times

       this.vhsmSubscription = this.vHsmApiService.subscribeTo(this.id, service => {
           this.service = angular.copy(service);
       });

       this.subscription = this.serviceBrokerService.subscribeTo(this.id, service => {
           if (service) {
               this.service = angular.copy(service);
               if (!subcomponentLoaded) {
                   subcomponentLoaded = true;

                   let component, html;

                   // this can be removed once we update cloudHSM-details to Angular
                   const updatedServiceTypes = ["salesforce_key_broker", "protectv", "vm_keystore", "azure"];

                   if (updatedServiceTypes.includes(service.serviceType)) {

                       component = `${service.serviceType.replace(/_/g, '-')}-details`; // requires matching component.  salesforce_key_broker -> salesforce-key-broker-details

                       if (service.serviceType === "salesforce_key_broker") { // because keybroker is not updated, can be removed when upgraded
                           html = `<${component} service="$ctrl.service"></${component}>`;
                       } else {
                           html = `<${component} [service]="$ctrl.service"></${component}>`;
                       }

                   } else {
                       component = 'cloud-hsm-details';
                       html = `<${component} open-download-client-bundle-section="$ctrl.openDownloadClientBundleSection" service="$ctrl.service"></${component}>`;
                   }

                   const element = $('#service-details');
                   element.append(this.$compile(html)(this.$scope));
               }

           } else {
               this.$state.go('^');
           }
       }, true);
   }

   $onDestroy() {
      this.subscription.unsubscribe();
      this.vhsmSubscription.unsubscribe();
   }

   deleteService() {
       const customWarningText = this.service.serviceType === "vm_keystore"
           ? protectVDeleteServiceWarning : cloudHSMDeleteServiceWarning;
       const ref = this.DialogService.open(DeleteServiceDialogComponent);
       const modal = ref.componentInstance;
       modal.serviceNameToDelete = this.service.name;
       modal.customWarning = customWarningText;

      let progress = { close: () => { } };
       ref.result.then(() => {
         progress = this.DialogService.progress("Deleting Service...");
         return this.serviceBrokerService.delete(this.id);
      })
         .then(() => this.$state.go('^'))
         .catch(error => this.DialogService.error(error))
         .finally(() => progress.close());
   }

    isSameSubscriberGroup() {
        if (this.service) {
            return this.service.subscriberGroup === this.authService.getSubscriberGroupId();
        }
        return false;
    }

    showP2PEDownloadButton() {
        return this.service && this.service.serviceType === ServiceShortCode.p2pe;
    }
}

detailsView.component("serviceDetails", {
   template,
   bindings: {
      id: "<",
      openDownloadClientBundleSection: "<",
   },
   controller: DetailsMainController,
});


