import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashIfEmpty'
})
export class DashIfEmptyPipe implements PipeTransform {
  transform(value: string | number | undefined ): string | number {
    if (value === undefined) {
      return '-';
    }
    if (typeof value === 'string' && value == '' ){
      return '-';
    }
    if (typeof value === 'number' && isNaN(value)) {
      return '-';
    }
    return value;
  }
}
