<dpod-table (filter)="onFilter($event)" [dataLength]="dataSource.data.length">
    <table #table mat-table matSort [dataSource]="dataSource">
        <caption class="d-none">List of services</caption>

        <!-- Service Name column -->
        <ng-container matColumnDef="name">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let service" class="service-name-column" title="{{ service.name }}">
                <ng-container *ngIf="isStatusDone(service.status)">
                    <a *ngIf="!displayExternalLink(service)" class="gem-link service-detail-link"
                       [uiSref]="'services.details'"
                       [uiParams]="{ id: service.service_id }"
                       [attr.data-service-name]="service.name">
                        {{ service.name }}
                    </a>
                    <a *ngIf="displayExternalLink(service)" href="{{ service.dashboard_url }}" externalLink>
                        <div class="service-name-link gem-link">{{ service.name }}</div>
                        <img class="external-url" src="images/externalLink.svg" alt="dashboard url" />
                    </a>
                </ng-container>
                <ng-container *ngIf="!isStatusDone(service.status)">
                    <span class="gem-link link-font" (click)="openServiceDialog(service)">
                          {{ service.name }}
                    </span>
                </ng-container>
            </td>
        </ng-container>

        <!-- Service Type column -->
        <ng-container matColumnDef="serviceType">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Service Type</th>
            <td mat-cell *matCellDef="let service" class="service-type-column"
                title="{{ service.formattedServiceType || service.serviceType }}">
                {{ service.formattedServiceType || service.serviceType }}
            </td>
        </ng-container>

        <!-- Status column -->
        <ng-container matColumnDef="status">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef class="all-columns">Status</th>
            <td mat-cell *matCellDef="let service" class="status-column all-columns"
                data-locator="service-status"
                [ngClass]="{'red-font': isStatusFailed(service.status) === true}">
                {{ service.status | underscoreToSpace | titlecase }}
            </td>
        </ng-container>

        <!-- Created At column -->
        <ng-container matColumnDef="createdAt">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef class="all-columns">Created</th>
            <td mat-cell *matCellDef="let service" class="created-at-column all-columns">
                {{ service.formattedCreatedAt }}
            </td>
        </ng-container>

        <!-- Subscriber Group column -->
        <ng-container matColumnDef="subscriberGroup">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef class="all-columns">Subscriber Group</th>
            <td mat-cell *matCellDef="let service" class="subscriber-group-column all-columns"
                title="{{ getSubscriberGroupName(service) }}">
                {{ getSubscriberGroupName(service) }}
            </td>
        </ng-container>

        <!-- Created By column -->
        <ng-container matColumnDef="createdBy">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef class="all-columns">Created By</th>
            <td mat-cell *matCellDef="let service" class="created-by-column all-columns"
                title="{{ service.created_by_username || 'Unavailable' }}">
                {{ service.created_by_username || 'Unavailable' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="purchase">
            <th scope="col" mat-header-cell *matHeaderCellDef class="white-font all-columns">Buy</th>
            <td mat-cell *matCellDef="let service" class="purchase-service-column all-columns">
                <a *ngIf="displayPurchaseLink(service.serviceType)" href="javascript: void(0)" data-locator="purchase-service" (click)="convertEvalButton(service.serviceType)">Purchase</a>
            </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
            <th scope="col" mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let service" class="actions-column text-center">
                <div *ngIf="isSameSubscriberGroup(service) && service.marketplace == marketplaceDPoD" class='icon-inside-icon'
                     [class.disable-cursor]="disableTrashIcon(service.status)">
                    <span title="Delete Service" role="button" tabindex="0" class="grid-action"
                            data-locator="delete-service"
                            [class.disabled]="disableTrashIcon(service.status)"
                            (click)="openDeleteServiceDialog(service)">
                        <svg class="icon-svg outer-icon">
                            <use xlink:href="#action-box" />
                        </svg>
                        <svg class="icon-svg inner-icon">
                            <use xlink:href="#trash" />
                        </svg>
                    </span>
                </div>
            </td>
        </ng-container>

        <!-- Rows definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let service; columns: displayedColumns;"
            [attr.data-service]="service.name" [attr.data-service-type]="service.serviceType"
            [attr.data-service-status]="service.status">
        </tr>
    </table>
</dpod-table>

<ng-template #emptyPlanListMessage>
    <p>{{noPlans.message}}<a class="external-link" externalLink href={{noPlans.linkRef}}>{{noPlans.linkText}}</a>.</p>
</ng-template>

<ng-template #retrievingPlansErrorMessage>
    <p>{{plansError.line1.message}}</p>
    <p>{{plansError.line2.message1}}<a class="external-link" externalLink href={{plansError.line2.linkRef}}>{{plansError.line2.linkText}}</a>{{plansError.line2.message2}}</p>
</ng-template>
