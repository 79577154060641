/**
 * This run block enforces the role restrictions on certain states.
 */
export default function requiresRoleHook($transitions, RequiresRoleService) {
    "ngInject";

    const matcher = {
        to: state => state.params && state.params.requiresRole && state.params.requiresRole.value(),
    };

    const redirect = transition => {
        const nextState = transition.to();
        const requiredRole = nextState.params.requiresRole;

        if (RequiresRoleService.hasRole(requiredRole)) {
            return;
        }

        // Send them to the 'home' state, which will determine the default state for their role.
        const $state = transition.router.stateService;
        const dest = 'home';
        return $state.target(dest, null, { location: "replace" });
    };

    // Priority is below login hook's, since role checks are only meaningful when logged in
    $transitions.onBefore(matcher, redirect, {priority: 50});
}
