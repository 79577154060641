<form #stepForm="ngForm">
    <div class="row">
        <div class="col form-group">
            <div class="details-panel">
                <div class="details-panel-section">
                    <div class="col-sm-12">
                        <label for="serviceName" class="control-label">Service Name</label>
                        <input id="serviceName" #serviceNameField="ngModel" name="serviceName" class="form-control"
                               minlength="4" maxlen="45" (input)="nameChange()" trim="blur" autofocus
                               [(ngModel)]="serviceNameInternal" validationDebounce required validationIcon>
                        <div *ngIf="serviceNameField.dirty" id="error-message-serviceName" class="text-danger error-message" role="alert">
                            <div *ngIf="serviceNameField.errors?.required">Please enter a service name</div>
                            <div *ngIf="serviceNameField.errors?.maxlen">Maximum 45 characters</div>
                            <div *ngIf="serviceNameField.errors?.minlength">Minimum 4 characters</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col form-group">
            <div class="details-panel">
                <div class="details-panel-section config-cloud-section">
                    <div class="col-sm-6 form-group">
                        <strong class="mr-1">Cloud Provider</strong>
                        <select id="cloudProvider" name="cloudProvider" [(ngModel)] ="selectedCloudProvider"
                                class="mr-2 form-control filter-select" required #cloudProvider="ngModel"
                                (change)="onCloudProviderChange()">
                            <option value="" disabled>Select a Cloud Provider</option>
                            <option *ngFor="let provider of cloudProviders" [ngValue]="provider">
                                {{ provider.label }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-6 form-group">
                        <strong class="mr-1">Cloud Region</strong>
                        <select id="cloudRegion" name="cloudRegion" [(ngModel)] ="selectedCloudRegion"
                                class="mr-2 form-control filter-select" required #cloudRegion="ngModel"
                                (change)="onCloudRegionChange()">
                            <option value="" disabled>Select a Region</option>
                            <option *ngFor="let region of cloudRegions" [ngValue]="region">
                                {{ region.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
