import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { DialogService } from '@app/components';
import { RequiresRoleService, WindowToken } from '@app/shared/services';
import { ServiceAgreementDetails, Tenant, TenantAccountStatus, TenantAccountType } from '../tenant.model';
import { getStatusLabel } from '../tenant.util';
import { TenantsService } from '../tenants.service';
import { Subscription } from 'rxjs';
import * as Roles from '../../auth/roles.constants';
import { filter } from 'rxjs/operators';
import { BackofficeService } from "@app/shared/services/backoffice.service";
import { countriesRequiringStates } from '@app/features/tenant/wizard/address.step.component';

const QUOTA_UNLIMITED = 0x7fffffff;

/**
 * Provides editing of the tenant's account info and admin user.
 */
@Component({
  selector: 'tenant-info',
  templateUrl: './tenant-info.component.html',
  styleUrls: ['./tenant-info.component.scss']
})
export class TenantInfoComponent implements OnInit, OnChanges, OnDestroy {
  /** Tenant to edit. This object will be mutated by our form controls. */
  @Input() tenant: Tenant;

  @Input() saved = false;

  /** Emits the modified tenant when the user clicks save */
  @Output() save = new EventEmitter<Tenant>();

  /** Emits when the user cancels */
  @Output() cancel = new EventEmitter<void>();

  editing = false;
  unlimitedQuota = QUOTA_UNLIMITED;

  stateRequired: boolean;

  @Input() serviceAgreement: ServiceAgreementDetails;
  @Input() tenantStatus: TenantAccountStatus;

  subscription: Subscription;

  constructor(
    private api: TenantsService,
    @Inject(WindowToken) private window: Window,
    private dialogService: DialogService,
    private requiresRoleService: RequiresRoleService,
    private backofficeService: BackofficeService,
  ) {}

  ngOnInit(): void {
    if (this.backofficeService.accountStatusMap) {
      const tenantAccountStatus = this.backofficeService.accountStatusMap.get(this.tenant.id);

      if (tenantAccountStatus) {
        this.tenantStatus = tenantAccountStatus;
      }
    }

    this.subscription = this.backofficeService.accountStatusStream
    .pipe(filter(s => s.tenantId === this.tenant.id))
    .subscribe(tenantAccountStatus => {
      if (tenantAccountStatus.evaluationStartDate === null) {
        // missing tenant account status, this could be a legacy eval tenant
        this.tenantStatus = null; // hides the Service Elections section
      } else {
        this.tenantStatus = tenantAccountStatus;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const savedChange = changes['saved'];
    if (savedChange && savedChange.currentValue) {
      //we saved therefore we're not editing anymore
      this.editing = false;
    }
  }

  showServiceAgreement(): boolean {
    if (this.requiresRoleService.hasRole(Roles.operator) || this.tenantStatus === null) {
      return false;
    }

    // if account type is undefined at this point we display it by default until we know the account type
    return !this.tenant.accountType || this.tenant.accountType !== TenantAccountType.serviceProvider;
  }

  getDomain() {
    const host = this.window.location.hostname;
    const idx = host.indexOf(".");
    return idx === -1 ? `${this.tenant.hostname}.market.dpondemand.io` : this.tenant.hostname + host.slice(idx);
  }

  getAccountType() {
    return this.backofficeService.getAccountTypeLabel(this.tenant);
  }

  getAccountStatus() {
    return getStatusLabel(this.tenant);
  }

  getTenantQuota() {
    return this.tenant.serviceQuota === QUOTA_UNLIMITED ? "Unlimited" : this.tenant.serviceQuota;
  }

  countryChange(/*countryName: string*/) {
    // reset the state to "not selected"
    this.tenant.billingAddress.state = null;
    this.stateRequired = countriesRequiringStates.includes(this.tenant.billingAddress.country);
  }

  doEdit() {
    this.saved = false;
    this.editing = true;
  }

  doSave() {
    this.save.emit(this.tenant);
  }

  doCancel() {
    this.saved = false;
    this.editing = false;
    this.cancel.emit();
  }

}
