import { NgModule } from '@angular/core';
import { SpinnerComponent } from './spinner.component';

// This is the Angular part of the module
@NgModule({
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
  entryComponents: [SpinnerComponent]
})
export class SpinnerModule {}

// This is the AngularJS part of the module
import * as angular from 'angular';
import {downgradeComponent} from '@angular/upgrade/static';

export const ng1Module = angular.module('gem.spinner', [])
  .directive('spinner', downgradeComponent({component: SpinnerComponent}) as angular.IDirectiveFactory);
