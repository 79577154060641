import { Component, Inject, Input, OnInit } from '@angular/core';
import { State } from '@app/ajs-upgraded-providers';
import { convertStartState } from '@app/features/eval-convert/eval-convert.constants';
import {
  AgreementApprovalStatus,
  EvaluationStatus,
  TenantAccountStatus,
  TenantParentInfo
} from '@app/features/tenant/tenant.model';
import { TenantsService } from '@app/features/tenant/tenants.service';
import { RequiresRoleService } from '@app/shared/services';
import { AccountStatusService } from '@app/shared/services/account-status.service';
import * as Roles from '../../auth/roles.constants';
import { zip } from "rxjs/internal/observable/zip";

/**
 * The 'Purple Packet' Trial to Prod convert an evaluation to subscriber tenant footer
 * NOTE: This footer should only be visible to tenant admins
 */
@Component({
  selector: 'evaluation-convert-footer',
  templateUrl: './evaluation-convert-footer.component.html',
  styleUrls: ['./evaluation-convert-footer.component.scss']
})
export class EvaluationConvertFooterComponent implements OnInit {

  // the account id of the tenant we want to get the status for
  @Input() accountId: string;

  // prevents the footer from appearing momentarily after it renders
  retrievedAccountStatus = false;

  accountStatus: TenantAccountStatus = {
    tenantId: null,
    evaluationStartDate: null,
    evaluationEndDate: null,
    evaluationStatus: null,
    agreementApprovalStatus: null,
  };

  parentName = '';

  constructor(
    requiresRoleService: RequiresRoleService,
    private tenantsService: TenantsService,
    private accountStatusService: AccountStatusService,
    @Inject(State) private $state: any,
  ) {
    if (!requiresRoleService.hasRole(Roles.admin)) {
      throw new Error('This eval convert footer should not have rendered if the user is not a tenant admin');
    }
  }

  ngOnInit() {
    // request the account status for the user as well as the parent info, only callback once we have both
    zip(this.tenantsService.getParentTenantInfo(), this.accountStatusService.getAccountStatus())
      .subscribe((account: [TenantParentInfo, TenantAccountStatus]) => {
        const [tenantParentInfo, tenantAccountStatus] = account;
        if (!tenantAccountStatus.evaluationStartDate) {
          // Legacy tenants don't have an eval start date and cannot be converted?
          // So hide the banner from them
          return;
        }

        this.parentName = tenantParentInfo.name;
        this.retrievedAccountStatus = true;
        this.accountStatus = tenantAccountStatus;
    });
  }

  hasSubmittedAgreement(): boolean {
    return this.accountStatus.agreementApprovalStatus === AgreementApprovalStatus.requested;
  }

  /**
   * For tenants where parents are an Ingram Service Provider we will display different UI
   */
  hasIngramParent(): boolean {
    return /^ingram-.+-tenants$/i.test(this.parentName);
  }

  convertEvalButton(): void {
    this.$state.go(convertStartState);
  }

  /**
   * Requires the account status has been retrieved for the user and they fall under the Subscriber Tenant
   * @returns whether the Purple Packet banner is displayed or not
   */
  isDisplayed(): boolean {
    return this.retrievedAccountStatus && !this.isSubscriberTenant();
  }

  /**
   * @returns Whether the tenant is a paying customer, or once was a paying customer
   */
  isSubscriberTenant(): boolean {
    return this.accountStatus.evaluationStatus === EvaluationStatus.inAgreement ||
      this.accountStatus.evaluationStatus === EvaluationStatus.agreementEnded;
  }

}
