export * from './breadcrumb.component';

// AngularJs compatibility module
import { BreadcrumbComponent } from './breadcrumb.component';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';

export default angular.module("app.breadcrumb", [])
  .directive("appBreadcrumb", downgradeComponent({component: BreadcrumbComponent}))
  .name;
