import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "prompt-redirect",
  templateUrl: "./modal-redirect.html",
})
export class PromptRedirectComponent {

  @Input() bodyText?: string;
  @Input() goToText: string;
  @Input() hostName? = "";
  @Input() redirectTitle: string;
  @Input() website: string;
  @Output() close: EventEmitter<void> = new EventEmitter();

  redirect(): void {
    window.location.href = this.website;
  }

  getBodyText(): string {
    if (this.bodyText) {
      return this.bodyText;
    }

    return `This action requires you to log in to the ${this.hostName} website.  After logging in, you will be returned to Data Protection on Demand.`;
  }


}
