<div class="details-panel main-panel">
    <div class="details-panel-section">
        <div class="row form-group">

            <div class="col-sm-6">
                <div class="row">

                    <div class="col-sm-4">
                        <div class="row">
                            <div class="col">Service Name</div>
                        </div>

                        <div class="row">
                            <div class="col font-weight-bold">
                                {{service.name}}
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="row">
                            <div class="col">
                                Service Type
                            </div>
                        </div>
                        <div class="row">
                            <div class="col font-weight-bold">
                                {{service.formattedServiceType}}
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="row">
                            <div class="col">
                                Created
                            </div>
                        </div>
                        <div class="row">
                            <div class="col font-weight-bold">
                                {{service.formattedCreatedAt}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-sm-6">
                <div class="row">

                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col">
                                Keystore URL
                            </div>
                        </div>
                        <div class="row word-wrap-break">
                            <div class="col">
                                <strong>{{getKeystoreUrl()}}</strong>

                                <div title="Copy to Clipboard"
                                     ngxClipboard
                                     [cbContent]="getKeystoreUrl()"
                                     (cbOnSuccess)="hideAllCopiedMessages(); copiedKeystoreUrl = true"
                                     class="action-cell icon-inside-icon copy">
                                   <span class="grid-action">
                                      <svg class="icon-svg outer-icon"><use xlink:href="#action-box"></use></svg>
                                      <svg class="icon-svg inner-icon"><use xlink:href="#copy"></use></svg>
                                   </span>
                                </div>

                                <gem-feedback class="d-block mt-1" text="Client Keystore URL copied to clipboard"
                                              [(trigger)]="copiedKeystoreUrl"></gem-feedback>

                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col">
                                OAuth Token URL
                            </div>
                        </div>
                        <div class="row word-wrap-break">
                            <div class="col">
                                <strong>{{getOAuthUrl()}}</strong>

                                <div title="Copy to Clipboard"
                                     ngxClipboard
                                     [cbContent]="getOAuthUrl()"
                                     (cbOnSuccess)="hideAllCopiedMessages(); copiedOAuthUrl = true"
                                     class="action-cell icon-inside-icon copy">
                                   <span class="grid-action">
                                      <svg class="icon-svg outer-icon"><use xlink:href="#action-box"></use></svg>
                                      <svg class="icon-svg inner-icon"><use xlink:href="#copy"></use></svg>
                                   </span>
                                </div>

                                <gem-feedback class="d-block mt-1" text="Client OAuth URL copied to clipboard"
                                              [(trigger)]="copiedOAuthUrl"></gem-feedback>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<ngb-tabset type="pills">
    <ngb-tab title="Keys">
        <ng-template ngbTabContent>
            <div *ngIf="isConnected() && !updatingKeysTable">
                <protectv-keys-list
                    [stateFilter]="stateFilter"
                    [service]="service">

                    <div class="row">
                        <div class="col-sm-6">
                            <form class="form-inline pl-4">
                                <div class="form-group">
                                    <strong class="mr-1">Filter State</strong>
                                    <select class="mr-2 form-control filter-select" [(ngModel)]="stateFilter" (ngModelChange)="resync()"
                                        name="status-filter">
                                        <option value="">All States</option>
                                        <option value="Active">Active</option>
                                        <option value="Revoked">Revoked</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div class="col-sm-6">
                            <button (click)="resync()" class="btn btn-default float-right">
                                Refresh List
                            </button>
                        </div>
                    </div>

                </protectv-keys-list>
            </div>

            <div class="text-center details-panel main-panel updating-block" *ngIf="updatingKeysTable">
                <div class="details-panel-section">
                    <spinner class="d-inline-block align-middle"></spinner>
                    <h4 class="d-inline align-middle">Updating...</h4>
                </div>
            </div>

            <div *ngIf="!isConnected() && !updatingKeysTable">
                <div class="details-panel">
                    <div class="details-panel-section">
                        <h5>You haven't connected a ProtectV Manager to this service yet.</h5>
                        Configure your ProtectV management server to authenticate Data Protection on Demand using
                        the
                        credentials you received when you created this service.
                        <br/>
                        If you need to generate more credentials, you may do so in the <strong>Credentials</strong>
                        section above.
                        <br/>
                        <svg class="mt-4 fieldset-disabled protectv">
                            <use xlink:href="#card-protectv"/>
                        </svg>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-tab>
    <ngb-tab title="Credentials">
        <ng-template ngbTabContent>
            <credentials-section
                [useServiceBroker]="true"
                [showClientID]="false"
                [service]="service"
                [credentialsNamePrefix]="'VMES'"
                [permissions]="permissions">
            </credentials-section>
        </ng-template>
    </ngb-tab>
</ngb-tabset>
