import { RequiresRoleService, TilesService } from "@app/shared/services";
import * as Roles from "@app/features/auth/roles.constants";
import { PurchaseState } from "@app/features/purchase-wizard/purchase.interface";
import { Inject, Injectable, TemplateRef } from '@angular/core';
import { AgreementApprovalStatus, TenantParentInfo } from "@app/features/tenant/tenant.model";
import { BackofficeService } from "@app/shared/services/backoffice.service";
import { AuthService } from "@app/features/auth";
import { PurchaseServiceModalComponent } from "@app/components/service-purchase/purchase-service.modal.component";
import { EXTERNAL_LINKS } from "@app/shared/external-urls.config";
import { State } from "@app/ajs-upgraded-providers";
import { DialogService } from "@app/components";
import { TenantSubscriptions } from "@app/features/gem-services/services/tenant-subscription/subscription.constants";
import { TenantsService } from "@app/features/tenant/tenants.service";

@Injectable()
export class PurchaseDialogService {
  parentName = "";
  constructor(private requiresRoleService: RequiresRoleService,
              private backofficeService: BackofficeService,
              private authService: AuthService,
              private tilesService: TilesService,
              @Inject(State) public $state: any,
              public dialogService: DialogService,
              public tenantsService: TenantsService) {
    if (this.isAdminAndVerified()) {
      this.tenantsService.getParentTenantInfo().subscribe((data: TenantParentInfo) => this.parentName = data.name);
    }
  }

  /**
   * Used to get purchase service dialog contents
   * This returns a PurchaseState object containing dialog body and button texts
   */
  getPurchaseDialogContents(): PurchaseState {
    if (this.isAdminAndVerified()) {
      const tilesWithSubscriptionInfo = this.tilesService.getTilesWithSubscriptionInfo();
      // since trial + subscription combination cannot be returned, suffice to check the first element for being Prod
      if (tilesWithSubscriptionInfo?.find(t => t.subscriptionInfo[0]?.type === TenantSubscriptions.TYPE.PRODUCTION)) {
        return PurchaseState.FILL_ASE;
      }
      const accountStatusMap = this.backofficeService.accountStatusMap?.get(this.authService.getTenantId());
      if (this.hasNeverSubmittedServiceAgreement(accountStatusMap)) {
        return PurchaseState.FILL_ISE;
      }
      if (this.hasSubmittedServiceAgreement(accountStatusMap)) {
        return PurchaseState.WAIT_SP;
      }
      if (this.hasApprovedServiceAgreement(accountStatusMap)) {
        return PurchaseState.FILL_ASE;
      }
    }
    return PurchaseState.CONTACT_TA;
  }

  isAdminAndVerified() {
    return this.requiresRoleService.hasRole(Roles.admin) && this.authService.isEmailVerified();
  }

  hasNeverSubmittedServiceAgreement(tenantStatus): boolean {
    return tenantStatus && tenantStatus.agreementApprovalStatus === AgreementApprovalStatus.notRequested;
  }

  hasSubmittedServiceAgreement(tenantStatus): boolean {
    return tenantStatus && tenantStatus.agreementApprovalStatus === AgreementApprovalStatus.requested;
  }

  hasApprovedServiceAgreement(tenantStatus): boolean {
    return tenantStatus && tenantStatus.agreementApprovalStatus === AgreementApprovalStatus.approved;
  }

  /**
   * Opens a purchase service dialog
   * @param serviceType service short code
   * @param subscriptionState Optional. State of subscription cancelled(production) or expired(trial) to determine extra message
   * @param additionalData Optional. Projected content to display in the notification/error dialogs in case if no plans are available for ISE.
   */
  async openDialog(serviceType: string, subscriptionState: string = '', additionalData?: AdditionalData) {
    const purchaseState = this.getPurchaseDialogContents();
    if (purchaseState === PurchaseState.FILL_ISE) {
      try {
        const servicePlans = await this.retrieveServicePlans(serviceType, subscriptionState, additionalData);
        await this.openPurchaseServiceDialog(purchaseState, serviceType, subscriptionState, servicePlans);
      } catch (err) {
        if (err) {
          console.error(err);
        }
      }
      return;
    }
    await this.openPurchaseServiceDialog(purchaseState, serviceType, subscriptionState);
  }

  private async openPurchaseServiceDialog(purchaseState: PurchaseState, serviceType: string, subscriptionState: string = '', servicePlans?) {
    const ref = this.constructPurchaseServiceDialog(purchaseState, subscriptionState);
    await ref.result;
    // We never reach this point if the user cancels
    if (purchaseState.confirmButtonAction) {
      this.$state.go(purchaseState.confirmButtonAction, {
        serviceType,
        servicePlans
      });
    }
    if (purchaseState.confirmButtonLink) {
      const tenantId = this.authService.getTenantId();
      const { baseUrl, addServiceElectionsUrl } = EXTERNAL_LINKS.SERVICE_ELECTIONS;
      // TODO check which other query params need to be sent to finalize this url
      window.open(`${baseUrl}${addServiceElectionsUrl}?tenantID=${tenantId}&spParentName=${this.parentName}`,"_blank")
    }
  }

  private async retrieveServicePlans(serviceType: string, subscriptionState: string, additionalData?: AdditionalData) {
    const progress = this.dialogService.progress("Retrieving Plans…");
    try {
      const result = await this.backofficeService.getServicePlan(serviceType).toPromise();
      progress.close();
      const servicePlans = result && result['plans'];
      if (!servicePlans?.length) {
        this.constructPurchaseServiceDialog(PurchaseState.CONTACT_SALES, subscriptionState, additionalData?.noPlansDialogContent);
        return Promise.reject();
      }
      return servicePlans;
    } catch (err) {
      progress.close();
      this.dialogService.error({
        projectedContent: additionalData?.errorGettingPlansDialogContent,
        traceid: err?.data?.traceid
      });
      return Promise.reject(err);
    }
  }

  private constructPurchaseServiceDialog(purchaseState: PurchaseState, subscriptionState: string, projectedContent?: TemplateRef<any>) {
    const ref = this.dialogService.open<PurchaseServiceModalComponent>(PurchaseServiceModalComponent, { windowClass: 'purchase-modal' });
    const modal = ref.componentInstance;
    modal.projectedContent = projectedContent;
    switch (subscriptionState) {
      case TenantSubscriptions.STATE.CANCELLED:
         modal.bodyText = `${PurchaseState.PRODUCTION_CANCELLED_MESSAGE} ${purchaseState.bodyText}`;
         break;
      case TenantSubscriptions.STATE.EXPIRED:
         modal.bodyText = `${PurchaseState.TRIAL_EXPIRED_MESSAGE} ${purchaseState.bodyText}`;
        break;
      default:
        modal.bodyText = purchaseState.bodyText;
        break;
    }
    modal.confirmButtonText = purchaseState.confirmButtonText;
    modal.cancelButtonText = purchaseState.cancelButtonText;
    return ref;
  }
}

interface AdditionalData {
  noPlansDialogContent: TemplateRef<any>,
  errorGettingPlansDialogContent: TemplateRef<any>
}
