import { Component, Inject, OnInit, Input, TemplateRef } from '@angular/core';
import { LegacyModal } from '../legacy-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// This is what we can accept from outside
export type ErrInput = Error | WrappedError;

export interface WrappedError {
  data: string | Error;
}

// This is what we normalize it into
export interface Error {
  error_description?: string;
  error?: string;
  message?: string;
  traceid?: string;
  title?: string;
  contact?: string;
  icon?: string;
  projectedContent?: TemplateRef<any>;
}

@Component({
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  @Input() text: string;
  @Input() error: ErrInput;

  projectedContent?: TemplateRef<any>;
  cleanedText: string;
  traceid: string;
  title = 'An Error Occurred';
  contact = 'If you wish to contact your service provider, please note the following incident ID.';
  icon = '#bang-triangle';

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.setError(this.error || {});
  }

  setError(errInput: ErrInput) {
    let cleanedError: Error;

    // Unwrap `data` property if present
    if (isWrappedError(errInput)) {
      if (typeof errInput.data === 'string') {
        cleanedError = { message: errInput.data };
      } else if (typeof errInput.data === 'object') {
        cleanedError = errInput.data;
      }
    } else {
      cleanedError = errInput;
    }

    this.traceid = cleanedError.traceid;
    if (cleanedError.title) {
      this.title = cleanedError.title;
      this.contact = cleanedError.contact;
      this.icon = cleanedError.icon;
    }

    if (cleanedError.projectedContent) {
      this.projectedContent = cleanedError.projectedContent;
      return;
    }

    // turn error_descriptiopn array into a <p> separated string
    // TODO does the server actually return an error here?
    if (Array.isArray(cleanedError.error_description)) {
      cleanedError.error_description = Array.from(cleanedError.error_description, arrVal => `<p>${arrVal}</p>`).join('');
    }

    // Heuristic that tries to split sentences into paragraphs
    if (cleanedError.message) {
      const result = cleanedError.message.match(/([\S\s]+?)([.!?]\s|[.!?]$|$)/g);
      if (result) {
        cleanedError.message = Array.from(result, arrVal => `<p>${arrVal}</p>`).join('');
      }
    }

    this.cleanedText = this.text || cleanedError.message || cleanedError.error_description || "Sorry, an error occurred.";
  }

  close() {
    this.activeModal.close();
  }
}

function isWrappedError(value: any): value is WrappedError {
  return typeof value.data !== "undefined";
}
