import { HostBinding, Input, ViewChild, Directive } from '@angular/core';
import {NgForm} from '@angular/forms';

/**
 * Abstract class for wizard steps. This can be extends by components that want to
 * render as a step within a wizard.
 *
 * If your wizard step contains a form, your subclass must define the template variable:
 *  <form #stepForm="ngForm">
 * so that the Wizard can hook into its validity logic.
 *
 * If your wizard step doesn't contain any form logic, you don't need to extend this class.
 * Instead, just put your step content inside <wizard-step></wizard-step>.
 */
@Directive()
export abstract class WizardStep {
  @Input()
  name?: string;

  @Input()
  instructions?: string;

  @Input()
  mainInstructions?: [string, string];

  @Input()
  nextButtonText?: string;

  @Input()
  previousButtonText?: string;

  // Whether this step is currently the active step
  active: boolean;

  // Reference to this step's NgForm, if it has one. The parent Wizard uses this
  // property to determine whether the current step is valid.
  @ViewChild('stepForm', { static: true }) stepForm: NgForm;

  // Whether this page is currently being shown or hidden
  @HostBinding('hidden')
  public get hidden(): boolean {
    return !this.active;
  }
}
