import { SharedModule } from "../../index";
import { CommonModule } from "@angular/common";
import { downgradeComponent } from "@angular/upgrade/static";
import { NgModule } from "@angular/core";
import { CredentialsTable } from "./credentials.component.table";
import { CredentialsDetailsComponent } from "./dialogs/modal-credentials-details";
import { RegeneratedClientSecretComponent } from "./dialogs/regenerated-client-secret.component";
import { NewCredentialsWizardComponent } from "../new-credentials-wizard/new-credentials-wizard.component";
import { ReviewPermissionsStepComponent } from "../new-credentials-wizard/review-permissions-step.component";
import { ServiceCredentialSummaryComponent } from "./service-credential-summary/service-credential-summary.component";
import { CredentialsSectionComponent } from "./credentials-section.component";
import { UIRouterModule } from '@uirouter/angular';

@NgModule({
  declarations: [
    CredentialsTable,
    CredentialsSectionComponent,
    CredentialsDetailsComponent,
    NewCredentialsWizardComponent,
    RegeneratedClientSecretComponent,
    ReviewPermissionsStepComponent,
    ServiceCredentialSummaryComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    UIRouterModule,
  ],
  exports: [
    CredentialsTable,
    CredentialsSectionComponent,
    ServiceCredentialSummaryComponent,
    ReviewPermissionsStepComponent
  ],
  entryComponents: [
    CredentialsDetailsComponent,
    CredentialsSectionComponent,
    NewCredentialsWizardComponent,
    RegeneratedClientSecretComponent,
  ]
})
export class CredentialsListModule {
}

// required because gem-services details is still in AngularJS
require("./dialogs/modal-credentials-details");
export default require("./_init").default
  .directive('credentialsSection', downgradeComponent({ component: CredentialsSectionComponent }))
  .name;
