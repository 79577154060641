<div class="row">
    <div class="col form-group">
        <div class="details-panel">
            <div class="details-panel-section">
                <div class="row">
                    <div class="col-sm-4">
                        Service Name
                    </div>
                    <div class="col-sm-8 text-right">
                        <strong>{{serviceName}}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
