<ng-container *ngIf="!this.config.FF_ENABLE_ZUORA; else zuoraTemplate">
    <alert-with-icon *accountStatus="'subscriber'" [icon]="'#bang-triangle'" [class]="'alert-custom-warning'">
        Provisioning this service will count against your service elections and will be included on your invoice.
    </alert-with-icon>
    <form #stepForm="ngForm">
        <div class="card">
            <div class="card-block terms-of-service">
                <p *accountStatus="'evaluation'" class="card-text"
                   [innerHtml]="termsOfService | bypassSecurityTrustHtml"></p>
            </div>
        </div>
    </form>
</ng-container>
<ng-template #zuoraTemplate>
    <form #stepForm="ngForm">
        <div class="card">
            <div class="card-block terms-of-service">
                <p class="tos-header">{{getWizardTitle()}}</p>
                <p>{{getTosInfo()}}</p>
                <p *ngIf="hasNoSubscriptionOrActiveTrialSubscription()"
                   class="card-text" [innerHtml]="termsOfService | bypassSecurityTrustHtml"></p>
            </div>
        </div>
    </form>
</ng-template>
