import { Component, forwardRef, Input } from "@angular/core";
import { WizardStep } from "@app/components";
import { Office365CheckboxOptions, Office365ConfigurationOptions, Office365Subscription } from "../office365.common";

@Component({
  selector: "summary-step",
  templateUrl: "./summary.step.component.html",
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => SummaryStepOffice365Component)}
  ]
})
export class SummaryStepOffice365Component extends WizardStep {

  @Input() service;
  @Input() o365Config: Office365ConfigurationOptions;
  @Input() azureSubscription: Office365Subscription;

  getAzureSubscriptionDisplayName() {
    if (this.azureSubscription && this.azureSubscription.displayName) {
      return this.azureSubscription.displayName;
    }

    return "";
  }

  getResourceGroup(): string {
    if (this.o365Config.groupRadio === Office365CheckboxOptions.new) {
      return this.o365Config.newGroupName;
    }

    if (this.o365Config.selectedGroup && this.o365Config.selectedGroup.name) {
      return this.o365Config.selectedGroup.name;
    }

    return "";
  }

  getKeyVault(): string {
    return this.o365Config.formattedNewKeyVaultName;
  }

}
