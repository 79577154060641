<h5>{{ service.status | underscoreToSpace | titlecase }}</h5>
<p>
    {{ staticMessage }}
    <span *ngIf="isStatusFailed">
        <a class="support-link" href="https://supportportal.thalesgroup.com/csm" externalLink>Thales Customer Support</a> to raise a support ticket.
    </span>
</p>

<p *ngIf="service.statusMessage">
    {{ service.statusMessage }}
</p>

<div class="row no-gutters">
    <div class="float-sm-left">
        <button class="btn btn-next" name="cancel" type="button"
                (click)="self.dismiss()">Close</button>
    </div>
</div>
