// based off the following
// https://gitlab.gemalto.com/dpaas/shepherd/blob/master/src/main/resources/api.yaml

/**
 * POST body to create API credentials
 */
export interface ApiClientCreateParams {
  name: string;
  role: CredentialRole;
  subscriberGroups?: string[];
  serviceIds?: string[];
}

/**
 * PATCH body to update existing API credentials
 */
export interface APIClientUpdateParams {
  name: string;
}

/**
 * Response from a GET
 */
export interface ApiClient {
  id: string;
  clientId: string;
  createdAt: string;
  createdBy: string;
  name: string;
  role: CredentialRole;
  serviceIds: string[];
}

/**
 * Response from a resetSecret operation.
 */
export interface ApiClientSecret {
  clientSecret: string;
}

/**
 * Response from a POST to create the API creds, includes the client secret.
 */
export interface ApiClientWithSecret extends ApiClient, ApiClientSecret {
  }

/**
 * Synthetic convenience fields added by our data store layer.
 */
interface SyntheticFields {
  formattedCreatedAt: string;
  serviceName: string;
}

/** Model object returned by the data store */
export interface ApiClientUI extends ApiClient, SyntheticFields {}

export enum CredentialType {
  Platform = "Platform",
  Service = "Service",
  SPAdmin = "SPAdmin",
}

export enum CredentialRole {
  appowner = "appowner",
  service = "service",
  spadmin = 'spadmin',
}
