<div class="summary">

    <ngb-tabset type="pills">
        <ngb-tab title="Account">
            <ng-template ngbTabContent>
                <div class="table-summary-wrap">
                    <table class="table table-summary">
                        <thead>
                            <tr>
                                <th scope="col" class="type">Account Type</th>
                                <th scope="col" class="name">Tenant Account Name</th>
                                <th scope="col">Hostname</th>
                                <th scope="col" class="status">Account Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{accountType}}</td>
                                <td>{{tenant.name}}</td>
                                <td>{{tenant.hostname}}&#x200b;{{domainSuffix}}</td>
                                <td>{{tenant.accountStatus === 'active' ? "Active" : "Suspended"}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
        </ngb-tab>

        <ngb-tab title="Address">
            <ng-template ngbTabContent>
                <div class="table-summary-wrap">
                    <table class="table table-summary">
                        <tr>
                            <th scope="col" class="company-name">Company Name</th>
                            <th scope="col" class="department-name">Company Department</th>
                            <th scope="col" class="billing">Billing Address</th>
                        </tr>
                        <tr>
                            <td><optional [value]="tenant.companyName"></optional></td>
                            <td><optional [value]="tenant.departmentName"></optional></td>
                            <td><optional [value]="tenant.billingAddress.streetAddress"></optional></td>
                        </tr>
                    </table>

                    <table class="table table-summary mt-3">
                        <thead>
                            <tr>
                                <th scope="col" class="city">City</th>
                                <th scope="col" class="state">State / Province / Region</th>
                                <th scope="col" class="zip">ZIP / Postal Code</th>
                                <th scope="col" class="country">Country</th>
                            </tr>
                        </thead>
                        <tr>
                            <td><optional [value]="tenant.billingAddress.city"></optional></td>
                            <td><optional [value]="tenant.billingAddress.state"></optional></td>
                            <td><optional [value]="tenant.billingAddress.zip"></optional></td>
                            <td><optional [value]="tenant.billingAddress.country"></optional></td>
                        </tr>
                    </table>
                </div>
            </ng-template>
        </ngb-tab>

        <ngb-tab title="Admin User">
            <ng-template ngbTabContent>
                <div class="table-summary-wrap">
                    <table class="table table-summary">
                        <thead>
                            <tr>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col" class="email">Email Address</th>
                            </tr>
                        </thead>
                        <tr>
                            <td>{{tenant.adminGivenName}}</td>
                            <td>{{tenant.adminFamilyName}}</td>
                            <td><optional [value]="tenant.adminPhoneNumber"></optional></td>
                            <td>{{tenant.adminEmail}}</td>
                        </tr>
                    </table>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>
