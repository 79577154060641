import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { WizardStep } from '@app/components';
import { CredentialType } from '../../../features/credentials/credentials.interface';

@Component({
  selector: 'review-permissions-step',
  templateUrl: './review-permissions-step.component.html',
  styleUrls: ['./review-permissions-step.component.scss'],
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => ReviewPermissionsStepComponent)}
  ]
})
export class ReviewPermissionsStepComponent extends WizardStep {
  @Input() serviceName: string;
  @Input() permissions: string[];
  @Input() credentialsName = "";
  @Input() credentialType: CredentialType;

  @Output() credentialsNameChange = new EventEmitter<string>();
  @Output() reset = new EventEmitter<void>(); // emits when parent must reset the name to default value

  isServiceCredentials() {
    return this.credentialType === "Service";
  }

  checkName() {
    const field = this.stepForm.controls.credentialsNameField;
    if (!field.value.trim()) {
      this.reset.emit();
    }
  }
}
