import { Component, Input } from '@angular/core';
import { DialogService } from "@app/components";
import { GemToastComponent, GemToastService } from "@dpod/gem-ui-common-ng/toast";
import { Tile } from '@app/features/marketplace/tiles.interface';
import { TilesService } from '@app/shared/services';
import { Observable } from 'rxjs';
import { AgreementApprovalStatus, ServiceAgreementDetails, Tenant, TenantAccountStatus } from '../../tenant.model';
import { BackofficeService } from "@app/shared/services/backoffice.service";

@Component({
  selector: 'tenant-details-service-elections',
  templateUrl: './tenant-details-service-elections.component.html',
  styleUrls: [
    './tenant-details-service-elections.component.scss'
  ],
})
export class TenantDetailsServiceElectionsComponent {

  @Input() tenant: Tenant;
  @Input() tenantStatus: TenantAccountStatus;
  @Input() serviceAgreement: ServiceAgreementDetails;
  statusChangeMessage: 'Approving' | 'Rejecting' = null;
  tilesStream: Observable<Tile[]>;

  constructor(
    public toastService: GemToastService,
    public dialogService: DialogService,
    public tilesService: TilesService,
    public backOfficeService: BackofficeService,
  ) {
    this.tilesStream = tilesService.tiles();
  }

  // returns true when parent component passes in data
  // serviceAgreement is null if one has not been submitted
  hasServiceAgreementData(): boolean {
    return typeof this.serviceAgreement !== 'undefined';
  }

  // an error can occur where a tenant has requested approval or approved but we cannot retrieve the serviceAgreement
  // if the passed in serviceAgreement is null, it means a 404 has occurred which could mean:
  // no service agreement has been submitted
  // service agreement has been submitted according to tenant status but the actual service agreement is null
  errorHasOccurredWithData(): boolean {
    return (this.hasSubmittedServiceAgreement() || this.hasApprovedServiceAgreement()) && this.serviceAgreement === null;
  }

  hasNotSubmittedServiceAgreement(): boolean {
    return this.tenantStatus && this.tenantStatus.agreementApprovalStatus === AgreementApprovalStatus.notRequested;
  }

  hasSubmittedServiceAgreement(): boolean {
    return this.tenantStatus && this.tenantStatus.agreementApprovalStatus === AgreementApprovalStatus.requested;
  }

  hasApprovedServiceAgreement(): boolean {
    return this.tenantStatus && this.tenantStatus.agreementApprovalStatus === AgreementApprovalStatus.approved;
  }

  approveServiceAgreement(): void {
    this.statusChangeMessage = 'Approving';
    this.backOfficeService.approveServiceAgreement(this.tenant.id)
      .then(() => this.showNotification(`${this.tenant.name}'s initial service elections have been approved.`))
      .catch(error => {
        if (error.data.message.startsWith('Your account is not approved')) {
          error.data.title = 'Finalize your account setup';
          error.data.contact = 'If you contact support, please include the following incident ID.';
          error.data.icon = '#info-round';
        }

        return this.dialogService.error(error);

      })
      .finally(() => this.statusChangeMessage = null);
  }

  rejectServiceAgreement(): void {
    this.statusChangeMessage = 'Rejecting';
    this.backOfficeService.rejectServiceAgreement(this.tenant.id)
      .then(() => this.showNotification(`${this.tenant.name}'s initial service elections have been rejected. Please contact them to resubmit.`, 'no-icon'))
      .catch(error => this.dialogService.error(error))
      .finally(() => this.statusChangeMessage = null);
  }

  showNotification(msg: string, toastClass = "") {
    this.toastService.success(msg, GemToastComponent, toastClass);
  }

}
