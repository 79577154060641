export * from './spinner.component';
export * from './spinner.module';

// This is the AngularJS part of the module
import * as angular from 'angular';
import {SpinnerComponent} from './spinner.component';
import {downgradeComponent} from '@angular/upgrade/static';
export default angular.module('gem.spinner', [])
  .directive('spinner', downgradeComponent({component: SpinnerComponent}) as angular.IDirectiveFactory)
  .name;
