import { Component, Inject } from '@angular/core';
import { State, StateParams } from "@app/ajs-upgraded-providers";
import { DialogService } from "@app/components";
import { AvailableTerms } from '@app/features/eval-convert/eval-convert.constants';
import { EvalConvertSubmittedModalComponent } from "@app/features/eval-convert/submitted-modal/eval-convert-submitted.modal.component";
import { MBU, Plan, TenantParentInfo } from "@app/features/tenant/tenant.model";
import { TenantsService } from "@app/features/tenant/tenants.service";
import { TilesService } from '@app/shared/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tile } from '../marketplace/tiles.interface';
import { BackofficeService } from "@app/shared/services/backoffice.service";
import { ConfigToken } from "@dpod/gem-ui-common-ng";
import { DpodUiConfig } from "@app/core/dpod-ui-config";

@Component({
  selector: 'eval-convert-component',
  styleUrls: ['./eval-convert.scss'],
  templateUrl: './eval-convert.component.html',
})
export class EvalConvertComponent {
  tilesStream: Observable<Tile[]>;
  tileSelection: MBU[];
  planSelection: Plan;
  termSelection: number = AvailableTerms.THREE_YEARS;
  hideButtons = true;
  // parent name is used on the 'last slide' button
  parentName = "";
  step = 0;
  disableNextButton = false;
  selectedServiceType;
  servicePlans: Plan[];

  constructor(
    private tenantsService: TenantsService,
    @Inject(State) private $state: any,
    private dialogService: DialogService,
    private tilesService: TilesService,
    private backofficeService: BackofficeService,
    @Inject(ConfigToken) public config: DpodUiConfig,
    @Inject(StateParams) private $stateParams: any
  ) {
    this.tenantsService.getParentTenantInfo().subscribe((data: TenantParentInfo) => this.parentName = data.name);

    // For billing/backoffice purposes, we only deal with provisionable tiles,
    // not redirection tiles
    this.tilesStream = tilesService.tiles().pipe(map(tiles => tiles.filter(isProvisionable)));

    // show buttons after fetching tiles
    this.tilesStream.subscribe(() => this.hideButtons = false);

    this.selectedServiceType = $stateParams.serviceType;
    this.servicePlans = $stateParams.servicePlans;
  }

  finish() {
    this.disableNextButton = true;
    // after service submission, return to previous page regardless of success or failure
    return this.backofficeService.submitServiceAgreement({
      mbus: this.tileSelection,
      duration: this.termSelection,
    }).then(() => {
      const modal = this.dialogService.open(EvalConvertSubmittedModalComponent);
      const modalInstance = modal.componentInstance as EvalConvertSubmittedModalComponent;
      modalInstance.parentName = this.parentName;
      modalInstance.close.subscribe(() => modal.close());
    }).finally(() => this.$state.go(this.$state.previous.name));
  }

  tilesChanged(tiles: MBU[]) {
    this.tileSelection = tiles;
  }

  planChanged(plan: Plan) {
    this.planSelection = plan;
    if (this.config.FF_ENABLE_ZUORA) {
      this.disableNextButton = !this.planSelection;
    }
  }

  termSelectionChanged(term: number) {
    this.termSelection = term;
  }

  // the reason we want to have the step is because the <embed> PDF stops displaying when you go back and forth between the last step
  // therefore we only display the PDF once the user has gone to the last step
  stepChange(step: number) {
    this.step = step;
  }

}

function isProvisionable(t: Tile): boolean {
  return typeof t.redirectionUrl !== 'string';
}
