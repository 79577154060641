<div *ngIf="isEmpty() && isSameSubscriberGroup()">
    <!-- App Owners see this special state instead of an empty table-->
    <div class="empty-state-container">
        <div class="empty-state-header">
            Add a client to your service
        </div>
        <div class="empty-state-text">
            Create a new service client to get started. Follow the process to install your client and initialize your service.
            <a class="empty-state-help"
                href="{{ getHelpLink() | docLink }}" externalLink>Need help?</a>
        </div>
        <div>
            <img class="empty-state-img" src="images/clientEmptyState.svg" alt="envelope icon" role="presentation">
        </div>
        <div class="empty-state-btn-area">
            <button class="btn btn-next empty-state-btn" type="button"
                (click)="create.emit()">Create Service Client
            </button>
        </div>
    </div>
</div>

<dpod-table *ngIf="!isEmpty() || !isAppOwner()" (filter)="onFilter($event)"
            [description]="'Create a Service Client to securely connect to a service.'"
            [dataLength]="dataSource.data.length">
    <div class="float-right mr-2">
        <button class="btn btn-next float-right mr-2" type="button"
            *ngIf="isSameSubscriberGroup()"
            (click)="create.emit()" data-locator="new-client">New Service Client
        </button>
    </div>

    <table #table mat-table matSort [dataSource]="dataSource">
        <caption class="d-none">List of clients</caption>

        <!-- Name column -->
        <ng-container matColumnDef="name">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let client" class="name-column pr-2">
                {{ client.name }}
            </td>
        </ng-container>

        <!-- Created By column -->
        <ng-container matColumnDef="createdBy">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
            <td mat-cell *matCellDef="let client" class="created-by-column">
                {{ client.created_by_username }}
            </td>
        </ng-container>

        <!-- Created column -->
        <ng-container matColumnDef="createdAt">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
            <td mat-cell *matCellDef="let client" class="created-at-column">
                {{ client.formattedCreatedAt }}
            </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let client" class="actions-column">
                <div class="action-cell" [attr.data-group-name]="client.name">
                    <div class="icon-inside-icon">
                        <span class="grid-action" (click)="delete.emit(client.name)" data-locator="delete-client">
                            <svg class="icon-svg outer-icon">
                                <use xlink:href="#action-box" />
                            </svg>
                            <svg class="icon-svg inner-icon">
                                <use xlink:href="#trash" />
                            </svg>
                        </span>
                    </div>
                </div>
            </td>
        </ng-container>

        <!-- Rows definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let client; columns: displayedColumns;" [attr.data-client-name]="client.name">
        </tr>
    </table>
</dpod-table>
