"use strict";
import wizardBaseModule from "@app/components/wizard";
import dialogsModule from "@app/components/gem-dialogs";
import blacklist from "@app/components/blacklist";
import angularHaro from "@app/components/angular-haro";
import validationDebounce from "@app/components/validation-debounce";
import alertWithIconModule from "@app/components/alert-with-icon";
import detailsModule from './details';

import { HSMService } from './cloudHSM.service';
import { downgradeInjectable } from "@angular/upgrade/static";

export default angular.module("app.gem-services.cloudHSM", [
    detailsModule, wizardBaseModule, dialogsModule, blacklist, angularHaro, validationDebounce, alertWithIconModule
])
.service("VHsmApiService", downgradeInjectable(HSMService));
