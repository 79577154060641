import {NgModule} from "@angular/core";
import {SharedModule} from "@app/shared";
import {CommonModule} from "@angular/common";
import {TermsOfServiceStepComponent} from "./terms-of-service.step.component";

@NgModule({
  declarations: [
    TermsOfServiceStepComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    TermsOfServiceStepComponent,
  ],
  entryComponents: [
    TermsOfServiceStepComponent
  ]
})
export class TermsOfServiceModule {
}
