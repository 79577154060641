import { Component, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ServiceStatus } from "@app/features/gem-services/services.constants";

@Component({
  selector: 'async-service-dialog',
  templateUrl: './async-service-dialog.component.html',
})
export class AsyncServiceDialogComponent {
  @Input() status: string;

  constructor(public self: NgbActiveModal) {}

  getStatusMessage(state: string): string {
    switch (state) {
      case ServiceStatus.Provisioning:
        return ServiceStatus.Provisioned.toLowerCase();
      case ServiceStatus.Deprovisioning:
        return ServiceStatus.Deprovisioned.toLowerCase();
      default:
        return ''; // not implemented
    }
  }
}
