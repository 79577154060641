import { ConfigureStepComponent } from "./configure.step.component";
import { ServicesWizardComponent } from "./cloudHSM-wizard.component";
import { SummaryStepComponent } from "./summary.step.component";
import { SharedModule } from "@app/shared";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TermsOfServiceModule } from "./common/terms-of-service";

@NgModule({
  declarations: [
    ConfigureStepComponent,
    SummaryStepComponent,
    ServicesWizardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TermsOfServiceModule,
  ],
  entryComponents: [ServicesWizardComponent,]
})
export class HSMonDemandWizard {
}
