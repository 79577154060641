<h5 class="mb-3">
    <svg class="icon-svg">
        <use xlink:href="#restore" />
    </svg>
    Restore Key?
</h5>


<div class="row">
    <div class="col-sm-12">
        <p>
            This key will become active, and the VM will be granted access to the volume encrypted with this key.
        </p>
        <p>
            Do you wish to continue?
        </p>
    </div>
</div>

<div class="row no-gutters">
    <button class="btn btn-next" data-locator="revoke-key" (click)="restore.emit({ key: key })">Restore Key</button>
    <button class="btn btn-default" data-locator="cancel" (click)="close.emit()">Cancel</button>
</div>
