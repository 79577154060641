import { Component, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'subscribers-edit',
  template: '',
})
export class SubscriberGroupEditComponent extends UpgradeComponent {

  @Input() id: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super("subscribersEdit", elementRef, injector);
  }

}
