import { Directive, EmbeddedViewRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { EvaluationStatus, TenantAccountStatus } from '@app/features/tenant/tenant.model';
import { AccountStatusService } from '@app/shared/services/account-status.service';
import { Subscription } from 'rxjs';

type AccountStatusExpression = 'evaluation' | 'subscriber' | '!evaluation' | '!subscriber';

/**
 * Structural directive that creates or removes a view according to the `TenantAccountStatus`
 * of the tenant that the user is currently logged into. This directive can be used to hide or
 * show content from Evaluation or Subscriber tenants, for example.
 *
 * A leading `!` can be used for negation: this is useful when authoring content to be shown
 * to "non-paying" types of tenant such as SP tenants and the operator.
 *
 * Example:
 * ```html
 * <div *accountStatus="'evaluation'"> content shown to trial tenants only </div>
 * <div *accountStatus="'subscriber'"> content shown to paying subscribers only </div>
 * <div *accountStatus="'!subscriber'"> content shown to non-subscriber tenants only
 * (e.g. evaluation, SP tenant, operator)</div>
 * ````
 */
@Directive({
  selector: '[accountStatus]',
})
export class AccountStatusDirective implements OnInit {
  private viewRef: EmbeddedViewRef<any>;
  private sub: Subscription;
  private expression: AccountStatusExpression;
  private status: TenantAccountStatus;

  constructor(
    private accountStatusService: AccountStatusService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) { }

  ngOnInit() {
    this.sub = this.accountStatusService.getAccountStatus()
    .subscribe(status => {
      this.status = status;
      this.updateView();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  @Input()
  set accountStatus(expression: AccountStatusExpression) {
    this.expression = expression;
    this.updateView();
  }

  updateView() {
    this.viewContainer.clear();
    if (this.statusMatches()) {
      this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewRef = null;
    }
  }

  statusMatches(): boolean {
    if (!this.status) {
      return false;
    }

    let operator = opEqual;
    let rest: AccountStatusExpression = this.expression;
    if (this.expression[0] === '!') {
      operator = opNotEqual;
      rest = this.expression.substr(1) as AccountStatusExpression;
    }

    switch (rest) {
      case 'subscriber':
        return operator(this.status.evaluationStatus, EvaluationStatus.inAgreement, EvaluationStatus.agreementEnded);
      case 'evaluation':
        return operator(this.status.evaluationStatus, EvaluationStatus.evaluating, EvaluationStatus.expired);
    }
    throw new Error(`Not handled: ${this.expression}`);
  }

}

function opEqual(a: string, ...b: string[]) { return !!b.find(item => item === a) }
function opNotEqual(a: string, ...b: string[]) { return b.every(item => item !== a) }
