import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { IHttpResponse } from "angular";
import { WizardStep } from "@app/components";
import {
  Office365CheckboxOptions,
  Office365ConfigurationOptions,
  Office365KeyVault,
  office365KeyVaultRegex,
  Office365Location,
  Office365LocationsResponse,
  Office365ResourceGroup,
  office365ResourceGroupRegex,
  Office365ResourceGroupsResponse,
  Office365Subscription
} from "../office365.common";
import { Office365Service } from "../office365.service";

@Component({
  selector: "azure-details-step",
  styleUrls: ["azure-details.step.component.scss"],
  templateUrl: "./azure-details.step.component.html",
  providers: [
    {provide: WizardStep, useExisting: forwardRef(() => AzureDetailsStepComponent)}
  ]
})
export class AzureDetailsStepComponent extends WizardStep implements OnInit, OnChanges {

  @Input() o365Config: Office365ConfigurationOptions = {
    groupRadio: Office365CheckboxOptions.new,
    keyVaultRadio: Office365CheckboxOptions.new,
    newKeyVaultName: "",
    formattedNewKeyVaultName: "",
    newGroupName: "",
    selectedGroup: null,
    location: null
  };
  @Input() azureSubscription: Office365Subscription;
  @Input() accessToken: string;
  locationsList: Office365Location[] = [];
  keyVaultsList: Office365KeyVault[] = [];
  existingResourceGroups: Office365ResourceGroup[] = [];
  newKeyVaultCheckbox: boolean; // on init it is undefined, but toggles between true/false
  resourceGroupRegex: RegExp = office365ResourceGroupRegex;
  keyVaultPrefix = "";
  checkboxOpts = Office365CheckboxOptions;

  constructor(private Office365Service: Office365Service) {
    super();
  }

  ngOnInit() {
    this.o365Config.location = null; // fixes the default dropdown value for location
    this.generateRandomKeyVaultPrefix();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.azureSubscription && changes.azureSubscription.currentValue) {
      const azureSubscription: Office365Subscription = changes.azureSubscription.currentValue;
      this.listLocations(azureSubscription.subscriptionId, this.accessToken);
      this.listResourceGroups(azureSubscription.subscriptionId, this.accessToken);
    }

  }

  checkKeyVaultValidation(keyVaultName: string): boolean {
    let passedValidation = true;
    let error = {"pattern": true};

    const kvRegex: RegExp = new RegExp(office365KeyVaultRegex);
    const kvRegexMatches: boolean = kvRegex.test(keyVaultName);

    if (!kvRegexMatches) {
      passedValidation = false;
    }

    if (passedValidation) {
      // can't have consecutive dashes or have last character as dash
      passedValidation = !(/--|-$/.test(keyVaultName));
    }

    if (passedValidation) {
      error = null;
      this.transformKeyVaultName();
    } else {
      this.o365Config.formattedNewKeyVaultName = "";
    }

    this.stepForm.controls.newKeyVaultName.setErrors(error);
    return error === null;
  }

  // to avoid collisions in Azure we prefix the key vault name with `dpod_{randomHash}_`
  transformKeyVaultName(): void {
    const {newKeyVaultName} = this.o365Config;
    // the max length of a key vault name is 24 characters.  So with our randomization that leaves 12 characters
    this.o365Config.formattedNewKeyVaultName = `${this.keyVaultPrefix}${newKeyVaultName}`;
  }

  generateRandomKeyVaultPrefix(): string {
    const prefix = "dpod";
    // generate 6 character hash
    const randomHash = (Math.random() + 1).toString(36).substring(4).substring(0, 6);
    this.keyVaultPrefix = `${prefix}-${randomHash}-`;
    return this.keyVaultPrefix;
  }

  // if group is changed to new, empty out the selected dropdown vault
  groupChanged(val: Office365CheckboxOptions): void {
    if (val === Office365CheckboxOptions.new) {
      this.o365Config.selectedGroup = null;
      this.o365Config.keyVaultRadio = Office365CheckboxOptions.new;
    }
  }

  listLocations(subscriptionId: string, accessToken: string): void {
    this.Office365Service.listLocations(subscriptionId, accessToken)
      .then((listLocationsResponse: IHttpResponse<Office365LocationsResponse>) => this.locationsList = listLocationsResponse.data.value);
  }

  listResourceGroups(subscriptionId: string, accessToken): void {
    this.Office365Service.listResourceGroups(subscriptionId, accessToken)
      .then((resourceGroupResponse: IHttpResponse<Office365ResourceGroupsResponse>) => {
        this.o365Config.selectedGroup = null;
        // if no resource groups exist, `value` will be undefined, therefore we only update the `existingResourceGroups`
        // if value returns an array
        if (resourceGroupResponse.data.value) {
          this.existingResourceGroups = resourceGroupResponse.data.value;
        }
      });
  }

}
